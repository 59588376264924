import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import SignIn from "./screens/SignInScreen";
import SignUp from "./screens/SignUpScreen";
import UserVerification from "./screens/UserVerificationScreen";
import ForgotPassword from "./screens/ForgotPasswordScreen";
import ForgotPasswordSubmit from "./screens/ForgotPasswordSubmitScreen";
import MyAccount from "./screens/MyAccountScreen";
import ChangePassword from "./screens/ChangePasswordScreen";
import SelectSite from "./screens/SelectSite";
import RegisterSite from "./screens/RegisterSiteScreen";
import EditSite from "./screens/EditSiteScreen";
import SiteOverview from "./screens/SiteOverviewScreen";
import EditCompany from "./screens/EditCompanyScreen";
import SiteDataExport from "./screens/SiteDataExportScreen";
import SelectDevice from "./screens/SelectDeviceScreen";
import RegisterDevice from "./screens/RegisterDeviceScreen";
import EditDevice from "./screens/EditDeviceScreen";
import UserList from "./screens/UserListScreen";
import UserDetails from "./screens/UserDetailsScreen";
import EditUser from "./screens/EditUserScreen";
import EditNonRegisteredUser from "./screens/EditNonRegisteredUserScreen";
import TeamMemberRegistration from "./screens/TeamMemberRegistrationScreen";
import RegisterUser from "./screens/RegisterUserScreen";
import ScrollToTop from "./components/ScrollToTop";
import SiteQrCode from "./screens/SiteQrCodeScreen";
import RegisterUserBulk from "./screens/RegisterUserBulkScreen";
import RegisterUserBulkConfirm from "./screens/RegisterUserBulkConfirmScreen";
import VendorList from "./screens/VendorListScreen";
import RegisterVendor from "./screens/RegisterVendorScreen";
import RegisterVendorBulk from "./screens/RegisterVendorBulkScreen";
import RegisterVendorBulkConfirm from "./screens/RegisterVendorBulkConfirmScreen";
import CompanyUserList from "./screens/CompanyUserListScreen";
import RegisterCompanyUserBulk from "./screens/RegisterCompanyUserBulkScreen";
import RegisterCompanyUserBulkConfirm from "./screens/RegisterCompanyUserBulkConfirmScreen";
import ManageData from "./screens/ManageDataScreen";
import "./fonts/KintoSans-Regular.ttf";
import "./fonts/meiryo.ttf";

Amplify.configure(awsExports);

function App() {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up/*" element={<SignUp />} />
          <Route path="/user-verification" element={<UserVerification />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/forgot-password-submit"
            element={<ForgotPasswordSubmit />}
          />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/select-site" element={<SelectSite />} />
          <Route path="/register-site" element={<RegisterSite />} />
          <Route path="/edit-site" element={<EditSite />} />
          <Route path="/site-overview" element={<SiteOverview />} />
          <Route path="/edit-company" element={<EditCompany />} />
          <Route path="/site-data-export" element={<SiteDataExport />} />
          <Route path="/select-device" element={<SelectDevice />} />
          <Route path="/register-device" element={<RegisterDevice />} />
          <Route path="/edit-device" element={<EditDevice />} />
          <Route path="/user-list" element={<UserList />} />
          <Route path="/user-details" element={<UserDetails />} />
          <Route path="/edit-user" element={<EditUser />} />
          <Route
            path="/edit-non-registered-user"
            element={<EditNonRegisteredUser />}
          />
          <Route
            path="/team-member-registration"
            element={<TeamMemberRegistration />}
          />
          <Route path="/register-user" element={<RegisterUser />} />
          <Route path="/site-qr-code" element={<SiteQrCode />} />
          <Route path="/register-user-bulk" element={<RegisterUserBulk />} />
          <Route
            path="/register-user-bulk-confirm"
            element={<RegisterUserBulkConfirm />}
          />
          <Route path="/vendor-list" element={<VendorList />} />
          <Route path="/register-vendor" element={<RegisterVendor />} />
          <Route
            path="/register-vendor-bulk"
            element={<RegisterVendorBulk />}
          />
          <Route
            path="/register-vendor-bulk-confirm"
            element={<RegisterVendorBulkConfirm />}
          />
          <Route path="/company-user-list" element={<CompanyUserList />} />
          <Route
            path="/register-company-user-bulk"
            element={<RegisterCompanyUserBulk />}
          />
          <Route
            path="/register-company-user-bulk-confirm"
            element={<RegisterCompanyUserBulkConfirm />}
          />
          <Route path="/manage-data" element={<ManageData />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
