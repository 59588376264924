import { API, graphqlOperation } from "aws-amplify";
import { listUsers } from "../../graphql/queries";

export default async function fetchUsers(filter = null) {
  try {
    const userList = [];
    let response = await API.graphql(
      graphqlOperation(listUsers, {
        filter,
        limit: 100,
      })
    );

    userList.push(...response.data.listUsers.items);
    while (response.data.listUsers.nextToken !== null) {
      response = await API.graphql(
        graphqlOperation(listUsers, {
          filter: filter,
          limit: 100,
          nextToken: response.data.listUsers.nextToken,
        })
      );
      userList.push(...response.data.listUsers.items);
    }
    return [...userList];
  } catch (error) {
    console.log(error);
  }
}
