import * as React from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUser, getCompany, getSiteDataExport } from "../graphql/queries";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";
import fetchSiteRoles from "../components/queries/FetchSiteRoles";
import fetchSiteLogHistoryBySiteLogIdWithSiteLog from "../components/queries/FetchSiteLogHistoryBySiteLogIdWithSiteLog";
import fetchSiteLogs from "../components/queries/FetchSiteLogs";
import fetchVendors from "../components/queries/FetchVendors";
import { useNavigate } from "react-router-dom";
import SiteDataExportTable from "../components/SiteDataExportTable";
import NavigationDrawer from "../components/NavigationDrawer";
import LoadingProgress from "../components/LoadingProgress";
import { DEFAULT_ROLE_LEVEL } from "../components/utils/constants";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ja } from "date-fns/locale";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import fetchSiteClearances from "../components/queries/FetchSiteClearances";
import fetchLatestSiteLogHistoryBySiteLogIds from "../components/queries/FetchLatestSiteLogHistoryBySiteLogIds";

const drawerWidth = 280;
const RELEASE_DATE_OF_ENTER_EXIT_HISTORY = new Date("2023-10-18");

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      searchWords: "",
      companyDetails: "",
      dateStart: new Date(
        new Date().toLocaleString("ja-JP", { timeZone: "Japan" })
      ),
      dateEnd: new Date(
        new Date().toLocaleString("ja-JP", { timeZone: "Japan" })
      ),
      itemList: "",
      isLoading: false,
      mobileOpen: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDateStartChange = this.handleDateStartChange.bind(this);
    this.handleDateEndChange = this.handleDateEndChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    this.fetchItems();
  }

  handleDateStartChange(event) {
    this.setState({
      dateStart: event,
    });

    this.fetchItems();
  }

  handleDateEndChange(event) {
    this.setState({
      dateEnd: event,
    });

    this.fetchItems();
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  handleDrawerToggle(event) {
    const target = event.target;
    this.setState({
      mobileOpen: !this.state.mobileOpen,
    });
  }

  async componentDidMount() {
    this.fetchItems();
  }

  getDateRanges(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const ranges = [];
    // eslint-disable-next-line no-unmodified-loop-condition
    while (start < end) {
      const rangeStart = new Date(start);
      const rangeEnd = new Date(start);
      rangeEnd.setDate(rangeEnd.getDate() + 5);
      if (rangeEnd > end) {
        rangeEnd.setTime(end.getTime());
      }
      ranges.push({ start: rangeStart, end: new Date(rangeEnd) });
      start.setDate(start.getDate() + 5);
      start.setDate(start.getDate() - 1);
    }
    return ranges;
  }

  aggregateResponseData(responses) {
    const processedItems = [];
    const unprocessedItems = [];
    for (const response of responses) {
      const rangeItemList = JSON.parse(response.data.getSiteDataExport.body);
      for (const item of rangeItemList) {
        const itemUsedAtDate = new Date(item.usedAt);
        if (itemUsedAtDate < RELEASE_DATE_OF_ENTER_EXIT_HISTORY) {
          if (item.latestEnteredUsedAtFixed && item.latestExitedUsedAtExited) {
            item.usedAt = item.usedAtFixed;
            item.exitedAt = item.exitedAtFixed;
          }
          if (item.usedAtFixed) {
            item.usedAt = item.usedAtFixed;
          }
          if (item.exitedAtFixed) {
            item.exitedAt = item.exitedAtFixed;
          }
          processedItems.push(item);
        } else {
          unprocessedItems.push(item);
        }
      }
    }
    return [processedItems, unprocessedItems];
  }

  deleteMismatchExitTimeData(list) {
    list = list.filter((item) => {
      if (item.usedAt && item.exitedAt) {
        return (
          new Date(item.usedAt) < new Date(item.exitedAt) &&
          item.usedAt !== item.exitedAt
        );
      }
      return true;
    });

    return list;
  }

  modifyExitedStatus(list) {
    list = list
      .filter((item) => item.usedAt)
      .map((item) => {
        if (!item.exitedAt) {
          item.exitedAt = "";
        }
        if (!("exited" in item)) {
          item.exited = false;
        }
        if (item.usedAt && !item.exitedAt && item.exited) {
          return {
            ...item,
            exited: false,
          };
        } else {
          return item;
        }
      });

    return list;
  }

  deleteUsedAtIsOtherExitedAtData(list) {
    const exitedAtMap = new Map();
    list.forEach((item) => {
      if (item.exitedAt) {
        exitedAtMap.set(item.exitedAt, true);
      }
    });
    list = list.filter((item) => {
      if (item.usedAt && exitedAtMap.has(item.usedAt)) {
        return false;
      }
      return true;
    });

    return list;
  }

  mergeLatestEnteredAndExitedData(list) {
    const enteredSiteLogIdToSiteLogIdMap = list.reduce((acc, curr) => {
      acc[curr.enteredSiteLogId] = curr.siteLogId;
      return acc;
    }, {});
    const mergedItemList = Object.values(
      list.reduce((acc, curr) => {
        const key = enteredSiteLogIdToSiteLogIdMap[curr.siteLogId]
          ? enteredSiteLogIdToSiteLogIdMap[curr.siteLogId]
          : curr.siteLogId;
        if (!acc[key]) {
          acc[key] = curr;
        } else {
          acc[key] = this.mergeItems(acc[key], curr);
        }
        return acc;
      }, {})
    );

    return mergedItemList;
  }

  mergeItems(item1, item2) {
    const mergeFields = [
      "latestEnteredCreatedAt",
      "latestExitedCreatedAt",
      "latestEnteredEditor",
      "latestExitedEditor",
      "latestEnteredEmail",
      "latestExitedEmail",
      "latestEnteredPreUsedAt",
      "latestExitedPreUsedAt",
      "latestEnteredUsedAt",
      "latestExitedUsedAt",
    ];
    mergeFields.forEach((field) => {
      if (item2[field] !== "") {
        item1[field] = item2[field];
      }
    });

    return item1;
  }

  deleteDuplicatedData(list) {
    const seen1 = new Map();
    const seen2 = new Map();
    list.sort((a, b) => {
      if (a.latestEnteredUsedAt && b.latestEnteredUsedAt) {
        return (
          new Date(b.latestEnteredUsedAt) - new Date(a.latestEnteredUsedAt)
        );
      } else if (a.latestExitedUsedAt && b.latestExitedUsedAt) {
        return new Date(b.latestExitedUsedAt) - new Date(a.latestExitedUsedAt);
      } else {
        return b.latestEnteredUsedAt || b.latestExitedUsedAt ? 1 : -1;
      }
    });
    list = list.filter((item) => {
      const duplicateKey1 = `${item.usedAt}-${item.exitedAt}-${item.latestExitedCreatedAt}`;
      const duplicateKey2 = `${item.usedAt}-${item.familyName}`;
      if (seen1.has(duplicateKey1) || seen2.has(duplicateKey2)) {
        return false;
      } else {
        seen1.set(duplicateKey1, true);
        seen2.set(duplicateKey2, true);
        return true;
      }
    });

    return list;
  }

  deleteCreateEnteredHavingEnteredSiteIdData(list) {
    list = list.filter((item) => {
      if (
        item.action === "create" &&
        item.authType === "入場" &&
        item.enteredSiteLogId &&
        item.id === item.siteLogId &&
        item.exitedAt === ""
      ) {
        return false;
      }
      return true;
    });

    return list;
  }

  modifyAttachedComma(list) {
    return list.join("、");
  }

  modifySiteOccupation(list) {
    list = list.map((item) => {
      try {
        return {
          ...item,
          siteOccupation: this.modifyAttachedComma(item.siteOccupation),
        };
      } catch (error) {
        return {
          ...item,
          siteOccupation: "",
        };
      }
    });

    return list;
  }

  createItemList(responses) {
    const [processedItems, unprocessedItems] =
      this.aggregateResponseData(responses);

    let itemList = this.deleteMismatchExitTimeData(processedItems);
    itemList = this.modifyExitedStatus(itemList);
    itemList = this.deleteUsedAtIsOtherExitedAtData(itemList);
    itemList = this.mergeLatestEnteredAndExitedData(itemList);
    itemList = this.deleteDuplicatedData(itemList);
    itemList = this.deleteCreateEnteredHavingEnteredSiteIdData(itemList);
    itemList = this.modifySiteOccupation(itemList);
    itemList = itemList.concat(unprocessedItems);
    itemList = this.deleteDuplicatedData(itemList);
    itemList.sort((a, b) => new Date(b.usedAt) - new Date(a.usedAt));

    return itemList;
  }

  async fetchItems() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });

      const dateStartDayBefore = new Date(
        new Date(
          new Date(this.state.dateStart).toLocaleString("ja-JP", {
            timeZone: "Japan",
          })
        ).setHours(9, 0, 0, 0)
      );

      const dateEndFilter = new Date(
        new Date(
          new Date(this.state.dateEnd).toLocaleString("ja-JP", {
            timeZone: "Japan",
          })
        ).setHours(9, 0, 0, 0)
      );
      dateEndFilter.setDate(dateEndFilter.getDate() + 1);

      const filterSiteLogs = {
        and: [
          {
            siteCode: {
              eq: this.state.userDetails.currentSiteId,
            },
          },
          {
            usedAt: {
              between: [dateStartDayBefore, dateEndFilter],
            },
          },
          {
            authType: {
              eq: "入場",
            },
          },
        ],
      };

      const responseSiteLogs = await fetchSiteLogs(filterSiteLogs);
      const filterCompanyRoles = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      };
      const [companyRoles] = await fetchCompanyRoles(filterCompanyRoles);
      let roleLevel;
      let siteRoleList;
      if (companyRoles.roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = companyRoles.roleLevel;
      } else {
        const filterSiteRole = {
          and: [
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };
        const siteRoles = await fetchSiteRoles(filterSiteRole);
        siteRoleList = siteRoles;
        roleLevel = siteRoles[0].roleLevel;
      }

      const filterSiteList = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            id: {
              eq: this.state.userDetails.currentSiteId,
            },
          },
        ],
      };
      const dateRanges = this.getDateRanges(dateStartDayBefore, dateEndFilter);
      const promises = dateRanges.map((range) => {
        return API.graphql(
          graphqlOperation(getSiteDataExport, {
            siteCode: this.state.userDetails.currentSiteId,
            startDate: range.start.toISOString(),
            endDate: range.end.toISOString(),
          })
        );
      });
      const responses = await Promise.all(promises);
      const itemList = this.createItemList(responses);

      this.setState({
        itemList: itemList,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  render() {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: "#F9F9F9",
            borderBottom: 1,
            borderColor: "#E5E5E5",
          }}
          elevation={0}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              onClick={() => this.props.navigate("/site-overview")}
              sx={{
                mr: 2,
                pr: 2,
                pl: 1,
                borderRadius: 0,
                borderRight: 1,
                height: 64,
                borderColor: "#E5E5E5",
                color: "#383838",
              }}
            >
              <img
                src={require("../assets/images/leftarrow-on.png")}
                width="16"
                height="16"
              />
            </IconButton>
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontFamily: "Regular",
                fontSize: 21,
                fontWeight: "700",
                color: "#383838",
                flexGrow: 1,
              }}
            >
              {this.state.userDetails.currentSiteName}：出面表を出力
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <div>
              <Toolbar />
              <List>
                {["作業中現場一覧"].map((text, index) => (
                  <ListItem key={text} disablePadding>
                    <ListItemButton>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </div>
          </Drawer>
          <NavigationDrawer topIndex={1} bottomIndex={null} />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#F9F9F9",
            height: "100%",
            minHeight: "100vh",
            p: 3,
          }}
        >
          <LoadingProgress isLoading={this.state.isLoading} />
          <Grid item xs={12} mb={2}>
            <Toolbar />
          </Grid>

          <LocalizationProvider locale={ja} dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="開始日"
              // name='dateInput'
              inputFormat="MM月dd日"
              value={this.state.dateStart}
              onChange={this.handleDateStartChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    input: { fontFamily: "Regular", padding: "10px 14px" },
                    width: "150px",
                    mr: 2,
                    mb: 1,
                  }}
                />
              )}
            />
            <DesktopDatePicker
              label="終了日"
              // name='dateInput'
              inputFormat="MM月dd日"
              value={this.state.dateEnd}
              minDate={this.state.dateStart}
              onChange={this.handleDateEndChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    input: { fontFamily: "Regular", padding: "10px 14px" },
                    width: "150px",
                    mb: 1,
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <SiteDataExportTable
            rows={this.state.itemList}
            userDetails={this.state.userDetails}
            siteName={this.state.userDetails.currentSiteName}
            dateStart={this.state.dateStart}
            dateEnd={this.state.dateEnd}
          />
        </Box>
      </Box>
    );
  }
}

function SiteDataExportScreen(props) {
  const navigate = useNavigate();
  return <Input {...props} navigate={navigate} />;
}

export default SiteDataExportScreen;
