import * as React from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUser, getSite, getCompany, listUsers } from "../graphql/queries";
import { createSiteClearanceWithInvitation } from "../graphql/mutations";
import { useNavigate, useLocation } from "react-router-dom";
import NavigationDrawer from "../components/NavigationDrawer";
import LoadingProgress from "../components/LoadingProgress";
import RegisterUserBulkTable from "../components/RegisterUserBulkTable";
import fetchSiteClearances from "../components/queries/FetchSiteClearances";
import { ValidationSnackBar } from "../components/ValidationSnackBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { v4 as uuidv4 } from "uuid";
import { sha256 } from "crypto-hash";
import { format } from "date-fns";

const drawerWidth = 280;

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: this.props.location.state.userList,
      userDetails: "",
      siteDetails: "",
      companyDetails: "",
      isLoading: false,
      snackbar: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  validationHasChiefInDb = async () => {
    const filter = {
      and: [
        {
          siteCode: {
            eq: this.state.userDetails.currentSiteId,
          },
        },
        {
          isChief: {
            eq: true,
          },
        },
      ],
    };
    const resSiteClearances = await fetchSiteClearances(filter);
    if (
      resSiteClearances.length > 0 &&
      this.state.userList.some((user) => user.isChief === "true")
    ) {
      throw new Error("すでに所長・工事担当者が設定済みです");
    }
  };

  handleCreateSiteClearanceWithInvitation = async (i, userInfo) => {
    const newId = uuidv4();
    const hashResult = await sha256(
      "siteCode=" +
        this.state.userDetails.currentSiteId +
        "&siteName=" +
        this.state.userDetails.currentSiteName
    );
    const input = {
      id: newId,
      siteCode: this.state.userDetails.currentSiteId,
      siteName: this.state.userDetails.currentSiteName,
      siteAddress: this.state.siteDetails.address1,
      isEnteredBasicInfo: false,
      applyStatus: "招待中",
      startDate: this.state.userList[i].startDate,
      endDate: this.state.userList[i].endDate,
      notesFileUrl: this.state.siteDetails.notesFileUrl,
      familyName: this.state.userList[i].familyName,
      firstName: this.state.userList[i].firstName,
      familyNameKana: this.state.userList[i].familyNameKana,
      firstNameKana: this.state.userList[i].firstNameKana,
      email: this.state.userList[i].email,
      companyName: this.state.userList[i].companyName,
      companyId: userInfo.attributes["custom:company_id"],
      userId: userInfo.attributes.sub,
      invitationCode: newId,
      qrCodeDescription:
        "siteCode=" +
        this.state.userDetails.currentSiteId +
        "&sha256=" +
        hashResult,
      gender: this.state.userList[i].gender,
      zipCode: this.state.userList[i].zipCode,
      address1: this.state.userList[i].address1,
      address2: this.state.userList[i].address2,
      address3: this.state.userList[i].address3,
      mobileNumber: this.state.userList[i].mobileNumber,
      birthDate: this.state.userList[i].birthDate,
      emergencyContactName: this.state.userList[i].emergencyContactName,
      emergencyContactTell: this.state.userList[i].emergencyContactTell,
      isSelfEmployment: this.state.userList[i].isSelfEmployment,
      companyCEOName: this.state.userList[i].companyCEOName,
      companyZipCode: this.state.userList[i].companyZipCode,
      companyAddress1: this.state.userList[i].companyAddress1,
      companyAddress2: this.state.userList[i].companyAddress2,
      companyAddress3: this.state.userList[i].companyAddress3,
      companyTell: this.state.userList[i].companyTell,
      companyOccupation: this.state.userList[i].companyOccupation,
      experienceYearPresent: this.state.userList[i].experienceYearPresent
        ? Number(this.state.userList[i].experienceYearPresent)
        : 0,
      experienceMonthPresent: this.state.userList[i].experienceMonthPresent
        ? Number(this.state.userList[i].experienceMonthPresent)
        : 0,
      experienceYearTotal: this.state.userList[i].experienceYearTotal
        ? Number(this.state.userList[i].experienceYearTotal)
        : 0,
      experienceMonthTotal: this.state.userList[i].experienceMonthTotal
        ? Number(this.state.userList[i].experienceMonthTotal)
        : 0,
      licenceQualification: this.state.userList[i].licenceQualification,
      licenceQualificationOthers:
        this.state.userList[i].licenceQualificationOthers,
      skillTraining: this.state.userList[i].skillTraining,
      skillTrainingOthers: this.state.userList[i].skillTrainingOthers,
      specialTraining: this.state.userList[i].specialTraining,
      specialTrainingOthers: this.state.userList[i].specialTrainingOthers,
      isForeigner: this.state.userList[i].isForeigner,
      residenceCardNumber1: this.state.userList[i].residenceCardNumber1,
      residenceCardNumber2: this.state.userList[i].residenceCardNumber2,
      residenceCardNumber3: this.state.userList[i].residenceCardNumber3,
      stayPeriod: this.state.userList[i].stayPeriod,
      isSMEOwnerOrSingleMaster: this.state.userList[i].isSMEOwnerOrSingleMaster,
      insuranceGroupName: this.state.userList[i].insuranceGroupName,
      insuranceNumber1: this.state.userList[i].insuranceNumber1,
      insuranceNumber2: this.state.userList[i].insuranceNumber2,
      insuranceNumber3: this.state.userList[i].insuranceNumber3,
      insuranceExpirationDate: this.state.userList[i].insuranceExpirationDate,
      medicalCheckupCategory: this.state.userList[i].medicalCheckupCategory,
      medicalCheckupLatestDate: this.state.userList[i].medicalCheckupLatestDate,
      bloodPressureMax: this.state.userList[i].bloodPressureMax
        ? Number(this.state.userList[i].bloodPressureMax)
        : 0,
      bloodPressureMin: this.state.userList[i].bloodPressureMin
        ? Number(this.state.userList[i].bloodPressureMin)
        : 0,
      bloodType: this.state.userList[i].bloodType,
      anamnesis: this.state.userList[i].anamnesis,
      anamnesisOthers: this.state.userList[i].anamnesisOthers,
      canWorkWithoutProblems: this.state.userList[i].canWorkWithoutProblems,
      companyOrder: this.state.userList[i].companyOrder
        ? Number(this.state.userList[i].companyOrder)
        : 0,
      primaryCompanyName: this.state.userList[i].primaryCompanyName,
      secondaryCompanyName: this.state.userList[i].secondaryCompanyName,
      thirdCompanyName: this.state.userList[i].thirdCompanyName,
      fourthCompanyName: this.state.userList[i].fourthCompanyName,
      groupName: this.state.userList[i].groupName,
      isForeman: this.state.userList[i].isForeman,
      youthElderly: this.state.userList[i].youthElderly,
      notes: this.state.userList[i].notes,
      pihAgreementFlag: this.state.userList[i].pihAgreementFlag,
      normKnowledge: this.state.userList[i].normKnowledge,
      agreementOfYouth: this.state.userList[i].agreementOfYouth,
      agreementOfElderly: this.state.userList[i].agreementOfElderly,
      shootingProhibited: this.state.userList[i].shootingProhibited,
      snsPostingProhibited: this.state.userList[i].snsPostingProhibited,
      disclosureProhibited: this.state.userList[i].disclosureProhibited,
      discardCopyProhibited: this.state.userList[i].discardCopyProhibited,
      observancePledge: this.state.userList[i].observancePledge,
      checkListInputContents: this.state.userList[i].checkListInputContents,
      invitationType: this.state.userList[i].invitationType,
      providerCompanyName: this.state.companyDetails.companyName,
      isChief: this.state.userList[i].isChief?.toLowerCase() === "true",
    };
    await API.graphql(
      graphqlOperation(createSiteClearanceWithInvitation, input)
    );
  };

  validationHasChief = async (i, havingChief) => {
    if (i === 0) {
      havingChief = await this.validationHasChiefInDb();
    } else {
      if (this.state.userList[i - 1]?.isChief.toLowerCase() === "true") {
        havingChief = true;
      }
    }
    return havingChief;
  };

  async onSubmit() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();
      let havingChief = false;

      for (let i = 0; i < this.state.userList.length; i++) {
        havingChief = await this.validationHasChief(i, havingChief);
        if (havingChief) {
          this.state.userList[i].isChief = "false";
        }
        this.handleCreateSiteClearanceWithInvitation(i, userInfo);
      }

      this.setState({
        isLoading: false,
      });
      this.props.navigate("/user-list");
    } catch (error) {
      console.log(error);
      if (error.message === "すでに所長・工事担当者が設定済みです") {
        this.setState({
          snackbar: {
            children: error.message,
            severity: "error",
          },
        });
      }
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidMount() {
    this.fetchUsers();
  }

  async fetchUsers() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseSite = await API.graphql(
        graphqlOperation(getSite, {
          id: responseUser.data.getUser.currentSiteId,
        })
      );
      this.setState({
        siteDetails: responseSite.data.getSite,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  handleCloseSnackbar = () => this.setState({ snackbar: null });

  render() {
    const drawerList = [
      this.state.userDetails.currentSiteName,
      "現場状況",
      "メンバー管理",
      "現場の設定",
      "顔認証端末",
      "QRコード",
    ];

    const navigationList = [
      "/select-site",
      "/site-overview",
      "/user-list",
      "/edit-site",
      "/select-device",
      "/site-qr-code",
    ];

    const userInformation = [
      this.state.companyDetails.companyName,
      this.state.userDetails.lastName + "" + this.state.userDetails.firstName,
    ];

    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            backgroundColor: "#F9F9F9",
            borderBottom: 1,
            borderColor: "#E5E5E5",
          }}
          elevation={0}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              onClick={() => this.props.navigate("/register-user-bulk")}
              sx={{
                mr: 2,
                pr: 2,
                pl: 1,
                borderRadius: 0,
                borderRight: 1,
                height: 64,
                borderColor: "#E5E5E5",
                color: "#383838",
              }}
            >
              <img
                src={require("../assets/images/leftarrow-on.png")}
                width="16"
                height="16"
              />
            </IconButton>
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontSize: 21,
                fontWeight: "700",
                color: "#383838",
                flexGrow: 1,
              }}
            >
              人員登録の確認
            </Typography>
            {this.state.userList && (
              <Button
                type="submit"
                onClick={this.handleSubmit}
                variant="contained"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#383838",
                  backgroundColor: "#EBE91A",
                  height: 40,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  justifyContent: "space-between",
                  "&:hover": {
                    backgroundColor: "#EBE91A",
                  },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/rightarrow-on.png")}
                    width="20"
                    height="20"
                  />
                }
                disableElevation
              >
                登録する
              </Button>
            )}
            {Boolean(!this.state.userList) && (
              <Button
                variant="contained"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#C8C8C8",
                  backgroundColor: "#E5E5E5",
                  height: 40,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  justifyContent: "space-between",
                  "&:hover": {
                    backgroundColor: "#E5E5E5",
                  },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/rightarrow-gray.png")}
                    width="20"
                    height="20"
                  />
                }
                disableElevation
              >
                登録する
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <NavigationDrawer
            topIndex={2}
            bottomIndex={null}
            drawerList={drawerList}
            navigationList={navigationList}
            userInformation={userInformation}
          />
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#F9F9F9",
            height: "100%",
            minHeight: "100vh",
            p: 3,
          }}
        >
          <LoadingProgress isLoading={this.state.isLoading} />
          <Grid item xs={12} mb={2}>
            <Toolbar />
            <Box component="form" sx={{ width: 800 }}>
              <RegisterUserBulkTable rows={this.state.userList} />
            </Box>
          </Grid>
        </Box>
        {!!this.state.snackbar && (
          <ValidationSnackBar
            open={this.state.snackbar !== null}
            onClose={this.handleCloseSnackbar}
            snackbar={this.state.snackbar}
          />
        )}
      </Box>
    );
  }
}

function RegisterUserBulkConfirmScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default RegisterUserBulkConfirmScreen;
