import { createVendor } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";

export default async function createVendorWrapper(input) {
  try {
    await API.graphql(
      graphqlOperation(createVendor, {
        input,
      })
    );
  } catch (error) {
    console.error(error);
    throw new Error("一次業者作成に失敗しました。");
  }
}
