import React from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    background: {
      default: "#E5E5E5",
    },
  },
});

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.forgotPassword();
  }

  async forgotPassword() {
    try {
      await Auth.forgotPassword(this.state.username);
      this.props.navigate("/forgot-password-submit", {
        state: {
          username: this.state.username,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            position: "absolute",
            top: 99,
            left: 99,
            width: 440,
            backgroundColor: "#F9F9F9",
          }}
        >
          <Box
            sx={{
              paddingTop: 4,
              paddingBottom: 4,
              paddingLeft: 4,
              paddingRight: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              component="div"
              sx={{
                fontFamily: "Regular",
                fontSize: 28,
                fontWeight: "700",
                color: "#383838",
                marginBottom: 1,
              }}
            >
              パスワードを忘れた場合
            </Typography>
            <Typography
              component="div"
              sx={{
                fontFamily: "Regular",
                fontSize: 15,
                fontWeight: "400",
                color: "#383838",
                marginBottom: 0,
              }}
            >
              パスワードを再発行します。
            </Typography>
            <Typography
              component="div"
              sx={{
                fontFamily: "Regular",
                fontSize: 15,
                fontWeight: "400",
                color: "#383838",
                marginBottom: 3,
              }}
            >
              ご登録済みのメールアドレスを入力してください。
            </Typography>
            <Box component="form" sx={{ width: 328 }}>
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  marginBottom: 0,
                }}
              >
                メールアドレス
              </Typography>
              <TextField
                id="username"
                placeholder="メールアドレス"
                name="username"
                type="text"
                value={this.state.username}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                autoComplete="email"
                autoFocus
                sx={{
                  marginTop: 1,
                  marginBottom: 5,
                }}
              />
            </Box>
            <Button
              type="submit"
              onClick={this.handleSubmit}
              fullWidth
              variant="contained"
              sx={{
                fontFamily: "Regular",
                fontSize: 16,
                fontWeight: "700",
                color: "#383838",
                backgroundColor: "#EBE91A",
                height: 48,
                borderRadius: 100,
                paddingHorizontal: 18,
                marginBottom: 5,
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: "#EBE91A",
                },
              }}
              endIcon={
                <img
                  src={require("../assets/images/rightarrow-on.png")}
                  width="20"
                  height="20"
                />
              }
              disableElevation
            >
              送信
            </Button>
            <Button
              onClick={() => this.props.navigate("/sign-in")}
              sx={{
                fontFamily: "Regular",
                fontSize: 15,
                fontWeight: "700",
                color: "#383838",
                paddingLeft: 0,
                marginLeft: -1,
                "&:hover": {
                  backgroundColor: "#F9F9F9",
                },
              }}
            >
              戻る
            </Button>
          </Box>
        </Container>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            position: "absolute",
            top: 637,
            left: 981,
            width: 195,
            height: 97,
            backgroundColor: "#F9F9F9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={require("../assets/images/sagaseru-logo.png")}
            width="101"
            height="24"
          />
        </Container>
      </ThemeProvider>
    );
  }
}

function ForgotPasswordScreen(props) {
  const navigate = useNavigate();
  return <InputComponent {...props} navigate={navigate} />;
}

export default ForgotPasswordScreen;
