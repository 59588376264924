import * as React from "react";
import { Auth, API, graphqlOperation, Storage } from "aws-amplify";
import DOMPurify from "dompurify";
import { getUser, getCompany, getSite, getVendor } from "../graphql/queries";
import {
  updateSiteClearance,
  updateSiteAndSiteVendor,
  deleteSite,
  updateUser,
} from "../graphql/mutations";
import { useNavigate, useLocation } from "react-router-dom";
import { sha256 } from "crypto-hash";
import NavigationDrawer from "../components/NavigationDrawer";
import LoadingProgress from "../components/LoadingProgress";
import RequiredField from "../components/RequiredField";
import QrCode from "../components/QrCode";
import CommonDialog from "../components/CommonDialog";
import fetchUser from "../components/queries/FetchUser";
import CustomizedHook from "../components/SelectBoxAppend";
import DefaultCustomizedHook from "../components/DefaultSelectBoxAppend";
import SiteSpecifiedItem from "../components/SiteSpecifiedItem";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";
import fetchSiteClearances from "../components/queries/FetchSiteClearances";
import fetchSites from "../components/queries/FetchSites";
import fetchSiteRoles from "../components/queries/FetchSiteRoles";
import customCompanyToVendors from "../components/utils/customCompanyToVendors";
import { DEFAULT_ROLE_LEVEL } from "../components/utils/constants";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

const drawerWidth = 280;

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      companyDetails: "",
      accessLevel: "",
      siteDetails: "",
      itemList: "",
      masterCode: "",
      siteCode: "",
      siteName: "",
      qrCodeDescription: "",
      address1: "",
      notesFileUrl: "",
      notesFile: "",
      contentText1: "",
      choiceText1: "",
      contentText2: "",
      choiceText2: "",
      contentText3: "",
      choiceText3: "",
      contentText4: "",
      choiceText4: "",
      contentText5: "",
      choiceText5: "",
      contentText6: "",
      choiceText6: "",
      contentText7: "",
      choiceText7: "",
      contentText8: "",
      choiceText8: "",
      contentText9: "",
      choiceText9: "",
      contentText10: "",
      choiceText10: "",
      contentText11: "",
      choiceText11: "",
      contentText12: "",
      choiceText12: "",
      contentText13: "",
      choiceText13: "",
      contentText14: "",
      choiceText14: "",
      contentText15: "",
      choiceText15: "",
      contentText16: "",
      choiceText16: "",
      contentText17: "",
      choiceText17: "",
      contentText18: "",
      choiceText18: "",
      contentText19: "",
      choiceText19: "",
      contentText20: "",
      choiceText20: "",
      contentText21: "",
      choiceText21: "",
      contentText22: "",
      choiceText22: "",
      contentText23: "",
      choiceText23: "",
      contentText24: "",
      choiceText24: "",
      contentText25: "",
      choiceText25: "",
      contentText26: "",
      choiceText26: "",
      contentText27: "",
      choiceText27: "",
      contentText28: "",
      choiceText28: "",
      contentText29: "",
      choiceText29: "",
      contentText30: "",
      choiceText30: "",
      contentText31: "",
      choiceText31: "",
      contentText32: "",
      choiceText32: "",
      contentText33: "",
      choiceText33: "",
      contentText34: "",
      choiceText34: "",
      contentText35: "",
      choiceText35: "",
      contentText36: "",
      choiceText36: "",
      contentText37: "",
      choiceText37: "",
      contentText38: "",
      choiceText38: "",
      contentText39: "",
      choiceText39: "",
      contentText40: "",
      choiceText40: "",
      contentText41: "",
      choiceText41: "",
      contentText42: "",
      choiceText42: "",
      contentText43: "",
      choiceText43: "",
      contentText44: "",
      choiceText44: "",
      contentText45: "",
      choiceText45: "",
      contentText46: "",
      choiceText46: "",
      contentText47: "",
      choiceText47: "",
      contentText48: "",
      choiceText48: "",
      contentText49: "",
      choiceText49: "",
      contentText50: "",
      choiceText50: "",
      contentText51: "",
      choiceText51: "",
      contentText52: "",
      choiceText52: "",
      contentText53: "",
      choiceText53: "",
      contentText54: "",
      choiceText54: "",
      contentText55: "",
      choiceText55: "",
      contentText56: "",
      choiceText56: "",
      contentText57: "",
      choiceText57: "",
      contentText58: "",
      choiceText58: "",
      contentText59: "",
      choiceText59: "",
      contentText60: "",
      choiceText60: "",
      contentText61: "",
      choiceText61: "",
      contentText62: "",
      choiceText62: "",
      contentText63: "",
      choiceText63: "",
      contentText64: "",
      choiceText64: "",
      contentText65: "",
      choiceText65: "",
      contentText66: "",
      choiceText66: "",
      contentText67: "",
      choiceText67: "",
      contentText68: "",
      choiceText68: "",
      contentText69: "",
      choiceText69: "",
      contentText70: "",
      choiceText70: "",
      contentText71: "",
      choiceText71: "",
      contentText72: "",
      choiceText72: "",
      contentText73: "",
      choiceText73: "",
      contentText74: "",
      choiceText74: "",
      contentText75: "",
      choiceText75: "",
      contentText76: "",
      choiceText76: "",
      contentText77: "",
      choiceText77: "",
      contentText78: "",
      choiceText78: "",
      contentText79: "",
      choiceText79: "",
      contentText80: "",
      choiceText80: "",
      contentText81: "",
      choiceText81: "",
      contentText82: "",
      choiceText82: "",
      contentText83: "",
      choiceText83: "",
      contentText84: "",
      choiceText84: "",
      contentText85: "",
      choiceText85: "",
      contentText86: "",
      choiceText86: "",
      contentText87: "",
      choiceText87: "",
      contentText88: "",
      choiceText88: "",
      contentText89: "",
      choiceText89: "",
      contentText90: "",
      choiceText90: "",
      contentText91: "",
      choiceText91: "",
      contentText92: "",
      choiceText92: "",
      contentText93: "",
      choiceText93: "",
      contentText94: "",
      choiceText94: "",
      contentText95: "",
      choiceText95: "",
      contentText96: "",
      choiceText96: "",
      contentText97: "",
      choiceText97: "",
      contentText98: "",
      choiceText98: "",
      contentText99: "",
      choiceText99: "",
      contentText100: "",
      choiceText100: "",
      isLoading: false,
      inputKey: Date.now(),
      vendorList: [],
      vendorIds: [],
      vendors: [],
      defaultSelected: [],
      reload: false,
      error: false,
      commDlg: false,
    };
    this.qrRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteSite = this.handleDeleteSite.bind(this);
    this.prepareVendorIds = this.prepareVendorIds.bind(this);
    this.prepareDefaultSelected = this.prepareDefaultSelected.bind(this);
    this.setReload = this.setReload.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.fetchRoles();
    this.fetchItems();
    const listVendors = await customCompanyToVendors();
    const activeVendors = listVendors.filter(
      (vendor) => vendor.isActive !== false
    );

    this.setState({
      vendorList: activeVendors, // 有効な業者のみ表示に後で変更
      isLoading: false,
    });
  }

  setReload() {
    this.setState({
      reload: !this.reload,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  handleCancel(event) {
    this.props.navigate("/site-overview");
    event.preventDefault();
  }

  handleDelete = () => {
    this.setState({
      notesFile: "",
      notesFileUrl: "",
    });
  };

  handleDeleteSite = async () => {
    try {
      await API.graphql(
        graphqlOperation(deleteSite, {
          input: {
            id: this.state.userDetails.currentSiteId,
          },
        })
      );
      this.props.navigate("/select-site");
    } catch (err) {
      console.log(err);
    }
  };

  prepareVendorIds = (ids) => {
    this.setState({
      vendors: ids,
    });
  };

  async onSubmit() {
    try {
      this.setState({
        isLoading: true,
      });

      let bucketName;
      if (process.env.REACT_APP_ENV === "prod") {
        bucketName =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450190221-prod.s3.ap-northeast-1.amazonaws.com/public/";
      } else if (process.env.REACT_APP_ENV === "test") {
        bucketName =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450194438-test.s3.ap-northeast-1.amazonaws.com/public/";
      } else {
        bucketName =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450213306-dev.s3.ap-northeast-1.amazonaws.com/public/";
      }
      const userInfo = await Auth.currentAuthenticatedUser();
      const listSites = await fetchSites({
        masterCode: {
          eq: this.state.masterCode,
        },
      });
      if (listSites.length > 0 && listSites[0]?.id !== this.state.siteCode) {
        throw new Error("案件管理番号が重複しています。");
      }
      const hashResult = await sha256(
        "siteCode=" +
          this.state.userDetails.currentSiteId +
          "&siteName=" +
          this.state.siteName
      );

      if (
        this.state.notesFile.name &&
        this.state.notesFile.name !== "undefined"
      ) {
        Storage.put(
          userInfo.attributes["custom:company_id"] +
            "/" +
            this.state.userDetails.currentSiteId +
            "/notes-files/" +
            this.state.notesFile.name,
          this.state.notesFile,
          {}
        );
      }

      const ids = this.state.vendors.map((vendor) => {
        return vendor.id;
      });

      const input = {
        id: this.state.userDetails.currentSiteId,
        masterCode: this.state.masterCode,
        siteName: this.state.siteName,
        address1: this.state.address1,
        notesFileUrl:
          this.state.notesFile.name && this.state.notesFile.name !== "undefined"
            ? bucketName +
              userInfo.attributes["custom:company_id"] +
              "/" +
              this.state.userDetails.currentSiteId +
              "/notes-files/" +
              this.state.notesFile.name
            : this.state.notesFileUrl,
        qrCodeDescription:
          "siteCode=" +
          this.state.userDetails.currentSiteId +
          "&sha256=" +
          hashResult,
        contentText1: DOMPurify.sanitize(this.state.contentText1),
        choiceText1: DOMPurify.sanitize(this.state.choiceText1),
        contentText2: DOMPurify.sanitize(this.state.contentText2),
        choiceText2: DOMPurify.sanitize(this.state.choiceText2),
        contentText3: DOMPurify.sanitize(this.state.contentText3),
        choiceText3: DOMPurify.sanitize(this.state.choiceText3),
        contentText4: DOMPurify.sanitize(this.state.contentText4),
        choiceText4: DOMPurify.sanitize(this.state.choiceText4),
        contentText5: DOMPurify.sanitize(this.state.contentText5),
        choiceText5: DOMPurify.sanitize(this.state.choiceText5),
        contentText6: DOMPurify.sanitize(this.state.contentText6),
        choiceText6: DOMPurify.sanitize(this.state.choiceText6),
        contentText7: DOMPurify.sanitize(this.state.contentText7),
        choiceText7: DOMPurify.sanitize(this.state.choiceText7),
        contentText8: DOMPurify.sanitize(this.state.contentText8),
        choiceText8: DOMPurify.sanitize(this.state.choiceText8),
        contentText9: DOMPurify.sanitize(this.state.contentText9),
        choiceText9: DOMPurify.sanitize(this.state.choiceText9),
        contentText10: DOMPurify.sanitize(this.state.contentText10),
        choiceText10: DOMPurify.sanitize(this.state.choiceText10),
        contentText11: DOMPurify.sanitize(this.state.contentText11),
        choiceText11: DOMPurify.sanitize(this.state.choiceText11),
        contentText12: DOMPurify.sanitize(this.state.contentText12),
        choiceText12: DOMPurify.sanitize(this.state.choiceText12),
        contentText13: DOMPurify.sanitize(this.state.contentText13),
        choiceText13: DOMPurify.sanitize(this.state.choiceText13),
        contentText14: DOMPurify.sanitize(this.state.contentText14),
        choiceText14: DOMPurify.sanitize(this.state.choiceText14),
        contentText15: DOMPurify.sanitize(this.state.contentText15),
        choiceText15: DOMPurify.sanitize(this.state.choiceText15),
        contentText16: DOMPurify.sanitize(this.state.contentText16),
        choiceText16: DOMPurify.sanitize(this.state.choiceText16),
        contentText17: DOMPurify.sanitize(this.state.contentText17),
        choiceText17: DOMPurify.sanitize(this.state.choiceText17),
        contentText18: DOMPurify.sanitize(this.state.contentText18),
        choiceText18: DOMPurify.sanitize(this.state.choiceText18),
        contentText19: DOMPurify.sanitize(this.state.contentText19),
        choiceText19: DOMPurify.sanitize(this.state.choiceText19),
        contentText20: DOMPurify.sanitize(this.state.contentText20),
        choiceText20: DOMPurify.sanitize(this.state.choiceText20),
        contentText21: DOMPurify.sanitize(this.state.contentText21),
        choiceText21: DOMPurify.sanitize(this.state.choiceText21),
        contentText22: DOMPurify.sanitize(this.state.contentText22),
        choiceText22: DOMPurify.sanitize(this.state.choiceText22),
        contentText23: DOMPurify.sanitize(this.state.contentText23),
        choiceText23: DOMPurify.sanitize(this.state.choiceText23),
        contentText24: DOMPurify.sanitize(this.state.contentText24),
        choiceText24: DOMPurify.sanitize(this.state.choiceText24),
        contentText25: DOMPurify.sanitize(this.state.contentText25),
        choiceText25: DOMPurify.sanitize(this.state.choiceText25),
        contentText26: DOMPurify.sanitize(this.state.contentText26),
        choiceText26: DOMPurify.sanitize(this.state.choiceText26),
        contentText27: DOMPurify.sanitize(this.state.contentText27),
        choiceText27: DOMPurify.sanitize(this.state.choiceText27),
        contentText28: DOMPurify.sanitize(this.state.contentText28),
        choiceText28: DOMPurify.sanitize(this.state.choiceText28),
        contentText29: DOMPurify.sanitize(this.state.contentText29),
        choiceText29: DOMPurify.sanitize(this.state.choiceText29),
        contentText30: DOMPurify.sanitize(this.state.contentText30),
        choiceText30: DOMPurify.sanitize(this.state.choiceText30),
        contentText31: DOMPurify.sanitize(this.state.contentText31),
        choiceText31: DOMPurify.sanitize(this.state.choiceText31),
        contentText32: DOMPurify.sanitize(this.state.contentText32),
        choiceText32: DOMPurify.sanitize(this.state.choiceText32),
        contentText33: DOMPurify.sanitize(this.state.contentText33),
        choiceText33: DOMPurify.sanitize(this.state.choiceText33),
        contentText34: DOMPurify.sanitize(this.state.contentText34),
        choiceText34: DOMPurify.sanitize(this.state.choiceText34),
        contentText35: DOMPurify.sanitize(this.state.contentText35),
        choiceText35: DOMPurify.sanitize(this.state.choiceText35),
        contentText36: DOMPurify.sanitize(this.state.contentText36),
        choiceText36: DOMPurify.sanitize(this.state.choiceText36),
        contentText37: DOMPurify.sanitize(this.state.contentText37),
        choiceText37: DOMPurify.sanitize(this.state.choiceText37),
        contentText38: DOMPurify.sanitize(this.state.contentText38),
        choiceText38: DOMPurify.sanitize(this.state.choiceText38),
        contentText39: DOMPurify.sanitize(this.state.contentText39),
        choiceText39: DOMPurify.sanitize(this.state.choiceText39),
        contentText40: DOMPurify.sanitize(this.state.contentText40),
        choiceText40: DOMPurify.sanitize(this.state.choiceText40),
        contentText41: DOMPurify.sanitize(this.state.contentText41),
        choiceText41: DOMPurify.sanitize(this.state.choiceText41),
        contentText42: DOMPurify.sanitize(this.state.contentText42),
        choiceText42: DOMPurify.sanitize(this.state.choiceText42),
        contentText43: DOMPurify.sanitize(this.state.contentText43),
        choiceText43: DOMPurify.sanitize(this.state.choiceText43),
        contentText44: DOMPurify.sanitize(this.state.contentText44),
        choiceText44: DOMPurify.sanitize(this.state.choiceText44),
        contentText45: DOMPurify.sanitize(this.state.contentText45),
        choiceText45: DOMPurify.sanitize(this.state.choiceText45),
        contentText46: DOMPurify.sanitize(this.state.contentText46),
        choiceText46: DOMPurify.sanitize(this.state.choiceText46),
        contentText47: DOMPurify.sanitize(this.state.contentText47),
        choiceText47: DOMPurify.sanitize(this.state.choiceText47),
        contentText48: DOMPurify.sanitize(this.state.contentText48),
        choiceText48: DOMPurify.sanitize(this.state.choiceText48),
        contentText49: DOMPurify.sanitize(this.state.contentText49),
        choiceText49: DOMPurify.sanitize(this.state.choiceText49),
        contentText50: DOMPurify.sanitize(this.state.contentText50),
        choiceText50: DOMPurify.sanitize(this.state.choiceText50),
        contentText51: DOMPurify.sanitize(this.state.contentText51),
        choiceText51: DOMPurify.sanitize(this.state.choiceText51),
        contentText52: DOMPurify.sanitize(this.state.contentText52),
        choiceText52: DOMPurify.sanitize(this.state.choiceText52),
        contentText53: DOMPurify.sanitize(this.state.contentText53),
        choiceText53: DOMPurify.sanitize(this.state.choiceText53),
        contentText54: DOMPurify.sanitize(this.state.contentText54),
        choiceText54: DOMPurify.sanitize(this.state.choiceText54),
        contentText55: DOMPurify.sanitize(this.state.contentText55),
        choiceText55: DOMPurify.sanitize(this.state.choiceText55),
        contentText56: DOMPurify.sanitize(this.state.contentText56),
        choiceText56: DOMPurify.sanitize(this.state.choiceText56),
        contentText57: DOMPurify.sanitize(this.state.contentText57),
        choiceText57: DOMPurify.sanitize(this.state.choiceText57),
        contentText58: DOMPurify.sanitize(this.state.contentText58),
        choiceText58: DOMPurify.sanitize(this.state.choiceText58),
        contentText59: DOMPurify.sanitize(this.state.contentText59),
        choiceText59: DOMPurify.sanitize(this.state.choiceText59),
        contentText60: DOMPurify.sanitize(this.state.contentText60),
        choiceText60: DOMPurify.sanitize(this.state.choiceText60),
        contentText61: DOMPurify.sanitize(this.state.contentText61),
        choiceText61: DOMPurify.sanitize(this.state.choiceText61),
        contentText62: DOMPurify.sanitize(this.state.contentText62),
        choiceText62: DOMPurify.sanitize(this.state.choiceText62),
        contentText63: DOMPurify.sanitize(this.state.contentText63),
        choiceText63: DOMPurify.sanitize(this.state.choiceText63),
        contentText64: DOMPurify.sanitize(this.state.contentText64),
        choiceText64: DOMPurify.sanitize(this.state.choiceText64),
        contentText65: DOMPurify.sanitize(this.state.contentText65),
        choiceText65: DOMPurify.sanitize(this.state.choiceText65),
        contentText66: DOMPurify.sanitize(this.state.contentText66),
        choiceText66: DOMPurify.sanitize(this.state.choiceText66),
        contentText67: DOMPurify.sanitize(this.state.contentText67),
        choiceText67: DOMPurify.sanitize(this.state.choiceText67),
        contentText68: DOMPurify.sanitize(this.state.contentText68),
        choiceText68: DOMPurify.sanitize(this.state.choiceText68),
        contentText69: DOMPurify.sanitize(this.state.contentText69),
        choiceText69: DOMPurify.sanitize(this.state.choiceText69),
        contentText70: DOMPurify.sanitize(this.state.contentText70),
        choiceText70: DOMPurify.sanitize(this.state.choiceText70),
        contentText71: DOMPurify.sanitize(this.state.contentText71),
        choiceText71: DOMPurify.sanitize(this.state.choiceText71),
        contentText72: DOMPurify.sanitize(this.state.contentText72),
        choiceText72: DOMPurify.sanitize(this.state.choiceText72),
        contentText73: DOMPurify.sanitize(this.state.contentText73),
        choiceText73: DOMPurify.sanitize(this.state.choiceText73),
        contentText74: DOMPurify.sanitize(this.state.contentText74),
        choiceText74: DOMPurify.sanitize(this.state.choiceText74),
        contentText75: DOMPurify.sanitize(this.state.contentText75),
        choiceText75: DOMPurify.sanitize(this.state.choiceText75),
        contentText76: DOMPurify.sanitize(this.state.contentText76),
        choiceText76: DOMPurify.sanitize(this.state.choiceText76),
        contentText77: DOMPurify.sanitize(this.state.contentText77),
        choiceText77: DOMPurify.sanitize(this.state.choiceText77),
        contentText78: DOMPurify.sanitize(this.state.contentText78),
        choiceText78: DOMPurify.sanitize(this.state.choiceText78),
        contentText79: DOMPurify.sanitize(this.state.contentText79),
        choiceText79: DOMPurify.sanitize(this.state.choiceText79),
        contentText80: DOMPurify.sanitize(this.state.contentText80),
        choiceText80: DOMPurify.sanitize(this.state.choiceText80),
        contentText81: DOMPurify.sanitize(this.state.contentText81),
        choiceText81: DOMPurify.sanitize(this.state.choiceText81),
        contentText82: DOMPurify.sanitize(this.state.contentText82),
        choiceText82: DOMPurify.sanitize(this.state.choiceText82),
        contentText83: DOMPurify.sanitize(this.state.contentText83),
        choiceText83: DOMPurify.sanitize(this.state.choiceText83),
        contentText84: DOMPurify.sanitize(this.state.contentText84),
        choiceText84: DOMPurify.sanitize(this.state.choiceText84),
        contentText85: DOMPurify.sanitize(this.state.contentText85),
        choiceText85: DOMPurify.sanitize(this.state.choiceText85),
        contentText86: DOMPurify.sanitize(this.state.contentText86),
        choiceText86: DOMPurify.sanitize(this.state.choiceText86),
        contentText87: DOMPurify.sanitize(this.state.contentText87),
        choiceText87: DOMPurify.sanitize(this.state.choiceText87),
        contentText88: DOMPurify.sanitize(this.state.contentText88),
        choiceText88: DOMPurify.sanitize(this.state.choiceText88),
        contentText89: DOMPurify.sanitize(this.state.contentText89),
        choiceText89: DOMPurify.sanitize(this.state.choiceText89),
        contentText90: DOMPurify.sanitize(this.state.contentText90),
        choiceText90: DOMPurify.sanitize(this.state.choiceText90),
        contentText91: DOMPurify.sanitize(this.state.contentText91),
        choiceText91: DOMPurify.sanitize(this.state.choiceText91),
        contentText92: DOMPurify.sanitize(this.state.contentText92),
        choiceText92: DOMPurify.sanitize(this.state.choiceText92),
        contentText93: DOMPurify.sanitize(this.state.contentText93),
        choiceText93: DOMPurify.sanitize(this.state.choiceText93),
        contentText94: DOMPurify.sanitize(this.state.contentText94),
        choiceText94: DOMPurify.sanitize(this.state.choiceText94),
        contentText95: DOMPurify.sanitize(this.state.contentText95),
        choiceText95: DOMPurify.sanitize(this.state.choiceText95),
        contentText96: DOMPurify.sanitize(this.state.contentText96),
        choiceText96: DOMPurify.sanitize(this.state.choiceText96),
        contentText97: DOMPurify.sanitize(this.state.contentText97),
        choiceText97: DOMPurify.sanitize(this.state.choiceText97),
        contentText98: DOMPurify.sanitize(this.state.contentText98),
        choiceText98: DOMPurify.sanitize(this.state.choiceText98),
        contentText99: DOMPurify.sanitize(this.state.contentText99),
        choiceText99: DOMPurify.sanitize(this.state.choiceText99),
        contentText100: DOMPurify.sanitize(this.state.contentText100),
        choiceText100: DOMPurify.sanitize(this.state.choiceText100),
        companyId: userInfo.attributes["custom:company_id"],
        vendorIds: ids,
      };

      API.graphql(
        graphqlOperation(updateSiteAndSiteVendor, {
          input,
        })
      );
      const customerInput = {
        id: userInfo.attributes.sub,
        currentSiteId: this.state.userDetails.currentSiteId,
        currentSiteName: this.state.siteName,
        currentMasterCode: this.state.masterCode,
      };

      API.graphql(
        graphqlOperation(updateUser, {
          input: customerInput,
        })
      );

      const filterSite = {
        and: [
          {
            siteCode: {
              eq: this.state.userDetails.currentSiteId,
            },
          },
        ],
      };

      const resSiteClearances = await fetchSiteClearances(filterSite);
      for (let i = 0; i < resSiteClearances.length; i++) {
        const inputSiteClearance = {
          id: resSiteClearances[i].id,
          masterCode: this.state.masterCode,
          siteName: this.state.siteName,
          siteAddress: this.state.address1,
          qrCodeDescription:
            "siteCode=" +
            this.state.userDetails.currentSiteId +
            "&sha256=" +
            hashResult,
          notesFileUrl: this.state.notesFile
            ? bucketName +
              userInfo.attributes["custom:company_id"] +
              "/" +
              this.state.userDetails.currentSiteId +
              "/notes-files/" +
              this.state.notesFile.name
            : this.state.notesFileUrl,
        };

        API.graphql(
          graphqlOperation(updateSiteClearance, {
            input: inputSiteClearance,
          })
        );
      }

      this.setState({
        isLoading: false,
      });

      this.props.navigate("/site-overview", {
        state: {
          masterCode: this.state.masterCode,
          siteName: this.state.siteName,
        },
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error.message === "案件管理番号が重複しています。") {
        this.setState({
          error: true,
        });
      }
    }
  }

  async fetchRoles() {
    try {
      this.setState({
        isLoading: true,
      });
      const userInfo = await Auth.currentAuthenticatedUser();

      const filterCompanyRole = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      };

      const resCompanyRoles = await fetchCompanyRoles(filterCompanyRole);
      let roleLevel;
      if (resCompanyRoles[0].roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = resCompanyRoles[0].roleLevel;
      } else {
        const responseUser = await API.graphql(
          graphqlOperation(getUser, {
            id: userInfo.attributes.sub,
          })
        );

        const filterSiteRole = {
          and: [
            {
              siteCode: {
                eq: responseUser.data.getUser.currentSiteId,
              },
            },
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };

        const resSiteRoles = await fetchSiteRoles(filterSiteRole);
        roleLevel = resSiteRoles[0].roleLevel;
      }

      this.setState({
        accessLevel: roleLevel,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchItems() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const getUser = await fetchUser({
        id: userInfo.attributes.sub,
      });

      this.setState({
        userDetails: getUser,
      });

      const responseSite = await API.graphql(
        graphqlOperation(getSite, {
          id: getUser.currentSiteId,
        })
      );
      this.setState({
        masterCode: responseSite.data.getSite.masterCode,
        siteCode: responseSite.data.getSite.id,
        siteName: responseSite.data.getSite.siteName,
        qrCodeDescription: responseSite.data.getSite.qrCodeDescription,
        address1: responseSite.data.getSite.address1,
        notesFileUrl: responseSite.data.getSite.notesFileUrl,
        contentText1: responseSite.data.getSite.contentText1,
        choiceText1: responseSite.data.getSite.choiceText1,
        contentText2: responseSite.data.getSite.contentText2,
        choiceText2: responseSite.data.getSite.choiceText2,
        contentText3: responseSite.data.getSite.contentText3,
        choiceText3: responseSite.data.getSite.choiceText3,
        contentText4: responseSite.data.getSite.contentText4,
        choiceText4: responseSite.data.getSite.choiceText4,
        contentText5: responseSite.data.getSite.contentText5,
        choiceText5: responseSite.data.getSite.choiceText5,
        contentText6: responseSite.data.getSite.contentText6,
        choiceText6: responseSite.data.getSite.choiceText6,
        contentText7: responseSite.data.getSite.contentText7,
        choiceText7: responseSite.data.getSite.choiceText7,
        contentText8: responseSite.data.getSite.contentText8,
        choiceText8: responseSite.data.getSite.choiceText8,
        contentText9: responseSite.data.getSite.contentText9,
        choiceText9: responseSite.data.getSite.choiceText9,
        contentText10: responseSite.data.getSite.contentText10,
        choiceText10: responseSite.data.getSite.choiceText10,
        contentText11: responseSite.data.getSite.contentText11,
        choiceText11: responseSite.data.getSite.choiceText11,
        contentText12: responseSite.data.getSite.contentText12,
        choiceText12: responseSite.data.getSite.choiceText12,
        contentText13: responseSite.data.getSite.contentText13,
        choiceText13: responseSite.data.getSite.choiceText13,
        contentText14: responseSite.data.getSite.contentText14,
        choiceText14: responseSite.data.getSite.choiceText14,
        contentText15: responseSite.data.getSite.contentText15,
        choiceText15: responseSite.data.getSite.choiceText15,
        contentText16: responseSite.data.getSite.contentText16,
        choiceText16: responseSite.data.getSite.choiceText16,
        contentText17: responseSite.data.getSite.contentText17,
        choiceText17: responseSite.data.getSite.choiceText17,
        contentText18: responseSite.data.getSite.contentText18,
        choiceText18: responseSite.data.getSite.choiceText18,
        contentText19: responseSite.data.getSite.contentText19,
        choiceText19: responseSite.data.getSite.choiceText19,
        contentText20: responseSite.data.getSite.contentText20,
        choiceText20: responseSite.data.getSite.choiceText20,
        contentText21: responseSite.data.getSite.contentText21,
        choiceText21: responseSite.data.getSite.choiceText21,
        contentText22: responseSite.data.getSite.contentText22,
        choiceText22: responseSite.data.getSite.choiceText22,
        contentText23: responseSite.data.getSite.contentText23,
        choiceText23: responseSite.data.getSite.choiceText23,
        contentText24: responseSite.data.getSite.contentText24,
        choiceText24: responseSite.data.getSite.choiceText24,
        contentText25: responseSite.data.getSite.contentText25,
        choiceText25: responseSite.data.getSite.choiceText25,
        contentText26: responseSite.data.getSite.contentText26,
        choiceText26: responseSite.data.getSite.choiceText26,
        contentText27: responseSite.data.getSite.contentText27,
        choiceText27: responseSite.data.getSite.choiceText27,
        contentText28: responseSite.data.getSite.contentText28,
        choiceText28: responseSite.data.getSite.choiceText28,
        contentText29: responseSite.data.getSite.contentText29,
        choiceText29: responseSite.data.getSite.choiceText29,
        contentText30: responseSite.data.getSite.contentText30,
        choiceText30: responseSite.data.getSite.choiceText30,
        contentText31: responseSite.data.getSite.contentText31,
        choiceText31: responseSite.data.getSite.choiceText31,
        contentText32: responseSite.data.getSite.contentText32,
        choiceText32: responseSite.data.getSite.choiceText32,
        contentText33: responseSite.data.getSite.contentText33,
        choiceText33: responseSite.data.getSite.choiceText33,
        contentText34: responseSite.data.getSite.contentText34,
        choiceText34: responseSite.data.getSite.choiceText34,
        contentText35: responseSite.data.getSite.contentText35,
        choiceText35: responseSite.data.getSite.choiceText35,
        contentText36: responseSite.data.getSite.contentText36,
        choiceText36: responseSite.data.getSite.choiceText36,
        contentText37: responseSite.data.getSite.contentText37,
        choiceText37: responseSite.data.getSite.choiceText37,
        contentText38: responseSite.data.getSite.contentText38,
        choiceText38: responseSite.data.getSite.choiceText38,
        contentText39: responseSite.data.getSite.contentText39,
        choiceText39: responseSite.data.getSite.choiceText39,
        contentText40: responseSite.data.getSite.contentText40,
        choiceText40: responseSite.data.getSite.choiceText40,
        contentText41: responseSite.data.getSite.contentText41,
        choiceText41: responseSite.data.getSite.choiceText41,
        contentText42: responseSite.data.getSite.contentText42,
        choiceText42: responseSite.data.getSite.choiceText42,
        contentText43: responseSite.data.getSite.contentText43,
        choiceText43: responseSite.data.getSite.choiceText43,
        contentText44: responseSite.data.getSite.contentText44,
        choiceText44: responseSite.data.getSite.choiceText44,
        contentText45: responseSite.data.getSite.contentText45,
        choiceText45: responseSite.data.getSite.choiceText45,
        contentText46: responseSite.data.getSite.contentText46,
        choiceText46: responseSite.data.getSite.choiceText46,
        contentText47: responseSite.data.getSite.contentText47,
        choiceText47: responseSite.data.getSite.choiceText47,
        contentText48: responseSite.data.getSite.contentText48,
        choiceText48: responseSite.data.getSite.choiceText48,
        contentText49: responseSite.data.getSite.contentText49,
        choiceText49: responseSite.data.getSite.choiceText49,
        contentText50: responseSite.data.getSite.contentText50,
        choiceText50: responseSite.data.getSite.choiceText50,
        contentText51: responseSite.data.getSite.contentText51,
        choiceText51: responseSite.data.getSite.choiceText51,
        contentText52: responseSite.data.getSite.contentText52,
        choiceText52: responseSite.data.getSite.choiceText52,
        contentText53: responseSite.data.getSite.contentText53,
        choiceText53: responseSite.data.getSite.choiceText53,
        contentText54: responseSite.data.getSite.contentText54,
        choiceText54: responseSite.data.getSite.choiceText54,
        contentText55: responseSite.data.getSite.contentText55,
        choiceText55: responseSite.data.getSite.choiceText55,
        contentText56: responseSite.data.getSite.contentText56,
        choiceText56: responseSite.data.getSite.choiceText56,
        contentText57: responseSite.data.getSite.contentText57,
        choiceText57: responseSite.data.getSite.choiceText57,
        contentText58: responseSite.data.getSite.contentText58,
        choiceText58: responseSite.data.getSite.choiceText58,
        contentText59: responseSite.data.getSite.contentText59,
        choiceText59: responseSite.data.getSite.choiceText59,
        contentText60: responseSite.data.getSite.contentText60,
        choiceText60: responseSite.data.getSite.choiceText60,
        contentText61: responseSite.data.getSite.contentText61,
        choiceText61: responseSite.data.getSite.choiceText61,
        contentText62: responseSite.data.getSite.contentText62,
        choiceText62: responseSite.data.getSite.choiceText62,
        contentText63: responseSite.data.getSite.contentText63,
        choiceText63: responseSite.data.getSite.choiceText63,
        contentText64: responseSite.data.getSite.contentText64,
        choiceText64: responseSite.data.getSite.choiceText64,
        contentText65: responseSite.data.getSite.contentText65,
        choiceText65: responseSite.data.getSite.choiceText65,
        contentText66: responseSite.data.getSite.contentText66,
        choiceText66: responseSite.data.getSite.choiceText66,
        contentText67: responseSite.data.getSite.contentText67,
        choiceText67: responseSite.data.getSite.choiceText67,
        contentText68: responseSite.data.getSite.contentText68,
        choiceText68: responseSite.data.getSite.choiceText68,
        contentText69: responseSite.data.getSite.contentText69,
        choiceText69: responseSite.data.getSite.choiceText69,
        contentText70: responseSite.data.getSite.contentText70,
        choiceText70: responseSite.data.getSite.choiceText70,
        contentText71: responseSite.data.getSite.contentText71,
        choiceText71: responseSite.data.getSite.choiceText71,
        contentText72: responseSite.data.getSite.contentText72,
        choiceText72: responseSite.data.getSite.choiceText72,
        contentText73: responseSite.data.getSite.contentText73,
        choiceText73: responseSite.data.getSite.choiceText73,
        contentText74: responseSite.data.getSite.contentText74,
        choiceText74: responseSite.data.getSite.choiceText74,
        contentText75: responseSite.data.getSite.contentText75,
        choiceText75: responseSite.data.getSite.choiceText75,
        contentText76: responseSite.data.getSite.contentText76,
        choiceText76: responseSite.data.getSite.choiceText76,
        contentText77: responseSite.data.getSite.contentText77,
        choiceText77: responseSite.data.getSite.choiceText77,
        contentText78: responseSite.data.getSite.contentText78,
        choiceText78: responseSite.data.getSite.choiceText78,
        contentText79: responseSite.data.getSite.contentText79,
        choiceText79: responseSite.data.getSite.choiceText79,
        contentText80: responseSite.data.getSite.contentText80,
        choiceText80: responseSite.data.getSite.choiceText80,
        contentText81: responseSite.data.getSite.contentText81,
        choiceText81: responseSite.data.getSite.choiceText81,
        contentText82: responseSite.data.getSite.contentText82,
        choiceText82: responseSite.data.getSite.choiceText82,
        contentText83: responseSite.data.getSite.contentText83,
        choiceText83: responseSite.data.getSite.choiceText83,
        contentText84: responseSite.data.getSite.contentText84,
        choiceText84: responseSite.data.getSite.choiceText84,
        contentText85: responseSite.data.getSite.contentText85,
        choiceText85: responseSite.data.getSite.choiceText85,
        contentText86: responseSite.data.getSite.contentText86,
        choiceText86: responseSite.data.getSite.choiceText86,
        contentText87: responseSite.data.getSite.contentText87,
        choiceText87: responseSite.data.getSite.choiceText87,
        contentText88: responseSite.data.getSite.contentText88,
        choiceText88: responseSite.data.getSite.choiceText88,
        contentText89: responseSite.data.getSite.contentText89,
        choiceText89: responseSite.data.getSite.choiceText89,
        contentText90: responseSite.data.getSite.contentText90,
        choiceText90: responseSite.data.getSite.choiceText90,
        contentText91: responseSite.data.getSite.contentText91,
        choiceText91: responseSite.data.getSite.choiceText91,
        contentText92: responseSite.data.getSite.contentText92,
        choiceText92: responseSite.data.getSite.choiceText92,
        contentText93: responseSite.data.getSite.contentText93,
        choiceText93: responseSite.data.getSite.choiceText93,
        contentText94: responseSite.data.getSite.contentText94,
        choiceText94: responseSite.data.getSite.choiceText94,
        contentText95: responseSite.data.getSite.contentText95,
        choiceText95: responseSite.data.getSite.choiceText95,
        contentText96: responseSite.data.getSite.contentText96,
        choiceText96: responseSite.data.getSite.choiceText96,
        contentText97: responseSite.data.getSite.contentText97,
        choiceText97: responseSite.data.getSite.choiceText97,
        contentText98: responseSite.data.getSite.contentText98,
        choiceText98: responseSite.data.getSite.choiceText98,
        contentText99: responseSite.data.getSite.contentText99,
        choiceText99: responseSite.data.getSite.choiceText99,
        contentText100: responseSite.data.getSite.contentText100,
        choiceText100: responseSite.data.getSite.choiceText100,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );

      let tmpArray = [];
      if (Array.isArray(responseSite.data.getSite.vendorIds)) {
        tmpArray = await this.prepareDefaultSelected(tmpArray, responseSite);
      }

      this.setState({
        defaultSelected: [...tmpArray],
        vendorIds: responseSite.data.getSite.vendorIds,
        companyDetails: responseCompany.data.getCompany,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  async prepareDefaultSelected(tmpArray, responseSite) {
    for (const vendorId of responseSite.data.getSite.vendorIds) {
      const responseVendor = await API.graphql(
        graphqlOperation(getVendor, {
          id: vendorId,
        })
      );
      tmpArray.push(responseVendor.data.getVendor);
    }
    tmpArray = this.unifyDuplication(tmpArray);
    return tmpArray;
  }

  unifyDuplication(tmpArray) {
    tmpArray = tmpArray.filter(
      (element, index, self) =>
        self.findIndex((e) => e.id === element.id) === index
    );
    return tmpArray;
  }

  render() {
    if (
      (this.state.isLoading === true) |
      (this.state.accessLevel === 1) |
      (this.state.accessLevel === 2) |
      (this.state.accessLevel === 4)
    ) {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/site-overview")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                component="div"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.state.userDetails.currentSiteName}：現場情報の更新
              </Typography>
              {this.state.masterCode &&
              this.state.siteName &&
              this.state.address1 &&
              this.state.vendors.length > 0 &&
              (this.state.accessLevel === 1) |
                (this.state.accessLevel === 2) |
                (this.state.accessLevel === 4) ? (
                <Button
                  type="submit"
                  onClick={this.handleSubmit}
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#383838",
                    backgroundColor: "#EBE91A",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    mr: 1,
                    "&:hover": {
                      backgroundColor: "#EBE91A",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-on.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  更新する
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#C8C8C8",
                    backgroundColor: "#E5E5E5",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    mr: 1,
                    "&:hover": {
                      backgroundColor: "#E5E5E5",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-gray.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  更新する
                </Button>
              )}
              <Button
                type="submit"
                onClick={this.handleCancel}
                variant="outlined"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#383838",
                  backgroundColor: "#F9F9F9",
                  borderColor: "#C8C8C8",
                  borderWidth: 1,
                  height: 40,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  mr: 1,
                  "&:hover": {
                    backgroundColor: "#F9F9F9",
                  },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/remove-on.png")}
                    width="20"
                    height="20"
                  />
                }
                disableElevation
              >
                キャンセル
              </Button>
              {(this.state.accessLevel === 1) |
                (this.state.accessLevel === 2) && (
                <>
                  <CommonDialog
                    msg={"削除しますか？削除すると復元はできません。"}
                    isOpen={this.state.commDlg}
                    doYes={this.handleDeleteSite}
                    doNo={() => {
                      this.setState({ commDlg: false });
                    }}
                  />
                  <Button
                    type="submit"
                    onClick={() => this.setState({ commDlg: true })}
                    variant="outlined"
                    sx={{
                      fontSize: 15,
                      fontWeight: "700",
                      color: "#F9F9F9",
                      backgroundColor: "#DD3900",
                      borderColor: "#DD3900",
                      borderWidth: 1,
                      height: 40,
                      borderRadius: 100,
                      paddingHorizontal: 18,
                      mr: 1,
                      "&:hover": {
                        backgroundColor: "#DD3900",
                      },
                    }}
                    endIcon={
                      <img
                        src={require("../assets/images/remove-on.png")}
                        width="18"
                        height="18"
                      />
                    }
                    disableElevation
                  >
                    削除する
                  </Button>
                </>
              )}
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationDrawer topIndex={5} bottomIndex={null} />
          </Box>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 0,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Grid item xs={12} mb={2}>
              <Toolbar />
            </Grid>
            <Box
              component="form"
              sx={{
                width: 451,
                paddingLeft: 3,
                paddingRight: 5,
                paddingTop: 2,
                paddingBottom: 3,
              }}
            >
              <QrCode value={this.state.qrCodeDescription} qrRef={this.qrRef} />
              <Stack
                direction="row"
                sx={{
                  mt: 4,
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  案件管理番号
                </Typography>
                <RequiredField />
              </Stack>
              {(this.state.accessLevel === 1) |
              (this.state.accessLevel === 2) ? (
                <TextField
                  id="masterCode"
                  name="masterCode"
                  type="text"
                  value={this.state.masterCode}
                  onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  error={this.state.error === true}
                  helperText={
                    this.state.error === true
                      ? "案件管理番号が重複しています。"
                      : ""
                  }
                />
              ) : (
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                  }}
                >
                  {this.state.masterCode}
                </Typography>
              )}
              <Stack
                direction="row"
                sx={{
                  mt: 4,
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  現場コード
                </Typography>
                <RequiredField />
              </Stack>
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mt: 1,
                  mb: 4,
                }}
              >
                {this.state.siteCode}
              </Typography>
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  現場名
                </Typography>
                <RequiredField />
              </Stack>
              <TextField
                id="siteName"
                // placeholder="現場名"
                name="siteName"
                type="text"
                value={this.state.siteName}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  住所
                </Typography>
                <RequiredField />
              </Stack>
              <TextField
                id="address1"
                name="address1"
                type="text"
                value={this.state.address1}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              {this.state.defaultSelected.length === 0 ? (
                <DefaultCustomizedHook
                  vendorList={this.state.vendorList}
                  vendorIds={this.state.vendorIds}
                  defaultSelected={this.state.defaultSelected}
                  setReload={this.setReload}
                  prepareVendorIds={this.prepareVendorIds}
                  unifyDuplication={this.unifyDuplication}
                />
              ) : (
                <CustomizedHook
                  vendorList={this.state.vendorList}
                  vendorIds={this.state.vendorIds}
                  defaultSelected={this.state.defaultSelected}
                  setReload={this.setReload}
                  prepareVendorIds={this.prepareVendorIds}
                  unifyDuplication={this.unifyDuplication}
                />
              )}
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  現場注意事項ファイル
                </Typography>
              </Stack>
              <input
                type="file"
                accept="application/pdf"
                key={this.state.inputKey}
                onChange={(event) =>
                  this.setState({
                    notesFile: event.target.files[0],
                  })
                }
              />
              {this.state.notesFileUrl !== "" && (
                <TextField
                  id="notesFileUrl"
                  name="notesFileUrl"
                  type="text"
                  value={this.state.notesFileUrl}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  sx={{
                    pl: 0,
                    marginTop: 1,
                    marginBottom: 1,
                  }}
                />
              )}
              {this.state.notesFileUrl !== "" && (
                <Button
                  onClick={this.handleDelete}
                  edge="start"
                  sx={{
                    borderRadius: 0,
                    height: 24,
                    borderColor: "#E5E5E5",
                    color: "#DD3900",
                  }}
                >
                  ファイルを削除する
                </Button>
              )}
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 17,
                    fontWeight: "700",
                    color: "#383838",
                    mt: 2,
                    mb: 2,
                    mr: 1,
                  }}
                >
                  現場固有チェック項目1
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  チェック内容文章
                </Typography>
              </Stack>
              <TextField
                id="contentText1"
                name="contentText1"
                type="text"
                value={this.state.contentText1}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  チェックボックスの文字列
                </Typography>
              </Stack>
              <TextField
                id="choiceText1"
                name="choiceText1"
                type="text"
                value={this.state.choiceText1}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 17,
                    fontWeight: "700",
                    color: "#383838",
                    mt: 2,
                    mb: 2,
                    mr: 1,
                  }}
                >
                  現場固有チェック項目2
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  チェック内容文章
                </Typography>
              </Stack>
              <TextField
                id="contentText2"
                name="contentText2"
                type="text"
                value={this.state.contentText2}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  チェックボックスの文字列
                </Typography>
              </Stack>
              <TextField
                id="choiceText2"
                name="choiceText2"
                type="text"
                value={this.state.choiceText2}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 17,
                    fontWeight: "700",
                    color: "#383838",
                    mt: 2,
                    mb: 2,
                    mr: 1,
                  }}
                >
                  現場固有チェック項目3
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  チェック内容文章
                </Typography>
              </Stack>
              <TextField
                id="contentText3"
                name="contentText3"
                type="text"
                value={this.state.contentText3}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  チェックボックスの文字列
                </Typography>
              </Stack>
              <TextField
                id="choiceText3"
                name="choiceText3"
                type="text"
                value={this.state.choiceText3}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 17,
                    fontWeight: "700",
                    color: "#383838",
                    mt: 2,
                    mb: 2,
                    mr: 1,
                  }}
                >
                  現場固有チェック項目4
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  チェック内容文章
                </Typography>
              </Stack>
              <TextField
                id="contentText4"
                name="contentText4"
                type="text"
                value={this.state.contentText4}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  チェックボックスの文字列
                </Typography>
              </Stack>
              <TextField
                id="choiceText4"
                name="choiceText4"
                type="text"
                value={this.state.choiceText4}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 17,
                    fontWeight: "700",
                    color: "#383838",
                    mt: 2,
                    mb: 2,
                    mr: 1,
                  }}
                >
                  現場固有チェック項目5
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  チェック内容文章
                </Typography>
              </Stack>
              <TextField
                id="contentText5"
                name="contentText5"
                type="text"
                value={this.state.contentText5}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  チェックボックスの文字列
                </Typography>
              </Stack>
              <TextField
                id="choiceText5"
                // placeholder="チェックボックスの文字列"
                name="choiceText5"
                type="text"
                value={this.state.choiceText5}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                // autoFocus
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              {[...Array(95)].map((_, i) => (
                <SiteSpecifiedItem
                  key={i}
                  handleInputChange={this.handleInputChange}
                  index={i}
                  state={this.state}
                />
              ))}
            </Box>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/site-overview")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                component="div"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.state.userDetails.currentSiteName}：現場情報の更新
              </Typography>
            </Toolbar>
          </AppBar>
          <NavigationDrawer topIndex={5} bottomIndex={null} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 0,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Grid item xs={12} mb={2}>
              <Toolbar />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mb: 0,
                  mr: 1,
                }}
              >
                アクセス権限がありません
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  }
}

function EditSiteScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default EditSiteScreen;
