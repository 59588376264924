const schedule = require("node-schedule");
const _ = require("lodash");

export default async function fetchJobs() {
  try {
    const jobList = schedule.scheduledJobs;
    const jobNames = _.keys(schedule.scheduledJobs);
    if (jobList) {
      for (const name of jobNames) {
        schedule.cancelJob(name);
      }
    }
    const newJobList = schedule.scheduledJobs;
  } catch (error) {
    console.log(error);
  }
}
