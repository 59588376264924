import { API, graphqlOperation } from "aws-amplify";
import { listSiteLogs } from "../../graphql/queries";

export default async function fetchSiteLogs(filter = null) {
  try {
    const siteLogList = [];
    let response;
    response = await API.graphql(
      graphqlOperation(listSiteLogs, {
        filter: filter,
        limit: 500,
      })
    );

    siteLogList.push(...response.data.listSiteLogs.items);
    while (response.data.listSiteLogs.nextToken !== null) {
      response = await API.graphql(
        graphqlOperation(listSiteLogs, {
          filter: filter,
          limit: 500,
          nextToken: response.data.listSiteLogs.nextToken,
        })
      );
      siteLogList.push(...response.data.listSiteLogs.items);
    }
    return siteLogList;
  } catch (error) {
    console.log(error);
  }
}
