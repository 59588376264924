import * as React from "react";

import { useNavigate, useLocation } from "react-router-dom";
import NavigationMenu from "../components/NavigationMenu";
import LoadingProgress from "../components/LoadingProgress";
import TopBar from "../components/TopBar";
import makeDrawerList from "../components/utils/makeDrawerList";
import { DRAWER_WIDTH } from "../components/utils/constants";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyUserList: "",
      isLoading: false,
      isBlankValidationError: false,
      accessLevel: "",
    };

    this.handleFileSelect = this.handleFileSelect.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    const [drawerList, navigationList] = await makeDrawerList();

    this.setState({
      drawerList,
      navigationList,
      isLoading: false,
    });
  }

  parseCsv(data) {
    return data.split("\r\n").map((row) => row.split(","));
  }

  requiredValidation(familyName, givenName, email) {
    if (!familyName || !givenName || !email) {
      this.state.isBlankValidationError = true;
    }
  }

  csvValidation(familyName, givenName, email) {
    this.requiredValidation(familyName, givenName, email);
  }

  csvToJson(csvStr, companyUserOptions) {
    if (typeof csvStr !== "string") return null;

    const options = { header: 0, columnName: [], ignoreBlankLine: true };

    if (companyUserOptions) {
      if (companyUserOptions.header) options.header = companyUserOptions.header;
      if (companyUserOptions.columnName)
        options.columnName = companyUserOptions.columnName;
    }

    const rows = csvStr.split("\n");
    const json = [];
    let line = [];
    let row = "";
    let data = {};

    for (let i = 1, len = rows.length; i < len; i++) {
      if (i + 1 <= options.header) continue;
      if (
        (options.ignoreBlankLine && rows[i] === "") ||
        !/[^,]/.test(rows[i].trim())
      )
        continue;

      line = rows[i].split(",");
      // validation
      this.csvValidation(line[0], line[1], line[2], line[3]);

      if (options.columnName.length > 0) {
        data = {};
        for (let j = 0, len2 = options.columnName.length; j < len2; j++) {
          if (typeof line[j] !== "undefined") {
            row = line[j];
            row = row.replace(/^"(.+)?"$/, "$1");
            row = row.replace("\r", "");
          } else {
            row = null;
          }

          data[options.columnName[j]] = row;
        }
        json.push(data);
      } else {
        json.push(line);
      }
    }

    return json;
  }

  async handleFileSelect(event) {
    const companyUserOptions = {
      header: 0,
      columnName: [
        "familyName",
        "givenName",
        "email",
        "phoneNumber",
        "password",
      ],
      ignoreBlankLine: true,
    };
    this.state.isBlankValidationError = false;
    const file = this.csvToJson(
      await event.target.files[0].text(),
      companyUserOptions
    );
    this.setState({
      companyUserList: file,
    });
  }

  render() {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <TopBar
          topTitle="社内メンバーを一括招待"
          backTo="/company-user-list"
          navigate={this.props.navigate}
        >
          {this.state.companyUserList && !this.state.isBlankValidationError && (
            <Button
              type="submit"
              onClick={() =>
                this.props.navigate("/register-company-user-bulk-confirm", {
                  state: {
                    companyUserList: this.state.companyUserList,
                  },
                })
              }
              variant="contained"
              sx={{
                fontFamily: "Regular",
                fontSize: 16,
                fontWeight: "700",
                color: "#383838",
                backgroundColor: "#EBE91A",
                height: 40,
                borderRadius: 100,
                paddingHorizontal: 18,
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: "#EBE91A",
                },
              }}
              endIcon={
                <img
                  src={require("../assets/images/rightarrow-on.png")}
                  width="20"
                  height="20"
                />
              }
              disableElevation
            >
              アップロードする
            </Button>
          )}{" "}
          {(Boolean(!this.state.companyUserList) ||
            this.state.isBlankValidationError) && (
            <Button
              variant="contained"
              sx={{
                fontFamily: "Regular",
                fontSize: 16,
                fontWeight: "700",
                color: "#C8C8C8",
                backgroundColor: "#E5E5E5",
                height: 40,
                borderRadius: 100,
                paddingHorizontal: 18,
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: "#E5E5E5",
                },
              }}
              endIcon={
                <img
                  src={require("../assets/images/rightarrow-gray.png")}
                  width="20"
                  height="20"
                />
              }
              disableElevation
            >
              アップロードする
            </Button>
          )}
        </TopBar>
        <Box
          component="nav"
          sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <NavigationMenu
            drawerList={this.state.drawerList}
            navigationList={this.state.navigationList}
            topIndex={2}
            bottomIndex={null}
          />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#F9F9F9",
            height: "100%",
            minHeight: "100vh",
            p: 3,
          }}
        >
          <LoadingProgress isLoading={this.state.isLoading} />
          <Grid item xs={12} md={6}>
            <Toolbar />
            {this.state.isBlankValidationError && (
              <Alert severity="error" sx={{ mb: 1 }}>
                姓名やメールアドレスが不足している行があります。修正して再度インポートしてください。
              </Alert>
            )}
            <Box
              component="form"
              sx={{
                display: "flex",
                direction: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Card
                sx={{
                  width: 600,
                  height: 160,
                  border: "1px dashed #777777",
                  borderRadius: 2,
                  padding: 3,
                  marginTop: 3,
                  marginLeft: 3,
                  marginRight: 3,
                  textAlign: "center",
                }}
                elevation={0}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    width: 540,
                    mb: 0,
                    mr: 1,
                  }}
                >
                  インポートする際、CSV形式のファイルを使用してください。
                </Typography>
                <Link
                  href="https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450190221-prod.s3.ap-northeast-1.amazonaws.com/public/%E7%A4%BE%E5%86%85%E3%83%A1%E3%83%B3%E3%83%8F%E3%82%99%E3%83%BC%E4%B8%80%E6%8B%AC%E7%99%BB%E9%8C%B2%E3%82%B5%E3%83%B3%E3%83%95%E3%82%9A%E3%83%AB.csv"
                  target="_blank"
                  download
                >
                  CSVテンプレートをダウンロード
                </Link>
                <Box component="form" sx={{ mt: 4, width: 540 }}>
                  <input
                    type="file"
                    accept="text/csv"
                    onChange={this.handleFileSelect}
                  />
                </Box>
              </Card>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }
}

function RegisterCompanyUserBulk(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default RegisterCompanyUserBulk;
