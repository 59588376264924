import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import RequiredField from "./RequiredField";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { autocompleteClasses } from "@mui/material/Autocomplete";

Tag.propTypes = {
  value: PropTypes.string.isRequired,
  vendorName: PropTypes.string.isRequired,
  vendorCode: PropTypes.string.isRequired,
  prepareVendorIds: PropTypes.func,
  vendorIds: PropTypes.array,
  onDelete: PropTypes.func.isRequired,
};

function Tag(props) {
  const {
    value,
    vendorName,
    vendorCode,
    prepareVendorIds,
    vendorIds = [],
    onDelete,
    ...other
  } = props;
  return (
    <div {...other}>
      <span>
        {vendorName} ({vendorCode})
      </span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

export default function DefaultCustomizedHook({
  vendorList,
  prepareVendorIds,
  vendorIds,
  defaultSelected,
  setReload,
  unifyDuplication,
}) {
  // 編集画面でdefaultSelectedなし
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook",
    defaultValue: defaultSelected,
    multiple: true,
    options: vendorList,
    getOptionLabel: (option) => `${option.vendorName} ${option.vendorCode}`,
  });
  const unifiedValue = unifyDuplication(value);
  useEffect(() => {
    prepareVendorIds(value);
  }, [value]);

  useEffect(() => {
    setReload();
  }, [defaultSelected]);

  return (
    <Root>
      <div {...getRootProps()}>
        <Stack
          direction="row"
          sx={{
            mb: 0,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Regular",
              fontSize: 15,
              fontWeight: "400",
              color: "#383838",
              mb: 2,
              mr: 1,
            }}
            {...getInputLabelProps()}
          >
            第1次業者
          </Typography>
          <RequiredField />
        </Stack>
        <InputWrapper
          ref={setAnchorEl}
          className={focused ? "focused" : ""}
          sx={{
            width: "100%",
          }}
        >
          <TextField
            placeholder="検索するか選択してください"
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                e.stopPropagation();
              }
            }}
            InputProps={{
              ...getInputProps(),
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={require("../assets/images/search-on.png")}
                    width="16"
                    height="16"
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: 439,
            }}
          />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })} key={option.id}>
              <span>
                {option.vendorName} ({option.vendorCode})
              </span>
              <CheckIcon fontSize="small" />
            </li>
          ))}
        </Listbox>
      ) : null}
      <Stack
        sx={{
          marginTop: 1,
          marginBottom: 4,
        }}
      >
        {unifiedValue.map((option, index) => {
          return (
            <StyledTag
              {...getTagProps({ index })}
              key={option?.id}
              value={option?.id}
              vendorName={option?.vendorName}
              vendorCode={option?.vendorCode}
              prepareVendorIds={prepareVendorIds}
              vendorIds={vendorIds}
            />
          );
        })}
      </Stack>
    </Root>
  );
}

const Root = styled("div")(
  ({ theme }) => `
  color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"
  };
  font-size: 15px;
`
);

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled("div")(
  ({ theme }) => `
  width: 300px;
  background-color: ${theme.palette.mode === "dark" ? "inherit" : "inherit"};
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border: 2px;
    border-color: ${theme.palette.mode === "dark" ? "#fff" : "#fff"};
  }

  &.focused {
    border-color: ${theme.palette.mode === "dark" ? "#fff" : "#fff"};
    outline: 0;
  }

  & div.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
    padding-left: 0px;
  }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "inherit" : "inherit"};
    color: ${
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)"
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }

  & fieldset {
    border: 0;
    border-radius: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }
`
);

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
  };
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 4px 4px 4px 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 15px;
    cursor: pointer;
    padding: 4px;
    color: ${theme.palette.mode === "dark" ? "#DD3900" : "#DD3900"};
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
  width: 360px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);
