import { API, graphqlOperation } from "aws-amplify";
import { getFreeidUser } from "../../graphql/queries";

export default async function fetchFreeidUser(condition) {
  try {
    const response = await API.graphql(
      graphqlOperation(getFreeidUser, condition)
    );
    return response.data.getFreeidUser;
  } catch (error) {
    console.log(error);
  }
}
