import React from "react";
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    background: {
      default: "#E5E5E5",
    },
  },
});

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authCode: "",
      password: "",
      confirmPassword: "",
      passwordError: null,
      passwordLengthError: null,
      codeLengthError: null,
      codeMismatchError: null,
      showPassword: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange =
      this.handleConfirmPasswordChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleCodeChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if (event.target.value.length === 6) {
      this.setState({
        codeLengthError: null,
        codeMismatchError: null,
      });
    } else {
      this.setState({
        codeLengthError: "認証コードは6桁です",
        codeMismatchError: null,
      });
    }
  }

  handlePasswordChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if (event.target.value == this.state.password) {
      this.setState({
        passwordError: null,
        passwordLengthError: null,
      });
    } else {
      this.setState({
        passwordLengthError: null,
        passwordError: "パスワードが一致しません。もう一度入力してください。",
      });
    }

    if (event.target.value.length >= 8) {
      this.setState({
        passwordLengthError: null,
      });
    } else {
      this.setState({
        passwordLengthError: "パスワードは8文字以上が必須です。",
      });
    }
  }

  handleConfirmPasswordChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if (event.target.value == this.state.password) {
      this.setState({
        passwordError: null,
        passwordLengthError: null,
      });
    } else {
      this.setState({
        passwordLengthError: null,
        passwordError: "パスワードが一致しません。もう一度入力してください。",
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.forgotPasswordSubmit();
  }

  handleClickShowPassword(event) {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleMouseDownPassword(event) {
    event.preventDefault();
  }

  async forgotPasswordSubmit() {
    try {
      await Auth.forgotPasswordSubmit(
        this.props.location.state.username,
        this.state.authCode,
        this.state.password
      );
      this.props.navigate("/sign-in");
    } catch (error) {
      console.log(error);
      this.setState({
        codeMismatchError:
          "入力された認証コードに誤りがあります。再度入力お願いします。",
      });
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            position: "absolute",
            top: 99,
            left: 99,
            width: 440,
            backgroundColor: "#F9F9F9",
          }}
        >
          <Box
            sx={{
              paddingTop: 4,
              paddingBottom: 4,
              paddingLeft: 4,
              paddingRight: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              component="div"
              sx={{
                fontFamily: "Regular",
                fontSize: 28,
                fontWeight: "700",
                color: "#383838",
                marginBottom: 3,
              }}
            >
              パスワードをリセットする
            </Typography>
            {/* <Typography
              component="div"
              sx={{
                fontFamily: 'Regular',
                fontSize: 15,
                fontWeight: '400',
                color: '#383838',
                marginBottom: 0,
              }}
            >
              パスワードを再発行します。
            </Typography>
            <Typography
              component="div"
              sx={{
                fontFamily: 'Regular',
                fontSize: 15,
                fontWeight: '400',
                color: '#383838',
                marginBottom: 3,
              }}
            >
              ご登録済みのメールアドレスを入力してください。
            </Typography> */}
            <Box component="form" sx={{ width: 328 }}>
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  marginBottom: 0,
                }}
              >
                メールアドレス
              </Typography>
              <TextField
                id="username"
                name="username"
                type="text"
                value={this.props.location.state.username}
                variant="standard"
                margin="normal"
                fullWidth
                autoComplete="email"
                autoFocus
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  marginBottom: 0,
                }}
              >
                認証コード
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 12,
                  fontWeight: "500",
                  color: "#DD3900",
                  mb: 0,
                }}
              >
                {this.state.codeLengthError}
                {this.state.codeMismatchError}
              </Typography>
              <TextField
                id="authCode"
                placeholder="認証コード"
                name="authCode"
                type="text"
                value={this.state.authCode}
                onChange={this.handleCodeChange}
                variant="standard"
                margin="normal"
                fullWidth
                autoFocus
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  marginBottom: 0,
                }}
              >
                新しいパスワード
              </Typography>
              <FormControl
                fullWidth
                variant="standard"
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              >
                <Input
                  id="password"
                  placeholder="パスワード"
                  name="password"
                  type={this.state.showPassword ? "text" : "password"}
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#DD3900",
                    mb: 0,
                  }}
                >
                  {this.state.passwordLengthError}
                </Typography>
              </FormControl>
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  marginBottom: 0,
                }}
              >
                新しいパスワード再確認
              </Typography>
              <FormControl
                fullWidth
                variant="standard"
                sx={{
                  marginTop: 1,
                  marginBottom: 5,
                }}
              >
                <Input
                  id="confirmPassword"
                  placeholder="パスワード"
                  name="confirmPassword"
                  type={this.state.showPassword ? "text" : "password"}
                  value={this.state.confirmPassword}
                  onChange={this.handleConfirmPasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#DD3900",
                    mb: 0,
                  }}
                >
                  {this.state.passwordError}
                </Typography>
              </FormControl>
            </Box>
            <Button
              type="submit"
              onClick={this.handleSubmit}
              fullWidth
              variant="contained"
              sx={{
                fontFamily: "Regular",
                fontSize: 16,
                fontWeight: "700",
                color: "#383838",
                backgroundColor: "#EBE91A",
                height: 48,
                borderRadius: 100,
                paddingHorizontal: 18,
                marginBottom: 5,
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: "#EBE91A",
                },
              }}
              endIcon={
                <img
                  src={require("../assets/images/rightarrow-on.png")}
                  width="20"
                  height="20"
                />
              }
              disableElevation
            >
              更新する
            </Button>
            <Button
              onClick={() => this.props.navigate("/forgot-password")}
              sx={{
                fontFamily: "Regular",
                fontSize: 15,
                fontWeight: "700",
                color: "#383838",
                paddingLeft: 0,
                marginLeft: -1,
                "&:hover": {
                  backgroundColor: "#F9F9F9",
                },
              }}
            >
              戻る
            </Button>
          </Box>
        </Container>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            position: "absolute",
            top: 637,
            left: 981,
            width: 195,
            height: 97,
            backgroundColor: "#F9F9F9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={require("../assets/images/sagaseru-logo.png")}
            width="101"
            height="24"
          />
        </Container>
      </ThemeProvider>
    );
  }
}

function ForgotPasswordSubmitScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default ForgotPasswordSubmitScreen;
