import { API, graphqlOperation } from "aws-amplify";
import { listSiteRoles } from "../../graphql/queries";

export default async function fetchSiteRoles(filter = null) {
  try {
    const siteRoleList = [];
    let response;
    response = await API.graphql(
      graphqlOperation(listSiteRoles, {
        filter: filter,
        limit: 500,
      })
    );

    siteRoleList.push(...response.data.listSiteRoles.items);
    while (response.data.listSiteRoles.nextToken !== null) {
      response = await API.graphql(
        graphqlOperation(listSiteRoles, {
          filter: filter,
          limit: 500,
          nextToken: response.data.listSiteRoles.nextToken,
        })
      );
      siteRoleList.push(...response.data.listSiteRoles.items);
    }
    return siteRoleList;
  } catch (error) {
    console.log(error);
  }
}
