import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

function CommonDialog({ msg, isOpen, doYes, doNo }) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={() => doNo()}
        aria-labelledby="common-dialog-title"
        aria-describedby="common-dialog-description"
      >
        <DialogContent>{msg}</DialogContent>
        <DialogActions>
          <Button onClick={() => doYes()} color="primary">
            削除します
          </Button>
          <Button onClick={() => doNo()} color="primary">
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CommonDialog;
