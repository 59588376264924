import * as React from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUser, getCompany } from "../graphql/queries";
import { updateCompany, updateSiteClearance } from "../graphql/mutations";
import { useNavigate, useLocation } from "react-router-dom";
import NavigationMenu from "../components/NavigationMenu";
import LoadingProgress from "../components/LoadingProgress";
import RequiredField from "../components/RequiredField";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";
import fetchSiteRoles from "../components/queries/FetchSiteRoles";
import fetchSiteClearances from "../components/queries/FetchSiteClearances";
import { DEFAULT_ROLE_LEVEL } from "../components/utils/constants";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const drawerWidth = 280;

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      companyDetails: "",
      accessLevel: "",
      contractCompanyCode: "",
      companyName: "",
      agreementUrl: "",
      licenceQualificationChoices: "",
      skillTrainingChoices: "",
      specialTrainingChoices: "",
      occupationChoices: "",
      primaryCompanies: "",
      isLoading: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  async componentDidMount() {
    this.fetchUsers();
    this.fetchRoles();
  }

  async onSubmit() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const licenceQualificationChoices =
        this.state.licenceQualificationChoices
          .replace("、、", "")
          .replace(",,", "")
          .substr(-1, 1) === "、"
          ? this.state.licenceQualificationChoices
              .replace("、、", "")
              .replace(",,", "")
              .slice(0, -1)
          : this.state.licenceQualificationChoices
              .replace("、、", "")
              .replace(",,", "")
              .substr(-1, 1) === ","
          ? this.state.licenceQualificationChoices
              .replace("、、", "")
              .replace(",,", "")
              .slice(0, -1)
          : this.state.licenceQualificationChoices
              .replace("、、", "")
              .replace(",,", "");

      const skillTrainingChoices =
        this.state.skillTrainingChoices
          .replace("、、", "")
          .replace(",,", "")
          .substr(-1, 1) === "、"
          ? this.state.skillTrainingChoices
              .replace("、、", "")
              .replace(",,", "")
              .slice(0, -1)
          : this.state.skillTrainingChoices
              .replace("、、", "")
              .replace(",,", "")
              .substr(-1, 1) === ","
          ? this.state.skillTrainingChoices
              .replace("、、", "")
              .replace(",,", "")
              .slice(0, -1)
          : this.state.skillTrainingChoices
              .replace("、、", "")
              .replace(",,", "");

      const specialTrainingChoices =
        this.state.specialTrainingChoices
          .replace("、、", "")
          .replace(",,", "")
          .substr(-1, 1) === "、"
          ? this.state.specialTrainingChoices
              .replace("、、", "")
              .replace(",,", "")
              .slice(0, -1)
          : this.state.specialTrainingChoices
              .replace("、、", "")
              .replace(",,", "")
              .substr(-1, 1) === ","
          ? this.state.specialTrainingChoices
              .replace("、、", "")
              .replace(",,", "")
              .slice(0, -1)
          : this.state.specialTrainingChoices
              .replace("、、", "")
              .replace(",,", "");

      const occupationChoices =
        this.state.occupationChoices
          .replace("、、", "")
          .replace(",,", "")
          .substr(-1, 1) === "、"
          ? this.state.occupationChoices
              .replace("、、", "")
              .replace(",,", "")
              .slice(0, -1)
          : this.state.occupationChoices
              .replace("、、", "")
              .replace(",,", "")
              .substr(-1, 1) === ","
          ? this.state.occupationChoices
              .replace("、、", "")
              .replace(",,", "")
              .slice(0, -1)
          : this.state.occupationChoices.replace("、、", "").replace(",,", "");

      const primaryCompanies =
        this.state.primaryCompanies
          .replace("、、", "")
          .replace(",,", "")
          .substr(-1, 1) === "、"
          ? this.state.primaryCompanies
              .replace("、、", "")
              .replace(",,", "")
              .slice(0, -1)
          : this.state.primaryCompanies
              .replace("、、", "")
              .replace(",,", "")
              .substr(-1, 1) === ","
          ? this.state.primaryCompanies
              .replace("、、", "")
              .replace(",,", "")
              .slice(0, -1)
          : this.state.primaryCompanies.replace("、、", "").replace(",,", "");

      const input = {
        id: this.state.contractCompanyCode,
        companyName: this.state.companyName,
        agreementUrl: this.state.agreementUrl,
        licenceQualificationChoices,
        skillTrainingChoices,
        specialTrainingChoices,
        occupationChoices,
        primaryCompanies,
      };

      const response = await API.graphql(
        graphqlOperation(updateCompany, {
          input,
        })
      );

      const filterCompany = {
        and: [
          {
            companyId: {
              eq: this.state.contractCompanyCode,
            },
          },
        ],
      };

      const resSiteClearances = await fetchSiteClearances(filterCompany);
      for (let i = 0; i < resSiteClearances.length; i++) {
        const inputSiteClearance = {
          id: resSiteClearances[i].id,
          agreementUrl: this.state.agreementUrl,
          licenceQualificationChoices: this.state.licenceQualificationChoices,
          skillTrainingChoices: this.state.skillTrainingChoices,
          specialTrainingChoices: this.state.specialTrainingChoices,
          occupationChoices: this.state.occupationChoices,
          primaryCompanies: this.state.primaryCompanies,
        };

        const responseSiteClearance = await API.graphql(
          graphqlOperation(updateSiteClearance, {
            input: inputSiteClearance,
          })
        );
      }

      this.props.navigate("/select-site");

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  }

  async fetchRoles() {
    try {
      this.setState({
        isLoading: true,
      });
      const userInfo = await Auth.currentAuthenticatedUser();

      const filterCompanyRole = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      };

      const resCompanyRoles = await fetchCompanyRoles(filterCompanyRole);
      let roleLevel;
      roleLevel = resCompanyRoles[0].roleLevel;
      if (resCompanyRoles[0].roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = resCompanyRoles[0].roleLevel;
      } else {
        const responseUser = await API.graphql(
          graphqlOperation(getUser, {
            id: userInfo.attributes.sub,
          })
        );

        const filterSiteRole = {
          and: [
            {
              siteCode: {
                eq: responseUser.data.getUser.currentSiteId,
              },
            },
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };

        const resSiteRoles = await fetchSiteRoles(filterSiteRole);
        roleLevel = resSiteRoles[0].roleLevel;
      }

      this.setState({
        accessLevel: roleLevel,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchUsers() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
        contractCompanyCode: responseCompany.data.getCompany.id,
        companyName: responseCompany.data.getCompany.companyName,
        agreementUrl: responseCompany.data.getCompany.agreementUrl,
        licenceQualificationChoices:
          responseCompany.data.getCompany.licenceQualificationChoices,
        skillTrainingChoices:
          responseCompany.data.getCompany.skillTrainingChoices,
        specialTrainingChoices:
          responseCompany.data.getCompany.specialTrainingChoices,
        occupationChoices: responseCompany.data.getCompany.occupationChoices,
        primaryCompanies: responseCompany.data.getCompany.primaryCompanies,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  render() {
    const drawerList = ["作業中現場一覧"];

    const navigationList = ["/select-site"];

    if (
      (this.state.isLoading === true) |
      (this.state.accessLevel === 1) |
      (this.state.accessLevel === 2)
    ) {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/select-site")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                企業情報を更新する
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationMenu bottomIndex={null} />
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 3,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Grid item xs={12} mb={2}>
              <Toolbar />
              <Box component="form" sx={{ width: 480 }}>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    契約企業コード
                  </Typography>
                  <RequiredField />
                </Stack>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "400",
                    color: "#383838",
                    mt: 1,
                    mb: 4,
                  }}
                >
                  {this.state.contractCompanyCode}
                </Typography>
                {/* <TextField
                id="contractCompanyCode"
                name="contractCompanyCode"
                type="text"
                value={this.state.contractCompanyCode}
                variant='standard'
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              /> */}
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    契約企業名
                  </Typography>
                  <RequiredField />
                </Stack>
                <TextField
                  id="companyName"
                  placeholder="契約企業名"
                  name="companyName"
                  type="text"
                  value={this.state.companyName}
                  onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    個人情報取扱同意文書
                  </Typography>
                  <RequiredField />
                </Stack>
                {this.state.agreementUrl ? (
                  <Stack
                    direction="row"
                    sx={{
                      mt: 1,
                      mb: 4,
                    }}
                  >
                    <Button
                      href={this.state.agreementUrl}
                      target="_blank"
                      sx={{
                        fontFamily: "Regular",
                        fontSize: 16,
                        fontWeight: "700",
                        color: "#383838",
                        backgroundColor: "#EBE91A",
                        height: 40,
                        borderRadius: 100,
                        paddingHorizontal: 18,
                        pl: 2,
                        pr: 2,
                        // marginBottom: 5,
                        justifyContent: "space-between",
                        mr: 1,
                        "&:hover": {
                          backgroundColor: "#EBE91A",
                        },
                      }}
                    >
                      確認する
                    </Button>
                  </Stack>
                ) : (
                  <TextField
                    id="agreementUrl"
                    placeholder="同意文書URL"
                    name="agreementUrl"
                    type="text"
                    value={this.state.agreementUrl}
                    // onChange={this.handleInputChange}
                    variant="standard"
                    margin="normal"
                    fullWidth
                    sx={{
                      marginTop: 1,
                      marginBottom: 4,
                    }}
                  />
                )}
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    免許・資格選択肢
                  </Typography>
                  {/* <RequiredField /> */}
                </Stack>
                <TextField
                  id="licenceQualificationChoices"
                  placeholder="免許1,免許2"
                  name="licenceQualificationChoices"
                  type="text"
                  value={this.state.licenceQualificationChoices}
                  onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    技能講習選択肢
                  </Typography>
                  {/* <RequiredField /> */}
                </Stack>
                <TextField
                  id="skillTrainingChoices"
                  placeholder="技能1,技能2"
                  name="skillTrainingChoices"
                  type="text"
                  value={this.state.skillTrainingChoices}
                  onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    特別講習選択肢
                  </Typography>
                  {/* <RequiredField /> */}
                </Stack>
                <TextField
                  id="specialTrainingChoices"
                  placeholder="特別1,特別2"
                  name="specialTrainingChoices"
                  type="text"
                  value={this.state.specialTrainingChoices}
                  onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    所属会社職種選択肢
                  </Typography>
                  {/* <RequiredField /> */}
                </Stack>
                <TextField
                  id="occupationChoices"
                  placeholder="農業,林業"
                  name="occupationChoices"
                  type="text"
                  value={this.state.occupationChoices}
                  onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    1次事業者選択肢
                  </Typography>
                  {/* <RequiredField /> */}
                </Stack>
                <TextField
                  id="primaryCompanies"
                  placeholder="A工務店,B工務店"
                  name="primaryCompanies"
                  type="text"
                  value={this.state.primaryCompanies}
                  onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
              </Box>
            </Grid>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/select-site")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                component="div"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                企業情報を更新する
              </Typography>
            </Toolbar>
          </AppBar>
          <NavigationMenu bottomIndex={null} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 0,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Grid item xs={12} mb={2}>
              <Toolbar />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mb: 0,
                  mr: 1,
                }}
              >
                アクセス権限がありません
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  }
}

function EditCompanyScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default EditCompanyScreen;
