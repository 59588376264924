import React from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import LoadingProgress from "../components/LoadingProgress";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import fetchUsers from "../components/queries/FetchUsers";

const theme = createTheme({
  palette: {
    background: {
      default: "#E5E5E5",
    },
  },
});

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      showPassword: false,
      isLoading: false,
      usernameError: null,
      usernameFormatError: null,
      passwordError: null,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEmailInputChange = this.handleEmailInputChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    if (username) {
      this.setState({ username });
    }
    if (password) {
      this.setState({ password });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleEmailInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if (
      /\S+@\S+\.\S+/.test(event.target.value) &&
      !/\s/g.test(event.target.value)
    ) {
      this.setState({
        usernameFormatError: null,
      });
    } else {
      this.setState({
        usernameFormatError: "不正な形式です。",
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.signIn();
  }

  handleClickShowPassword(event) {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleMouseDownPassword(event) {
    event.preventDefault();
  }

  async signIn() {
    try {
      this.setState({
        isLoading: true,
      });
      const rememberPassword =
        document.getElementById("remember-password").checked;
      if (rememberPassword) {
        localStorage.setItem("username", this.state.username);
        localStorage.setItem("password", this.state.password);
      }
      await Auth.signIn(this.state.username, this.state.password);
      const userInfo = await Auth.currentAuthenticatedUser();
      const filter = {
        id: {
          eq: userInfo.attributes.sub,
        },
      };
      const user = await fetchUsers(filter);
      if (user[0].isActive === false) {
        throw new Error("UserNotFoundException");
      }

      this.setState({
        isLoading: false,
      });
      this.props.navigate("/select-site");
    } catch (error) {
      console.log(error);
      if (
        error.code === "UserNotFoundException" ||
        error.message === "UserNotFoundException"
      ) {
        this.setState({
          isLoading: false,
          usernameError: "ユーザーが存在しません",
          passwordError: null,
        });
      }
      if (error.code === "UserNotConfirmedException") {
        this.setState({
          isLoading: false,
          usernameError: "ユーザーは検証されていません",
          passwordError: null,
        });
      }
      if (error.code === "NotAuthorizedException") {
        this.setState({
          isLoading: false,
          passwordError: "ユーザー名またはパスワードが違います",
          usernameError: null,
        });
      }
      if (this.state.username === "") {
        this.setState({
          isLoading: false,
          usernameError: "ユーザー名を入力してください",
        });
      }
      if (this.state.password === "") {
        this.setState({
          isLoading: false,
          passwordError: "パスワードを入力してください",
        });
      }
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <LoadingProgress isLoading={this.state.isLoading} />
            <Box
              sx={{
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 4,
                paddingRight: 4,
                background: "#ffffff",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={require("../assets/images/sagaseru-logo.png")}
                  width="101"
                  height="24"
                />

                <Typography
                  component="div"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 28,
                    fontWeight: "700",
                    color: "#383838",
                    marginBottom: 3,
                  }}
                >
                  ログインする
                </Typography>
              </Box>
              <Box component="form" sx={{ width: 328 }}>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    marginBottom: 0,
                  }}
                >
                  メールアドレス
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#DD3900",
                    mb: 0,
                  }}
                >
                  {this.state.usernameError}
                  {this.state.usernameFormatError}
                </Typography>
                <TextField
                  id="username"
                  placeholder="メールアドレス"
                  name="username"
                  type="text"
                  value={this.state.username}
                  onChange={this.handleEmailInputChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  autoComplete="email"
                  autoFocus
                  sx={{
                    marginTop: 1,
                    marginBottom: 5,
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    marginBottom: 0,
                  }}
                >
                  パスワード
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#DD3900",
                    mb: 0,
                  }}
                >
                  {this.state.passwordError}
                </Typography>
                <FormControl
                  fullWidth
                  variant="standard"
                  sx={{
                    marginTop: 1,
                    marginBottom: 3,
                  }}
                >
                  <Input
                    id="password"
                    placeholder="パスワード"
                    name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.state.password}
                    onChange={this.handleInputChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="remember-password"
                    value="true"
                    color="primary"
                  />
                }
                label="次回から省略"
                sx={{
                  marginBottom: 2,
                }}
              />
              {Boolean(!this.state.usernameFormatError) && (
                <Button
                  type="submit"
                  onClick={this.handleSubmit}
                  fullWidth
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#383838",
                    backgroundColor: "#EBE91A",
                    height: 48,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    marginBottom: 5,
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: "#EBE91A",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-on.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  ログイン
                </Button>
              )}
              {Boolean(this.state.usernameFormatError) && (
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#C8C8C8",
                    backgroundColor: "#E5E5E5",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    marginBottom: 5,
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: "#E5E5E5",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-on.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  ログイン
                </Button>
              )}
              <Button
                onClick={() => this.props.navigate("/forgot-password")}
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "700",
                  color: "#383838",
                  paddingLeft: 0,
                  marginBottom: 0,
                  "&:hover": {
                    backgroundColor: "#F9F9F9",
                  },
                }}
              >
                パスワードをお忘れの方
              </Button>
              <Button
                onClick={() => this.props.navigate("/sign-up")}
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "700",
                  color: "#383838",
                  paddingLeft: 0,
                  marginTop: 0,
                  "&:hover": {
                    backgroundColor: "#F9F9F9",
                  },
                }}
              >
                新規会員登録
              </Button>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

function SignIn(props) {
  const navigate = useNavigate();
  return <InputComponent {...props} navigate={navigate} />;
}

export default SignIn;
