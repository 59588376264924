import React, { useState, useRef, useCallback } from "react";
import {
  DataGrid,
  jaJP,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  Box,
  Snackbar,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import UpdateVendorWrapper from "./mutations/UpdateVendor";
import CreateVendorHistoryWrapper from "./mutations/CreateVendorHistory";
import validateVendorCode from "../components/utils/validateVendorCode";
import { format } from "date-fns";
import { Auth } from "aws-amplify";

const columns = [
  {
    field: "vendorCode",
    headerName: "業者コード",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: true,
    flex: 1,
  },
  {
    field: "vendorName",
    headerName: "1次業者名",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: true,
    flex: 1,
  },
  {
    field: "isActive",
    headerName: "ステータス",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: true,
    type: "singleSelect",
    valueOptions: ["有効", "無効"],
    flex: 1,
    hide: true,
    valueGetter: (params) => (params.value !== false ? "有効" : "無効"), // Nullも'有効'扱い
  },
  {
    field: "createdAt",
    headerName: "登録日",
    headerClassName: "super-app-theme--header",
    width: 200,
    editable: false,
    valueFormatter: (params) =>
      format(
        new Date(
          new Date(params.value).toLocaleString("ja-JP", { timeZone: "Japan" })
        ),
        "yyyy-MM-dd"
      ),
  },
  {
    field: "updatedAt",
    headerName: "更新日",
    headerClassName: "super-app-theme--header",
    width: 200,
    editable: false,
    valueGetter: (params) =>
      format(
        new Date(
          new Date(params.row.updatedAt).toLocaleString("ja-JP", {
            timeZone: "Japan",
          })
        ),
        "yyyy-MM-dd"
      ),
  },
];

function computeMutation(newRow, oldRow) {
  if (
    newRow.vendorName !== oldRow.vendorName ||
    newRow.vendorCode !== oldRow.vendorCode ||
    newRow.isActive !== oldRow.isActive
  ) {
    return true;
  }
  return null;
}

export default function VendorListTable({
  rows,
  setTargetArray,
  targetArray,
  liftUpSnackbar,
  handleLiftUpCloseSnackbar,
}) {
  const noButtonRef = useRef(null);

  const [pageSize, setPageSize] = useState(10);
  const [promiseArguments, setPromiseArguments] = useState(null);
  const [snackbar, setSnackbar] = useState(null);
  const [filterButtonEl, setFilterButtonEl] = useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow);
        }
      }),
    []
  );

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow);
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;
    try {
      if (newRow.vendorCode !== oldRow.vendorCode) {
        const isDuplicate = await validateVendorCode(newRow.vendorCode);
        if (isDuplicate) {
          setSnackbar({ children: "更新に失敗しました。", severity: "error" });
          resolve(oldRow);
          setPromiseArguments(null);
          return;
        }
      }

      const userInfo = await Auth.currentAuthenticatedUser();
      newRow.userInfo = userInfo.attributes;
      await Promise.all([
        CreateVendorHistoryWrapper(newRow, oldRow),
        UpdateVendorWrapper(newRow, oldRow),
      ]);
      setSnackbar({ children: "更新しました。", severity: "success" });
      resolve(newRow);
      setPromiseArguments(null);
    } catch (error) {
      setSnackbar({ children: "更新に失敗しました。", severity: "error" });
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    return (
      <Dialog maxWidth="xs" open={!!promiseArguments}>
        <DialogTitle>業者の情報を更新されますか?</DialogTitle>
        <DialogContent dividers>
          {`更新される場合は、「はい」を押して下さい。`}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes}>はい</Button>
          <Button ref={noButtonRef} onClick={handleNo}>
            いいえ
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const CustomToolbar = useCallback(() => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton ref={setFilterButtonEl} />
        <GridToolbarExport
          ref={setFilterButtonEl}
          csvOptions={{ utf8WithBom: true }}
        />
      </GridToolbarContainer>
    );
  }, []);
  return (
    <Box
      sx={{
        height: "90vh",
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: "#F9F9F9",
          border: 0,
          pl: 0,
          pt: 1,
          pb: 1,
          fontFamily: "Regular",
          fontSize: 12,
          fontWeight: "400",
          color: "#8D8D8D",
        },
        "& .super-app-theme--rows": {
          backgroundColor: "#F9F9F9",
          borderBottom: 1,
          borderColor: "#E5E5E5",
          pl: 0,
          fontFamily: "Regular",
          fontSize: 15,
          fontWeight: "400",
          color: "#383838",
          "&:hover": {
            backgroundColor: "#E3E9FA",
            borderBottom: 1,
            borderColor: "#99B4E3",
          },
        },
      }}
    >
      {renderConfirmDialog()}
      {rows && (
        <DataGrid
          rows={rows}
          columns={columns}
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
          onProcessRowUpdateError={(error) => console.log(error)}
          selectionModel={targetArray}
          onSelectionModelChange={setTargetArray}
          checkboxSelection
          sx={{
            borderTop: 1,
            borderBottom: 0,
            borderRight: 0,
            borderLeft: 0,
            borderRadius: 0,
            borderColor: "#E5E5E5",
          }}
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          getRowClassName={(params) =>
            `${params.row.isActive === false ? "grayedOut" : ""}`
          }
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
          rowsPerPageOptions={[10, 50, 100]}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            panel: {
              anchorEl: filterButtonEl,
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "vendorCode", sort: "asc" }],
            },
          }}
        />
      )}
      {!!snackbar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      {!!liftUpSnackbar && (
        <Dialog open={true}>
          <DialogContent sx={{ margin: 0, padding: 0 }}>
            <Alert {...liftUpSnackbar} onClose={handleLiftUpCloseSnackbar} />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}
