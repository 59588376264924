import { format } from "date-fns";

export const ERROR_MESSAGES = {
  entryAfterPreExit: "前回の退場時刻以前に入場時刻を修正することはできません",
  entryBeforeTheExit:
    "入場時刻を退場時刻より未来の日時に修正することはできません",
  entryBeforeFuture: "入場時刻を未来の日時に修正することはできません",
  exitAfterTheEntry: "退場時刻を入場時刻前に修正することはできません",
  exitBeforeNextEntry: "退場時刻は翌入場時刻以前でなければいけません",
  exitBeforeFuture: "退場時刻を未来の日時に修正することはできません",
};

export const ROLES = [
  "開発者",
  "企業管理者",
  "企業管理者(閲覧のみ)",
  "現場管理者",
  "チームメンバー",
];

export const DRAWER_WIDTH = 280;

export const DEFAULT_ROLE_LEVEL = 10;

export const SITE_COLUMNS = [
  {
    field: "siteName",
    headerName: "現場名",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "masterCode",
    headerName: "案件管理番号",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "address1",
    headerName: "自宅住所１（都道府県）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "address2",
    headerName: "自宅住所２（市区町村）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "address3",
    headerName: "自宅住所３（それ以降）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "city",
    headerName: "都道府県",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "zipCode",
    headerName: "自宅郵便番号（ハイフンなし）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "notesFileUrl",
    headerName: "注意事項等ファイルURL",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "qrCodeDescription",
    headerName: "qrコード情報",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText1",
    headerName: "チェック内容文章1",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText1",
    headerName: "選択肢文字列1",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText2",
    headerName: "チェック内容文章2",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText2",
    headerName: "選択肢文字列2",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText3",
    headerName: "チェック内容文章3",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText3",
    headerName: "選択肢文字列3",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText4",
    headerName: "チェック内容文章4",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText4",
    headerName: "選択肢文字列4",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText5",
    headerName: "チェック内容文章5",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText5",
    headerName: "選択肢文字列5",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText6",
    headerName: "チェック内容文章6",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText6",
    headerName: "選択肢文字列6",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText7",
    headerName: "チェック内容文章7",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText7",
    headerName: "選択肢文字列7",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText8",
    headerName: "チェック内容文章8",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText8",
    headerName: "選択肢文字列8",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText9",
    headerName: "チェック内容文章9",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText9",
    headerName: "選択肢文字列9",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText10",
    headerName: "チェック内容文章10",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText10",
    headerName: "選択肢文字列10",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText11",
    headerName: "チェック内容文章11",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText11",
    headerName: "選択肢文字列11",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText12",
    headerName: "チェック内容文章12",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText12",
    headerName: "選択肢文字列12",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText13",
    headerName: "チェック内容文章13",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText13",
    headerName: "選択肢文字列13",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText14",
    headerName: "チェック内容文章14",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText14",
    headerName: "選択肢文字列14",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText15",
    headerName: "チェック内容文章15",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText15",
    headerName: "選択肢文字列15",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText16",
    headerName: "チェック内容文章16",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText16",
    headerName: "選択肢文字列16",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText17",
    headerName: "チェック内容文章17",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText17",
    headerName: "選択肢文字列17",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText18",
    headerName: "チェック内容文章18",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText18",
    headerName: "選択肢文字列18",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText19",
    headerName: "チェック内容文章19",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText19",
    headerName: "選択肢文字列19",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText20",
    headerName: "チェック内容文章20",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText20",
    headerName: "選択肢文字列20",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText21",
    headerName: "チェック内容文章21",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText21",
    headerName: "選択肢文字列21",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText22",
    headerName: "チェック内容文章22",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText22",
    headerName: "選択肢文字列22",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText23",
    headerName: "チェック内容文章23",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText23",
    headerName: "選択肢文字列23",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText24",
    headerName: "チェック内容文章24",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText24",
    headerName: "選択肢文字列24",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText25",
    headerName: "チェック内容文章25",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText25",
    headerName: "選択肢文字列25",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText26",
    headerName: "チェック内容文章26",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText26",
    headerName: "選択肢文字列26",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText27",
    headerName: "チェック内容文章27",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText27",
    headerName: "選択肢文字列27",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText28",
    headerName: "チェック内容文章28",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText28",
    headerName: "選択肢文字列28",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText29",
    headerName: "チェック内容文章29",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText29",
    headerName: "選択肢文字列29",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText30",
    headerName: "チェック内容文章30",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText30",
    headerName: "選択肢文字列30",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText31",
    headerName: "チェック内容文章31",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText31",
    headerName: "選択肢文字列31",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText32",
    headerName: "チェック内容文章32",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText32",
    headerName: "選択肢文字列32",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText33",
    headerName: "チェック内容文章33",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText33",
    headerName: "選択肢文字列33",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText34",
    headerName: "チェック内容文章34",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText34",
    headerName: "選択肢文字列34",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText35",
    headerName: "チェック内容文章35",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText35",
    headerName: "選択肢文字列35",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText36",
    headerName: "チェック内容文章36",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText36",
    headerName: "選択肢文字列36",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText37",
    headerName: "チェック内容文章37",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText37",
    headerName: "選択肢文字列37",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText38",
    headerName: "チェック内容文章38",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText38",
    headerName: "選択肢文字列38",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText39",
    headerName: "チェック内容文章39",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText39",
    headerName: "選択肢文字列39",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText40",
    headerName: "チェック内容文章40",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText40",
    headerName: "選択肢文字列40",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText41",
    headerName: "チェック内容文章41",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText41",
    headerName: "選択肢文字列41",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText42",
    headerName: "チェック内容文章42",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText42",
    headerName: "選択肢文字列42",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText43",
    headerName: "チェック内容文章43",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText43",
    headerName: "選択肢文字列43",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText44",
    headerName: "チェック内容文章44",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText44",
    headerName: "選択肢文字列44",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText45",
    headerName: "チェック内容文章45",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText45",
    headerName: "選択肢文字列45",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText46",
    headerName: "チェック内容文章46",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText46",
    headerName: "選択肢文字列46",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText47",
    headerName: "チェック内容文章47",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText47",
    headerName: "選択肢文字列47",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText48",
    headerName: "チェック内容文章48",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText48",
    headerName: "選択肢文字列48",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText49",
    headerName: "チェック内容文章49",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText49",
    headerName: "選択肢文字列49",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText50",
    headerName: "チェック内容文章50",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText50",
    headerName: "選択肢文字列50",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText51",
    headerName: "チェック内容文章51",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText51",
    headerName: "選択肢文字列51",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText52",
    headerName: "チェック内容文章52",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText52",
    headerName: "選択肢文字列52",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText53",
    headerName: "チェック内容文章53",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText53",
    headerName: "選択肢文字列53",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText54",
    headerName: "チェック内容文章54",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText54",
    headerName: "選択肢文字列54",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText55",
    headerName: "チェック内容文章55",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText55",
    headerName: "選択肢文字列55",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText56",
    headerName: "チェック内容文章56",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText56",
    headerName: "選択肢文字列56",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText57",
    headerName: "チェック内容文章57",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText57",
    headerName: "選択肢文字列57",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText58",
    headerName: "チェック内容文章58",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText58",
    headerName: "選択肢文字列58",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText59",
    headerName: "チェック内容文章59",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText59",
    headerName: "選択肢文字列59",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText60",
    headerName: "チェック内容文章60",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText60",
    headerName: "選択肢文字列60",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText61",
    headerName: "チェック内容文章61",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText61",
    headerName: "選択肢文字列61",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText62",
    headerName: "チェック内容文章62",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText62",
    headerName: "選択肢文字列62",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText63",
    headerName: "チェック内容文章63",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText63",
    headerName: "選択肢文字列63",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText64",
    headerName: "チェック内容文章64",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText64",
    headerName: "選択肢文字列64",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText65",
    headerName: "チェック内容文章65",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText65",
    headerName: "選択肢文字列65",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText66",
    headerName: "チェック内容文章66",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText66",
    headerName: "選択肢文字列66",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText67",
    headerName: "チェック内容文章67",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText67",
    headerName: "選択肢文字列67",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText68",
    headerName: "チェック内容文章68",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText68",
    headerName: "選択肢文字列68",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText69",
    headerName: "チェック内容文章69",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText69",
    headerName: "選択肢文字列69",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText70",
    headerName: "チェック内容文章70",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText70",
    headerName: "選択肢文字列70",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText71",
    headerName: "チェック内容文章71",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText71",
    headerName: "選択肢文字列71",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText72",
    headerName: "チェック内容文章72",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText72",
    headerName: "選択肢文字列72",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText73",
    headerName: "チェック内容文章73",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText73",
    headerName: "選択肢文字列73",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText74",
    headerName: "チェック内容文章74",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText74",
    headerName: "選択肢文字列74",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText75",
    headerName: "チェック内容文章75",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText75",
    headerName: "選択肢文字列75",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText76",
    headerName: "チェック内容文章76",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText76",
    headerName: "選択肢文字列76",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText77",
    headerName: "チェック内容文章77",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText77",
    headerName: "選択肢文字列77",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText78",
    headerName: "チェック内容文章78",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText78",
    headerName: "選択肢文字列78",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText79",
    headerName: "チェック内容文章79",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText79",
    headerName: "選択肢文字列79",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText80",
    headerName: "チェック内容文章80",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText80",
    headerName: "選択肢文字列80",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText81",
    headerName: "チェック内容文章81",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText81",
    headerName: "選択肢文字列81",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText82",
    headerName: "チェック内容文章82",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText82",
    headerName: "選択肢文字列82",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText83",
    headerName: "チェック内容文章83",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText83",
    headerName: "選択肢文字列83",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText84",
    headerName: "チェック内容文章84",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText84",
    headerName: "選択肢文字列84",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText85",
    headerName: "チェック内容文章85",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText85",
    headerName: "選択肢文字列85",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText86",
    headerName: "チェック内容文章86",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText86",
    headerName: "選択肢文字列86",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText87",
    headerName: "チェック内容文章87",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText87",
    headerName: "選択肢文字列87",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText88",
    headerName: "チェック内容文章88",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText88",
    headerName: "選択肢文字列88",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText89",
    headerName: "チェック内容文章89",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText89",
    headerName: "選択肢文字列89",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText90",
    headerName: "チェック内容文章90",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText90",
    headerName: "選択肢文字列90",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText91",
    headerName: "チェック内容文章91",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText91",
    headerName: "選択肢文字列91",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText92",
    headerName: "チェック内容文章92",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText92",
    headerName: "選択肢文字列92",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText93",
    headerName: "チェック内容文章93",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText93",
    headerName: "選択肢文字列93",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText94",
    headerName: "チェック内容文章94",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText94",
    headerName: "選択肢文字列94",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText95",
    headerName: "チェック内容文章95",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText95",
    headerName: "選択肢文字列95",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText96",
    headerName: "チェック内容文章96",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText96",
    headerName: "選択肢文字列96",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText97",
    headerName: "チェック内容文章97",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText97",
    headerName: "選択肢文字列97",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText98",
    headerName: "チェック内容文章98",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText98",
    headerName: "選択肢文字列98",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText99",
    headerName: "チェック内容文章99",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText99",
    headerName: "選択肢文字列99",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText100",
    headerName: "チェック内容文章100",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText100",
    headerName: "選択肢文字列100",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyId",
    headerName: "企業名",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "vendorIds",
    headerName: "一次業者IDs",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "登録日",
    headerClassName: "super-app-theme--header",
    width: 200,
    editable: false,
    valueFormatter: (params) =>
      format(
        new Date(
          new Date(params.value).toLocaleString("ja-JP", { timeZone: "Japan" })
        ),
        "yyyy-MM-dd"
      ),
  },
  {
    field: "updatedAt",
    headerName: "更新日",
    headerClassName: "super-app-theme--header",
    width: 200,
    editable: false,
    valueGetter: (params) =>
      format(
        new Date(
          new Date(params.row.updatedAt).toLocaleString("ja-JP", {
            timeZone: "Japan",
          })
        ),
        "yyyy-MM-dd"
      ),
  },
];

export const SITE_CLEARANCE_COLUMNS = [
  {
    field: "id",
    headerName: "id",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "invitationCode",
    headerName: "招待コード",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "qrCodeDescription",
    headerName: "qrコード情報",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "masterCode",
    headerName: "案件管理番号",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "siteName",
    headerName: "現場名",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "siteAddress",
    headerName: "現場住所",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "applyStatus",
    headerName: "入場申請ステータス",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "startDate",
    headerName: "入場開始日",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "endDate",
    headerName: "入場終了日",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "notesFileUrl",
    headerName: "注意事項等ファイルURL",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "fieldUserId",
    headerName: "フィールドユーザーID",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "isEnteredBasicInfo",
    headerName: "基本情報入力済みフラグ",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "name",
    headerName: "名前",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "familyName",
    headerName: "氏名（姓）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "firstName",
    headerName: "氏名（名）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "familyNameKana",
    headerName: "姓(セイ)",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "firstNameKana",
    headerName: "氏名かな（姓）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "gender",
    headerName: "性別",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "zipCode",
    headerName: "自宅郵便番号（ハイフンなし）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "address1",
    headerName: "自宅住所１（都道府県）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "address2",
    headerName: "自宅住所２（市区町村）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "address3",
    headerName: "自宅住所３（それ以降）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "mobileNumber",
    headerName: "携帯電話番号（ハイフンなし）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "email",
    headerName: "Eメールアドレス",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "birthDate",
    headerName: "生年月日",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "emergencyContactName",
    headerName: "緊急連絡先（家族等）氏名",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "emergencyContactTell",
    headerName: "緊急連絡先（家族等）電話番号",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "isSelfEmployment",
    headerName: "個人事業主",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyName",
    headerName: "所属会社名",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyCEOName",
    headerName: "所属会社代表者名（フルネーム）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyZipCode",
    headerName: "所属会社郵便番号（ハイフンなし）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyAddress1",
    headerName: "所属会社住所１（都道府県）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyAddress2",
    headerName: "所属会社住所２（市区町村）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyAddress3",
    headerName: "所属会社住所３（それ以降）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyTell",
    headerName: "所属会社電話番号（ハイフンなし）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyOccupation",
    headerName: "所属会社職種",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "experienceYearPresent",
    headerName: "経験年数現在の会社（年）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "experienceMonthPresent",
    headerName: "経験年数現在の会社（月）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "experienceYearTotal",
    headerName: "経験年数通算（年）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "experienceMonthTotal",
    headerName: "経験年数通算（月）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "licenceQualification",
    headerName: "免許・資格",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "licenceQualificationOthers",
    headerName: "免許・資格（その他）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "skillTraining",
    headerName: "技能講習",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "skillTrainingOthers",
    headerName: "技能講習（その他）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "specialTraining",
    headerName: "特別講習",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "specialTrainingOthers",
    headerName: "特別講習（その他）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "isForeigner",
    headerName: "外国人労働者",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "residenceCardNumber1",
    headerName: "在留カードNo.（国籍）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "residenceCardNumber2",
    headerName: "在留カードNo.（番号）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "residenceCardNumber3",
    headerName: "在留カードNo.（資格区分）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "stayPeriod",
    headerName: "在留期限",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "isSMEOwnerOrSingleMaster",
    headerName: "中小事業主・一人親方",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "insuranceGroupName",
    headerName: "政府労災保険加入団体名（民間保険不可）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "insuranceNumber1",
    headerName: "政府労災保険労災保険No.（管轄）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "insuranceNumber2",
    headerName: "政府労災保険労災保険No.（基幹番号）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "insuranceNumber3",
    headerName: "政府労災保険労災保険No.（枝番号）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "insuranceExpirationDate",
    headerName: "政府労災保険有効期限",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "medicalCheckupCategory",
    headerName: "健康診断の受診区分",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "medicalCheckupLatestDate",
    headerName: "直近の健康診断受診年月日",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "bloodPressureMax",
    headerName: "平常時血圧最高",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "bloodPressureMin",
    headerName: "平常時血圧最低",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "bloodType",
    headerName: "血液型",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "anamnesis",
    headerName: "既往症",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "anamnesisOthers",
    headerName: "既往症（その他）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "canWorkWithoutProblems",
    headerName: "既往症ありの場合制限なく作業可能か",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "lastUpdateDate",
    headerName: "基本情報最終更新日",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "licenceQualificationChoices",
    headerName: "免許・資格選択肢",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "skillTrainingChoices",
    headerName: "技能講習選択肢",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "specialTrainingChoices",
    headerName: "特別講習選択肢",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "occupationChoices",
    headerName: "所属会社職種選択肢",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "primaryCompanies",
    headerName: "一次事業者選択肢",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "agreementUrl",
    headerName: "同意文書URL",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "pihAgreementFlag",
    headerName: "個人情報取扱同意フラグ",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "checkListInfo",
    headerName: "現場固有情報",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "contentText",
    headerName: "チェック内容文章",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "choiceText",
    headerName: "選択肢文字列",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyOrder",
    headerName: "所属会社次数",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "primaryCompanyName",
    headerName: "第一次事業者名",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "secondaryCompanyName",
    headerName: "第二次事業者名",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "thirdCompanyName",
    headerName: "第三次事業者名",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "fourthCompanyName",
    headerName: "第四次事業者名",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "groupName",
    headerName: "所属班名",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "isForeman",
    headerName: "職長・安全衛生責任者",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "youthElderly",
    headerName: "年少者・高年齢者",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "notes",
    headerName: "特記事項",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "normKnowledge",
    headerName: "心得",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "agreementOfYouth",
    headerName: "年少者の就労制限同意",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "agreementOfElderly",
    headerName: "高年齢者の就労制限同意",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "shootingProhibited",
    headerName: "撮影禁止",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "snsPostingProhibited",
    headerName: "SNS投稿禁止",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "disclosureProhibited",
    headerName: "情報開示禁止",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "discardCopyProhibited",
    headerName: "図面破棄複写禁止",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "observancePledge",
    headerName: "ルール守秘義務遵守誓約",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "checkListInputContents",
    headerName: "現場固有入力情報",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "accessLevel",
    headerName: "権限",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyId",
    headerName: "企業名",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "siteCode",
    headerName: "現場コード",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "userId",
    headerName: "userId",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "登録日",
    headerClassName: "super-app-theme--header",
    width: 200,
    editable: false,
    valueFormatter: (params) =>
      format(
        new Date(
          new Date(params.value).toLocaleString("ja-JP", { timeZone: "Japan" })
        ),
        "yyyy-MM-dd"
      ),
  },
  {
    field: "updatedAt",
    headerName: "更新日",
    headerClassName: "super-app-theme--header",
    width: 200,
    editable: false,
    valueGetter: (params) =>
      format(
        new Date(
          new Date(params.row.updatedAt).toLocaleString("ja-JP", {
            timeZone: "Japan",
          })
        ),
        "yyyy-MM-dd"
      ),
  },
];

export const FREEID_USER_COLUMNS = [
  {
    field: "isEnteredBasicInfo",
    headerName: "基本情報入力済みフラグ",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "familyName",
    headerName: "氏名（姓）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "firstName",
    headerName: "氏名（名）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "familyNameKana",
    headerName: "氏名かな（姓）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "firstNameKana",
    headerName: "氏名かな（名）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "gender",
    headerName: "性別",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "zipCode",
    headerName: "自宅郵便番号（ハイフンなし）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "address1",
    headerName: "自宅住所１（都道府県）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "address2",
    headerName: "自宅住所２（市区町村）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "address3",
    headerName: "自宅住所３（それ以降）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "mobileNumber",
    headerName: "携帯電話番号（ハイフンなし）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "email",
    headerName: "Eメールアドレス",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "birthDate",
    headerName: "生年月日",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "emergencyContactName",
    headerName: "緊急連絡先（家族等）氏名",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "emergencyContactTell",
    headerName: "緊急連絡先（家族等）電話番号",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "isSelfEmployment",
    headerName: "個人事業主",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyName",
    headerName: "所属会社名",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyCEOName",
    headerName: "所属会社代表者名（フルネーム）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyZipCode",
    headerName: "所属会社郵便番号（ハイフンなし）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyAddress1",
    headerName: "所属会社住所１（都道府県）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyAddress2",
    headerName: "所属会社住所２（市区町村）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyAddress3",
    headerName: "所属会社住所３（それ以降）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyTell",
    headerName: "所属会社電話番号（ハイフンなし）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyOccupation",
    headerName: "所属会社職種",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "experienceYearPresent",
    headerName: "経験年数現在の会社（年）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "experienceMonthPresent",
    headerName: "経験年数現在の会社（月）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "experienceYearTotal",
    headerName: "経験年数通算（年）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "experienceMonthTotal",
    headerName: "経験年数通算（月）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "licenceQualification",
    headerName: "免許・資格",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "licenceQualificationOthers",
    headerName: "免許・資格（その他）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "skillTraining",
    headerName: "技能講習",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "skillTrainingOthers",
    headerName: "技能講習（その他）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "specialTraining",
    headerName: "特別講習",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "specialTrainingOthers",
    headerName: "特別講習（その他）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "isForeigner",
    headerName: "外国人労働者",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "residenceCardNumber1",
    headerName: "在留カードNo.（国籍）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "residenceCardNumber2",
    headerName: "在留カードNo.（番号）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "residenceCardNumber3",
    headerName: "在留カードNo.（資格区分）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "stayPeriod",
    headerName: "在留期限",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "isSMEOwnerOrSingleMaster",
    headerName: "中小事業主・一人親方",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "insuranceGroupName",
    headerName: "政府労災保険加入団体名（民間保険不可）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "insuranceNumber1",
    headerName: "政府労災保険労災保険No.（管轄）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "insuranceNumber2",
    headerName: "政府労災保険労災保険No.（基幹番号）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "insuranceNumber3",
    headerName: "政府労災保険労災保険No.（枝番号）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "insuranceExpirationDate",
    headerName: "政府労災保険有効期限",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "medicalCheckupCategory",
    headerName: "健康診断の受診区分",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "medicalCheckupLatestDate",
    headerName: "直近の健康診断受診年月日",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "bloodPressureMax",
    headerName: "平常時血圧最高",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "bloodPressureMin",
    headerName: "平常時血圧最低",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "bloodType",
    headerName: "血液型",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "anamnesis",
    headerName: "既往症",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "anamnesisOthers",
    headerName: "既往症（その他）",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "canWorkWithoutProblems",
    headerName: "既往症ありの場合制限なく作業可能か",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "lastUpdateDate",
    headerName: "基本情報最終更新日",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "companyId",
    headerName: "企業名",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: true,
    editable: false,
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "登録日",
    headerClassName: "super-app-theme--header",
    width: 200,
    editable: false,
    valueFormatter: (params) =>
      format(
        new Date(
          new Date(params.value).toLocaleString("ja-JP", { timeZone: "Japan" })
        ),
        "yyyy-MM-dd"
      ),
  },
  {
    field: "updatedAt",
    headerName: "更新日",
    headerClassName: "super-app-theme--header",
    width: 200,
    editable: false,
    valueGetter: (params) =>
      format(
        new Date(
          new Date(params.row.updatedAt).toLocaleString("ja-JP", {
            timeZone: "Japan",
          })
        ),
        "yyyy-MM-dd"
      ),
  },
];
