import { updateVendor } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";

export default async function UpdateVendorWrapper(newRow, oldRow) {
  try {
    let input = {};
    if (newRow.vendorName !== oldRow.vendorName) {
      input = {
        id: newRow.id,
        vendorName: newRow.vendorName,
      };
    } else if (newRow.vendorCode !== oldRow.vendorCode) {
      input = {
        id: newRow.id,
        vendorCode: newRow.vendorCode,
      };
    } else if (newRow.isActive !== oldRow.isActive) {
      input = {
        id: newRow.id,
        isActive: newRow.isActive,
      };
    }

    const response = await API.graphql(
      graphqlOperation(updateVendor, {
        input,
      })
    );
  } catch (error) {
    console.log(error);
  }
}
