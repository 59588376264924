import { API, graphqlOperation } from "aws-amplify";
import { listVendors } from "../../graphql/queries";

export default async function fetchVendors(filter = null) {
  try {
    const vendorList = [];
    let response;
    response = await API.graphql(
      graphqlOperation(listVendors, {
        filter: filter,
        limit: 500,
      })
    );

    vendorList.push(...response.data.listVendors.items);
    while (response.data.listVendors.nextToken !== null) {
      response = await API.graphql(
        graphqlOperation(listVendors, {
          filter: filter,
          limit: 500,
          nextToken: response.data.listVendors.nextToken,
        })
      );
      vendorList.push(...response.data.listVendors.items);
    }
    return vendorList;
  } catch (error) {
    console.log(error);
  }
}
