import { API, graphqlOperation } from "aws-amplify";
import { listEnterExitHistorys } from "../../graphql/queries";

export default async function fetchEnterExitHistorys(
  filter = null,
  limit = 500,
  orderBy = { sortDirection: "DESC", fieldName: "createdAt" }
) {
  try {
    const enterExitHistoryList = [];
    let response;
    response = await API.graphql(
      graphqlOperation(listEnterExitHistorys, {
        filter,
        limit,
        orderBy,
      })
    );

    enterExitHistoryList.push(...response.data.listEnterExitHistorys.items);
    while (response.data.listEnterExitHistorys.nextToken !== null) {
      response = await API.graphql(
        graphqlOperation(listEnterExitHistorys, {
          filter: filter,
          limit: 500,
          nextToken: response.data.listEnterExitHistorys.nextToken,
        })
      );
      enterExitHistoryList.push(...response.data.listEnterExitHistorys.items);
    }
    return enterExitHistoryList;
  } catch (error) {
    console.log(error);
  }
}
