import { Auth } from "aws-amplify";
import fetchCustomCompanyVendors from "../queries/FetchCustomCompanyVendors";

export default async function customCompanyToVendors() {
  const userInfo = await Auth.currentAuthenticatedUser();
  const listCompanyVendors = await fetchCustomCompanyVendors({
    companyId: {
      eq: userInfo.attributes["custom:company_id"],
    },
  });

  const customListCompanyVendors = listCompanyVendors
    .filter((CompanyVendor) => CompanyVendor.vendor)
    .map((CompanyVendor) => ({
      id: CompanyVendor.vendorId,
      companyId: CompanyVendor.companyId,
      vendorName: CompanyVendor.vendor.vendorName,
      vendorCode: CompanyVendor.vendor.vendorCode,
      isActive: CompanyVendor.vendor.isActive,
    }));

  return customListCompanyVendors;
}
