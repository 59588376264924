import * as React from "react";
import { useRef } from "react";
import { Auth, API, graphqlOperation, Storage } from "aws-amplify";
import { getUser, getSite, getCompany } from "../graphql/queries";
import {
  createSiteAndSiteVendor,
  createSiteRole,
  createSiteClearance,
  createSiteClearanceWithInvitation,
} from "../graphql/mutations";
import {
  Alert,
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  Snackbar,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import NavigationMenu from "../components/NavigationMenu";
import LoadingProgress from "../components/LoadingProgress";
import makeDrawerList from "../components/utils/makeDrawerList";
import fetchVendors from "../components/queries/FetchVendors";
import fetchUsers from "../components/queries/FetchUsers";
import fetchSites from "../components/queries/FetchSites";
import createVendorWrapper from "../components/mutations/CreateVendor";
import createCompanyVendorWrapper from "../components/mutations/CreateCompanyVendor";
import { DEFAULT_ROLE_LEVEL } from "../components/utils/constants";
import getRoleLevel from "../components/utils/getRoleLevel";
import RequiredField from "../components/RequiredField";
import SearchBox from "../components/SearchBox";
import SearchResults from "../components/SearchResults";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { sha256 } from "crypto-hash";
const schedule = require("node-schedule");
const _ = require("lodash");
const ExcelJS = require("exceljs");

const drawerWidth = 280;

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "80%" },
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: { sm: 4, xs: 1 },
};

const closeButtonStyle = {
  height: 0,
  textAlign: "right",
};

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      companyDetails: "",
      itemList: "",
      siteList: [],
      siteId: "",
      accessLevel: "",
      isLoading: false,
      mobileOpen: false,
      drawerList: [],
      navigationList: [],
      companyMembers: [],
      primaryVendorMembers: [],
      open: false,
      setOpen: false,
      modalText: [],
      masterCode: "",
      siteName: "",
      address1: "",
      notesFileUrl: "",
      notesFile: "",
      contentText1: "",
      choiceText1: "",
      contentText2: "",
      choiceText2: "",
      contentText3: "",
      choiceText3: "",
      contentText4: "",
      choiceText4: "",
      contentText5: "",
      choiceText5: "",
      contentText6: "",
      choiceText6: "",
      contentText7: "",
      choiceText7: "",
      contentText8: "",
      choiceText8: "",
      contentText9: "",
      choiceText9: "",
      contentText10: "",
      choiceText10: "",
      contentText11: "",
      choiceText11: "",
      contentText12: "",
      choiceText12: "",
      contentText13: "",
      choiceText13: "",
      contentText14: "",
      choiceText14: "",
      contentText15: "",
      choiceText15: "",
      contentText16: "",
      choiceText16: "",
      contentText17: "",
      choiceText17: "",
      contentText18: "",
      choiceText18: "",
      contentText19: "",
      choiceText19: "",
      contentText20: "",
      choiceText20: "",
      contentText21: "",
      choiceText21: "",
      contentText22: "",
      choiceText22: "",
      contentText23: "",
      choiceText23: "",
      contentText24: "",
      choiceText24: "",
      contentText25: "",
      choiceText25: "",
      contentText26: "",
      choiceText26: "",
      contentText27: "",
      choiceText27: "",
      contentText28: "",
      choiceText28: "",
      contentText29: "",
      choiceText29: "",
      contentText30: "",
      choiceText30: "",
      contentText31: "",
      choiceText31: "",
      contentText32: "",
      choiceText32: "",
      contentText33: "",
      choiceText33: "",
      contentText34: "",
      choiceText34: "",
      contentText35: "",
      choiceText35: "",
      contentText36: "",
      choiceText36: "",
      contentText37: "",
      choiceText37: "",
      contentText38: "",
      choiceText38: "",
      contentText39: "",
      choiceText39: "",
      contentText40: "",
      choiceText40: "",
      contentText41: "",
      choiceText41: "",
      contentText42: "",
      choiceText42: "",
      contentText43: "",
      choiceText43: "",
      contentText44: "",
      choiceText44: "",
      contentText45: "",
      choiceText45: "",
      contentText46: "",
      choiceText46: "",
      contentText47: "",
      choiceText47: "",
      contentText48: "",
      choiceText48: "",
      contentText49: "",
      choiceText49: "",
      contentText50: "",
      choiceText50: "",
      contentText51: "",
      choiceText51: "",
      contentText52: "",
      choiceText52: "",
      contentText53: "",
      choiceText53: "",
      contentText54: "",
      choiceText54: "",
      contentText55: "",
      choiceText55: "",
      contentText56: "",
      choiceText56: "",
      contentText57: "",
      choiceText57: "",
      contentText58: "",
      choiceText58: "",
      contentText59: "",
      choiceText59: "",
      contentText60: "",
      choiceText60: "",
      contentText61: "",
      choiceText61: "",
      contentText62: "",
      choiceText62: "",
      contentText63: "",
      choiceText63: "",
      contentText64: "",
      choiceText64: "",
      contentText65: "",
      choiceText65: "",
      contentText66: "",
      choiceText66: "",
      contentText67: "",
      choiceText67: "",
      contentText68: "",
      choiceText68: "",
      contentText69: "",
      choiceText69: "",
      contentText70: "",
      choiceText70: "",
      contentText71: "",
      choiceText71: "",
      contentText72: "",
      choiceText72: "",
      contentText73: "",
      choiceText73: "",
      contentText74: "",
      choiceText74: "",
      contentText75: "",
      choiceText75: "",
      contentText76: "",
      choiceText76: "",
      contentText77: "",
      choiceText77: "",
      contentText78: "",
      choiceText78: "",
      contentText79: "",
      choiceText79: "",
      contentText80: "",
      choiceText80: "",
      contentText81: "",
      choiceText81: "",
      contentText82: "",
      choiceText82: "",
      contentText83: "",
      choiceText83: "",
      contentText84: "",
      choiceText84: "",
      contentText85: "",
      choiceText85: "",
      contentText86: "",
      choiceText86: "",
      contentText87: "",
      choiceText87: "",
      contentText88: "",
      choiceText88: "",
      contentText89: "",
      choiceText89: "",
      contentText90: "",
      choiceText90: "",
      contentText91: "",
      choiceText91: "",
      contentText92: "",
      choiceText92: "",
      contentText93: "",
      choiceText93: "",
      contentText94: "",
      choiceText94: "",
      contentText95: "",
      choiceText95: "",
      contentText96: "",
      choiceText96: "",
      contentText97: "",
      choiceText97: "",
      contentText98: "",
      choiceText98: "",
      contentText99: "",
      choiceText99: "",
      contentText100: "",
      choiceText100: "",
      vendorList: [],
      vendors: [],
      userList: [],
      isButtonClickable: true,
      isBlankExcelValidationError: true,
      xlsxPath: "",
      snackbarOpen: false,
      snackbarMessage: "",
      isUnsupportedExcel: false,
      selectedFile: null,
      searchWords: "",
      searchTarget: "siteName",
      isUploadedExcelFile: false,
    };

    const isMobile = /iPhone|iPad|Android/i.test(navigator.userAgent);
    const debounceTime = isMobile ? 1000 : 300;
    this.debouncedFetchAndListSites = _.debounce(
      this.fetchAndListSites,
      debounceTime
    );

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.handleSelectXlsxFileClick = this.handleSelectXlsxFileClick.bind(this);
    this.handleSelectSitePrecautionsFileClick =
      this.handleSelectSitePrecautionsFileClick.bind(this);
    this.handleCreateClick = this.handleCreateClick.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value =
      target.name === "searchTarget" || target.name === "searchWords"
        ? target.value
        : "";
    const name = target.name;
    this.setState({
      [name]: value,
    });

    this.debouncedFetchAndListSites();
  }

  handleDrawerToggle(event) {
    this.setState({
      mobileOpen: !this.state.mobileOpen,
    });
  }

  handleOpen(event) {
    this.setState({
      open: true,
      setOpen: true,
    });
  }

  handleClose(event) {
    location.reload();
  }

  async handleSelectXlsxFileClick(event) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    this.setState({ isLoading: true, isUploadedExcelFile: true });
    const self = this;
    const blobURL = window.URL.createObjectURL(event.target.files[0]);
    const xhr = new XMLHttpRequest();
    this.setState({ xlsxPath: event.target.files[0].name });
    xhr.onload = async function () {
      const result = xhr.response;
      const data = new Uint8Array(result);
      self.loadSiteBasicInfoSheet(data, 2);
      if (!self.state.isUnsupportedExcel) {
        await self.loadPrimaryVendorInfoSheet(data, 2);
        await self.loadSiteChoiceTextsSheet(data, 1);
        await self.loadCompanyMembersSheet(data, 2);
      }
      self.setState({ isLoading: false });
    };
    xhr.responseType = "arraybuffer";
    xhr.open("GET", blobURL);
    xhr.send();
  }

  async handleSelectSitePrecautionsFileClick(event) {
    if (event.target.files.length > 0) {
      this.setState({
        notesFile: event.target.files[0],
      });
    }
  }

  async handleCreateClick(event) {
    try {
      this.setState({
        isLoading: true,
        isButtonClickable: false,
      });

      if (this.state.error) {
        this.setState({
          isLoading: false,
          isButtonClickable: true,
          snackbarOpen: true,
          snackbarMessage:
            "現場作成インポートに失敗しました。詳細はエラーメッセージを確認してください。",
        });
        return;
      }

      let ret = await this.createSite();
      if (ret) {
        ret = await this.createSiteRoles(this.state.companyMembers);
      }
      if (ret) {
        await this.createSiteMembers(this.state.primaryVendorMembers);
      }

      if (this.state.modalText == "" && !this.state.error) {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: "現場作成インポートに成功しました。",
        });
        setTimeout(() => {
          location.reload();
        }, 2000);
      } else if (!this.state.error) {
        this.setState({
          snackbarOpen: true,
          snackbarMessage:
            "現場作成インポートに成功しました。一部データは手動での追加登録が必要です。詳細はエラーメッセージを確認してください。",
        });
      } else {
        this.setState({
          snackbarOpen: true,
          snackbarMessage:
            "現場作成インポートに失敗しました。詳細はエラーメッセージを確認してください。",
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        snackbarOpen: true,
        snackbarMessage:
          "現場作成インポートに失敗しました。詳細はエラーメッセージを確認してください。",
      });
      throw error;
    }
  }

  async checkAndRemoveDuplicates(primaryVendorMembers) {
    const vendorMap = new Map();
    for (const member of primaryVendorMembers) {
      if (vendorMap.has(member.vendorCode)) {
        const existingName = vendorMap.get(member.vendorCode);
        if (existingName !== member.vendorName) {
          throw new Error(
            `同じ vendorCode (${member.vendorCode}) に異なる vendorName (${existingName} と ${member.vendorName}) が存在します。`
          );
        }
      } else {
        vendorMap.set(member.vendorCode, member.vendorName);
      }
    }
    return Array.from(vendorMap).map(([vendorCode, vendorName]) => {
      return { vendorCode, vendorName };
    });
  }

  async createSite() {
    try {
      this.setState({
        isLoading: true,
      });

      let bucketName;
      if (process.env.REACT_APP_ENV === "prod") {
        bucketName =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450190221-prod.s3.ap-northeast-1.amazonaws.com/public/";
      } else if (process.env.REACT_APP_ENV === "test") {
        bucketName =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450194438-test.s3.ap-northeast-1.amazonaws.com/public/";
      } else {
        bucketName =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450213306-dev.s3.ap-northeast-1.amazonaws.com/public/";
      }
      const userInfo = await Auth.currentAuthenticatedUser();
      const listSites = await fetchSites({
        masterCode: {
          eq: this.state.masterCode,
        },
      });
      if (listSites.length > 0) {
        throw new Error("案件管理番号が重複しています。");
      }
      const shortid = require("shortid");
      var r = "";
      const c = {
        small: "abcdefghijklmnopqrstuvwxyz",
        capital: "ABCDEFGHIJKLMNPQRSTUVWXYZ",
        num: "1234567890",
        symbol: "_-!",
      };
      const str = r.concat(c.small, c.capital, c.num, c.symbol);
      shortid.characters(str);
      const newId = shortid.generate();
      await this.setState({
        siteId: newId,
      });

      const hashResult = await sha256(
        "siteCode=" + newId + "&siteName=" + this.state.siteName
      );

      if (
        this.state.notesFile.name &&
        this.state.notesFile.name !== "undefined"
      ) {
        await Storage.put(
          userInfo.attributes["custom:company_id"] +
            "/" +
            newId +
            "/notes-files/" +
            this.state.notesFile.name,
          this.state.notesFile,
          {}
        );
      }
      const registeredVendorIds = [];
      const registeredVendorCodes = [];
      let notRegisteredVendorCodes = [];
      const uniqueVendors = await this.checkAndRemoveDuplicates(
        this.state.primaryVendorMembers
      );
      uniqueVendors.forEach((member) => {
        notRegisteredVendorCodes.push(member.vendorCode);
        this.state.vendorList.forEach((vendor) => {
          if (member.vendorCode === vendor.vendorCode) {
            registeredVendorIds.push(vendor.id);
            registeredVendorCodes.push(vendor.vendorCode);
          }
        });
      });
      notRegisteredVendorCodes = notRegisteredVendorCodes.filter(function (v) {
        return !registeredVendorCodes.includes(v);
      });
      const notRegisteredVendors = uniqueVendors
        .map(function (member) {
          if (notRegisteredVendorCodes.includes(member.vendorCode)) {
            return member;
          } else {
            return null;
          }
        })
        .filter((v) => v);
      if (notRegisteredVendors) {
        for (let i = 0; i < notRegisteredVendors.length; i++) {
          try {
            const newVendorId = uuidv4();
            registeredVendorIds.push(newVendorId);
            const inputCreateVendor = {
              id: newVendorId,
              vendorName: notRegisteredVendors[i].vendorName,
              vendorCode: notRegisteredVendors[i].vendorCode,
            };
            await createVendorWrapper(inputCreateVendor);
            const inputCreateCompanyVendor = {
              companyId: this.state.userDetails.companyId,
              vendorId: newVendorId,
            };
            createCompanyVendorWrapper(inputCreateCompanyVendor);
          } catch (error) {
            console.error(error);
            throw error;
          }
        }
      }

      const input = {
        id: newId,
        masterCode: this.state.masterCode,
        siteName: this.state.siteName,
        address1: this.state.address1,
        notesFileUrl:
          this.state.notesFile.name && this.state.notesFile.name !== "undefined"
            ? bucketName +
              userInfo.attributes["custom:company_id"] +
              "/" +
              newId +
              "/notes-files/" +
              this.state.notesFile.name
            : "",
        qrCodeDescription: "siteCode=" + newId + "&sha256=" + hashResult,
        contentText1: this.state.contentText1,
        choiceText1: this.state.choiceText1,
        contentText2: this.state.contentText2,
        choiceText2: this.state.choiceText2,
        contentText3: this.state.contentText3,
        choiceText3: this.state.choiceText3,
        contentText4: this.state.contentText4,
        choiceText4: this.state.choiceText4,
        contentText5: this.state.contentText5,
        choiceText5: this.state.choiceText5,
        contentText6: this.state.contentText6,
        choiceText6: this.state.choiceText6,
        contentText7: this.state.contentText7,
        choiceText7: this.state.choiceText7,
        contentText8: this.state.contentText8,
        choiceText8: this.state.choiceText8,
        contentText9: this.state.contentText9,
        choiceText9: this.state.choiceText9,
        contentText10: this.state.contentText10,
        choiceText10: this.state.choiceText10,
        contentText11: this.state.contentText11,
        choiceText11: this.state.choiceText11,
        contentText12: this.state.contentText12,
        choiceText12: this.state.choiceText12,
        contentText13: this.state.contentText13,
        choiceText13: this.state.choiceText13,
        contentText14: this.state.contentText14,
        choiceText14: this.state.choiceText14,
        contentText15: this.state.contentText15,
        choiceText15: this.state.choiceText15,
        contentText16: this.state.contentText16,
        choiceText16: this.state.choiceText16,
        contentText17: this.state.contentText17,
        choiceText17: this.state.choiceText17,
        contentText18: this.state.contentText18,
        choiceText18: this.state.choiceText18,
        contentText19: this.state.contentText19,
        choiceText19: this.state.choiceText19,
        contentText20: this.state.contentText20,
        choiceText20: this.state.choiceText20,
        contentText21: this.state.contentText21,
        choiceText21: this.state.choiceText21,
        contentText22: this.state.contentText22,
        choiceText22: this.state.choiceText22,
        contentText23: this.state.contentText23,
        choiceText23: this.state.choiceText23,
        contentText24: this.state.contentText24,
        choiceText24: this.state.choiceText24,
        contentText25: this.state.contentText25,
        choiceText25: this.state.choiceText25,
        contentText26: this.state.contentText26,
        choiceText26: this.state.choiceText26,
        contentText27: this.state.contentText27,
        choiceText27: this.state.choiceText27,
        contentText28: this.state.contentText28,
        choiceText28: this.state.choiceText28,
        contentText29: this.state.contentText29,
        choiceText29: this.state.choiceText29,
        contentText30: this.state.contentText30,
        choiceText30: this.state.choiceText30,
        contentText31: this.state.contentText31,
        choiceText31: this.state.choiceText31,
        contentText32: this.state.contentText32,
        choiceText32: this.state.choiceText32,
        contentText33: this.state.contentText33,
        choiceText33: this.state.choiceText33,
        contentText34: this.state.contentText34,
        choiceText34: this.state.choiceText34,
        contentText35: this.state.contentText35,
        choiceText35: this.state.choiceText35,
        contentText36: this.state.contentText36,
        choiceText36: this.state.choiceText36,
        contentText37: this.state.contentText37,
        choiceText37: this.state.choiceText37,
        contentText38: this.state.contentText38,
        choiceText38: this.state.choiceText38,
        contentText39: this.state.contentText39,
        choiceText39: this.state.choiceText39,
        contentText40: this.state.contentText40,
        choiceText40: this.state.choiceText40,
        contentText41: this.state.contentText41,
        choiceText41: this.state.choiceText41,
        contentText42: this.state.contentText42,
        choiceText42: this.state.choiceText42,
        contentText43: this.state.contentText43,
        choiceText43: this.state.choiceText43,
        contentText44: this.state.contentText44,
        choiceText44: this.state.choiceText44,
        contentText45: this.state.contentText45,
        choiceText45: this.state.choiceText45,
        contentText46: this.state.contentText46,
        choiceText46: this.state.choiceText46,
        contentText47: this.state.contentText47,
        choiceText47: this.state.choiceText47,
        contentText48: this.state.contentText48,
        choiceText48: this.state.choiceText48,
        contentText49: this.state.contentText49,
        choiceText49: this.state.choiceText49,
        contentText50: this.state.contentText50,
        choiceText50: this.state.choiceText50,
        contentText51: this.state.contentText51,
        choiceText51: this.state.choiceText51,
        contentText52: this.state.contentText52,
        choiceText52: this.state.choiceText52,
        contentText53: this.state.contentText53,
        choiceText53: this.state.choiceText53,
        contentText54: this.state.contentText54,
        choiceText54: this.state.choiceText54,
        contentText55: this.state.contentText55,
        choiceText55: this.state.choiceText55,
        contentText56: this.state.contentText56,
        choiceText56: this.state.choiceText56,
        contentText57: this.state.contentText57,
        choiceText57: this.state.choiceText57,
        contentText58: this.state.contentText58,
        choiceText58: this.state.choiceText58,
        contentText59: this.state.contentText59,
        choiceText59: this.state.choiceText59,
        contentText60: this.state.contentText60,
        choiceText60: this.state.choiceText60,
        contentText61: this.state.contentText61,
        choiceText61: this.state.choiceText61,
        contentText62: this.state.contentText62,
        choiceText62: this.state.choiceText62,
        contentText63: this.state.contentText63,
        choiceText63: this.state.choiceText63,
        contentText64: this.state.contentText64,
        choiceText64: this.state.choiceText64,
        contentText65: this.state.contentText65,
        choiceText65: this.state.choiceText65,
        contentText66: this.state.contentText66,
        choiceText66: this.state.choiceText66,
        contentText67: this.state.contentText67,
        choiceText67: this.state.choiceText67,
        contentText68: this.state.contentText68,
        choiceText68: this.state.choiceText68,
        contentText69: this.state.contentText69,
        choiceText69: this.state.choiceText69,
        contentText70: this.state.contentText70,
        choiceText70: this.state.choiceText70,
        contentText71: this.state.contentText71,
        choiceText71: this.state.choiceText71,
        contentText72: this.state.contentText72,
        choiceText72: this.state.choiceText72,
        contentText73: this.state.contentText73,
        choiceText73: this.state.choiceText73,
        contentText74: this.state.contentText74,
        choiceText74: this.state.choiceText74,
        contentText75: this.state.contentText75,
        choiceText75: this.state.choiceText75,
        contentText76: this.state.contentText76,
        choiceText76: this.state.choiceText76,
        contentText77: this.state.contentText77,
        choiceText77: this.state.choiceText77,
        contentText78: this.state.contentText78,
        choiceText78: this.state.choiceText78,
        contentText79: this.state.contentText79,
        choiceText79: this.state.choiceText79,
        contentText80: this.state.contentText80,
        choiceText80: this.state.choiceText80,
        contentText81: this.state.contentText81,
        choiceText81: this.state.choiceText81,
        contentText82: this.state.contentText82,
        choiceText82: this.state.choiceText82,
        contentText83: this.state.contentText83,
        choiceText83: this.state.choiceText83,
        contentText84: this.state.contentText84,
        choiceText84: this.state.choiceText84,
        contentText85: this.state.contentText85,
        choiceText85: this.state.choiceText85,
        contentText86: this.state.contentText86,
        choiceText86: this.state.choiceText86,
        contentText87: this.state.contentText87,
        choiceText87: this.state.choiceText87,
        contentText88: this.state.contentText88,
        choiceText88: this.state.choiceText88,
        contentText89: this.state.contentText89,
        choiceText89: this.state.choiceText89,
        contentText90: this.state.contentText90,
        choiceText90: this.state.choiceText90,
        contentText91: this.state.contentText91,
        choiceText91: this.state.choiceText91,
        contentText92: this.state.contentText92,
        choiceText92: this.state.choiceText92,
        contentText93: this.state.contentText93,
        choiceText93: this.state.choiceText93,
        contentText94: this.state.contentText94,
        choiceText94: this.state.choiceText94,
        contentText95: this.state.contentText95,
        choiceText95: this.state.choiceText95,
        contentText96: this.state.contentText96,
        choiceText96: this.state.choiceText96,
        contentText97: this.state.contentText97,
        choiceText97: this.state.choiceText97,
        contentText98: this.state.contentText98,
        choiceText98: this.state.choiceText98,
        contentText99: this.state.contentText99,
        choiceText99: this.state.choiceText99,
        contentText100: this.state.contentText100,
        choiceText100: this.state.choiceText100,
        companyId: userInfo.attributes["custom:company_id"],
        vendorIds: registeredVendorIds,
      };
      await API.graphql(
        graphqlOperation(createSiteAndSiteVendor, {
          input,
        })
      );

      this.setState({
        isLoading: false,
      });
      return true;
    } catch (error) {
      console.error(error);
      this.setState((prevState) => ({
        modalText: [...prevState.modalText, error.message],
        isLoading: false,
      }));
      if (
        error.message === "案件管理番号が重複しています。" ||
        error.message.includes("同じ vendorCode") ||
        error.message === "一次業者作成に失敗しました。"
      ) {
        this.setState({
          error: true,
        });
        throw error;
      }
      return false;
    }
  }

  havingChiefOrNot = async (hasChief, companyMember) => {
    if (!hasChief) {
      return !!companyMember.isChief;
    } else {
      companyMember.isChief = false;
      return hasChief;
    }
  };

  async createSiteClearances(
    userList,
    userInfo,
    bucketName,
    companyMember,
    hasChief
  ) {
    hasChief = await this.havingChiefOrNot(hasChief, companyMember);
    const newId = uuidv4();
    const hashResult = await sha256(
      "siteCode=" + this.state.siteId + "&siteName=" + this.state.siteName
    );
    const todayDate = format(
      new Date(new Date().toLocaleString("ja-JP", { timeZone: "Japan" })),
      "yyyy-MM-dd"
    );
    const inputSiteClearance = {
      id: newId,
      siteCode: this.state.siteId,
      siteName: this.state.siteName,
      siteAddress: this.state.address1,
      isEnteredBasicInfo: false,
      applyStatus: "招待中",
      startDate: todayDate,
      endDate: todayDate,
      notesFileUrl:
        this.state.notesFile.name && this.state.notesFile.name !== "undefined"
          ? bucketName +
            userInfo.attributes["custom:company_id"] +
            "/" +
            newId +
            "/notes-files/" +
            this.state.notesFile.name
          : "",
      familyName: userList.lastName,
      firstName: userList.firstName,
      familyNameKana: userList.lastNameFurigana,
      firstNameKana: userList.firstNameFurigana,
      email: userList.email,
      companyName: this.state.companyDetails.companyName,
      companyId: userList.companyId,
      userId: userList.id,
      invitationCode: newId,
      qrCodeDescription:
        "siteCode=" + this.state.siteId + "&sha256=" + hashResult,
      isChief: !!companyMember.isChief,
    };
    await API.graphql(
      graphqlOperation(createSiteClearance, {
        input: inputSiteClearance,
      })
    );
    return hasChief;
  }

  async createSiteRoles(companyMembers) {
    try {
      this.setState({
        isLoading: true,
      });

      const userList = await this.fetchUserInfo();

      const userInfo = await Auth.currentAuthenticatedUser();
      let bucketName;
      if (process.env.REACT_APP_ENV === "prod") {
        bucketName =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450190221-prod.s3.ap-northeast-1.amazonaws.com/public/";
      } else if (process.env.REACT_APP_ENV === "test") {
        bucketName =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450194438-test.s3.ap-northeast-1.amazonaws.com/public/";
      } else {
        bucketName =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450213306-dev.s3.ap-northeast-1.amazonaws.com/public/";
      }

      const modalText = this.state.modalText;
      let hasChief = false;
      for (let i = 0; i < companyMembers.length; i++) {
        let executed = false;
        for (let j = 0; j < userList.length; j++) {
          if (companyMembers[i].email === userList[j].email) {
            executed = true;
            const input = {
              id: uuidv4(),
              roleName:
                companyMembers[i].roleLevel === 4
                  ? "現場管理者"
                  : "チームメンバー",
              roleLevel: companyMembers[i].roleLevel,
              companyId: userList[j].companyId,
              siteCode: this.state.siteId,
              userId: userList[j].id,
            };
            await API.graphql(
              graphqlOperation(createSiteRole, {
                input,
              })
            );
            hasChief = await this.createSiteClearances(
              userList[j],
              userInfo,
              bucketName,
              companyMembers[i],
              hasChief
            );
            break;
          }
        }
        if (!executed && companyMembers[i].email != "") {
          modalText.push(
            companyMembers[i].email +
              "は、社内メンバーに登録されていないため、別途管理者による登録が必要です。"
          );
        }
      }
      this.setState({
        modalText: modalText,
      });

      this.setState({
        isLoading: false,
      });
      return true;
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      return false;
    }
  }

  async wrapperCreateSiteClearanceWithInvitation(
    userInfo,
    todayDate,
    primaryVendorMembers
  ) {
    const newId = uuidv4();
    const hashResult = await sha256(
      "siteCode=" + this.state.siteId + "&siteName=" + this.state.siteName
    );

    let bucketName;
    if (process.env.REACT_APP_ENV === "prod") {
      bucketName =
        "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450190221-prod.s3.ap-northeast-1.amazonaws.com/public/";
    } else if (process.env.REACT_APP_ENV === "test") {
      bucketName =
        "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450194438-test.s3.ap-northeast-1.amazonaws.com/public/";
    } else {
      bucketName =
        "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450213306-dev.s3.ap-northeast-1.amazonaws.com/public/";
    }

    const input = {
      id: newId,
      siteCode: this.state.siteId,
      siteName: this.state.siteName,
      siteAddress: this.state.address1,
      isEnteredBasicInfo: false,
      applyStatus: "招待中",
      startDate: todayDate,
      endDate: "",
      notesFileUrl:
        this.state.notesFile.name && this.state.notesFile.name !== "undefined"
          ? bucketName +
            userInfo.attributes["custom:company_id"] +
            "/" +
            newId +
            "/notes-files/" +
            this.state.notesFile.name
          : "",
      familyName: primaryVendorMembers.lastName,
      firstName: primaryVendorMembers.firstName,
      familyNameKana: primaryVendorMembers.lastNameKana,
      firstNameKana: primaryVendorMembers.firstNameKana,
      email: primaryVendorMembers.email,
      companyName: primaryVendorMembers.vendorName,
      companyId: userInfo.attributes["custom:company_id"],
      userId: userInfo.attributes.sub,
      invitationCode: newId,
      qrCodeDescription:
        "siteCode=" + this.state.siteId + "&sha256=" + hashResult,
      gender: "",
      zipCode: "",
      address1: "",
      address2: "",
      address3: "",
      mobileNumber: "",
      birthDate: "",
      emergencyContactName: "",
      emergencyContactTell: "",
      isSelfEmployment: "",
      companyCEOName: "",
      companyZipCode: "",
      companyAddress1: "",
      companyAddress2: "",
      companyAddress3: "",
      companyTell: "",
      companyOccupation: "",
      experienceYearPresent: 0,
      experienceMonthPresent: 0,
      experienceYearTotal: 0,
      experienceMonthTotal: 0,
      licenceQualification: "",
      licenceQualificationOthers: "",
      skillTraining: "",
      skillTrainingOthers: "",
      specialTraining: "",
      specialTrainingOthers: "",
      isForeigner: "",
      residenceCardNumber1: "",
      residenceCardNumber2: "",
      residenceCardNumber3: "",
      stayPeriod: "",
      isSMEOwnerOrSingleMaster: "",
      insuranceGroupName: "",
      insuranceNumber1: "",
      insuranceNumber2: "",
      insuranceNumber3: "",
      insuranceExpirationDate: "",
      medicalCheckupCategory: "",
      medicalCheckupLatestDate: "",
      bloodPressureMax: 0,
      bloodPressureMin: 0,
      bloodType: "",
      anamnesis: "",
      anamnesisOthers: "",
      canWorkWithoutProblems: "",
      companyOrder: 0,
      primaryCompanyName: primaryVendorMembers.vendorName,
      secondaryCompanyName: "",
      thirdCompanyName: "",
      fourthCompanyName: "",
      groupName: "",
      isForeman: "",
      youthElderly: "",
      notes: "",
      pihAgreementFlag: "",
      normKnowledge: "",
      agreementOfYouth: "",
      agreementOfElderly: "",
      shootingProhibited: "",
      snsPostingProhibited: "",
      disclosureProhibited: "",
      discardCopyProhibited: "",
      observancePledge: "",
      checkListInputContents: "",
      invitationType: primaryVendorMembers.invitationType,
      providerCompanyName: this.state.companyDetails.companyName,
      isChief: primaryVendorMembers.isChief,
    };
    await API.graphql(
      graphqlOperation(createSiteClearanceWithInvitation, input)
    );
  }

  async createSiteMembers(primaryVendorMembers) {
    try {
      this.setState({
        isLoading: true,
      });

      let bucketName;
      if (process.env.REACT_APP_ENV === "prod") {
        bucketName =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450190221-prod.s3.ap-northeast-1.amazonaws.com/public/";
      } else if (process.env.REACT_APP_ENV === "test") {
        bucketName =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450194438-test.s3.ap-northeast-1.amazonaws.com/public/";
      } else {
        bucketName =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450213306-dev.s3.ap-northeast-1.amazonaws.com/public/";
      }

      const userInfo = await Auth.currentAuthenticatedUser();
      const todayDate = format(
        new Date(new Date().toLocaleString("ja-JP", { timeZone: "Japan" })),
        "yyyy-MM-dd"
      );

      for (let i = 0; i < primaryVendorMembers.length; i++) {
        this.wrapperCreateSiteClearanceWithInvitation(
          userInfo,
          todayDate,
          primaryVendorMembers[i]
        );
      }

      this.setState({
        isLoading: false,
      });
      return true;
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      return false;
    }
  }

  async loadSiteBasicInfoSheet(data) {
    const modalText = this.state.modalText;
    this.setState({
      isUnsupportedExcel: true,
    });
    try {
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(data);
      const worksheet = workbook.getWorksheet("現場基本情報");
      if (worksheet == undefined) {
        modalText.push(
          "添付されたファイルが正しくありません。EXCELテンプレートを使用してください。"
        );
        this.setState({
          isUnsupportedExcel: true,
          modalText: modalText,
        });
      }
      const row = worksheet.getRow(2);
      const masterCode = row.getCell(1).value;
      const siteName = row.getCell(2).value;
      const address1 = row.getCell(3).value;
      if (!masterCode || masterCode == "" || typeof masterCode !== "string") {
        modalText.push("マスターコードが正しくありません。");
      }
      if (!siteName || typeof siteName !== "string") {
        modalText.push("現場名が正しくありません。");
      }
      if (!address1 || typeof address1 !== "string") {
        modalText.push("住所1が正しくありません。");
      }
      if (modalText.length > 0) {
        this.setState({
          modalText: modalText,
          error: true,
        });
        return;
      }
      this.setState({
        masterCode: masterCode,
        siteName: siteName,
        address1: address1,
        isUnsupportedExcel: false,
        isBlankExcelValidationError: false,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async loadPrimaryVendorInfoSheet(data, startRow) {
    const modalText = this.state.modalText;
    try {
      const items = [];
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(data);
      const worksheet = workbook.getWorksheet("一次協力会社職長情報登録");
      let row = worksheet.getRow(1);
      for (let i = startRow; i <= worksheet.rowCount; i++) {
        row = worksheet.getRow(i);
        if (row.getCell(2).value !== null) {
          if (![0, 1, 2, null].includes(row.getCell(9).value)) {
            throw new Error("メール送信フラグが正しい値ではありません。");
          }
          const item = {
            vendorCode:
              row.getCell(2).value != null ? row.getCell(2).value : "",
            vendorName:
              row.getCell(3).value != null ? row.getCell(3).value : "",
            lastName: row.getCell(4).value != null ? row.getCell(4).value : "",
            firstName: row.getCell(5).value != null ? row.getCell(5).value : "",
            lastNameKana:
              row.getCell(6).value != null ? row.getCell(6).value : "",
            firstNameKana:
              row.getCell(7).value != null ? row.getCell(7).value : "",
            email:
              row.getCell(8).value == null
                ? ""
                : typeof row.getCell(8).value == "object"
                ? row.getCell(8).value.text
                : row.getCell(8).value,
            invitationType: [0, 1, 2].includes(row.getCell(9).value)
              ? row.getCell(9).value
              : 0,
          };
          items.push(item);
        } else if (row.values.length > 0) {
          modalText.push(
            i +
              "行目（一次協力会社職長情報登録シート）で、協力会社コードが不足しています。"
          );
          throw new Error(
            i +
              "行目（一次協力会社職長情報登録シート）で、協力会社コードが不足しています。"
          );
        }
      }
      this.setState({
        primaryVendorMembers: items,
        modalText: modalText,
      });

      const listVendors = await fetchVendors();
      this.setState({ vendorList: listVendors });
    } catch (e) {
      console.error(e);
      if (
        e.message.includes(
          "行目（一次協力会社職長情報登録シート）で、協力会社コードが不足しています。"
        )
      ) {
        this.setState({
          error: true,
        });
      }
      if (e.message === "メール送信フラグが正しい値ではありません。") {
        modalText.push("メール送信フラグが正しい値ではありません。");
      }
      if (modalText == "") {
        modalText.push(
          "添付されたファイルが正しくありません。EXCELテンプレートを使用してください。"
        );
      }
      this.setState({
        isUnsupportedExcel: true,
        modalText: modalText,
      });
    }
  }

  async loadSiteChoiceTextsSheet(data, startRow) {
    const modalText = this.state.modalText;
    try {
      const MAX_CHOICE_TEXT_COUNT = 100;
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(data);
      const worksheet = workbook.getWorksheet("現場注意事項");
      const contentText = {};
      const choiceText = {};
      let row = worksheet.getRow(1);
      for (let i = startRow; i <= MAX_CHOICE_TEXT_COUNT; i++) {
        row = worksheet.getRow(i + 2);
        if (row.getCell(2).value !== null) {
          contentText["contentText" + i] = row.getCell(2).value;
          choiceText["choiceText" + i] = row.getCell(3).value;
        }
      }
      this.setState(contentText);
      this.setState(choiceText);
    } catch (e) {
      console.error(e);
      if (modalText == "") {
        modalText.push(
          "添付されたファイルが正しくありません。EXCELテンプレートを使用してください。"
        );
      }
      this.setState({
        isUnsupportedExcel: true,
        modalText: modalText,
      });
    }
  }

  async loadCompanyMembersSheet(data, startRow) {
    try {
      const items = [];
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(data);
      const worksheet = workbook.getWorksheet("社内メンバー");
      let row = worksheet.getRow(1);
      let hasChief = false;
      for (let i = startRow; i <= worksheet.rowCount; i++) {
        row = worksheet.getRow(i);
        if (row.getCell(1).value !== null) {
          let isChief = false;
          if (row.getCell(6).value === 1) {
            isChief = true;
            hasChief = true;
          } else if (row.getCell(6).value !== null) {
            throw new Error("所長・工事担当者が正しい値ではありません。");
          }
          const item = {
            id: row.getCell(1).value,
            division: row.getCell(2).value,
            name: row.getCell(3).value,
            email:
              row.getCell(4).value == null
                ? ""
                : typeof row.getCell(4).value == "object"
                ? row.getCell(4).value.text
                : row.getCell(4).value,
            roleLevel: row.getCell(5).value,
            isChief: row.getCell(6).value === 1,
          };
          items.push(item);
        }
      }
      if (!hasChief) {
        throw new Error("所長・工事担当者が設定されていません。");
      }
      this.setState({ companyMembers: items });
    } catch (e) {
      const modalText = this.state.modalText;
      console.error(e);
      if (
        e.message === "所長・工事担当者が正しい値ではありません。" ||
        e.message === "所長・工事担当者が設定されていません。"
      ) {
        modalText.push(e.message);
        this.setState({
          error: true,
        });
      }
      if (modalText == "") {
        modalText.push(
          "添付されたファイルが正しくありません。EXCELテンプレートを使用してください。"
        );
      }
      this.setState({
        isUnsupportedExcel: true,
        modalText: modalText,
      });
    }
  }

  async componentDidMount() {
    this.fetchItems();
    this.fetchAndListSites();
    this.fetchJobs();
    const [drawerList, navigationList] = await makeDrawerList();

    this.setState({
      drawerList,
      navigationList,
    });
  }

  async fetchUserInfo() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const responseSite = await API.graphql(
        graphqlOperation(getSite, {
          id: this.state.siteId
            ? this.state.siteId
            : this.state.userDetails.currentSiteId,
        })
      );
      this.setState({
        siteDetails: responseSite.data.getSite,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });

      const filter = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
        ],
      };

      const resUsers = await fetchUsers(filter);
      this.setState({
        userList: resUsers,
      });
      if (!this.state.searchWords) {
        const filter = {
          and: [
            {
              companyId: {
                eq: userInfo.attributes["custom:company_id"],
              },
            },
          ],
        };

        const resUsers = await fetchUsers(filter);
        this.setState({
          userList: resUsers,
        });

        this.setState({
          isLoading: false,
        });

        return resUsers;
      } else {
        const filter = {
          and: [
            {
              companyId: {
                eq: userInfo.attributes["custom:company_id"],
              },
            },
            {
              lastName: {
                contains: this.state.searchWords,
              },
            },
          ],
        };

        const resUsers = await fetchUsers(filter);

        this.setState({
          userList: resUsers,
        });

        this.setState({
          isLoading: false,
        });

        return resUsers;
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  async fetchJobs() {
    try {
      const jobList = schedule.scheduledJobs;
      const jobNames = _.keys(schedule.scheduledJobs);
      if (jobList) {
        for (const name of jobNames) {
          schedule.cancelJob(name);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async fetchAndListSites() {
    try {
      const [roleLevel, siteRoleList] = await getRoleLevel(true);
      const userInfo = await Auth.currentAuthenticatedUser();

      let filterSiteList = {
        id: {
          eq: "default",
        },
      };
      if ((roleLevel === 1) | (roleLevel === 2) | (roleLevel === 3)) {
        filterSiteList = {
          and: [
            {
              companyId: {
                eq: userInfo.attributes["custom:company_id"],
              },
            },
          ],
        };
      } else if (roleLevel < DEFAULT_ROLE_LEVEL) {
        const roleList = siteRoleList.map((item) => {
          return {
            id: {
              eq: item.siteCode,
            },
          };
        });

        filterSiteList = {
          or: roleList,
        };
      }
      if (this.state.searchTarget) {
        filterSiteList = this.searchedFilter(filterSiteList);
      }
      const resSites = await fetchSites(filterSiteList);
      this.setState({
        accessLevel: roleLevel,
        siteList: resSites,
      });
    } catch (error) {
      console.log(error);
    }
  }

  searchedFilter(filterSiteList) {
    if (!filterSiteList.and) {
      filterSiteList.and = [];
    }
    filterSiteList.and.push({
      [this.state.searchTarget]: {
        contains: this.state.searchWords,
      },
    });
    return filterSiteList;
  }

  async fetchItems() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );

      this.setState({
        userDetails: responseUser.data.getUser,
        companyDetails: responseCompany.data.getCompany,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  render() {
    const drawerListMobile = ["作業中現場一覧"];
    const navigationListMobile = ["/select-site"];
    const container =
      window !== undefined ? () => window().document.body : undefined;

    const xlsxFileUpload = () => {
      this.setState({
        modalText: [],
        error: false,
        snackbarOpen: false,
        snackbarMessage: [],
        isButtonClickable: true,
      });
      this.setState({
        selectedFile: null,
      });
      this.props.inputXlsxFileRef.current.click();
    };

    const sitePrecautionsFileUpload = () => {
      this.props.inputSitePrecautionsFileRef.current.click();
    };

    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { md: `calc(100% - ${drawerWidth}px)` },
            ml: { md: `${drawerWidth}px` },
            backgroundColor: "#F9F9F9",
            borderBottom: 1,
            borderColor: "#E5E5E5",
          }}
          elevation={0}
        >
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" }, color: "#383838" }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontFamily: "Regular",
                fontSize: 21,
                fontWeight: "700",
                color: "#383838",
                flexGrow: 1,
              }}
            >
              現場一覧
            </Typography>
            {Boolean(
              (this.state.accessLevel === 1) | (this.state.accessLevel === 2)
            ) && (
              <Typography component="div">
                <Button
                  type="submit"
                  onClick={this.handleOpen}
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#383838",
                    backgroundColor: "#EBE91A",
                    height: 32,
                    borderRadius: 100,
                    mr: 2,
                    paddingHorizontal: 18,
                    "&:hover": {
                      backgroundColor: "#EBE91A",
                    },
                    display: { xs: "none", sm: "none", md: "flex" },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/addcircle-on.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  現場作成インポート
                </Button>
                <Modal
                  open={this.state.open}
                  onClose={this.handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Box sx={closeButtonStyle}>
                      <IconButton onClick={this.handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <Typography
                      id="modal-modal-title"
                      sx={{
                        fontSize: 21,
                        fontWeight: "700",
                        color: "#383838",
                      }}
                    >
                      現場作成インポート
                    </Typography>
                    <Stack
                      direction="row"
                      sx={{
                        mt: 2,
                        mb: 1,
                      }}
                    >
                      <Stack
                        direction="row"
                        sx={{
                          mb: 0,
                        }}
                      >
                        <Typography
                          id="modal-modal-title"
                          sx={{
                            fontFamily: "Regular",
                            fontSize: 15,
                            fontWeight: "400",
                            color: "#383838",
                            mb: 0,
                            mr: 1,
                          }}
                        >
                          現場作成シート（形式：xlsx）
                        </Typography>
                        <RequiredField />
                        <Typography
                          sx={{
                            fontFamily: "Regular",
                            fontSize: 15,
                            fontWeight: "500",
                            color: "#DD3900",
                            mb: 0.5,
                            marginLeft: 1.0,
                          }}
                        >
                          <Link
                            href="https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450190221-prod.s3.ap-northeast-1.amazonaws.com/public/%E7%8F%BE%E5%A0%B4%E4%BD%9C%E6%88%90%E3%82%B7%E3%83%BC%E3%83%88.xlsx"
                            target="_blank"
                            download
                          >
                            Excelテンプレートをダウンロード
                          </Link>
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      sx={{
                        mb: 0,
                      }}
                    >
                      <Typography
                        component="div"
                        sx={{
                          fontFamily: "Regular",
                          fontSize: 15,
                          fontWeight: "400",
                          color: "#8D8D8D",
                          marginBottom: 0.75,
                        }}
                      >
                        {Boolean(this.state.isUploadedExcelFile) && (
                          <>
                            <Button
                              type="submit"
                              variant="contained"
                              sx={{
                                fontFamily: "Regular",
                                fontSize: 16,
                                fontWeight: "700",
                                color: "#C8C8C8",
                                backgroundColor: "#E5E5E5",
                                height: 40,
                                borderRadius: 100,
                                paddingHorizontal: 18,
                                justifyContent: "space-between",
                                "&:hover": {
                                  backgroundColor: "#E5E5E5",
                                },
                              }}
                              disabled={this.state.isLoading}
                              disableElevation
                            >
                              ファイル選択
                            </Button>
                            <input
                              hidden
                              ref={this.props.inputXlsxFileRef}
                              type="file"
                              accept=".xlsx"
                              onChange={this.handleSelectXlsxFileClick}
                              value={this.state.selectedFile}
                              disabled={this.state.isLoading}
                            />
                          </>
                        )}
                        {Boolean(!this.state.isUploadedExcelFile) && (
                          <>
                            <Button
                              type="submit"
                              onClick={xlsxFileUpload}
                              variant="contained"
                              sx={{
                                fontFamily: "Regular",
                                fontSize: 16,
                                fontWeight: "700",
                                color: "#383838",
                                backgroundColor: "#DEDEDE",
                                height: 32,
                                borderRadius: 100,
                                paddingHorizontal: 18,
                                "&:hover": {
                                  backgroundColor: "#EBE91A",
                                },
                                display: { xs: "none", sm: "flex" },
                              }}
                              disableElevation
                            >
                              ファイル選択
                            </Button>
                            <input
                              hidden
                              ref={this.props.inputXlsxFileRef}
                              type="file"
                              accept=".xlsx"
                              onChange={this.handleSelectXlsxFileClick}
                              value={this.state.selectedFile}
                              disabled={this.state.isLoading}
                            />
                          </>
                        )}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Regular",
                          fontSize: 18,
                          fontWeight: "500",
                          color: "#383838",
                          marginTop: 0.4,
                          marginLeft: 3.0,
                        }}
                      >
                        {this.state.xlsxPath}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      sx={{
                        mb: 1,
                        mt: 2,
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        sx={{
                          fontFamily: "Regular",
                          fontSize: 15,
                          fontWeight: "400",
                          color: "#383838",
                          mb: 0,
                          mr: 1,
                        }}
                      >
                        現場注意事項（形式：pdf）
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      sx={{
                        mb: 0,
                      }}
                    >
                      <Typography
                        component="div"
                        sx={{
                          fontFamily: "Regular",
                          fontSize: 15,
                          fontWeight: "400",
                          color: "#8D8D8D",
                          marginBottom: 0.75,
                        }}
                      >
                        <Button
                          type="submit"
                          onClick={sitePrecautionsFileUpload}
                          variant="contained"
                          sx={{
                            fontFamily: "Regular",
                            fontSize: 16,
                            fontWeight: "700",
                            color: "#383838",
                            backgroundColor: "#DEDEDE",
                            height: 32,
                            borderRadius: 100,
                            paddingHorizontal: 18,
                            "&:hover": {
                              backgroundColor: "#EBE91A",
                            },
                            display: { xs: "none", sm: "flex" },
                          }}
                          disableElevation
                        >
                          ファイル選択
                        </Button>
                        <input
                          hidden
                          ref={this.props.inputSitePrecautionsFileRef}
                          type="file"
                          accept=".pdf"
                          onChange={this.handleSelectSitePrecautionsFileClick}
                        />
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Regular",
                          fontSize: 18,
                          fontWeight: "500",
                          color: "#383838",
                          marginTop: 0.4,
                          marginLeft: 3.0,
                        }}
                      >
                        {this.state.notesFile.name}
                      </Typography>
                    </Stack>
                    <Typography
                      component="div"
                      sx={{
                        fontFamily: "Regular",
                        fontSize: 15,
                        fontWeight: "400",
                        color: "#8D8D8D",
                        mt: 2,
                        marginBottom: 0.75,
                      }}
                    >
                      {Boolean(
                        this.state.isBlankExcelValidationError |
                          !this.state.isButtonClickable |
                          this.state.isUnsupportedExcel
                      ) && (
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            fontFamily: "Regular",
                            fontSize: 16,
                            fontWeight: "700",
                            color: "#C8C8C8",
                            backgroundColor: "#E5E5E5",
                            height: 40,
                            borderRadius: 100,
                            paddingHorizontal: 18,
                            justifyContent: "space-between",
                            "&:hover": {
                              backgroundColor: "#E5E5E5",
                            },
                          }}
                          disabled={this.state.isLoading}
                          disableElevation
                        >
                          インポートする
                        </Button>
                      )}
                      {Boolean(
                        !this.state.isBlankExcelValidationError &&
                          this.state.isButtonClickable &&
                          !this.state.isUnsupportedExcel
                      ) && (
                        <Button
                          type="submit"
                          onClick={this.handleCreateClick}
                          variant="contained"
                          sx={{
                            fontFamily: "Regular",
                            fontSize: 16,
                            fontWeight: "700",
                            color: "#383838",
                            backgroundColor: "#EBE91A",
                            height: 40,
                            borderRadius: 100,
                            paddingHorizontal: 18,
                            "&:hover": {
                              backgroundColor: "#EBE91A",
                            },
                            display: { xs: "none", sm: "flex" },
                          }}
                          disabled={this.state.isLoading}
                          disableElevation
                        >
                          インポートする
                        </Button>
                      )}
                    </Typography>
                    {Boolean(this.state.modalText != "") && (
                      <Typography
                        component="div"
                        sx={{
                          fontFamily: "Regular",
                          fontSize: 15,
                          fontWeight: "400",
                          color: "#8D8D8D",
                          marginLeft: 3.0,
                          marginBottom: 0.75,
                          marginTop: 4.75,
                        }}
                      >
                        <Alert
                          variant="filled"
                          severity={this.state.error ? "error" : "warning"}
                          sx={{
                            whiteSpace: "pre-line",
                          }}
                        >
                          {this.state.modalText.join("\n")}
                        </Alert>
                      </Typography>
                    )}
                  </Box>
                </Modal>
              </Typography>
            )}
            {Boolean(
              (this.state.accessLevel === 1) | (this.state.accessLevel === 2)
            ) && (
              <Button
                type="submit"
                onClick={() => this.props.navigate("/register-site")}
                variant="contained"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#383838",
                  backgroundColor: "#EBE91A",
                  height: 32,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  "&:hover": {
                    backgroundColor: "#EBE91A",
                  },
                  display: { xs: "none", sm: "none", md: "flex" },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/addcircle-on.png")}
                    width="20"
                    height="20"
                  />
                }
                disableElevation
              >
                現場の作成
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <div>
              <Toolbar>
                <img
                  src={require("../assets/images/sagaseru-logo.png")}
                  width="76"
                  height="18"
                />
              </Toolbar>
              {drawerListMobile && navigationListMobile && (
                <List disablePadding>
                  {drawerListMobile.map((text, index) => (
                    <ListItem key={text}>
                      <ListItemButton
                        onClick={() => {
                          this.props.navigate(navigationListMobile[index]);
                        }}
                      >
                        <ListItemText
                          primary={text}
                          primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: "500",
                            color: "#383838",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
              <Divider variant="middle" />
              {["ログアウト"] && (
                <List disablePadding>
                  {["ログアウト"].map((text, index) => (
                    <ListItem key={text}>
                      <ListItemButton
                        onClick={() => {
                          Auth.signOut();
                          this.props.navigate("/sign-in");
                        }}
                      >
                        <ListItemText
                          primary={text}
                          primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: "500",
                            color: "#383838",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          </Drawer>
          <NavigationMenu
            drawerList={this.state.drawerList}
            navigationList={this.state.navigationList}
            topIndex={0}
            bottomIndex={null}
          />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100%",
            minHeight: "100vh",
          }}
        >
          <LoadingProgress isLoading={this.state.isLoading} />
          <Toolbar />
          {this.state.siteList.length > 0 ||
          this.state.searchWords ||
          this.state.accessLevel !== DEFAULT_ROLE_LEVEL ? (
            <>
              <SearchBox
                searchWords={this.state.searchWords}
                searchTarget={this.state.searchTarget}
                handleInputChange={this.handleInputChange}
              />
              {this.state.siteList.map((_obj, index) => (
                <SearchResults
                  key={index}
                  siteList={this.state.siteList}
                  // handleSubmit={this.handleSubmit}
                  index={index}
                />
              ))}
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mb: 0,
                  mr: 1,
                }}
              >
                アクセス権限がありません
              </Typography>
            </Box>
          )}
        </Box>
        <Snackbar
          open={this.state.snackbarOpen}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            variant="filled"
            severity={this.state.error ? "error" : "success"}
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

function SelectSite(props) {
  const navigate = useNavigate();
  const inputXlsxFileRef = useRef(null);
  const inputSitePrecautionsFileRef = useRef(null);
  return (
    <InputComponent
      {...props}
      navigate={navigate}
      inputSitePrecautionsFileRef={inputSitePrecautionsFileRef}
      inputXlsxFileRef={inputXlsxFileRef}
    />
  );
}

export default SelectSite;
