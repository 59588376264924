import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

function SiteSpecifiedItem({ handleInputChange, index, state }) {
  return (
    <>
      <Stack
        direction="row"
        sx={{
          mb: 0,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Regular",
            fontSize: 17,
            fontWeight: "700",
            color: "#383838",
            mt: 2,
            mb: 2,
            mr: 1,
          }}
        >
          {`現場固有チェック項目${index + 6}`}
        </Typography>
        {/* <RequiredField /> */}
      </Stack>
      <Stack
        direction="row"
        sx={{
          mb: 0,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Regular",
            fontSize: 15,
            fontWeight: "400",
            color: "#383838",
            mb: 0,
            mr: 1,
          }}
        >
          チェック内容文章
        </Typography>
        {/* <RequiredField /> */}
      </Stack>
      <TextField
        id={`contentText[${index + 6}]`}
        name={`contentText${index + 6}`}
        type="text"
        value={state[`contentText${index + 6}`]}
        onChange={handleInputChange}
        variant="standard"
        margin="normal"
        fullWidth
        sx={{
          marginTop: 1,
          marginBottom: 4,
        }}
      />
      <Stack
        direction="row"
        sx={{
          mb: 0,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Regular",
            fontSize: 15,
            fontWeight: "400",
            color: "#383838",
            mb: 0,
            mr: 1,
          }}
        >
          選択肢文字列
        </Typography>
        {/* <RequiredField /> */}
      </Stack>
      <TextField
        id={`choiceText${index + 6}`}
        name={`choiceText${index + 6}`}
        type="text"
        value={state[`choiceText${index + 6}`]}
        onChange={handleInputChange}
        variant="standard"
        margin="normal"
        fullWidth
        sx={{
          marginTop: 1,
          marginBottom: 4,
        }}
      />
    </>
  );
}

export default SiteSpecifiedItem;
