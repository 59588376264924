import * as React from "react";
import { getUser, getCompany, getFreeidUser } from "../graphql/queries";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { updateSiteClearance } from "../graphql/mutations";
import { useNavigate } from "react-router-dom";
import UserListTable from "../components/UserListTable";
import NavigationDrawer from "../components/NavigationDrawer";
import LoadingProgress from "../components/LoadingProgress";
import fetchSiteClearances from "../components/queries/FetchSiteClearances";
import fetchSiteRoles from "../components/queries/FetchSiteRoles";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";
import { DEFAULT_ROLE_LEVEL } from "../components/utils/constants";
import {
  Alert,
  AppBar,
  Box,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  Link,
  Snackbar,
  Toolbar,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import dayjs from "dayjs";
import xlsx from "xlsx-js-style";
import ExcelJS from "exceljs";
import fetchFreeidUsers from "../components/queries/FetchFreeidUsers";
import fetchFreeidUser from "../components/queries/FetchFreeidUser";

const drawerWidth = 280;

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      companyDetails: "",
      clearanceList: "",
      accessLevel: "",
      toggle: true,
      isLoading: false,
      snackBar: null,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleExistingClick = this.handleExistingClick.bind(this);
    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleBulkNewClick = this.handleBulkNewClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    this.fetchUsers();
  }

  handleChange(event) {
    this.setState({
      toggle: !this.state.toggle,
    });
    event.preventDefault();
  }

  handleExistingClick(event) {
    this.props.navigate("/team-member-registration");
    event.preventDefault();
  }

  handleNewClick(event) {
    this.props.navigate("/register-user");
    event.preventDefault();
  }

  handleBulkNewClick(event) {
    this.props.navigate("/register-user-bulk");
    event.preventDefault();
  }

  handleClick(event) {
    if (this.state.toggle) {
      this.props.navigate("/team-member-registration");
    } else {
      this.props.navigate("/register-user");
    }
    event.preventDefault();
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  async componentDidMount() {
    this.fetchRoles();
    this.fetchUsers();
  }

  async fetchRoles() {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();

      const filterCompanyRole = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      };

      const [responseCompanyRole] = await fetchCompanyRoles(filterCompanyRole);
      let roleLevel;
      if (responseCompanyRole.roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = responseCompanyRole.roleLevel;
      } else {
        const responseUser = await API.graphql(
          graphqlOperation(getUser, {
            id: userInfo.attributes.sub,
          })
        );

        const filterSiteRole = {
          and: [
            {
              siteCode: {
                eq: responseUser.data.getUser.currentSiteId,
              },
            },
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };

        const [resSiteRoles] = await fetchSiteRoles(filterSiteRole);
        roleLevel = resSiteRoles.roleLevel;
      }

      this.setState({
        accessLevel: roleLevel,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchUsers() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });

      const filter = {
        and: [
          {
            siteCode: {
              eq: this.state.userDetails.currentSiteId,
            },
          },
          {
            familyName: {
              attributeExists: true,
            },
          },
          {
            familyName: {
              ne: "",
            },
          },
          {
            firstName: {
              attributeExists: true,
            },
          },
          {
            firstName: {
              ne: "",
            },
          },
        ],
      };

      const resSiteClearances = await fetchSiteClearances(filter);
      const freeidIds = resSiteClearances
        .filter((item) => item.freeidId)
        .map((item) => item.freeidId);

      const filterIds = freeidIds.map((freeidId) => {
        return {
          id: {
            eq: freeidId,
          },
        };
      });

      const filterFreeidUsers = {
        or: filterIds,
      };
      const allFreeidUsers = await fetchFreeidUsers(filterFreeidUsers);

      const updatedSiteClearances = await Promise.all(
        resSiteClearances.map(async (resSiteClearance) => {
          if (resSiteClearance.freeidId) {
            const matchedFreeidUser = allFreeidUsers.find(
              (user) => user.id === resSiteClearance.freeidId
            );
            if (matchedFreeidUser) {
              const { id, ...rest } = matchedFreeidUser;
              return {
                ...resSiteClearance,
                ...rest,
              };
            }
          }
          return resSiteClearance;
        })
      );

      this.setState({
        clearanceList: updatedSiteClearances,
        isLoading: false,
      });

      const filterSite = {
        and: [
          {
            siteCode: {
              eq: this.state.userDetails.currentSiteId,
            },
          },
        ],
      };
      const resSiteClearancesBySite = await fetchSiteClearances(filterSite);
      for (let i = 0; i < resSiteClearancesBySite.length; i++) {
        let responseFreeidUser;
        if (resSiteClearancesBySite[i].freeidId) {
          responseFreeidUser = await API.graphql(
            graphqlOperation(getFreeidUser, {
              id: resSiteClearancesBySite[i].freeidId,
            })
          );
        }

        const startDate = resSiteClearancesBySite[i].startDate;
        const endDate = resSiteClearancesBySite[i].endDate;
        const todayDate = format(
          new Date(new Date().toLocaleString("ja-JP", { timeZone: "Japan" })),
          "yyyy-MM-dd"
        );
        const updatedStatus = !responseFreeidUser
          ? "招待中"
          : !responseFreeidUser.data.getFreeidUser?.isEnteredBasicInfo
          ? "招待中"
          : !resSiteClearancesBySite[i].checkListInputContents
          ? "招待中"
          : !resSiteClearancesBySite[i].startDate &&
            !resSiteClearancesBySite[i].endDate
          ? "入場可"
          : !resSiteClearancesBySite[i].startDate &&
            resSiteClearancesBySite[i].endDate &&
            todayDate <= endDate
          ? "入場可"
          : !resSiteClearancesBySite[i].startDate &&
            resSiteClearancesBySite[i].endDate &&
            todayDate > endDate
          ? "終了"
          : resSiteClearancesBySite[i].startDate &&
            !resSiteClearancesBySite[i].endDate &&
            todayDate < startDate
          ? "待機中"
          : resSiteClearancesBySite[i].startDate &&
            !resSiteClearancesBySite[i].endDate &&
            todayDate >= startDate
          ? "入場可"
          : resSiteClearancesBySite[i].endDate && todayDate > endDate
          ? "終了"
          : resSiteClearancesBySite[i].startDate &&
            resSiteClearancesBySite[i].endDate &&
            todayDate >= startDate &&
            todayDate <= endDate
          ? "入場可"
          : resSiteClearancesBySite[i].startDate &&
            resSiteClearancesBySite[i].endDate &&
            todayDate < startDate
          ? "待機中"
          : "待機中";

        const inputSiteClearance = {
          id: resSiteClearancesBySite[i].id,
          applyStatus: updatedStatus,
        };

        await API.graphql(
          graphqlOperation(updateSiteClearance, {
            input: inputSiteClearance,
          })
        );
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  birthdayToAge(birthday) {
    if (!birthday) return "-";
    const today = dayjs();
    const birthDate = dayjs(birthday);
    const baseAge = today.year() - birthDate.year();
    if (isNaN(baseAge)) return "-";
    const thisBirthday = dayjs(
      `${today.year()}-${birthDate.month() + 1}-${birthDate.date()}`
    );
    return today.isBefore(thisBirthday) ? `${baseAge - 1}歳` : `${baseAge}歳`;
  }

  exportToExcel = async () => {
    try {
      const todayString = `(${dayjs().format("YYYY年  MM月 DD日")}作成)`;

      let template;
      if (process.env.REACT_APP_ENV === "prod") {
        template =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450190221-prod.s3.ap-northeast-1.amazonaws.com/public/worker-roster-template.xlsx";
      } else if (process.env.REACT_APP_ENV === "test") {
        template =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450194438-test.s3.ap-northeast-1.amazonaws.com/public/worker-roster-template.xlsx";
      } else {
        template =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450213306-dev.s3.ap-northeast-1.amazonaws.com/public/worker-roster-template.xlsx";
      }
      const response = await fetch(template);
      const buffer = await response.arrayBuffer();
      const workBook = new ExcelJS.Workbook();
      await workBook.xlsx.load(buffer);
      let currentSheet = null;
      let sheetRowIndex = 0;
      let formattedSiteName = "";
      let templateSheet = null;
      const sheetIndex = {};

      const generateDataCells = (
        row,
        rowIndex,
        formattedDate,
        ageString,
        formattedEducationDate
      ) => [
        { data: rowIndex + 1, cell: `A${rowIndex * 6 + 15}` },
        {
          data: `${row.familyNameKana} ${row.firstNameKana}`,
          cell: `B${rowIndex * 6 + 15}`,
        },
        {
          data: `${row.familyName} ${row.firstName}`,
          cell: `B${rowIndex * 6 + 17}`,
        },
        { data: row.ccusNumber || "-", cell: `B${rowIndex * 6 + 19}` },
        {
          data: row.siteOccupation?.length > 0 ? row.siteOccupation[0] : "-",
          cell: `F${rowIndex * 6 + 15}`,
        },
        {
          data: row.workerType?.slice(0, 5).join(",\n"),
          cell: `I${rowIndex * 6 + 15}`,
          style: {
            font: { sz: 8 },
            alignment: {
              horizontal: "center",
              wrapText: true,
            },
          },
        },
        {
          data: row.birthDate
            ? `${row.birthDate.split("-")[0]}年${
                row.birthDate.split("-")[1]
              }月${row.birthDate.split("-")[2]}日`
            : "-",
          cell: `J${rowIndex * 6 + 15}`,
        },
        { data: ageString, cell: "J" + (rowIndex * 6 + 18) },
        {
          data: row.healthInsurance ? row.healthInsurance : "-",
          cell: `M${rowIndex * 6 + 15}`,
        },
        {
          data: row.pensionInsurance ? row.pensionInsurance : "-",
          cell: `M${rowIndex * 6 + 17}`,
        },
        {
          data: row.employmentInsurance ? row.employmentInsurance : "-",
          cell: `M${rowIndex * 6 + 19}`,
        },
        {
          data: row.employmentInsuranceNumber
            ? row.employmentInsuranceNumber
            : "-",
          cell: `N${rowIndex * 6 + 19}`,
        },
        {
          data: row.hasKentaikyo ? "加入あり" : "加入なし",
          cell: `O${rowIndex * 6 + 15}`,
        },
        {
          data: row.hasChutaikyo ? "加入あり" : "加入なし",
          cell: `O${rowIndex * 6 + 18}`,
        },
        {
          data:
            row.specialTraining === "null" || !row.specialTraining
              ? "-"
              : row.specialTraining?.slice(1, -1),
          cell: `P${rowIndex * 6 + 15}`,
        },
        {
          data:
            row.skillTraining === "null" || !row.skillTraining
              ? "-"
              : row.skillTraining?.slice(1, -1),
          cell: `Q${rowIndex * 6 + 15}`,
        },
        {
          data:
            row.licenceQualification === "null" || !row.licenceQualification
              ? "-"
              : row.licenceQualification?.slice(1, -1),
          cell: `V${rowIndex * 6 + 15}`,
        },
        {
          data: row.startDate === "null" ? "-" : formattedDate,
          cell: `W${rowIndex * 6 + 15}`,
          style: {
            font: { sz: 8 },
          },
        },
        {
          data:
            row.educationDate === "null" || !row.educationDate
              ? formattedDate
              : formattedEducationDate,
          cell: `W${rowIndex * 6 + 18}`,
          style: {
            font: { sz: 8 },
          },
        },
      ];

      const generateSheetName = (row) => {
        let lastCompanyNameKana = null;
        let n = "";
        if (row.companyOrder === 4) {
          lastCompanyNameKana = row.fourthCompanyNameKana;
          n = "四";
        } else if (row.companyOrder === 3) {
          lastCompanyNameKana = row.thirdCompanyNameKana;
          n = "三";
        } else if (row.companyOrder === 2) {
          lastCompanyNameKana = row.secondaryCompanyNameKana;
          n = "二";
        }
        const sheetName = lastCompanyNameKana
          ? `${row.primaryCompanyName}_${lastCompanyNameKana}`.replace(
              /[:\\/?*[\]]/g,
              ""
            )
          : `${row.primaryCompanyName}`.replace(/[:\\/?*[\]]/g, "");
        return [sheetName, lastCompanyNameKana, n];
      };

      const searchChiefNameFromFreeid = async () => {
        const chiefClearances = await searchChief();
        let chiefName = "";
        if (chiefClearances.length === 1 && chiefClearances[0].freeidId) {
          const chiefFreeidUser = await fetchFreeidUser({
            id: chiefClearances[0].freeidId,
          });
          chiefName = `${chiefFreeidUser.familyName}  ${chiefFreeidUser.firstName}`;
        }
        return chiefName;
      };

      const searchChief = async () => {
        const chiefClearance = await fetchSiteClearances({
          and: [
            {
              siteCode: {
                eq: this.state.userDetails.currentSiteId,
              },
            },
            {
              isChief: {
                eq: true,
              },
            },
          ],
        });
        return chiefClearance;
      };

      const addDataToSheet = (sheet, data, cell, style) => {
        const cellRef = sheet.getCell(cell);
        const existingBorderStyle = cellRef.border;

        if (style) {
          cellRef.style = style;
        }
        cellRef.border = existingBorderStyle;
        cellRef.value = data;
      };
      const chiefName = await searchChiefNameFromFreeid();
      this.state.clearanceList.forEach((row) => {
        if (!row.primaryCompanyName || !row.companyOrder) {
          return;
        }
        formattedSiteName = row.siteName.replace(/[:\\/?*[\]]/g, " ");
        const [sheetName, lastCompanyNameKana, n] = generateSheetName(row);
        if (!workBook.getWorksheet(sheetName)) {
          templateSheet = workBook.worksheets[0];
          const newSheet = workBook.addWorksheet(sheetName);
          templateSheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
              const newCell = newSheet.getCell(rowNumber, colNumber);
              newCell.value = cell.value;
              newCell.style = { ...cell.style };
              newCell.border = { ...cell.border };
            });
          });
          templateSheet.model.merges.forEach((mergedRangeString) => {
            newSheet.mergeCells(mergedRangeString);
          });
          currentSheet = newSheet;
          sheetRowIndex = 0;
          sheetIndex[sheetName] = 0;
          addDataToSheet(currentSheet, todayString, "J2");
          addDataToSheet(currentSheet, row.siteName, "D3");
          addDataToSheet(currentSheet, chiefName, "D4");
          addDataToSheet(currentSheet, row.primaryCompanyName, "T6");
          addDataToSheet(
            currentSheet,
            n ? `${n}次会社名\n・事業者ID` : "",
            "V6"
          );
          addDataToSheet(currentSheet, lastCompanyNameKana, "W6");
        } else {
          currentSheet = workBook.getWorksheet(sheetName);
          sheetRowIndex = sheetIndex[sheetName];
        }

        const ageString = this.birthdayToAge(dayjs(row.birthDate));
        const [year = " ", month = "    ", day = "    "] =
          row.startDate?.split("-") || [];
        const formattedDate = `${year}年${month}月${day}日`;
        const [
          educationYear = " ",
          educationMonth = "    ",
          educationDay = "    ",
        ] = row.educationDate?.split("-") || [];
        const formattedEducationDate = `${educationYear}年${educationMonth}月${educationDay}日`;
        const dataCells = generateDataCells(
          row,
          sheetIndex[sheetName],
          formattedDate,
          ageString,
          formattedEducationDate
        );
        if (sheetIndex[sheetName] < 8) {
          dataCells.forEach(({ data, cell, style }) => {
            addDataToSheet(currentSheet, data, cell, style);
          });
        } else {
          const sourceRangeStart = currentSheet.getCell("A15");
          const sourceRangeEnd = currentSheet.getCell("X20");

          currentSheet.spliceRows(
            sheetIndex[sheetName] * 6 + 15,
            0,
            ...new Array(sourceRangeEnd.row - sourceRangeStart.row + 1).fill([])
          );

          const unmergeAndMerge = (sheet, cellRange) => {
            sheet.unMergeCells(cellRange);
            sheet.mergeCells(cellRange);
          };
          const offset = sheetIndex[sheetName] * 6 + 15;
          const mergeRanges = [
            `A${offset}:A${offset + 5}`,
            `B${offset}:E${offset + 1}`,
            `B${offset + 2}:E${offset + 3}`,
            `B${offset + 4}:E${offset + 5}`,
            `F${offset}:H${offset + 5}`,
            `I${offset}:I${offset + 5}`,
            `J${offset}:L${offset + 2}`,
            `J${offset + 3}:L${offset + 5}`,
            `M${offset}:M${offset + 1}`,
            `M${offset + 2}:M${offset + 3}`,
            `M${offset + 4}:M${offset + 5}`,
            `N${offset}:N${offset + 1}`,
            `N${offset + 2}:N${offset + 3}`,
            `N${offset + 4}:N${offset + 5}`,
            `O${offset}:O${offset + 2}`,
            `O${offset + 3}:O${offset + 5}`,
            `P${offset}:P${offset + 5}`,
            `Q${offset}:U${offset + 5}`,
            `V${offset}:V${offset + 5}`,
            `W${offset}:X${offset + 2}`,
            `W${offset + 3}:X${offset + 5}`,
          ];
          for (const range of mergeRanges) {
            unmergeAndMerge(currentSheet, range);
          }
          dataCells.forEach(({ data, cell, style }) => {
            addDataToSheet(currentSheet, data, cell, style);
          });

          function generateRanges(
            baseColumns,
            baseRows,
            sheetIndex,
            sheetName
          ) {
            const ranges = [];
            for (const column of baseColumns) {
              for (const row of baseRows) {
                const source = `${column}${row}`;
                const target = `${column}${sheetIndex[sheetName] * 6 + row}`;
                ranges.push({ source, target });
              }
            }
            return ranges;
          }

          function copyStyles(currentSheet, ranges) {
            for (const range of ranges) {
              const sourceCell = currentSheet.getCell(range.source);
              const [colStart, rowStart] = range.target
                .split(":")[0]
                .match(/(\D+)(\d+)/)
                .slice(1);
              const [colEnd, rowEnd] = range.target
                .split(":")[1]
                ?.match(/(\D+)(\d+)/)
                .slice(1) || [colStart, rowStart];

              for (let row = +rowStart; row <= +rowEnd; row++) {
                for (
                  let col = colStart.charCodeAt(0) - 64;
                  col <= colEnd.charCodeAt(0) - 64;
                  col++
                ) {
                  const targetCell = currentSheet.getCell(row, col);
                  targetCell.style = sourceCell.style;
                }
              }
            }
          }

          const baseColumns = [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
          ];
          const baseRows = [15, 16, 17, 18, 19, 20];
          const ranges = generateRanges(
            baseColumns,
            baseRows,
            sheetIndex,
            sheetName
          );
          copyStyles(currentSheet, ranges);
        }
        sheetRowIndex++;
        sheetIndex[sheetName] = sheetRowIndex;
      });
      workBook.removeWorksheet(templateSheet.id);

      for (const sheetName in sheetIndex) {
        const currentSheet = workBook.getWorksheet(sheetName);

        const sheetIdxValue = sheetIndex[sheetName];

        const footerRowNum = sheetIdxValue < 8 ? 63 : sheetIdxValue * 6 + 15;

        currentSheet.mergeCells(`A${footerRowNum}:P${footerRowNum}`);
        currentSheet.mergeCells(`Q${footerRowNum}:X${footerRowNum}`);
        currentSheet.getCell(`A${footerRowNum}`).value =
          "（注)１.※印欄には次の記号を入れる。";

        for (let i = 1; i <= 17; i++) {
          if ([3, 5, 9, 10].includes(i)) {
            continue;
          }
          const startRow = footerRowNum + i;
          const endRow = i === 2 ? startRow + 3 : startRow + (i === 8 ? 2 : 0);
          if (i !== 4) {
            currentSheet.mergeCells(`Q${startRow}:X${endRow}`);
          }

          if (i === 2) {
            currentSheet.mergeCells(`B${startRow}:D${startRow}`);
            currentSheet.getCell(`B${startRow}`).value = " …現場代理人";
            currentSheet.mergeCells(`E${startRow}:G${startRow}`);
            currentSheet.getCell(`E${startRow}`).value =
              " …作業主任者（（注）2.)";
            currentSheet.mergeCells(`J${startRow}:L${startRow}`);
            currentSheet.getCell(`J${startRow}`).value = " …女性作業員";
            currentSheet.mergeCells(`M${startRow}:P${startRow}`);
            currentSheet.getCell(`M${startRow}`).value =
              "       …18歳未満の作業員";
          } else if (i === 4) {
            currentSheet.mergeCells(`B${startRow}:D${startRow}`);
            currentSheet.getCell(`B${startRow}`).value = " …主任技術者";
            currentSheet.mergeCells(`E${startRow}:H${startRow}`);
            currentSheet.getCell(`E${startRow}`).value = " …職 長";
            currentSheet.mergeCells(`I${startRow}:K${startRow}`);
            currentSheet.getCell(`I${startRow}`).value = " …安全衛生責任者";
            currentSheet.mergeCells(`L${startRow}:M${startRow}`);
            currentSheet.getCell(`L${startRow}`).value = " …能力向上教育";
            currentSheet.mergeCells(`N${startRow}:P${startRow}`);
            currentSheet.getCell(`N${startRow}`).value =
              " …危険有害業務・再発防止教育";
          } else if (i === 6) {
            currentSheet.mergeCells(`B${startRow}:D${startRow}`);
            currentSheet.getCell(`B${startRow}`).value = " …外国人技能実習生";
            currentSheet.mergeCells(`F${startRow}:J${startRow}`);
            currentSheet.getCell(`F${startRow}`).value = " …外国人建設就労者";
            currentSheet.mergeCells(`L${startRow}:M${startRow}`);
            currentSheet.getCell(`L${startRow}`).value = " …１号特定技能外国人";
          } else if (i === 8) {
            currentSheet.mergeCells(`A${startRow}:P${startRow + 1}`);
            currentSheet.getCell(`A${startRow}`).value =
              "（注）２.作業主任者は作業を直接指揮する義務を負うので、同時に施工されている他の現場や、同一現場においても\n他の作業個所との作業主任者を兼務することは、法的に認められていないので、複数の選任としなければならない。";
          }
        }

        const footerTexts = [
          "（注）３．各社別に作成するのが原則だが、リース機械等の運転者は一緒でもよい。",
          "（注）４．資格・免許等の写しを添付することが望ましい。",
          "（注）５．健康保険欄には、左欄に健康保険の名称（健康保険組合、協会けんぽ、\n建設国保、国民健康保険）を記載。上記の保険に加入しておらず、後期高齢者で\nある等により、国民健康保険の適用除外である場合には、左欄に「適用除外」と記\n載。",
          "（注）６．年金保険欄には、左欄に年金保険の名称（厚生年金、国民年金）を記載。\n各年金の受給者である場合は、左欄に「受給者」と記載。",
          "（注）７．雇用保険欄には右欄に被保険者番号の下４けたを記載。（日雇労働被保\n険者の場合には左欄に「日雇保険」と記載）事業主である等により雇用保険の適用\n除外である場合には左欄に「適用除外」と記載。",
          "（注）８．建設業退職金共済制度及び中小企業退職金共済制度への加入の有無につい\nては、それぞれの欄に「有」又は「無」と記載。",
          "（注）９．安全衛生に関する教育の内容（例：雇入時教育、職長教育、建設用リフト\nの運転の業務に係る特別教育）については「雇入・職長特別教育」欄に記載。",
          "（注）１０．建設工事に係る知識及び技術又は技能に関する資格（例：登録○○基幹\n技能者、○級○○施工管理技士）を有する場合は、「免許」欄に記載。",
          "（注）１１．記載事項の一部について、別紙を用いて記載しても差し支えない。",
        ];

        footerTexts.forEach((text, idx) => {
          if (idx < 3) {
            currentSheet.getCell(`Q${footerRowNum + idx}`).value = text;
          } else if (idx < 6) {
            currentSheet.getCell(`Q${footerRowNum + idx + 3}`).value = text;
          } else {
            currentSheet.getCell(`Q${footerRowNum + idx + 5}`).value = text;
          }
        });
      }

      const writtenBuffer = await workBook.xlsx.writeBuffer();
      const blob = new Blob([writtenBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${formattedSiteName}.xlsx`;
      a.click();
    } catch (error) {
      console.log("error", error);
      let message = "";
      switch (true) {
        case error.message.includes("Workbook is empty"):
        case error.message.includes("Cannot read properties of null"):
          message = "エクスポートするデータが存在しません";
          break;
        default:
          message = "名簿エクスポートに失敗しました";
          break;
      }
      this.setState({
        snackBar: {
          children: message,
          severity: "error",
        },
      });
    }
  };

  handleCloseSnackBar = () => this.setState({ snackBar: null });

  render() {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            backgroundColor: "#F9F9F9",
            borderBottom: 1,
            borderColor: "#E5E5E5",
          }}
          elevation={0}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              onClick={() => this.props.navigate("/site-overview")}
              sx={{
                mr: 2,
                pr: 2,
                pl: 1,
                borderRadius: 0,
                borderRight: 1,
                height: 64,
                borderColor: "#E5E5E5",
                color: "#383838",
              }}
            >
              <img
                src={require("../assets/images/leftarrow-on.png")}
                width="16"
                height="16"
              />
            </IconButton>
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontSize: 21,
                fontWeight: "700",
                color: "#383838",
                flexGrow: 1,
              }}
            >
              {this.state.userDetails.currentSiteName}：メンバー管理
            </Typography>
            {Boolean(
              (this.state.accessLevel === 1) |
                (this.state.accessLevel === 2) |
                (this.state.accessLevel === 4)
            ) && (
              <Button
                type="submit"
                onClick={this.handleNewClick}
                variant="outlined"
                sx={{
                  fontSize: 15,
                  fontWeight: "700",
                  color: "#383838",
                  backgroundColor: "#EBE91A",
                  borderColor: "#EBE91A",
                  borderWidth: 1,
                  height: 32,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  mr: 1,
                  "&:hover": {
                    backgroundColor: "#EBE91A",
                    borderColor: "#EBE91A",
                  },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/add-on.png")}
                    width="18"
                    height="18"
                  />
                }
                disableElevation
              >
                追加する
              </Button>
            )}
            {Boolean(
              (this.state.accessLevel === 1) |
                (this.state.accessLevel === 2) |
                (this.state.accessLevel === 4)
            ) && (
              <Button
                type="submit"
                onClick={this.handleBulkNewClick}
                variant="outlined"
                sx={{
                  fontSize: 15,
                  fontWeight: "700",
                  color: "#383838",
                  backgroundColor: "#EBE91A",
                  borderColor: "#EBE91A",
                  borderWidth: 1,
                  height: 32,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  "&:hover": {
                    backgroundColor: "#EBE91A",
                    borderColor: "#EBE91A",
                  },
                  mr: 1,
                }}
                endIcon={
                  <img
                    src={require("../assets/images/add-on.png")}
                    width="18"
                    height="18"
                  />
                }
                disableElevation
              >
                一括登録
              </Button>
            )}
            {Boolean(
              (this.state.accessLevel === 1) |
                (this.state.accessLevel === 2) |
                (this.state.accessLevel === 4)
            ) && (
              <Button
                data-testid="exportButton"
                type="submit"
                onClick={this.exportToExcel}
                variant="contained"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#383838",
                  backgroundColor: "#EBE91A",
                  height: 32,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  "&:hover": {
                    backgroundColor: "#EBE91A",
                  },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/addcircle-on.png")}
                    width="20"
                    height="20"
                  />
                }
                disableElevation
              >
                作業員名簿エクスポート
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <NavigationDrawer topIndex={2} bottomIndex={null} />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#F9F9F9",
            height: "100%",
            minHeight: "100vh",
            p: 3,
          }}
        >
          <LoadingProgress isLoading={this.state.isLoading} />
          <Grid item xs={12} mb={2}>
            <Toolbar />
            <Typography
              variant="h5"
              component="h2"
              sx={{
                fontSize: 21,
                fontWeight: "700",
                color: "#383838",
                marginBottom: 3,
              }}
            >
              メンバー一覧
              <Link
                sx={{
                  fontSize: 21,
                  marginLeft: 2,
                }}
                href="/team-member-registration"
              >
                社内メンバー一覧
              </Link>
            </Typography>
          </Grid>
          <UserListTable
            rows={this.state.clearanceList}
            userDetails={this.state.userDetails}
            accessLevel={this.state.accessLevel}
          />
        </Box>
        {!!this.state.snackBar && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open
            onClose={this.handleCloseSnackBar}
            autoHideDuration={6000}
          >
            <Alert
              {...this.state.snackBar}
              onClose={this.handleCloseSnackBar}
            />
          </Snackbar>
        )}
      </Box>
    );
  }
}

function UserListScreen(props) {
  const navigate = useNavigate();
  return <Input {...props} navigate={navigate} />;
}

export { Input };
export default UserListScreen;
