import * as React from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUser, getSite, getCompany } from "../graphql/queries";
import { deleteSiteGate } from "../graphql/mutations";
import { useNavigate, useLocation } from "react-router-dom";
import NavigationDrawer from "../components/NavigationDrawer";
import LoadingProgress from "../components/LoadingProgress";
import CommonDialog from "../components/CommonDialog";
import fetchSiteRoles from "../components/queries/FetchSiteRoles";
import fetchSiteGates from "../components/queries/FetchSiteGates";
import { DEFAULT_ROLE_LEVEL } from "../components/utils/constants";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CardActionArea from "@mui/material/CardActionArea";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";

const drawerWidth = 280;

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      companyDetails: "",
      itemList: "",
      siteList: "",
      siteId: "",
      accessLevel: "",
      isLoading: false,
      commDlg: false,
      deviceCode: "",
      deviceName: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    this.fetchSites();
  }

  handleSubmit(event) {
    this.onSubmit(event);
  }

  handleDelete(event) {
    this.onDelete(event);
    this.fetchSites();
    event.preventDefault();
  }

  async onDelete(obj) {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const input = {
        id: obj,
      };

      const response = await API.graphql(
        graphqlOperation(deleteSiteGate, {
          input,
        })
      );

      this.setState({ commDlg: false });
      this.props.navigate("/select-device");
      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidMount() {
    this.fetchRoles();
    this.fetchSites();
    this.fetchItems();
  }

  async fetchRoles() {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();

      const filterCompanyRole = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      };

      const resCompanyRoles = await fetchCompanyRoles(filterCompanyRole);
      let roleLevel;
      if (resCompanyRoles[0].roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = resCompanyRoles[0].roleLevel;
      } else {
        const responseUser = await API.graphql(
          graphqlOperation(getUser, {
            id: userInfo.attributes.sub,
          })
        );

        const filterSiteRole = {
          and: [
            {
              siteCode: {
                eq: responseUser.data.getUser.currentSiteId,
              },
            },
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };

        const resSiteRoles = await fetchSiteRoles(filterSiteRole);

        roleLevel = resSiteRoles[0].roleLevel;
      }

      this.setState({
        accessLevel: roleLevel,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchSites() {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseSite = await API.graphql(
        graphqlOperation(getSite, {
          id: responseUser.data.getUser.currentSiteId,
        })
      );

      this.setState({
        siteCode: responseSite.data.getSite.id,
      });

      const filterSiteList = {
        and: [
          {
            siteCode: {
              eq: this.state.userDetails.currentSiteId,
            },
          },
        ],
      };

      const resSiteGates = await fetchSiteGates(filterSiteList);
      this.setState({
        siteList: resSiteGates,
      });
    } catch (error) {
      console.log(error);
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  async onSubmit(obj) {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();

      this.props.navigate("/edit-device", {
        state: {
          id: obj.id,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchItems() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            backgroundColor: "#F9F9F9",
            borderBottom: 1,
            borderColor: "#E5E5E5",
          }}
          elevation={0}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              onClick={() => this.props.navigate("/site-overview")}
              sx={{
                mr: 2,
                pr: 2,
                pl: 1,
                borderRadius: 0,
                borderRight: 1,
                height: 64,
                borderColor: "#E5E5E5",
                color: "#383838",
              }}
            >
              <img
                src={require("../assets/images/leftarrow-on.png")}
                width="16"
                height="16"
              />
            </IconButton>
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontSize: 21,
                fontWeight: "700",
                color: "#383838",
                flexGrow: 1,
              }}
            >
              {this.state.userDetails.currentSiteName}：顔認証端末の管理
            </Typography>
            {Boolean(
              (this.state.accessLevel === 1) |
                (this.state.accessLevel === 2) |
                (this.state.accessLevel === 4)
            ) && (
              <Button
                type="submit"
                onClick={() => this.props.navigate("/register-device")}
                variant="contained"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#383838",
                  backgroundColor: "#EBE91A",
                  height: 32,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  "&:hover": {
                    backgroundColor: "#EBE91A",
                  },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/addcircle-on.png")}
                    width="20"
                    height="20"
                  />
                }
                disableElevation
              >
                追加する
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <NavigationDrawer topIndex={3} bottomIndex={null} />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#FFFFFF",
            height: "100%",
            minHeight: "100vh",
            p: 0,
          }}
        >
          <LoadingProgress isLoading={this.state.isLoading} />
          <Grid item xs={12} mb={2}>
            <Toolbar />
          </Grid>
          {this.state.siteList &&
            this.state.siteList.map((obj, index) => (
              <Card
                sx={{
                  width: 475,
                  height: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: 2,
                  padding: 3,
                  paddingTop: 2,
                  paddingBottom: 2,
                  margin: 3,
                }}
                elevation={0}
              >
                <CardActionArea
                  onClick={() => {
                    if (
                      (this.state.accessLevel === 1) |
                      (this.state.accessLevel === 2) |
                      (this.state.accessLevel === 4)
                    ) {
                      this.handleSubmit(obj);
                    }
                  }}
                >
                  <Typography
                    component="div"
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 21,
                      fontWeight: "700",
                      color: "#383838",
                      // marginBottom: 0.75,
                    }}
                  >
                    {obj.deviceName}
                  </Typography>
                  <Typography
                    component="div"
                    sx={{
                      fontFamily: "Meiryo",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#8D8D8D",
                      marginBottom: 0.75,
                    }}
                  >
                    現場コード: {this.state.siteCode}
                  </Typography>
                  <Typography
                    component="div"
                    sx={{
                      fontFamily: "Meiryo",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#8D8D8D",
                      marginBottom: 0.75,
                    }}
                  >
                    デバイスコード: {obj.id}
                  </Typography>
                </CardActionArea>
                <CommonDialog
                  msg={`顔認証端末「${this.state.deviceName}」を削除しますか？`}
                  isOpen={this.state.commDlg}
                  doYes={() => this.handleDelete(this.state.deviceCode)}
                  doNo={() => {
                    this.setState({ commDlg: false });
                  }}
                />
                {Boolean(
                  (this.state.accessLevel === 1) |
                    (this.state.accessLevel === 2) |
                    (this.state.accessLevel === 4)
                ) && (
                  <CardActionArea
                    onClick={() =>
                      this.setState({
                        commDlg: true,
                        deviceName: obj.deviceName,
                        deviceCode: obj.id,
                      })
                    }
                  >
                    <Typography
                      component="div"
                      sx={{
                        fontFamily: "Regular",
                        fontSize: 15,
                        fontWeight: "400",
                        color: "#FF0000",
                        // marginBottom: 0.75,
                      }}
                    >
                      削除する
                    </Typography>
                  </CardActionArea>
                )}
              </Card>
            ))}
        </Box>
      </Box>
    );
  }
}

function SelectDeviceScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default SelectDeviceScreen;
