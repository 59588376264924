import { API, graphqlOperation } from "aws-amplify";
import { listSites } from "../../graphql/queries";

export default async function fetchSites(filter = null) {
  const BATCH_SIZE = 100;

  try {
    let siteList = [];

    if (filter?.or && filter.or.length > BATCH_SIZE) {
      const batches = Math.ceil(filter.or.length / BATCH_SIZE);
      for (let i = 0; i < batches; i++) {
        const currentBatch = filter.or.slice(
          i * BATCH_SIZE,
          (i + 1) * BATCH_SIZE
        );
        const batchResults = await fetchBatch({ or: currentBatch });
        siteList.push(...batchResults);
      }
    } else {
      siteList = await fetchBatch(filter);
    }

    return siteList;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function fetchBatch(filter) {
  try {
    const siteList = [];
    let response;
    response = await API.graphql(
      graphqlOperation(listSites, {
        filter: filter,
        limit: 500,
      })
    );

    siteList.push(...response.data.listSites.items);
    while (response.data.listSites.nextToken !== null) {
      response = await API.graphql(
        graphqlOperation(listSites, {
          filter: filter,
          limit: 500,
          nextToken: response.data.listSites.nextToken,
        })
      );
      siteList.push(...response.data.listSites.items);
    }
    return siteList;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
