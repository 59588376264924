import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { updateUser } from "../graphql/mutations";
import { Card, CardActionArea, Grid, Typography } from "@mui/material";

const onSubmit = async (obj, navigation) => {
  try {
    const userInfo = await Auth.currentAuthenticatedUser();

    const input = {
      id: userInfo.attributes.sub,
      currentSiteId: obj.id,
      currentSiteName: obj.siteName,
      currentMasterCode: obj.masterCode,
    };

    await API.graphql(
      graphqlOperation(updateUser, {
        input,
      })
    );

    navigation("/site-overview", { state: { currentSiteId: obj.id } });
  } catch (error) {
    console.log(error);
  }
};

export default function SearchResults({ siteList, index }) {
  const navigation = useNavigate();
  const handleSubmit = (event) => {
    onSubmit(event, navigation);
  };

  const SiteCard = ({ site }) => (
    <Card
      sx={{
        width: { xs: "94%", sm: "94%", md: 475 },
        height: "100%",
        backgroundColor: "#F9F9F9",
        borderRadius: 2,
        padding: 3,
        paddingTop: 2,
        paddingBottom: 2,
        marginBottom: 1,
        marginLeft: { xs: 2, sm: 3 },
        marginRight: { xs: 0, sm: 2 },
      }}
      elevation={0}
    >
      <CardActionArea onClick={() => handleSubmit(site)}>
        <Typography
          component="div"
          sx={{
            fontFamily: "Regular",
            fontSize: 21,
            fontWeight: "700",
            color: "#383838",
          }}
        >
          {site.siteName}
        </Typography>
        <Typography
          component="div"
          sx={{
            fontFamily: "Regular",
            fontSize: 15,
            fontWeight: "400",
            color: "#8D8D8D",
            marginBottom: 0.75,
          }}
        >
          案件管理番号: {site.masterCode}
        </Typography>
        <Typography
          component="div"
          sx={{
            fontFamily: "Regular",
            fontSize: 15,
            fontWeight: "400",
            color: "#8D8D8D",
            marginBottom: 0.75,
          }}
        >
          現場コード: {site.id}
        </Typography>
        <Typography
          component="div"
          sx={{
            fontFamily: "Regular",
            fontSize: 15,
            fontWeight: "400",
            color: "#8D8D8D",
          }}
        >
          住所: {site.address1}
        </Typography>
      </CardActionArea>
    </Card>
  );

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={6} sx={{ marginTop: 3 }}>
        {index * 2 < siteList.length && (
          <SiteCard site={siteList[index * 2]} handleSubmit={handleSubmit} />
        )}
      </Grid>
      <Grid item xs={12} md={6} sx={{ marginTop: 3 }}>
        {index * 2 + 1 < siteList.length && (
          <SiteCard
            site={siteList[index * 2 + 1]}
            handleSubmit={handleSubmit}
          />
        )}
      </Grid>
    </Grid>
  );
}
