/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSiteDataExport = /* GraphQL */ `
  query GetSiteDataExport(
    $siteCode: String!
    $startDate: String!
    $endDate: String!
  ) {
    getSiteDataExport(
      siteCode: $siteCode
      startDate: $startDate
      endDate: $endDate
    ) {
      statusCode
      body
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      uuid
      iosId
      tagName
      itemDate
      itemVendor
      itemName
      itemStatus
      itemNotes
      tagStatus
      anchorStatus
      latitude
      longitude
      altitude
      anchorFloor
      intendedFloor
      siteFloor
      anchorRoom
      siteRoom
      anchorRowIndex
      anchorColumnIndex
      siteRowIndex
      siteColumnIndex
      rssi
      image1
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      companyId
      siteId
      userId
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uuid
        iosId
        tagName
        itemDate
        itemVendor
        itemName
        itemStatus
        itemNotes
        tagStatus
        anchorStatus
        latitude
        longitude
        altitude
        anchorFloor
        intendedFloor
        siteFloor
        anchorRoom
        siteRoom
        anchorRowIndex
        anchorColumnIndex
        siteRowIndex
        siteColumnIndex
        rssi
        image1
        companyId
        siteId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPosition = /* GraphQL */ `
  query GetPosition($id: ID!) {
    getPosition(id: $id) {
      id
      uuid
      createdAt
      latitude
      longitude
      altitude
      siteFloor
      siteRoom
      siteRowIndex
      siteColumnIndex
      rssi
      companyId
      siteId
      userId
      tagId
      tag {
        id
        uuid
        iosId
        tagName
        itemDate
        itemVendor
        itemName
        itemStatus
        itemNotes
        tagStatus
        anchorStatus
        latitude
        longitude
        altitude
        anchorFloor
        intendedFloor
        siteFloor
        anchorRoom
        siteRoom
        anchorRowIndex
        anchorColumnIndex
        siteRowIndex
        siteColumnIndex
        rssi
        image1
        companyId
        siteId
        userId
        createdAt
        updatedAt
      }
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const listPositions = /* GraphQL */ `
  query ListPositions(
    $filter: ModelPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPositions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uuid
        createdAt
        latitude
        longitude
        altitude
        siteFloor
        siteRoom
        siteRowIndex
        siteColumnIndex
        rssi
        companyId
        siteId
        userId
        tagId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserPosition = /* GraphQL */ `
  query GetUserPosition($id: ID!) {
    getUserPosition(id: $id) {
      id
      uuid
      createdAt
      siteFloor
      siteRoom
      siteRowIndex
      siteColumnIndex
      rssi
      companyId
      siteId
      userId
      tagId
      tag {
        id
        uuid
        iosId
        tagName
        itemDate
        itemVendor
        itemName
        itemStatus
        itemNotes
        tagStatus
        anchorStatus
        latitude
        longitude
        altitude
        anchorFloor
        intendedFloor
        siteFloor
        anchorRoom
        siteRoom
        anchorRowIndex
        anchorColumnIndex
        siteRowIndex
        siteColumnIndex
        rssi
        image1
        companyId
        siteId
        userId
        createdAt
        updatedAt
      }
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const listUserPositions = /* GraphQL */ `
  query ListUserPositions(
    $filter: ModelUserPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPositions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uuid
        createdAt
        siteFloor
        siteRoom
        siteRowIndex
        siteColumnIndex
        rssi
        companyId
        siteId
        userId
        tagId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      itemName
      itemVendor
      companyId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        itemName
        itemVendor
        companyId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getManufacturer = /* GraphQL */ `
  query GetManufacturer($id: ID!) {
    getManufacturer(id: $id) {
      id
      itemVendor
      companyId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const listManufacturers = /* GraphQL */ `
  query ListManufacturers(
    $filter: ModelManufacturerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManufacturers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        itemVendor
        companyId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFloorPlan = /* GraphQL */ `
  query GetFloorPlan($id: ID!) {
    getFloorPlan(id: $id) {
      id
      siteFloor
      image
      cellWidth
      cellHeight
      anchorRssi
      itemRssi
      companyId
      siteId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const listFloorPlans = /* GraphQL */ `
  query ListFloorPlans(
    $filter: ModelFloorPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFloorPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        siteFloor
        image
        cellWidth
        cellHeight
        anchorRssi
        itemRssi
        companyId
        siteId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEnterExitHistory = /* GraphQL */ `
  query GetEnterExitHistory($id: ID!) {
    getEnterExitHistory(id: $id) {
      id
      companyId
      siteCode
      siteName
      familyName
      firstName
      freeidId
      usedAt
      deviceName
      enteredThermometryInfo
      exitedThermometryInfo
      exitedAt
      exitDeviceName
      exited
      vendorCode
      vendorName
      companyName
      enteredSiteLogId
      latestEnteredPreUsedAt
      latestEnteredUsedAt
      latestEnteredCreatedAt
      latestEnteredEditor
      latestEnteredEmail
      latestExitedPreUsedAt
      latestExitedUsedAt
      latestExitedCreatedAt
      latestExitedEditor
      latestExitedEmail
      siteOccupation
      createdAt
      updatedAt
    }
  }
`;
export const listEnterExitHistorys = /* GraphQL */ `
  query ListEnterExitHistorys(
    $filter: ModelEnterExitHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnterExitHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        siteCode
        siteName
        familyName
        firstName
        freeidId
        usedAt
        deviceName
        enteredThermometryInfo
        exitedThermometryInfo
        exitedAt
        exitDeviceName
        exited
        vendorCode
        vendorName
        companyName
        enteredSiteLogId
        latestEnteredPreUsedAt
        latestEnteredUsedAt
        latestEnteredCreatedAt
        latestEnteredEditor
        latestEnteredEmail
        latestExitedPreUsedAt
        latestExitedUsedAt
        latestExitedCreatedAt
        latestExitedEditor
        latestExitedEmail
        siteOccupation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byTag = /* GraphQL */ `
  query ByTag(
    $tagId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTag(
      tagId: $tagId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uuid
        createdAt
        latitude
        longitude
        altitude
        siteFloor
        siteRoom
        siteRowIndex
        siteColumnIndex
        rssi
        companyId
        siteId
        userId
        tagId
        updatedAt
      }
      nextToken
    }
  }
`;
export const byUser = /* GraphQL */ `
  query ByUser(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uuid
        createdAt
        siteFloor
        siteRoom
        siteRowIndex
        siteColumnIndex
        rssi
        companyId
        siteId
        userId
        tagId
        updatedAt
      }
      nextToken
    }
  }
`;
export const byTagUser = /* GraphQL */ `
  query ByTagUser(
    $tagId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTagUser(
      tagId: $tagId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uuid
        createdAt
        siteFloor
        siteRoom
        siteRowIndex
        siteColumnIndex
        rssi
        companyId
        siteId
        userId
        tagId
        updatedAt
      }
      nextToken
    }
  }
`;
export const enterExitHistoriesBySiteAndUsedAt = /* GraphQL */ `
  query EnterExitHistoriesBySiteAndUsedAt(
    $siteCode: String
    $usedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEnterExitHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    enterExitHistoriesBySiteAndUsedAt(
      siteCode: $siteCode
      usedAt: $usedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        siteCode
        siteName
        familyName
        firstName
        freeidId
        usedAt
        deviceName
        enteredThermometryInfo
        exitedThermometryInfo
        exitedAt
        exitDeviceName
        exited
        vendorCode
        vendorName
        companyName
        enteredSiteLogId
        latestEnteredPreUsedAt
        latestEnteredUsedAt
        latestEnteredCreatedAt
        latestEnteredEditor
        latestEnteredEmail
        latestExitedPreUsedAt
        latestExitedUsedAt
        latestExitedCreatedAt
        latestExitedEditor
        latestExitedEmail
        siteOccupation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const enterExitHistoriesByUpdatedAt = /* GraphQL */ `
  query EnterExitHistoriesByUpdatedAt(
    $companyId: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEnterExitHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    enterExitHistoriesByUpdatedAt(
      companyId: $companyId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        siteCode
        siteName
        familyName
        firstName
        freeidId
        usedAt
        deviceName
        enteredThermometryInfo
        exitedThermometryInfo
        exitedAt
        exitDeviceName
        exited
        vendorCode
        vendorName
        companyName
        enteredSiteLogId
        latestEnteredPreUsedAt
        latestEnteredUsedAt
        latestEnteredCreatedAt
        latestEnteredEditor
        latestEnteredEmail
        latestExitedPreUsedAt
        latestExitedUsedAt
        latestExitedCreatedAt
        latestExitedEditor
        latestExitedEmail
        siteOccupation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      firstName
      lastName
      firstNameFurigana
      lastNameFurigana
      phoneNumber
      currentSiteId
      currentMasterCode
      currentSiteName
      currentSiteFloor
      currentSiteRoom
      currentRowIndex
      currentColumnIndex
      isActive
      tags {
        nextToken
      }
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      companyId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      companyroles {
        nextToken
      }
      siteroles {
        nextToken
      }
      sitemanagements {
        nextToken
      }
      siteaccesss {
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      contractCompanyCode
      companyName
      address1
      address2
      city
      zipCode
      type
      phoneNumber
      emailFormat
      agreementUrl
      licenceQualificationChoices
      skillTrainingChoices
      specialTrainingChoices
      occupationChoices
      primaryCompanies
      floorplans {
        nextToken
      }
      tags {
        nextToken
      }
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      items {
        nextToken
      }
      createdAt
      updatedAt
      users {
        nextToken
      }
      sites {
        nextToken
      }
      companyroles {
        nextToken
      }
      siteroles {
        nextToken
      }
      sitemanagements {
        nextToken
      }
      siteaccesss {
        nextToken
      }
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSite = /* GraphQL */ `
  query GetSite($id: ID!) {
    getSite(id: $id) {
      id
      siteName
      masterCode
      address1
      address2
      address3
      city
      zipCode
      notesFileUrl
      qrCodeDescription
      contentText1
      choiceText1
      contentText2
      choiceText2
      contentText3
      choiceText3
      contentText4
      choiceText4
      contentText5
      choiceText5
      contentText6
      choiceText6
      contentText7
      choiceText7
      contentText8
      choiceText8
      contentText9
      choiceText9
      contentText10
      choiceText10
      contentText11
      choiceText11
      contentText12
      choiceText12
      contentText13
      choiceText13
      contentText14
      choiceText14
      contentText15
      choiceText15
      contentText16
      choiceText16
      contentText17
      choiceText17
      contentText18
      choiceText18
      contentText19
      choiceText19
      contentText20
      choiceText20
      contentText21
      choiceText21
      contentText22
      choiceText22
      contentText23
      choiceText23
      contentText24
      choiceText24
      contentText25
      choiceText25
      contentText26
      choiceText26
      contentText27
      choiceText27
      contentText28
      choiceText28
      contentText29
      choiceText29
      contentText30
      choiceText30
      contentText31
      choiceText31
      contentText32
      choiceText32
      contentText33
      choiceText33
      contentText34
      choiceText34
      contentText35
      choiceText35
      contentText36
      choiceText36
      contentText37
      choiceText37
      contentText38
      choiceText38
      contentText39
      choiceText39
      contentText40
      choiceText40
      contentText41
      choiceText41
      contentText42
      choiceText42
      contentText43
      choiceText43
      contentText44
      choiceText44
      contentText45
      choiceText45
      contentText46
      choiceText46
      contentText47
      choiceText47
      contentText48
      choiceText48
      contentText49
      choiceText49
      contentText50
      choiceText50
      contentText51
      choiceText51
      contentText52
      choiceText52
      contentText53
      choiceText53
      contentText54
      choiceText54
      contentText55
      choiceText55
      contentText56
      choiceText56
      contentText57
      choiceText57
      contentText58
      choiceText58
      contentText59
      choiceText59
      contentText60
      choiceText60
      contentText61
      choiceText61
      contentText62
      choiceText62
      contentText63
      choiceText63
      contentText64
      choiceText64
      contentText65
      choiceText65
      contentText66
      choiceText66
      contentText67
      choiceText67
      contentText68
      choiceText68
      contentText69
      choiceText69
      contentText70
      choiceText70
      contentText71
      choiceText71
      contentText72
      choiceText72
      contentText73
      choiceText73
      contentText74
      choiceText74
      contentText75
      choiceText75
      contentText76
      choiceText76
      contentText77
      choiceText77
      contentText78
      choiceText78
      contentText79
      choiceText79
      contentText80
      choiceText80
      contentText81
      choiceText81
      contentText82
      choiceText82
      contentText83
      choiceText83
      contentText84
      choiceText84
      contentText85
      choiceText85
      contentText86
      choiceText86
      contentText87
      choiceText87
      contentText88
      choiceText88
      contentText89
      choiceText89
      contentText90
      choiceText90
      contentText91
      choiceText91
      contentText92
      choiceText92
      contentText93
      choiceText93
      contentText94
      choiceText94
      contentText95
      choiceText95
      contentText96
      choiceText96
      contentText97
      choiceText97
      contentText98
      choiceText98
      contentText99
      choiceText99
      contentText100
      choiceText100
      latitude
      longitude
      altitude
      height
      floors
      latitude1
      longitude1
      latitude2
      longitude2
      latitude3
      longitude3
      latitude4
      longitude4
      latitude5
      longitude5
      itemRssi
      anchorRssi
      updateFrequency
      floorplans {
        nextToken
      }
      tags {
        nextToken
      }
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      companyId
      vendorIds
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      siteroles {
        nextToken
      }
      sitemanagements {
        nextToken
      }
      siteaccesss {
        nextToken
      }
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const sitesByUpdatedAt = /* GraphQL */ `
  query SitesByUpdatedAt(
    $companyId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sitesByUpdatedAt(
      companyId: $companyId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyRole = /* GraphQL */ `
  query GetCompanyRole($id: ID!) {
    getCompanyRole(id: $id) {
      id
      roleName
      roleLevel
      createdAt
      updatedAt
      companyId
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const listCompanyRoles = /* GraphQL */ `
  query ListCompanyRoles(
    $filter: ModelCompanyRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleName
        roleLevel
        createdAt
        updatedAt
        companyId
        userId
      }
      nextToken
    }
  }
`;
export const getSiteRole = /* GraphQL */ `
  query GetSiteRole($id: ID!) {
    getSiteRole(id: $id) {
      id
      roleName
      roleLevel
      createdAt
      updatedAt
      companyId
      siteCode
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSiteRoles = /* GraphQL */ `
  query ListSiteRoles(
    $filter: ModelSiteRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleName
        roleLevel
        createdAt
        updatedAt
        companyId
        siteCode
        userId
      }
      nextToken
    }
  }
`;
export const getFreeidUser = /* GraphQL */ `
  query GetFreeidUser($id: ID!) {
    getFreeidUser(id: $id) {
      id
      isEnteredBasicInfo
      familyName
      firstName
      familyNameKana
      firstNameKana
      gender
      zipCode
      address1
      address2
      address3
      mobileNumber
      email
      birthDate
      emergencyContactName
      emergencyContactTell
      isSelfEmployment
      companyName
      companyCEOName
      companyZipCode
      companyAddress1
      companyAddress2
      companyAddress3
      companyTell
      companyOccupation
      experienceYearPresent
      experienceMonthPresent
      experienceYearTotal
      experienceMonthTotal
      licenceQualification
      licenceQualificationOthers
      skillTraining
      skillTrainingOthers
      specialTraining
      specialTrainingOthers
      isForeigner
      residenceCardNumber1
      residenceCardNumber2
      residenceCardNumber3
      stayPeriod
      isSMEOwnerOrSingleMaster
      insuranceGroupName
      insuranceNumber1
      insuranceNumber2
      insuranceNumber3
      insuranceExpirationDate
      medicalCheckupCategory
      medicalCheckupLatestDate
      bloodPressureMax
      bloodPressureMin
      bloodType
      anamnesis
      anamnesisOthers
      canWorkWithoutProblems
      lastUpdateDate
      hasKentaikyo
      hasCcus
      ccusNumber
      healthInsurance
      pensionInsurance
      employmentInsurance
      employmentInsuranceNumber
      hasChutaikyo
      createdAt
      updatedAt
      companyId
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const listFreeidUsers = /* GraphQL */ `
  query ListFreeidUsers(
    $filter: ModelFreeidUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFreeidUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isEnteredBasicInfo
        familyName
        firstName
        familyNameKana
        firstNameKana
        gender
        zipCode
        address1
        address2
        address3
        mobileNumber
        email
        birthDate
        emergencyContactName
        emergencyContactTell
        isSelfEmployment
        companyName
        companyCEOName
        companyZipCode
        companyAddress1
        companyAddress2
        companyAddress3
        companyTell
        companyOccupation
        experienceYearPresent
        experienceMonthPresent
        experienceYearTotal
        experienceMonthTotal
        licenceQualification
        licenceQualificationOthers
        skillTraining
        skillTrainingOthers
        specialTraining
        specialTrainingOthers
        isForeigner
        residenceCardNumber1
        residenceCardNumber2
        residenceCardNumber3
        stayPeriod
        isSMEOwnerOrSingleMaster
        insuranceGroupName
        insuranceNumber1
        insuranceNumber2
        insuranceNumber3
        insuranceExpirationDate
        medicalCheckupCategory
        medicalCheckupLatestDate
        bloodPressureMax
        bloodPressureMin
        bloodType
        anamnesis
        anamnesisOthers
        canWorkWithoutProblems
        lastUpdateDate
        hasKentaikyo
        hasCcus
        ccusNumber
        healthInsurance
        pensionInsurance
        employmentInsurance
        employmentInsuranceNumber
        hasChutaikyo
        createdAt
        updatedAt
        companyId
        userId
      }
      nextToken
    }
  }
`;
export const freeidUsersByUpdatedAt = /* GraphQL */ `
  query FreeidUsersByUpdatedAt(
    $companyId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFreeidUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    freeidUsersByUpdatedAt(
      companyId: $companyId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isEnteredBasicInfo
        familyName
        firstName
        familyNameKana
        firstNameKana
        gender
        zipCode
        address1
        address2
        address3
        mobileNumber
        email
        birthDate
        emergencyContactName
        emergencyContactTell
        isSelfEmployment
        companyName
        companyCEOName
        companyZipCode
        companyAddress1
        companyAddress2
        companyAddress3
        companyTell
        companyOccupation
        experienceYearPresent
        experienceMonthPresent
        experienceYearTotal
        experienceMonthTotal
        licenceQualification
        licenceQualificationOthers
        skillTraining
        skillTrainingOthers
        specialTraining
        specialTrainingOthers
        isForeigner
        residenceCardNumber1
        residenceCardNumber2
        residenceCardNumber3
        stayPeriod
        isSMEOwnerOrSingleMaster
        insuranceGroupName
        insuranceNumber1
        insuranceNumber2
        insuranceNumber3
        insuranceExpirationDate
        medicalCheckupCategory
        medicalCheckupLatestDate
        bloodPressureMax
        bloodPressureMin
        bloodType
        anamnesis
        anamnesisOthers
        canWorkWithoutProblems
        lastUpdateDate
        hasKentaikyo
        hasCcus
        ccusNumber
        healthInsurance
        pensionInsurance
        employmentInsurance
        employmentInsuranceNumber
        hasChutaikyo
        createdAt
        updatedAt
        companyId
        userId
      }
      nextToken
    }
  }
`;
export const getFreeidCompanyUser = /* GraphQL */ `
  query GetFreeidCompanyUser($id: ID!) {
    getFreeidCompanyUser(id: $id) {
      id
      freeidId
      pihAgreementFlag
      createdAt
      updatedAt
      companyId
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const listFreeidCompanyUsers = /* GraphQL */ `
  query ListFreeidCompanyUsers(
    $filter: ModelFreeidCompanyUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFreeidCompanyUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        freeidId
        pihAgreementFlag
        createdAt
        updatedAt
        companyId
      }
      nextToken
    }
  }
`;
export const getSiteClearance = /* GraphQL */ `
  query GetSiteClearance($id: ID!) {
    getSiteClearance(id: $id) {
      id
      freeidId
      invitationCode
      qrCodeDescription
      masterCode
      siteName
      siteAddress
      applyStatus
      startDate
      endDate
      notesFileUrl
      fieldUserId
      isEnteredBasicInfo
      name
      firstName
      familyName
      firstNameKana
      familyNameKana
      gender
      zipCode
      address1
      address2
      address3
      mobileNumber
      email
      birthDate
      emergencyContactName
      emergencyContactTell
      isSelfEmployment
      companyName
      companyCEOName
      companyZipCode
      companyAddress1
      companyAddress2
      companyAddress3
      companyTell
      companyOccupation
      experienceYearPresent
      experienceMonthPresent
      experienceYearTotal
      experienceMonthTotal
      licenceQualification
      licenceQualificationOthers
      skillTraining
      skillTrainingOthers
      specialTraining
      specialTrainingOthers
      hasKentaikyo
      hasCcus
      ccusNumber
      isForeigner
      residenceCardNumber1
      residenceCardNumber2
      residenceCardNumber3
      stayPeriod
      isSMEOwnerOrSingleMaster
      insuranceGroupName
      insuranceNumber1
      insuranceNumber2
      insuranceNumber3
      insuranceExpirationDate
      medicalCheckupCategory
      medicalCheckupLatestDate
      bloodPressureMax
      bloodPressureMin
      bloodType
      anamnesis
      anamnesisOthers
      canWorkWithoutProblems
      lastUpdateDate
      licenceQualificationChoices
      skillTrainingChoices
      specialTrainingChoices
      occupationChoices
      primaryCompanies
      agreementUrl
      pihAgreementFlag
      checkListInfo
      contentText
      choiceText
      companyOrder
      primaryCompanyName
      secondaryCompanyName
      secondaryCompanyNameKana
      thirdCompanyName
      thirdCompanyNameKana
      fourthCompanyName
      fourthCompanyNameKana
      groupName
      siteOccupation
      workerType
      isForeman
      youthElderly
      notes
      normKnowledge
      agreementOfYouth
      agreementOfElderly
      shootingProhibited
      snsPostingProhibited
      disclosureProhibited
      discardCopyProhibited
      observancePledge
      checkListInputContents
      healthInsurance
      pensionInsurance
      employmentInsurance
      employmentInsuranceNumber
      hasChutaikyo
      accessLevel
      educationDate
      isChief
      createdAt
      updatedAt
      companyId
      siteCode
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSiteClearances = /* GraphQL */ `
  query ListSiteClearances(
    $filter: ModelSiteClearanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteClearances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        freeidId
        invitationCode
        qrCodeDescription
        masterCode
        siteName
        siteAddress
        applyStatus
        startDate
        endDate
        notesFileUrl
        fieldUserId
        isEnteredBasicInfo
        name
        firstName
        familyName
        firstNameKana
        familyNameKana
        gender
        zipCode
        address1
        address2
        address3
        mobileNumber
        email
        birthDate
        emergencyContactName
        emergencyContactTell
        isSelfEmployment
        companyName
        companyCEOName
        companyZipCode
        companyAddress1
        companyAddress2
        companyAddress3
        companyTell
        companyOccupation
        experienceYearPresent
        experienceMonthPresent
        experienceYearTotal
        experienceMonthTotal
        licenceQualification
        licenceQualificationOthers
        skillTraining
        skillTrainingOthers
        specialTraining
        specialTrainingOthers
        hasKentaikyo
        hasCcus
        ccusNumber
        isForeigner
        residenceCardNumber1
        residenceCardNumber2
        residenceCardNumber3
        stayPeriod
        isSMEOwnerOrSingleMaster
        insuranceGroupName
        insuranceNumber1
        insuranceNumber2
        insuranceNumber3
        insuranceExpirationDate
        medicalCheckupCategory
        medicalCheckupLatestDate
        bloodPressureMax
        bloodPressureMin
        bloodType
        anamnesis
        anamnesisOthers
        canWorkWithoutProblems
        lastUpdateDate
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        agreementUrl
        pihAgreementFlag
        checkListInfo
        contentText
        choiceText
        companyOrder
        primaryCompanyName
        secondaryCompanyName
        secondaryCompanyNameKana
        thirdCompanyName
        thirdCompanyNameKana
        fourthCompanyName
        fourthCompanyNameKana
        groupName
        siteOccupation
        workerType
        isForeman
        youthElderly
        notes
        normKnowledge
        agreementOfYouth
        agreementOfElderly
        shootingProhibited
        snsPostingProhibited
        disclosureProhibited
        discardCopyProhibited
        observancePledge
        checkListInputContents
        healthInsurance
        pensionInsurance
        employmentInsurance
        employmentInsuranceNumber
        hasChutaikyo
        accessLevel
        educationDate
        isChief
        createdAt
        updatedAt
        companyId
        siteCode
        userId
      }
      nextToken
    }
  }
`;
export const getSiteClearanceHistory = /* GraphQL */ `
  query GetSiteClearanceHistory($id: ID!) {
    getSiteClearanceHistory(id: $id) {
      id
      siteClearanceId
      changedColumns
      createdAt
      updatedAt
      accessLevel
      address1
      address2
      address3
      agreementOfElderly
      agreementOfYouth
      agreementUrl
      anamnesis
      anamnesisOthers
      applyStatus
      birthDate
      bloodPressureMax
      bloodPressureMin
      bloodType
      canWorkWithoutProblems
      ccusNumber
      checkListInfo
      checkListInputContents
      choiceText
      companyAddress1
      companyAddress2
      companyAddress3
      companyCEOName
      companyId
      companyName
      companyOccupation
      companyOrder
      companyTell
      companyZipCode
      contentText
      discardCopyProhibited
      disclosureProhibited
      educationDate
      email
      emergencyContactName
      emergencyContactTell
      employmentInsurance
      employmentInsuranceNumber
      endDate
      experienceMonthPresent
      experienceMonthTotal
      experienceYearPresent
      experienceYearTotal
      familyName
      familyNameKana
      fieldUserId
      firstName
      firstNameKana
      fourthCompanyName
      fourthCompanyNameKana
      freeidId
      gender
      groupName
      hasCcus
      hasChutaikyo
      hasKentaikyo
      healthInsurance
      insuranceExpirationDate
      insuranceGroupName
      insuranceNumber1
      insuranceNumber2
      insuranceNumber3
      invitationCode
      isChief
      isEnteredBasicInfo
      isForeigner
      isForeman
      isSelfEmployment
      isSMEOwnerOrSingleMaster
      lastUpdateDate
      licenceQualification
      licenceQualificationChoices
      licenceQualificationOthers
      masterCode
      medicalCheckupCategory
      medicalCheckupLatestDate
      mobileNumber
      name
      normKnowledge
      notes
      notesFileUrl
      observancePledge
      occupationChoices
      pensionInsurance
      pihAgreementFlag
      primaryCompanies
      primaryCompanyName
      qrCodeDescription
      residenceCardNumber1
      residenceCardNumber2
      residenceCardNumber3
      secondaryCompanyName
      secondaryCompanyNameKana
      shootingProhibited
      siteAddress
      siteClearanceCreatedAt
      siteClearanceUpdatedAt
      siteCode
      siteName
      siteOccupation
      skillTraining
      skillTrainingChoices
      skillTrainingOthers
      snsPostingProhibited
      specialTraining
      specialTrainingChoices
      specialTrainingOthers
      startDate
      stayPeriod
      thirdCompanyName
      thirdCompanyNameKana
      userId
      workerType
      youthElderly
      zipCode
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      siteClearance {
        id
        freeidId
        invitationCode
        qrCodeDescription
        masterCode
        siteName
        siteAddress
        applyStatus
        startDate
        endDate
        notesFileUrl
        fieldUserId
        isEnteredBasicInfo
        name
        firstName
        familyName
        firstNameKana
        familyNameKana
        gender
        zipCode
        address1
        address2
        address3
        mobileNumber
        email
        birthDate
        emergencyContactName
        emergencyContactTell
        isSelfEmployment
        companyName
        companyCEOName
        companyZipCode
        companyAddress1
        companyAddress2
        companyAddress3
        companyTell
        companyOccupation
        experienceYearPresent
        experienceMonthPresent
        experienceYearTotal
        experienceMonthTotal
        licenceQualification
        licenceQualificationOthers
        skillTraining
        skillTrainingOthers
        specialTraining
        specialTrainingOthers
        hasKentaikyo
        hasCcus
        ccusNumber
        isForeigner
        residenceCardNumber1
        residenceCardNumber2
        residenceCardNumber3
        stayPeriod
        isSMEOwnerOrSingleMaster
        insuranceGroupName
        insuranceNumber1
        insuranceNumber2
        insuranceNumber3
        insuranceExpirationDate
        medicalCheckupCategory
        medicalCheckupLatestDate
        bloodPressureMax
        bloodPressureMin
        bloodType
        anamnesis
        anamnesisOthers
        canWorkWithoutProblems
        lastUpdateDate
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        agreementUrl
        pihAgreementFlag
        checkListInfo
        contentText
        choiceText
        companyOrder
        primaryCompanyName
        secondaryCompanyName
        secondaryCompanyNameKana
        thirdCompanyName
        thirdCompanyNameKana
        fourthCompanyName
        fourthCompanyNameKana
        groupName
        siteOccupation
        workerType
        isForeman
        youthElderly
        notes
        normKnowledge
        agreementOfYouth
        agreementOfElderly
        shootingProhibited
        snsPostingProhibited
        disclosureProhibited
        discardCopyProhibited
        observancePledge
        checkListInputContents
        healthInsurance
        pensionInsurance
        employmentInsurance
        employmentInsuranceNumber
        hasChutaikyo
        accessLevel
        educationDate
        isChief
        createdAt
        updatedAt
        companyId
        siteCode
        userId
      }
    }
  }
`;
export const listSiteClearanceHistorys = /* GraphQL */ `
  query ListSiteClearanceHistorys(
    $filter: ModelSiteClearanceHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteClearanceHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        siteClearanceId
        changedColumns
        createdAt
        updatedAt
        accessLevel
        address1
        address2
        address3
        agreementOfElderly
        agreementOfYouth
        agreementUrl
        anamnesis
        anamnesisOthers
        applyStatus
        birthDate
        bloodPressureMax
        bloodPressureMin
        bloodType
        canWorkWithoutProblems
        ccusNumber
        checkListInfo
        checkListInputContents
        choiceText
        companyAddress1
        companyAddress2
        companyAddress3
        companyCEOName
        companyId
        companyName
        companyOccupation
        companyOrder
        companyTell
        companyZipCode
        contentText
        discardCopyProhibited
        disclosureProhibited
        educationDate
        email
        emergencyContactName
        emergencyContactTell
        employmentInsurance
        employmentInsuranceNumber
        endDate
        experienceMonthPresent
        experienceMonthTotal
        experienceYearPresent
        experienceYearTotal
        familyName
        familyNameKana
        fieldUserId
        firstName
        firstNameKana
        fourthCompanyName
        fourthCompanyNameKana
        freeidId
        gender
        groupName
        hasCcus
        hasChutaikyo
        hasKentaikyo
        healthInsurance
        insuranceExpirationDate
        insuranceGroupName
        insuranceNumber1
        insuranceNumber2
        insuranceNumber3
        invitationCode
        isChief
        isEnteredBasicInfo
        isForeigner
        isForeman
        isSelfEmployment
        isSMEOwnerOrSingleMaster
        lastUpdateDate
        licenceQualification
        licenceQualificationChoices
        licenceQualificationOthers
        masterCode
        medicalCheckupCategory
        medicalCheckupLatestDate
        mobileNumber
        name
        normKnowledge
        notes
        notesFileUrl
        observancePledge
        occupationChoices
        pensionInsurance
        pihAgreementFlag
        primaryCompanies
        primaryCompanyName
        qrCodeDescription
        residenceCardNumber1
        residenceCardNumber2
        residenceCardNumber3
        secondaryCompanyName
        secondaryCompanyNameKana
        shootingProhibited
        siteAddress
        siteClearanceCreatedAt
        siteClearanceUpdatedAt
        siteCode
        siteName
        siteOccupation
        skillTraining
        skillTrainingChoices
        skillTrainingOthers
        snsPostingProhibited
        specialTraining
        specialTrainingChoices
        specialTrainingOthers
        startDate
        stayPeriod
        thirdCompanyName
        thirdCompanyNameKana
        userId
        workerType
        youthElderly
        zipCode
      }
      nextToken
    }
  }
`;
export const siteClearancesByUpdatedAt = /* GraphQL */ `
  query SiteClearancesByUpdatedAt(
    $companyId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiteClearanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteClearancesByUpdatedAt(
      companyId: $companyId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        freeidId
        invitationCode
        qrCodeDescription
        masterCode
        siteName
        siteAddress
        applyStatus
        startDate
        endDate
        notesFileUrl
        fieldUserId
        isEnteredBasicInfo
        name
        firstName
        familyName
        firstNameKana
        familyNameKana
        gender
        zipCode
        address1
        address2
        address3
        mobileNumber
        email
        birthDate
        emergencyContactName
        emergencyContactTell
        isSelfEmployment
        companyName
        companyCEOName
        companyZipCode
        companyAddress1
        companyAddress2
        companyAddress3
        companyTell
        companyOccupation
        experienceYearPresent
        experienceMonthPresent
        experienceYearTotal
        experienceMonthTotal
        licenceQualification
        licenceQualificationOthers
        skillTraining
        skillTrainingOthers
        specialTraining
        specialTrainingOthers
        hasKentaikyo
        hasCcus
        ccusNumber
        isForeigner
        residenceCardNumber1
        residenceCardNumber2
        residenceCardNumber3
        stayPeriod
        isSMEOwnerOrSingleMaster
        insuranceGroupName
        insuranceNumber1
        insuranceNumber2
        insuranceNumber3
        insuranceExpirationDate
        medicalCheckupCategory
        medicalCheckupLatestDate
        bloodPressureMax
        bloodPressureMin
        bloodType
        anamnesis
        anamnesisOthers
        canWorkWithoutProblems
        lastUpdateDate
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        agreementUrl
        pihAgreementFlag
        checkListInfo
        contentText
        choiceText
        companyOrder
        primaryCompanyName
        secondaryCompanyName
        secondaryCompanyNameKana
        thirdCompanyName
        thirdCompanyNameKana
        fourthCompanyName
        fourthCompanyNameKana
        groupName
        siteOccupation
        workerType
        isForeman
        youthElderly
        notes
        normKnowledge
        agreementOfYouth
        agreementOfElderly
        shootingProhibited
        snsPostingProhibited
        disclosureProhibited
        discardCopyProhibited
        observancePledge
        checkListInputContents
        healthInsurance
        pensionInsurance
        employmentInsurance
        employmentInsuranceNumber
        hasChutaikyo
        accessLevel
        educationDate
        isChief
        createdAt
        updatedAt
        companyId
        siteCode
        userId
      }
      nextToken
    }
  }
`;
export const getSiteLog = /* GraphQL */ `
  query GetSiteLog($id: ID!) {
    getSiteLog(id: $id) {
      id
      freeidId
      thermometryInfo
      usedAt
      authType
      deviceName
      companyName
      siteName
      firstName
      familyName
      createdAt
      updatedAt
      exited
      exitedAt
      exitDeviceCode
      exitDeviceName
      enteredSiteLogId
      companyId
      siteCode
      deviceCode
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      sitegate {
        id
        deviceCode
        siteName
        contractCompanyCode
        deviceName
        companyId
        siteCode
        userId
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSiteLogs = /* GraphQL */ `
  query ListSiteLogs(
    $filter: ModelSiteLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        freeidId
        thermometryInfo
        usedAt
        authType
        deviceName
        companyName
        siteName
        firstName
        familyName
        createdAt
        updatedAt
        exited
        exitedAt
        exitDeviceCode
        exitDeviceName
        enteredSiteLogId
        companyId
        siteCode
        deviceCode
        userId
      }
      nextToken
    }
  }
`;
export const siteLogByCompany = /* GraphQL */ `
  query SiteLogByCompany(
    $companyId: ID
    $createdAtUpdatedAt: ModelSiteLogByCompanyCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiteLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteLogByCompany(
      companyId: $companyId
      createdAtUpdatedAt: $createdAtUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        freeidId
        thermometryInfo
        usedAt
        authType
        deviceName
        companyName
        siteName
        firstName
        familyName
        createdAt
        updatedAt
        exited
        exitedAt
        exitDeviceCode
        exitDeviceName
        enteredSiteLogId
        companyId
        siteCode
        deviceCode
        userId
      }
      nextToken
    }
  }
`;
export const siteLogBySite = /* GraphQL */ `
  query SiteLogBySite(
    $siteCode: ID
    $createdAtUpdatedAt: ModelSiteLogBySiteCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiteLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteLogBySite(
      siteCode: $siteCode
      createdAtUpdatedAt: $createdAtUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        freeidId
        thermometryInfo
        usedAt
        authType
        deviceName
        companyName
        siteName
        firstName
        familyName
        createdAt
        updatedAt
        exited
        exitedAt
        exitDeviceCode
        exitDeviceName
        enteredSiteLogId
        companyId
        siteCode
        deviceCode
        userId
      }
      nextToken
    }
  }
`;
export const siteLogBySiteGate = /* GraphQL */ `
  query SiteLogBySiteGate(
    $deviceCode: ID
    $createdAtUpdatedAt: ModelSiteLogBySiteGateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiteLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteLogBySiteGate(
      deviceCode: $deviceCode
      createdAtUpdatedAt: $createdAtUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        freeidId
        thermometryInfo
        usedAt
        authType
        deviceName
        companyName
        siteName
        firstName
        familyName
        createdAt
        updatedAt
        exited
        exitedAt
        exitDeviceCode
        exitDeviceName
        enteredSiteLogId
        companyId
        siteCode
        deviceCode
        userId
      }
      nextToken
    }
  }
`;
export const siteLogByUser = /* GraphQL */ `
  query SiteLogByUser(
    $userId: ID
    $createdAtUpdatedAt: ModelSiteLogByUserCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiteLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteLogByUser(
      userId: $userId
      createdAtUpdatedAt: $createdAtUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        freeidId
        thermometryInfo
        usedAt
        authType
        deviceName
        companyName
        siteName
        firstName
        familyName
        createdAt
        updatedAt
        exited
        exitedAt
        exitDeviceCode
        exitDeviceName
        enteredSiteLogId
        companyId
        siteCode
        deviceCode
        userId
      }
      nextToken
    }
  }
`;
export const siteCodeByFreeidId = /* GraphQL */ `
  query SiteCodeByFreeidId(
    $siteCode: ID
    $freeidId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiteLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteCodeByFreeidId(
      siteCode: $siteCode
      freeidId: $freeidId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        freeidId
        thermometryInfo
        usedAt
        authType
        deviceName
        companyName
        siteName
        firstName
        familyName
        createdAt
        updatedAt
        exited
        exitedAt
        exitDeviceCode
        exitDeviceName
        enteredSiteLogId
        companyId
        siteCode
        deviceCode
        userId
      }
      nextToken
    }
  }
`;
export const exitedSiteLogsByUpdatedAt = /* GraphQL */ `
  query ExitedSiteLogsByUpdatedAt(
    $enteredSiteLogId: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiteLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exitedSiteLogsByUpdatedAt(
      enteredSiteLogId: $enteredSiteLogId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        freeidId
        thermometryInfo
        usedAt
        authType
        deviceName
        companyName
        siteName
        firstName
        familyName
        createdAt
        updatedAt
        exited
        exitedAt
        exitDeviceCode
        exitDeviceName
        enteredSiteLogId
        companyId
        siteCode
        deviceCode
        userId
      }
      nextToken
    }
  }
`;
export const siteLogBySiteAndUsedAt = /* GraphQL */ `
  query SiteLogBySiteAndUsedAt(
    $siteCode: ID
    $usedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiteLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteLogBySiteAndUsedAt(
      siteCode: $siteCode
      usedAt: $usedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        freeidId
        thermometryInfo
        usedAt
        authType
        deviceName
        companyName
        siteName
        firstName
        familyName
        createdAt
        updatedAt
        exited
        exitedAt
        exitDeviceCode
        exitDeviceName
        enteredSiteLogId
        companyId
        siteCode
        deviceCode
        userId
      }
      nextToken
    }
  }
`;
export const getSiteLogHistory = /* GraphQL */ `
  query GetSiteLogHistory($id: ID!) {
    getSiteLogHistory(id: $id) {
      id
      siteLogId
      usedAt
      preUsedAt
      editor
      email
      action
      authType
      createdAt
      updatedAt
      siteLog {
        id
        freeidId
        thermometryInfo
        usedAt
        authType
        deviceName
        companyName
        siteName
        firstName
        familyName
        createdAt
        updatedAt
        exited
        exitedAt
        exitDeviceCode
        exitDeviceName
        enteredSiteLogId
        companyId
        siteCode
        deviceCode
        userId
      }
    }
  }
`;
export const listSiteLogHistorys = /* GraphQL */ `
  query ListSiteLogHistorys(
    $filter: ModelSiteLogHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteLogHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        siteLogId
        usedAt
        preUsedAt
        editor
        email
        action
        authType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const siteLogHistoryBySiteLogId = /* GraphQL */ `
  query SiteLogHistoryBySiteLogId(
    $siteLogId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiteLogHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteLogHistoryBySiteLogId(
      siteLogId: $siteLogId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        siteLogId
        usedAt
        preUsedAt
        editor
        email
        action
        authType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSiteGate = /* GraphQL */ `
  query GetSiteGate($id: ID!) {
    getSiteGate(id: $id) {
      id
      deviceCode
      siteName
      contractCompanyCode
      deviceName
      companyId
      siteCode
      userId
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      sitelogs {
        nextToken
      }
    }
  }
`;
export const listSiteGates = /* GraphQL */ `
  query ListSiteGates(
    $filter: ModelSiteGateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteGates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deviceCode
        siteName
        contractCompanyCode
        deviceName
        companyId
        siteCode
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSiteManagement = /* GraphQL */ `
  query GetSiteManagement($id: ID!) {
    getSiteManagement(id: $id) {
      id
      companyName
      siteName
      fieldUserId
      lastName
      firstName
      email
      phoneNumber
      status
      accessLevel
      startDate
      endDate
      companyId
      siteId
      userId
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSiteManagements = /* GraphQL */ `
  query ListSiteManagements(
    $filter: ModelSiteManagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteManagements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        siteName
        fieldUserId
        lastName
        firstName
        email
        phoneNumber
        status
        accessLevel
        startDate
        endDate
        companyId
        siteId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSiteAccess = /* GraphQL */ `
  query GetSiteAccess($id: ID!) {
    getSiteAccess(id: $id) {
      id
      companyName
      siteName
      gateName
      lastName
      firstName
      userTemperature
      entry
      exit
      createdAt
      updatedAt
      companyId
      siteId
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSiteAccesss = /* GraphQL */ `
  query ListSiteAccesss(
    $filter: ModelSiteAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteAccesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        siteName
        gateName
        lastName
        firstName
        userTemperature
        entry
        exit
        createdAt
        updatedAt
        companyId
        siteId
        userId
      }
      nextToken
    }
  }
`;
export const siteAccessByCompany = /* GraphQL */ `
  query SiteAccessByCompany(
    $companyId: ID
    $createdAtUpdatedAt: ModelSiteAccessByCompanyCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiteAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteAccessByCompany(
      companyId: $companyId
      createdAtUpdatedAt: $createdAtUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyName
        siteName
        gateName
        lastName
        firstName
        userTemperature
        entry
        exit
        createdAt
        updatedAt
        companyId
        siteId
        userId
      }
      nextToken
    }
  }
`;
export const siteAccessBySite = /* GraphQL */ `
  query SiteAccessBySite(
    $siteId: ID
    $createdAtUpdatedAt: ModelSiteAccessBySiteCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiteAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteAccessBySite(
      siteId: $siteId
      createdAtUpdatedAt: $createdAtUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyName
        siteName
        gateName
        lastName
        firstName
        userTemperature
        entry
        exit
        createdAt
        updatedAt
        companyId
        siteId
        userId
      }
      nextToken
    }
  }
`;
export const siteAccessByUser = /* GraphQL */ `
  query SiteAccessByUser(
    $userId: ID
    $createdAtUpdatedAt: ModelSiteAccessByUserCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiteAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteAccessByUser(
      userId: $userId
      createdAtUpdatedAt: $createdAtUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyName
        siteName
        gateName
        lastName
        firstName
        userTemperature
        entry
        exit
        createdAt
        updatedAt
        companyId
        siteId
        userId
      }
      nextToken
    }
  }
`;
export const getCompanyVendor = /* GraphQL */ `
  query GetCompanyVendor($companyId: ID!, $vendorId: ID!) {
    getCompanyVendor(companyId: $companyId, vendorId: $vendorId) {
      companyId
      vendorId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      vendor {
        id
        vendorCode
        vendorName
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const listCompanyVendors = /* GraphQL */ `
  query ListCompanyVendors(
    $companyId: ID
    $vendorId: ModelIDKeyConditionInput
    $filter: ModelCompanyVendorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyVendors(
      companyId: $companyId
      vendorId: $vendorId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        companyId
        vendorId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const companyByVendor = /* GraphQL */ `
  query CompanyByVendor(
    $vendorId: ID
    $companyId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyByVendor(
      vendorId: $vendorId
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        companyId
        vendorId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSharePointDataLink = /* GraphQL */ `
  query GetSharePointDataLink($id: ID!) {
    getSharePointDataLink(id: $id) {
      id
      companyId
      dataType
      dataId
      sent
      sentAt
      shareable
      latestSentAt
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSharePointDataLinks = /* GraphQL */ `
  query ListSharePointDataLinks(
    $filter: ModelSharePointDataLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSharePointDataLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        dataType
        dataId
        sent
        sentAt
        shareable
        latestSentAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSharePointDataLinkHistory = /* GraphQL */ `
  query GetSharePointDataLinkHistory($id: ID!) {
    getSharePointDataLinkHistory(id: $id) {
      id
      companyId
      dataType
      dataId
      fileName
      content
      sent
      sentAt
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSharePointDataLinkHistorys = /* GraphQL */ `
  query ListSharePointDataLinkHistorys(
    $filter: ModelSharePointDataLinkHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSharePointDataLinkHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        dataType
        dataId
        fileName
        content
        sent
        sentAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSiteVendor = /* GraphQL */ `
  query GetSiteVendor($id: ID!) {
    getSiteVendor(id: $id) {
      id
      siteId
      vendorId
      createdAt
      updatedAt
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      vendor {
        id
        vendorCode
        vendorName
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSiteVendors = /* GraphQL */ `
  query ListSiteVendors(
    $filter: ModelSiteVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        siteId
        vendorId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVendor = /* GraphQL */ `
  query GetVendor($id: ID!) {
    getVendor(id: $id) {
      id
      vendorCode
      vendorName
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const listVendors = /* GraphQL */ `
  query ListVendors(
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vendorCode
        vendorName
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byVendorCode = /* GraphQL */ `
  query ByVendorCode(
    $vendorCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byVendorCode(
      vendorCode: $vendorCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vendorCode
        vendorName
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byVendorName = /* GraphQL */ `
  query ByVendorName(
    $vendorName: String
    $sortDirection: ModelSortDirection
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byVendorName(
      vendorName: $vendorName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vendorCode
        vendorName
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVendorHistory = /* GraphQL */ `
  query GetVendorHistory($id: ID!) {
    getVendorHistory(id: $id) {
      id
      vendorId
      editedColumn
      preVendorName
      vendorName
      preVendorCode
      vendorCode
      editor
      createdAt
      updatedAt
      vendor {
        id
        vendorCode
        vendorName
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const listVendorHistorys = /* GraphQL */ `
  query ListVendorHistorys(
    $filter: ModelVendorHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendorHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vendorId
        editedColumn
        preVendorName
        vendorName
        preVendorCode
        vendorCode
        editor
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byVendorId = /* GraphQL */ `
  query ByVendorId(
    $vendorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVendorHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byVendorId(
      vendorId: $vendorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vendorId
        editedColumn
        preVendorName
        vendorName
        preVendorCode
        vendorCode
        editor
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
