import React, { useState, useCallback } from "react";
import {
  DataGrid,
  jaJP,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { format } from "date-fns";

export default function ManageDataSiteGrid({ rows, columns, fileName }) {
  const [pageSize, setPageSize] = useState(10);
  const [filterButtonEl, setFilterButtonEl] = useState(null);

  const CustomToolbar = useCallback(() => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton ref={setFilterButtonEl} />
        <GridToolbarExport
          csvOptions={{
            fileName: `${fileName}_${format(
              new Date(
                new Date().toLocaleString("ja-JP", { timeZone: "Japan" })
              ),
              "yyyy-MM-dd"
            )}`,
            utf8WithBom: true,
            allColumns: true,
          }}
          printOptions={{ disableToolbarButton: true }}
        />
      </GridToolbarContainer>
    );
  }, [fileName]);

  return (
    <Box
      sx={{
        height: "80vh",
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: "#F9F9F9",
          border: 0,
          pl: 0,
          pt: 1,
          pb: 1,
          fontFamily: "Regular",
          fontSize: 12,
          fontWeight: "400",
          color: "#8D8D8D",
        },
        "& .super-app-theme--rows": {
          backgroundColor: "#F9F9F9",
          borderBottom: 1,
          borderColor: "#E5E5E5",
          pl: 0,
          fontFamily: "Regular",
          fontSize: 15,
          fontWeight: "400",
          color: "#383838",
          "&:hover": {
            backgroundColor: "#E3E9FA",
            borderBottom: 1,
            borderColor: "#99B4E3",
          },
        },
      }}
    >
      {rows && (
        <DataGrid
          rows={rows}
          columns={columns}
          sx={{
            borderTop: 1,
            borderBottom: 0,
            borderRight: 0,
            borderLeft: 0,
            borderRadius: 0,
            borderColor: "#E5E5E5",
          }}
          disableSelectionOnClick
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          getRowClassName={(params) => `super-app-theme--rows`}
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
          rowsPerPageOptions={[10, 50, 100]}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            panel: {
              anchorEl: filterButtonEl,
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "vendorCode", sort: "asc" }],
            },
            columns: {
              columnVisibilityModel: {
                // site
                id: false,
                address1: false,
                address2: false,
                address3: false,
                city: false,
                zipCode: false,
                notesFileUrl: false,
                qrCodeDescription: false,
                contentText1: false,
                choiceText1: false,
                contentText2: false,
                choiceText2: false,
                contentText3: false,
                choiceText3: false,
                contentText4: false,
                choiceText4: false,
                contentText5: false,
                choiceText5: false,
                contentText6: false,
                choiceText6: false,
                contentText7: false,
                choiceText7: false,
                contentText8: false,
                choiceText8: false,
                contentText9: false,
                choiceText9: false,
                contentText10: false,
                choiceText10: false,
                contentText11: false,
                choiceText11: false,
                contentText12: false,
                choiceText12: false,
                contentText13: false,
                choiceText13: false,
                contentText14: false,
                choiceText14: false,
                contentText15: false,
                choiceText15: false,
                contentText16: false,
                choiceText16: false,
                contentText17: false,
                choiceText17: false,
                contentText18: false,
                choiceText18: false,
                contentText19: false,
                choiceText19: false,
                contentText20: false,
                choiceText20: false,
                contentText21: false,
                choiceText21: false,
                contentText22: false,
                choiceText22: false,
                contentText23: false,
                choiceText23: false,
                contentText24: false,
                choiceText24: false,
                contentText25: false,
                choiceText25: false,
                contentText26: false,
                choiceText26: false,
                contentText27: false,
                choiceText27: false,
                contentText28: false,
                choiceText28: false,
                contentText29: false,
                choiceText29: false,
                contentText30: false,
                choiceText30: false,
                contentText31: false,
                choiceText31: false,
                contentText32: false,
                choiceText32: false,
                contentText33: false,
                choiceText33: false,
                contentText34: false,
                choiceText34: false,
                contentText35: false,
                choiceText35: false,
                contentText36: false,
                choiceText36: false,
                contentText37: false,
                choiceText37: false,
                contentText38: false,
                choiceText38: false,
                contentText39: false,
                choiceText39: false,
                contentText40: false,
                choiceText40: false,
                contentText41: false,
                choiceText41: false,
                contentText42: false,
                choiceText42: false,
                contentText43: false,
                choiceText43: false,
                contentText44: false,
                choiceText44: false,
                contentText45: false,
                choiceText45: false,
                contentText46: false,
                choiceText46: false,
                contentText47: false,
                choiceText47: false,
                contentText48: false,
                choiceText48: false,
                contentText49: false,
                choiceText49: false,
                contentText50: false,
                choiceText50: false,
                contentText51: false,
                choiceText51: false,
                contentText52: false,
                choiceText52: false,
                contentText53: false,
                choiceText53: false,
                contentText54: false,
                choiceText54: false,
                contentText55: false,
                choiceText55: false,
                contentText56: false,
                choiceText56: false,
                contentText57: false,
                choiceText57: false,
                contentText58: false,
                choiceText58: false,
                contentText59: false,
                choiceText59: false,
                contentText60: false,
                choiceText60: false,
                contentText61: false,
                choiceText61: false,
                contentText62: false,
                choiceText62: false,
                contentText63: false,
                choiceText63: false,
                contentText64: false,
                choiceText64: false,
                contentText65: false,
                choiceText65: false,
                contentText66: false,
                choiceText66: false,
                contentText67: false,
                choiceText67: false,
                contentText68: false,
                choiceText68: false,
                contentText69: false,
                choiceText69: false,
                contentText70: false,
                choiceText70: false,
                contentText71: false,
                choiceText71: false,
                contentText72: false,
                choiceText72: false,
                contentText73: false,
                choiceText73: false,
                contentText74: false,
                choiceText74: false,
                contentText75: false,
                choiceText75: false,
                contentText76: false,
                choiceText76: false,
                contentText77: false,
                choiceText77: false,
                contentText78: false,
                choiceText78: false,
                contentText79: false,
                choiceText79: false,
                contentText80: false,
                choiceText80: false,
                contentText81: false,
                choiceText81: false,
                contentText82: false,
                choiceText82: false,
                contentText83: false,
                choiceText83: false,
                contentText84: false,
                choiceText84: false,
                contentText85: false,
                choiceText85: false,
                contentText86: false,
                choiceText86: false,
                contentText87: false,
                choiceText87: false,
                contentText88: false,
                choiceText88: false,
                contentText89: false,
                choiceText89: false,
                contentText90: false,
                choiceText90: false,
                contentText91: false,
                choiceText91: false,
                contentText92: false,
                choiceText92: false,
                contentText93: false,
                choiceText93: false,
                contentText94: false,
                choiceText94: false,
                contentText95: false,
                choiceText95: false,
                contentText96: false,
                choiceText96: false,
                contentText97: false,
                choiceText97: false,
                contentText98: false,
                choiceText98: false,
                contentText99: false,
                choiceText99: false,
                contentText100: false,
                choiceText100: false,
                latitude: false,
                longitude: false,
                altitude: false,
                height: false,
                floors: false,
                latitude1: false,
                longitude1: false,
                latitude2: false,
                longitude2: false,
                latitude3: false,
                longitude3: false,
                latitude4: false,
                longitude4: false,
                latitude5: false,
                longitude5: false,
                itemRssi: false,
                anchorRssi: false,
                updateFrequency: false,
                // freeidUser
                isEnteredBasicInfo: false,
                familyNameKana: false,
                firstNameKana: false,
                gender: false,
                mobileNumber: false,
                email: false,
                birthDate: false,
                emergencyContactName: false,
                emergencyContactTell: false,
                isSelfEmployment: false,
                companyName: false,
                companyCEOName: false,
                companyZipCode: false,
                companyAddress1: false,
                companyAddress2: false,
                companyAddress3: false,
                companyTell: false,
                companyOccupation: false,
                experienceYearPresent: false,
                experienceMonthPresent: false,
                experienceYearTotal: false,
                experienceMonthTotal: false,
                licenceQualification: false,
                licenceQualificationOthers: false,
                skillTraining: false,
                skillTrainingOthers: false,
                specialTraining: false,
                specialTrainingOthers: false,
                isForeigner: false,
                residenceCardNumber1: false,
                residenceCardNumber2: false,
                residenceCardNumber3: false,
                stayPeriod: false,
                isSMEOwnerOrSingleMaster: false,
                insuranceGroupName: false,
                insuranceNumber1: false,
                insuranceNumber2: false,
                insuranceNumber3: false,
                insuranceExpirationDate: false,
                medicalCheckupCategory: false,
                medicalCheckupLatestDate: false,
                bloodPressureMax: false,
                bloodPressureMin: false,
                bloodType: false,
                anamnesis: false,
                anamnesisOthers: false,
                canWorkWithoutProblems: false,
                lastUpdateDate: false,
                companyId: false,
                userId: false,
                vendorIds: false,
                // siteClearance
                invitationCode: false,
                siteAddress: false,
                applyStatus: false,
                startDate: false,
                endDate: false,
                fieldUserId: false,
                name: false,
                licenceQualificationChoices: false,
                skillTrainingChoices: false,
                specialTrainingChoices: false,
                occupationChoices: false,
                primaryCompanies: false,
                agreementUrl: false,
                pihAgreementFlag: false,
                checkListInfo: false,
                contentText: false,
                choiceText: false,
                companyOrder: false,
                primaryCompanyName: false,
                secondaryCompanyName: false,
                thirdCompanyName: false,
                fourthCompanyName: false,
                groupName: false,
                isForeman: false,
                youthElderly: false,
                notes: false,
                normKnowledge: false,
                agreementOfYouth: false,
                agreementOfElderly: false,
                shootingProhibited: false,
                snsPostingProhibited: false,
                disclosureProhibited: false,
                discardCopyProhibited: false,
                observancePledge: false,
                checkListInputContents: false,
                accessLevel: false,
                siteCode: false,
              },
            },
          }}
        />
      )}
    </Box>
  );
}
