import * as React from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUser, getCompany, getFreeidUser } from "../graphql/queries";
import { updateSiteClearance } from "../graphql/mutations";
import { useNavigate, useLocation } from "react-router-dom";
import NavigationDrawer from "../components/NavigationDrawer";
import DatePicker from "../components/DatePicker";
import LoadingProgress from "../components/LoadingProgress";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";
import fetchSiteRoles from "../components/queries/FetchSiteRoles";
import { DEFAULT_ROLE_LEVEL } from "../components/utils/constants";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { format } from "date-fns";

const drawerWidth = 280;

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      companyDetails: "",
      familyName: this.props.location.state.siteClearanceList.familyName,
      firstName: this.props.location.state.siteClearanceList.firstName,
      familyNameKana:
        this.props.location.state.siteClearanceList.familyNameKana,
      firstNameKana: this.props.location.state.siteClearanceList.firstNameKana,
      companyName: this.props.location.state.siteClearanceList.companyName,
      email: this.props.location.state.siteClearanceList.email,
      startDate: this.props.location.state.siteClearanceList.startDate
        ? new Date(
            new Date(
              this.props.location.state.siteClearanceList.startDate
            ).toLocaleString("ja-JP", { timeZone: "Japan" })
          )
        : null,
      endDate: this.props.location.state.siteClearanceList.endDate
        ? new Date(
            new Date(
              this.props.location.state.siteClearanceList.endDate
            ).toLocaleString("ja-JP", { timeZone: "Japan" })
          )
        : null,
      error: null,
      errorDate: null,
      accessLevel: "",
      isLoading: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleStringChange = this.handleStringChange.bind(this);
    this.handleDateDelete = this.handleDateDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleEmailChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if (/\S+@\S+\.\S+/.test(event.target.value)) {
      this.setState({
        error: null,
      });
    } else {
      this.setState({
        error: "不正な形式です。",
      });
    }
  }

  handleStartDateChange(event) {
    this.setState({
      startDate: event,
    });
    if (this.state.endDate === null) {
      this.setState({
        errorDate: null,
      });
    } else {
      if (
        format(event, "yyyy-MM-dd") > format(this.state.endDate, "yyyy-MM-dd")
      ) {
        this.setState({
          errorDate: "終了日は開始日後に設定してください",
        });
      } else {
        this.setState({
          errorDate: null,
        });
      }
    }
  }

  handleDateDelete = () => {
    this.setState({
      endDate: null,
      errorDate: "",
    });
  };

  handleEndDateChange(event) {
    this.setState({
      endDate: event,
    });
    if (
      format(this.state.endDate, "yyyy-MM-dd") > format(event, "yyyy-MM-dd")
    ) {
      this.setState({
        errorDate: "終了日は開始日後に設定してください",
      });
    } else {
      this.setState({
        errorDate: null,
      });
    }
  }

  handleStringChange(event) {
    if (event) {
      return event.toString().replace("[", "").replace("]", "");
    } else {
      return event;
    }
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  async componentDidMount() {
    this.fetchUsers();
    this.fetchRoles();
  }

  async onSubmit() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      let responseFreeidUser;
      if (this.props.location.state.freeidId) {
        responseFreeidUser = await API.graphql(
          graphqlOperation(getFreeidUser, {
            id: this.props.location.state.freeidId,
          })
        );
      }

      const todayDate = new Date(
        new Date().toLocaleString("ja-JP", { timeZone: "Japan" })
      );

      const updatedStatus = !responseFreeidUser
        ? "招待中"
        : !responseFreeidUser.data.getFreeidUser.isEnteredBasicInfo
        ? "招待中"
        : !this.props.location.state.siteClearanceList.checkListInputContents
        ? "招待中"
        : !this.state.startDate && !this.state.endDate
        ? "入場可"
        : !this.state.startDate &&
          this.state.endDate &&
          todayDate <= this.state.endDate
        ? "入場可"
        : !this.state.startDate &&
          this.state.endDate &&
          todayDate > this.state.endDate
        ? "終了"
        : this.state.startDate &&
          !this.state.endDate &&
          todayDate < this.state.startDate
        ? "待機中"
        : this.state.startDate &&
          !this.state.endDate &&
          todayDate >= this.state.startDate
        ? "入場可"
        : this.state.endDate && todayDate > this.state.endDate
        ? "終了"
        : this.state.startDate &&
          this.state.endDate &&
          todayDate >= this.state.startDate &&
          todayDate <= this.state.endDate
        ? "入場可"
        : this.state.startDate &&
          this.state.endDate &&
          todayDate < this.state.startDate
        ? "待機中"
        : "待機中";

      const input = {
        id: this.props.location.state.siteClearanceList.id,
        siteCode: this.state.userDetails.currentSiteId,
        startDate: !this.state.startDate
          ? ""
          : this.state.startDate.length === 0
          ? ""
          : format(this.state.startDate, "yyyy-MM-dd"),
        endDate: !this.state.endDate
          ? ""
          : this.state.endDate.length === 0
          ? ""
          : format(this.state.endDate, "yyyy-MM-dd"),
        familyName: this.state.familyName,
        firstName: this.state.firstName,
        familyNameKana: this.state.familyNameKana,
        firstNameKana: this.state.firstNameKana,
        email: this.state.email,
        companyName: this.state.companyName,
        companyId: userInfo.attributes["custom:company_id"],
        freeidId: this.state.freeidId,
        applyStatus: updatedStatus,
      };

      const response = await API.graphql(
        graphqlOperation(updateSiteClearance, {
          input: input,
        })
      );

      this.setState({
        isLoading: false,
      });

      this.props.navigate("/user-list", {
        state: {
          id: this.props.location.state.siteClearanceList.id,
          freeidId: this.props.location.state.userList.id,
        },
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  }

  async fetchRoles() {
    console.log(this.props.location.state.siteClearanceList.familyName);
    try {
      this.setState({
        isLoading: true,
      });
      const userInfo = await Auth.currentAuthenticatedUser();

      const filterCompanyRole = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      };

      const resCompanyRoles = await fetchCompanyRoles(filterCompanyRole);

      let roleLevel;
      let siteRoleList;
      if (resCompanyRoles[0].roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = resCompanyRoles[0].roleLevel;
      } else {
        const responseUser = await API.graphql(
          graphqlOperation(getUser, {
            id: userInfo.attributes.sub,
          })
        );

        const filterSiteRole = {
          and: [
            {
              siteCode: {
                eq: responseUser.data.getUser.currentSiteId,
              },
            },
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };

        const resSiteRoles = await fetchSiteRoles(filterSiteRole);

        siteRoleList = resSiteRoles;
        roleLevel = resSiteRoles[0].roleLevel;
      }

      this.setState({
        accessLevel: roleLevel,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchUsers() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: this.props.location.state.siteClearanceList.userId,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  render() {
    const fieldList = [];

    if (
      (this.state.isLoading === true) |
      (this.state.accessLevel === 1) |
      (this.state.accessLevel === 2) |
      (this.state.accessLevel === 4)
    ) {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() =>
                  this.props.navigate("/user-details", {
                    state: {
                      id: this.props.location.state.siteClearanceList.id,
                    },
                  })
                }
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.props.location.state.siteClearanceList.siteName}
                ：作業員の更新
              </Typography>
              {this.state.familyName &&
                this.state.firstName &&
                this.state.email &&
                !this.state.error &&
                !this.state.errorDate &&
                (this.state.accessLevel === 1) |
                  (this.state.accessLevel === 2) |
                  (this.state.accessLevel === 4) && (
                  <Button
                    type="submit"
                    onClick={this.handleSubmit}
                    variant="contained"
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 16,
                      fontWeight: "700",
                      color: "#383838",
                      backgroundColor: "#EBE91A",
                      height: 40,
                      borderRadius: 100,
                      paddingHorizontal: 18,
                      justifyContent: "space-between",
                      mr: 1,
                      "&:hover": {
                        backgroundColor: "#EBE91A",
                      },
                    }}
                    endIcon={
                      <img
                        src={require("../assets/images/rightarrow-on.png")}
                        width="20"
                        height="20"
                      />
                    }
                    disableElevation
                  >
                    更新する
                  </Button>
                )}
              {Boolean(
                !this.state.familyName |
                  !this.state.firstName |
                  !this.state.email |
                  !!this.state.error |
                  !!this.state.errorDate
              ) && (
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#C8C8C8",
                    backgroundColor: "#E5E5E5",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    justifyContent: "space-between",
                    mr: 1,
                    "&:hover": {
                      backgroundColor: "#E5E5E5",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-gray.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  登録する
                </Button>
              )}
              <Button
                type="submit"
                onClick={() =>
                  this.props.navigate("/user-details", {
                    state: {
                      id: this.props.location.state.siteClearanceList.id,
                    },
                  })
                }
                variant="outlined"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#383838",
                  backgroundColor: "#F9F9F9",
                  borderColor: "#C8C8C8",
                  borderWidth: 1,
                  height: 40,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  "&:hover": {
                    backgroundColor: "#F9F9F9",
                  },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/remove-on.png")}
                    width="20"
                    height="20"
                  />
                }
                disableElevation
              >
                キャンセル
              </Button>
            </Toolbar>
          </AppBar>
          <NavigationDrawer topIndex={2} bottomIndex={null} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 3,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Grid item xs={12} mb={2}>
              <Toolbar />
              <Box component="form" sx={{ width: 480 }}>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#8D8D8D",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    名前
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    marginTop: 0,
                    marginBottom: 4,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 18,
                      fontWeight: "400",
                      color: "#383838",
                      mt: 1,
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    {this.state.familyName + this.state.firstName}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#8D8D8D",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    フリガナ
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    marginTop: 0,
                    marginBottom: 4,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 18,
                      fontWeight: "400",
                      color: "#383838",
                      mt: 1,
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    {this.state.familyNameKana + this.state.firstNameKana}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#8D8D8D",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    会社名
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 18,
                    fontWeight: "400",
                    color: "#383838",
                    mt: 1,
                    mb: 4,
                    mr: 1,
                  }}
                >
                  {this.state.companyName}
                </Typography>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#8D8D8D",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    メールアドレス
                  </Typography>
                  {/* <RequiredField /> */}
                </Stack>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#DD3900",
                    mb: 0,
                  }}
                >
                  {this.state.error}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 18,
                    fontWeight: "400",
                    color: "#383838",
                    mt: 1,
                    mb: 4,
                    mr: 1,
                  }}
                >
                  {this.state.email}
                </Typography>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#8D8D8D",
                      mb: 2.5,
                      mr: 1,
                    }}
                  >
                    入場可能期間
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: 3,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 18,
                      fontWeight: "400",
                      color: "#383838",
                      marginBottom: 0,
                      marginRight: 4,
                    }}
                  >
                    開始日
                  </Typography>
                  <DatePicker
                    value={this.state.startDate}
                    onChange={this.handleStartDateChange}
                  />
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 18,
                      fontWeight: "400",
                      color: "#383838",
                      marginBottom: 0,
                      marginRight: 4,
                    }}
                  >
                    終了日
                  </Typography>
                  <DatePicker
                    value={this.state.endDate}
                    minDate={this.state.startDate}
                    onChange={this.handleEndDateChange}
                  />
                  {this.state.endDate ? (
                    <Button
                      onClick={this.handleDateDelete}
                      edge="start"
                      sx={{
                        borderRadius: 0,
                        height: 24,
                        borderColor: "#E5E5E5",
                        color: "#DD3900",
                      }}
                    >
                      終了日をリセットする
                    </Button>
                  ) : (
                    ""
                  )}
                </Stack>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#DD3900",
                    mb: 4,
                  }}
                >
                  {this.state.errorDate}
                </Typography>
                {fieldList.map((obj, index) => (
                  <Box>
                    <Stack
                      direction="row"
                      sx={{
                        mb: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Regular",
                          fontSize: 15,
                          fontWeight: "400",
                          color: "#8D8D8D",
                          mb: 0,
                          mr: 1,
                        }}
                      >
                        {obj.fieldNameJp}
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        height: 20,
                        mt: 1,
                        mb: 5,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Regular",
                          fontSize: 18,
                          fontWeight: "400",
                          color: "#383838",
                        }}
                      >
                        {this.handleStringChange(this.state[obj.fieldName])}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/user-list")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                component="div"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.state.currentSiteName}：作業員の更新
              </Typography>
            </Toolbar>
          </AppBar>
          <NavigationDrawer topIndex={2} bottomIndex={null} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 0,
            }}
          >
            <Grid item xs={12} mb={2}>
              <Toolbar />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mb: 0,
                  mr: 1,
                }}
              >
                アクセス権限がありません
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  }
}

function EditUserScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default EditUserScreen;
