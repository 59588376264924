import React, { useState, useEffect } from "react";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import Box from "@mui/material/Box";

export default function TeamMemberRegistrationGrid({ rows, updateState }) {
  const [selectionModel, setSelectionModel] = useState([]);
  const [userList, setUserList] = useState(null);
  const [timer, setTimer] = useState(null);
  const [pageSize, setPageSize] = React.useState(10);

  const columns = [
    {
      field: "name",
      headerName: "名前",
      editable: false,
      sortable: true,
      width: 175,
      valueGetter: (params) =>
        `${params.row.lastName || ""} ${params.row.firstName || ""}`,
    },
    {
      field: "email",
      headerName: "メールアドレス",
      headerClassName: "super-app-theme--header",
      width: 250,
      editable: false,
    },
  ];

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
          updateState(newSelectionModel);
        }}
        selectionModel={selectionModel}
        disableSelectionOnClick
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        getRowClassName={(params) => `super-app-theme--rows`}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        rowsPerPageOptions={[10, 50, 100]}
        sx={{ marginBottom: 2.5 }}
      />
    </Box>
  );
}
