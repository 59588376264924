import React from "react";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

function TopBar({ topTitle, backTo, navigate, children }) {
  const drawerWidth = 280;

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        backgroundColor: "#F9F9F9",
        borderBottom: 1,
        borderColor: "#E5E5E5",
      }}
      elevation={0}
    >
      <Toolbar>
        {backTo && (
          <IconButton
            size="large"
            edge="start"
            onClick={() => navigate(backTo)}
            sx={{
              mr: 2,
              pr: 2,
              pl: 1,
              borderRadius: 0,
              borderRight: 1,
              height: 64,
              borderColor: "#E5E5E5",
              color: "#383838",
            }}
          >
            <img
              src={require("../assets/images/leftarrow-on.png")}
              width="16"
              height="16"
            />
          </IconButton>
        )}
        <Typography
          variant="h5"
          component="div"
          sx={{
            fontFamily: "Regular",
            fontSize: 21,
            fontWeight: "700",
            color: "#383838",
            flexGrow: 1,
          }}
        >
          {topTitle}
        </Typography>
        {children}
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
