import { API, graphqlOperation } from "aws-amplify";
import { exitedSiteLogsByUpdatedAt } from "../../graphql/queries";

export default async function fetchExitedSiteLogsByUpdatedAt({
  filter,
  enteredSiteLogId,
  limit = 500,
  sortDirection = "ASC",
}) {
  try {
    const exitedSiteLogHistoryList = [];
    let response;
    response = await API.graphql(
      graphqlOperation(exitedSiteLogsByUpdatedAt, {
        filter,
        enteredSiteLogId,
        limit,
        sortDirection,
      })
    );
    exitedSiteLogHistoryList.push(
      ...response.data.exitedSiteLogsByUpdatedAt.items
    );
    while (response.data.exitedSiteLogsByUpdatedAt.nextToken !== null) {
      response = await API.graphql(
        graphqlOperation(exitedSiteLogsByUpdatedAt, {
          filter,
          enteredSiteLogId,
          limit,
          nextToken: response.data.exitedSiteLogsByUpdatedAt.nextToken,
        })
      );
      exitedSiteLogHistoryList.push(
        ...response.data.exitedSiteLogsByUpdatedAt.items
      );
    }
    return exitedSiteLogHistoryList;
  } catch (error) {
    console.log(error);
  }
}
