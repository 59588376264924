import React, { useState, useEffect } from "react";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import Box from "@mui/material/Box";

export default function TeamMemberEditGrid({ rows, updateEditCell }) {
  const [userListByRole, setUserListByRole] = useState(null);
  const [timer, setTimer] = useState(null);
  const [pageSize, setPageSize] = React.useState(10);

  const columns = [
    {
      field: "name",
      headerName: "名前",
      editable: false,
      sortable: true,
      width: 175,
      valueGetter: (params) =>
        `${params.row.familyName || ""} ${params.row.firstName || ""}`,
    },
    {
      field: "roleName",
      label: "権限を選択",
      type: "singleSelect",
      headerName: "権限",
      width: 250,
      editable: true,
      valueOptions: ["現場管理者", "チームメンバー"],
    },
  ];

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        getRowClassName={(params) => `super-app-theme--rows`}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        rowsPerPageOptions={[10, 50, 100]}
        sx={{ marginBottom: 2.5 }}
        onCellEditCommit={(v) => {
          updateEditCell(v);
        }}
      />
    </Box>
  );
}
