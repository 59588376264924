import { API, graphqlOperation } from "aws-amplify";
import { companyByVendor } from "../../graphql/queries";

export default async function fetchCompanyByVendor(condition) {
  try {
    const response = await API.graphql(
      graphqlOperation(companyByVendor, condition)
    );
    return response.data.companyByVendor;
  } catch (error) {
    console.log(error);
  }
}
