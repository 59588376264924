import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CssBaseline,
  Toolbar,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { Auth, API, graphqlOperation } from "aws-amplify";
import NavigationMenu from "../components/NavigationMenu";
import LoadingProgress from "../components/LoadingProgress";
import ManageDataSiteGrid from "../components/ManageDataSiteGrid";
import TopBar from "../components/TopBar";
import fetchSites from "../components/queries/FetchSites";
import fetchSiteClearances from "../components/queries/FetchSiteClearances";
import fetchFreeidUsers from "../components/queries/FetchFreeidUsers";
import fetchFreeidCompanyUsers from "../components/queries/FetchFreeidCompanyUsers";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";
import customCompanyToVendors from "../components/utils/customCompanyToVendors";
import {
  DRAWER_WIDTH,
  SITE_COLUMNS,
  SITE_CLEARANCE_COLUMNS,
  FREEID_USER_COLUMNS,
  DEFAULT_ROLE_LEVEL,
} from "../components/utils/constants";
import { getCompany } from "../graphql/queries";
import makeDrawerList from "../components/utils/makeDrawerList";

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessLevel: "",
      isLoading: false,
      searchWords: "",
      siteList: [],
      siteColumns: [],
      vendorData: [],
      drawerList: [],
      navigationList: [],
      rows: [],
      columns: [],
      fileName: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async componentDidMount() {
    await this.siteListSet();
    this.myAccessLevelSet();
    const [drawerList, navigationList] = await makeDrawerList();

    this.setState({
      drawerList,
      navigationList,
    });
  }

  removeRichTextFormatting(text) {
    const richTextRegex = /richText=\[[^]]+\]/g;
    if (text) {
      return text.replace(richTextRegex, "");
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
    this.siteListSet();
  }

  async siteListSet() {
    try {
      this.setState({
        isLoading: true,
      });
      const userInfo = await Auth.currentAuthenticatedUser();
      let listSites = [];
      listSites = await fetchSites({
        companyId: {
          eq: userInfo.attributes["custom:company_id"],
        },
      });
      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      const vendorData = await customCompanyToVendors();
      function getVendorNameById(vendorId) {
        const vendor = vendorData.find((v) => v.id === vendorId);
        return vendor ? vendor.vendorName : vendorId;
      }
      const columnsWithCompanyAndVendorName = SITE_COLUMNS.map((column) => {
        for (let i = 1; i <= 100; i++) {
          if (column.field === `contentText${i}`) {
            return {
              ...column,
              valueGetter: (params) =>
                this.removeRichTextFormatting(params.value),
            };
          }
        }
        if (column.field === "companyId") {
          return {
            ...column,
            valueGetter: (params) =>
              responseCompany.data.getCompany.companyName,
          };
        }
        if (column.field === "vendorIds") {
          return {
            ...column,
            valueFormatter: (params) => {
              if (Array.isArray(params.value)) {
                return params.value
                  .map((vendorId) => getVendorNameById(vendorId))
                  .join(", ");
              } else {
                return getVendorNameById(params.value);
              }
            },
          };
        }
        return column;
      });
      this.setState({
        siteList: listSites,
        siteColumns: SITE_COLUMNS,
        columns: columnsWithCompanyAndVendorName,
        rows: listSites,
        fileName: `${responseCompany.data.getCompany.companyName}_現場データ`,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  async siteClearanceListSet() {
    try {
      this.setState({
        isLoading: true,
      });
      const userInfo = await Auth.currentAuthenticatedUser();

      let listSiteClearances = [];
      listSiteClearances = await fetchSiteClearances({
        companyId: {
          eq: userInfo.attributes["custom:company_id"],
        },
      });
      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      const columnsWithCompanyName = SITE_CLEARANCE_COLUMNS.map((column) => {
        if (column.field === "companyId") {
          return {
            ...column,
            valueGetter: (params) =>
              responseCompany.data.getCompany.companyName,
          };
        }
        if (column.field === "checkListInfo") {
          return {
            ...column,
            valueGetter: (params) =>
              this.removeRichTextFormatting(params.value),
          };
        }
        return column;
      });
      this.setState({
        rows: listSiteClearances,
        columns: columnsWithCompanyName,
        fileName: `${responseCompany.data.getCompany.companyName}_現場入場許可データ`,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  async freeidUserListSet() {
    try {
      this.setState({
        isLoading: true,
      });
      const userInfo = await Auth.currentAuthenticatedUser();

      const listFreeidCompanyUsers = await fetchFreeidCompanyUsers({
        companyId: {
          eq: userInfo.attributes["custom:company_id"],
        },
      });
      const freeidIds = listFreeidCompanyUsers.map((freeidCompanyUser) => {
        return freeidCompanyUser.freeidId;
      });
      const conditions = freeidIds.map((freeidId) => {
        return {
          id: {
            eq: freeidId,
          },
        };
      });
      const listFreeidUsers = await fetchFreeidUsers({
        or: conditions,
      });
      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      const columnsWithCompanyName = FREEID_USER_COLUMNS.map((column) => {
        if (column.field === "companyId") {
          return {
            ...column,
            valueGetter: (params) =>
              responseCompany.data.getCompany.companyName,
          };
        }
        return column;
      });
      this.setState({
        rows: listFreeidUsers,
        columns: columnsWithCompanyName,
        fileName: `${responseCompany.data.getCompany.companyName}_FreeiDユーザーデータ`,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  async myAccessLevelSet() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();
      const [listCompanyRoles] = await fetchCompanyRoles({
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      });
      let roleLevel;
      if (listCompanyRoles.roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = listCompanyRoles.roleLevel;
      }
      this.setState({
        accessLevel: roleLevel,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    if (
      (this.state.isLoading === true) |
      (this.state.accessLevel === 1) |
      (this.state.accessLevel === 2)
    ) {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <TopBar topTitle="データ管理"></TopBar>
          <Box
            component="nav"
            sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationMenu
              drawerList={this.state.drawerList}
              navigationList={this.state.navigationList}
              topIndex={3}
              bottomIndex={null}
            />
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              px: 3,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Toolbar />
            <Grid item xs={12} mt={2}>
              <Button
                type="submit"
                onClick={() => this.siteListSet()}
                variant="outlined"
                sx={{
                  fontSize: 15,
                  fontWeight: "600",
                  color: "#383838",
                  backgroundColor: "#F9F9F9",
                  borderColor: "#C8C8C8",
                  borderWidth: 1,
                  height: 32,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  mr: 1,
                  mb: 1,
                  "&:hover": {
                    backgroundColor: "#F9F9F9",
                  },
                }}
                disableElevation
              >
                現場データ
              </Button>
              <Button
                type="submit"
                onClick={() => this.siteClearanceListSet()}
                variant="outlined"
                sx={{
                  fontSize: 15,
                  fontWeight: "600",
                  color: "#383838",
                  backgroundColor: "#F9F9F9",
                  borderColor: "#C8C8C8",
                  borderWidth: 1,
                  height: 32,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  mr: 1,
                  mb: 1,
                  "&:hover": {
                    backgroundColor: "#F9F9F9",
                  },
                }}
                disableElevation
              >
                現場入場許可データ
              </Button>
              <Button
                type="submit"
                onClick={() => this.freeidUserListSet()}
                variant="outlined"
                sx={{
                  fontSize: 15,
                  fontWeight: "600",
                  color: "#383838",
                  backgroundColor: "#F9F9F9",
                  borderColor: "#C8C8C8",
                  borderWidth: 1,
                  height: 32,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  mr: 1,
                  mb: 1,
                  "&:hover": {
                    backgroundColor: "#F9F9F9",
                  },
                  textTransform: "none",
                }}
                disableElevation
              >
                FreeiDユーザーデータ
              </Button>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} md={12}>
                <ManageDataSiteGrid
                  rows={this.state.rows || this.state.siteList}
                  columns={this.state.columns || this.state.siteColumns}
                  fileName={this.state.fileName}
                  // visibility={this.state.visibility || this.state.siteVisibility}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <TopBar topTitle="データ管理">
            <Button
              type="submit"
              variant="outlined"
              sx={{
                fontSize: 15,
                fontWeight: "700",
                color: "#383838",
                backgroundColor: "#F9F9F9",
                borderColor: "#C8C8C8",
                borderWidth: 1,
                height: 32,
                borderRadius: 100,
                paddingHorizontal: 18,
                mr: 1,
                "&:hover": {
                  backgroundColor: "#F9F9F9",
                },
              }}
              disableElevation
            >
              現場データ
            </Button>
            <Button
              type="submit"
              variant="outlined"
              sx={{
                fontSize: 15,
                fontWeight: "700",
                color: "#383838",
                backgroundColor: "#F9F9F9",
                borderColor: "#C8C8C8",
                borderWidth: 1,
                height: 32,
                borderRadius: 100,
                paddingHorizontal: 18,
                mr: 1,
                "&:hover": {
                  backgroundColor: "#F9F9F9",
                },
              }}
              disableElevation
            >
              現場入場許可データ
            </Button>
            <Button
              type="submit"
              variant="outlined"
              sx={{
                fontSize: 15,
                fontWeight: "700",
                color: "#383838",
                backgroundColor: "#F9F9F9",
                borderColor: "#C8C8C8",
                borderWidth: 1,
                height: 32,
                borderRadius: 100,
                paddingHorizontal: 18,
                mr: 1,
                "&:hover": {
                  backgroundColor: "#F9F9F9",
                },
              }}
              disableElevation
            >
              Freeidユーザーデータ
            </Button>
          </TopBar>
          <NavigationMenu
            drawerList={this.state.drawerList}
            navigationList={this.state.navigationList}
            topIndex={3}
            bottomIndex={null}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 0,
            }}
          >
            <Grid item xs={12} mb={2}>
              <Toolbar />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mb: 0,
                  mr: 1,
                }}
              >
                アクセス権限がありません
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  }
}

function ManageData(props) {
  const navigate = useNavigate();
  return <InputComponent {...props} navigate={navigate} />;
}

export default ManageData;
