import * as React from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUser, getSite, getCompany } from "../graphql/queries";
import { createSiteClearanceWithInvitation } from "../graphql/mutations";
import { useNavigate } from "react-router-dom";
import NavigationDrawer from "../components/NavigationDrawer";
import DatePicker from "../components/DatePicker";
import { sha256 } from "crypto-hash";
import LoadingProgress from "../components/LoadingProgress";
import RequiredField from "../components/RequiredField";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";
import fetchSiteRoles from "../components/queries/FetchSiteRoles";
import { DEFAULT_ROLE_LEVEL } from "../components/utils/constants";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";

const drawerWidth = 280;

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      siteDetails: "",
      companyDetails: "",
      accessLevel: "",
      lastName: "",
      firstName: "",
      lastNameKana: "",
      firstNameKana: "",
      companyName: "",
      email: "",
      dateStart: new Date(
        new Date().toLocaleString("ja-JP", {
          timeZone: "Japan",
        })
      ).setHours(0, 0, 0, 0),
      dateEnd: "",
      gender: "",
      zipCode: "",
      address1: "",
      address2: "",
      address3: "",
      mobileNumber: "",
      birthDate: "",
      emergencyContactName: "",
      emergencyContactTell: "",
      isSelfEmployment: "",
      companyCEOName: "",
      companyZipCode: "",
      companyAddress1: "",
      companyAddress2: "",
      companyAddress3: "",
      companyTell: "",
      companyOccupation: "",
      experienceYearPresent: "",
      experienceMonthPresent: "",
      experienceYearTotal: "",
      experienceMonthTotal: "",
      licenceQualification: "",
      licenceQualificationOthers: "",
      skillTraining: "",
      skillTrainingOthers: "",
      specialTraining: "",
      specialTrainingOthers: "",
      isForeigner: "",
      residenceCardNumber1: "",
      residenceCardNumber2: "",
      residenceCardNumber3: "",
      stayPeriod: "",
      isSMEOwnerOrSingleMaster: "",
      insuranceGroupName: "",
      insuranceNumber1: "",
      insuranceNumber2: "",
      insuranceNumber3: "",
      insuranceExpirationDate: "",
      medicalCheckupCategory: "",
      medicalCheckupLatestDate: "",
      bloodPressureMax: "",
      bloodPressureMin: "",
      bloodType: "",
      anamnesis: "",
      anamnesisOthers: "",
      canWorkWithoutProblems: "",
      companyOrder: "",
      primaryCompanyName: "",
      secondaryCompanyName: "",
      thirdCompanyName: "",
      fourthCompanyName: "",
      groupName: "",
      isForeman: "",
      youthElderly: "",
      notes: "",
      pihAgreementFlag: "",
      normKnowledge: "",
      agreementOfYouth: "",
      agreementOfElderly: "",
      shootingProhibited: "",
      snsPostingProhibited: "",
      disclosureProhibited: "",
      discardCopyProhibited: "",
      observancePledge: "",
      checkListInputContents: "",
      invitationType: 1,
      isLoading: false,
      error: null,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleDateStartChange = this.handleDateStartChange.bind(this);
    this.handleDateEndChange = this.handleDateEndChange.bind(this);
    this.handleDateDelete = this.handleDateDelete.bind(this);
    this.handleInvitationTypeChange =
      this.handleInvitationTypeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    // this.fetchUsers();
  }

  handleEmailChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if (/\S+@\S+\.\S+/.test(event.target.value)) {
      this.setState({
        error: null,
      });
    } else {
      this.setState({
        error: "不正な形式です。",
      });
    }
  }

  handleDateStartChange(event) {
    this.setState({
      dateStart: event,
    });
    if (this.state.dateEnd === "" || this.state.dateEnd === null) {
      this.setState({
        errorDate: null,
      });
    } else {
      if (
        format(event, "yyyy-MM-dd") > format(this.state.dateEnd, "yyyy-MM-dd")
      ) {
        this.setState({
          errorDate: "終了日は開始日後に設定してください",
        });
      } else {
        this.setState({
          errorDate: null,
        });
      }
    }
  }

  handleDateEndChange(event) {
    this.setState({
      dateEnd: event,
    });
    if (
      format(this.state.dateStart, "yyyy-MM-dd") > format(event, "yyyy-MM-dd")
    ) {
      this.setState({
        errorDate: "終了日は開始日後に設定してください",
      });
    } else {
      this.setState({
        errorDate: null,
      });
    }
  }

  handleInvitationTypeChange(event) {
    this.setState({
      invitationType: event.target.checked ? 2 : 1,
    });
  }

  handleDateDelete = () => {
    this.setState({
      dateEnd: null,
      errorDate: "",
    });
  };

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  async componentDidMount() {
    this.fetchRoles();
    this.fetchUsers();
  }

  async onSubmit() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();
      const newId = uuidv4();
      const hashResult = await sha256(
        "siteCode=" +
          this.state.userDetails.currentSiteId +
          "&siteName=" +
          this.state.userDetails.currentSiteName
      );

      const input = {
        id: newId,
        siteCode: this.state.userDetails.currentSiteId,
        siteName: this.state.userDetails.currentSiteName,
        siteAddress: this.state.siteDetails.address1,
        isEnteredBasicInfo: false,
        applyStatus: "招待中",
        startDate: this.state.dateStart
          ? format(this.state.dateStart, "yyyy-MM-dd")
          : "",
        endDate: this.state.dateEnd
          ? format(this.state.dateEnd, "yyyy-MM-dd")
          : "",
        notesFileUrl: this.state.siteDetails.notesFileUrl,
        familyName: this.state.lastName,
        firstName: this.state.firstName,
        familyNameKana: this.state.lastNameKana,
        firstNameKana: this.state.firstNameKana,
        email: this.state.email,
        companyName: this.state.companyName,
        companyId: userInfo.attributes["custom:company_id"],
        userId: userInfo.attributes.sub,
        invitationCode: newId,
        qrCodeDescription:
          "siteCode=" +
          this.state.userDetails.currentSiteId +
          "&sha256=" +
          hashResult,
        gender: this.state.gender,
        zipCode: this.state.zipCode,
        address1: this.state.address1,
        address2: this.state.address2,
        address3: this.state.address3,
        mobileNumber: this.state.mobileNumber,
        birthDate: this.state.birthDate,
        emergencyContactName: this.state.emergencyContactName,
        emergencyContactTell: this.state.emergencyContactTell,
        isSelfEmployment: this.state.isSelfEmployment,
        companyCEOName: this.state.companyCEOName,
        companyZipCode: this.state.companyZipCode,
        companyAddress1: this.state.companyAddress1,
        companyAddress2: this.state.companyAddress2,
        companyAddress3: this.state.companyAddress3,
        companyTell: this.state.companyTell,
        companyOccupation: this.state.companyOccupation,
        experienceYearPresent: this.state.experienceYearPresent
          ? this.state.experienceYearPresent
          : 0,
        experienceMonthPresent: this.state.experienceMonthPresent
          ? this.state.experienceMonthPresent
          : 0,
        experienceYearTotal: this.state.experienceYearTotal
          ? this.state.experienceYearTotal
          : 0,
        experienceMonthTotal: this.state.experienceMonthTotal
          ? this.state.experienceMonthTotal
          : 0,
        licenceQualification: this.state.licenceQualification,
        licenceQualificationOthers: this.state.licenceQualificationOthers,
        skillTraining: this.state.skillTraining,
        skillTrainingOthers: this.state.skillTrainingOthers,
        specialTraining: this.state.specialTraining,
        specialTrainingOthers: this.state.specialTrainingOthers,
        isForeigner: this.state.isForeigner,
        residenceCardNumber1: this.state.residenceCardNumber1,
        residenceCardNumber2: this.state.residenceCardNumber2,
        residenceCardNumber3: this.state.residenceCardNumber3,
        stayPeriod: this.state.stayPeriod,
        isSMEOwnerOrSingleMaster: this.state.isSMEOwnerOrSingleMaster,
        insuranceGroupName: this.state.insuranceGroupName,
        insuranceNumber1: this.state.insuranceNumber1,
        insuranceNumber2: this.state.insuranceNumber2,
        insuranceNumber3: this.state.insuranceNumber3,
        insuranceExpirationDate: this.state.insuranceExpirationDate,
        medicalCheckupCategory: this.state.medicalCheckupCategory,
        medicalCheckupLatestDate: this.state.medicalCheckupLatestDate,
        bloodPressureMax: this.state.bloodPressureMax
          ? this.state.bloodPressureMax
          : 0,
        bloodPressureMin: this.state.bloodPressureMin
          ? this.state.bloodPressureMin
          : 0,
        bloodType: this.state.bloodType,
        anamnesis: this.state.anamnesis,
        anamnesisOthers: this.state.anamnesisOthers,
        canWorkWithoutProblems: this.state.canWorkWithoutProblems,
        companyOrder: this.state.companyOrder ? this.state.companyOrder : 0,
        primaryCompanyName: this.state.primaryCompanyName,
        secondaryCompanyName: this.state.secondaryCompanyName,
        thirdCompanyName: this.state.thirdCompanyName,
        fourthCompanyName: this.state.fourthCompanyName,
        groupName: this.state.groupName,
        isForeman: this.state.isForeman,
        youthElderly: this.state.youthElderly,
        notes: this.state.notes,
        pihAgreementFlag: this.state.pihAgreementFlag,
        normKnowledge: this.state.normKnowledge,
        agreementOfYouth: this.state.agreementOfYouth,
        agreementOfElderly: this.state.agreementOfElderly,
        shootingProhibited: this.state.shootingProhibited,
        snsPostingProhibited: this.state.snsPostingProhibited,
        disclosureProhibited: this.state.disclosureProhibited,
        discardCopyProhibited: this.state.discardCopyProhibited,
        observancePledge: this.state.observancePledge,
        checkListInputContents: this.state.checkListInputContents,
        invitationType: this.state.invitationType,
        providerCompanyName: this.state.companyDetails.companyName,
      };

      const response = await API.graphql(
        graphqlOperation(createSiteClearanceWithInvitation, input)
      );

      console.log(process.env.REACT_APP_ENV);

      this.setState({
        isLoading: false,
      });
      this.props.navigate("/user-list");
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  }

  async fetchRoles() {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();

      const filterCompanyRole = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      };

      const resCompanyRoles = await fetchCompanyRoles(filterCompanyRole);
      let roleLevel;
      if (resCompanyRoles[0].roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = resCompanyRoles[0].roleLevel;
      } else {
        const responseUser = await API.graphql(
          graphqlOperation(getUser, {
            id: userInfo.attributes.sub,
          })
        );

        const filterSiteRole = {
          and: [
            {
              siteCode: {
                eq: responseUser.data.getUser.currentSiteId,
              },
            },
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };

        const resSiteRoles = await fetchSiteRoles(filterCompanyRole);
        roleLevel = resSiteRoles[0].roleLevel;
      }

      this.setState({
        accessLevel: roleLevel,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchUsers() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseSite = await API.graphql(
        graphqlOperation(getSite, {
          id: responseUser.data.getUser.currentSiteId,
        })
      );
      this.setState({
        siteDetails: responseSite.data.getSite,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  render() {
    const fieldList = [];

    if (
      (this.state.accessLevel === 1) |
      (this.state.accessLevel === 2) |
      (this.state.accessLevel === 4)
    ) {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/user-list")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.state.userDetails.currentSiteName}：作業員の登録
              </Typography>
              {this.state.lastName &&
                this.state.firstName &&
                this.state.email &&
                !this.state.error &&
                !this.state.errorDate && (
                  <Button
                    type="submit"
                    onClick={this.handleSubmit}
                    variant="contained"
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 16,
                      fontWeight: "700",
                      color: "#383838",
                      backgroundColor: "#EBE91A",
                      height: 40,
                      borderRadius: 100,
                      paddingHorizontal: 18,
                      justifyContent: "space-between",
                      "&:hover": {
                        backgroundColor: "#EBE91A",
                      },
                    }}
                    endIcon={
                      <img
                        src={require("../assets/images/rightarrow-on.png")}
                        width="20"
                        height="20"
                      />
                    }
                    disableElevation
                  >
                    登録する
                  </Button>
                )}
              {Boolean(
                !this.state.lastName |
                  !this.state.firstName |
                  !this.state.email |
                  !!this.state.error |
                  !!this.state.errorDate
              ) && (
                <Button
                  // type="submit"
                  // onClick={this.handleSubmit}
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#C8C8C8",
                    backgroundColor: "#E5E5E5",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: "#E5E5E5",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-gray.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  登録する
                </Button>
              )}
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationDrawer topIndex={2} bottomIndex={null} />
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 3,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Grid item xs={12} mb={2}>
              <Toolbar />
              <Box component="form" sx={{ width: 480 }}>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    名前
                  </Typography>
                  <RequiredField />
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    marginTop: 0,
                    marginBottom: 4,
                  }}
                >
                  <TextField
                    id="lastName"
                    placeholder="姓"
                    name="lastName"
                    type="text"
                    value={this.state.lastName}
                    onChange={this.handleInputChange}
                    variant="standard"
                    margin="normal"
                    fullWidth
                    autoFocus
                    sx={{
                      marginRight: 2,
                    }}
                  />
                  <TextField
                    id="firstName"
                    placeholder="名"
                    name="firstName"
                    type="text"
                    value={this.state.firstName}
                    onChange={this.handleInputChange}
                    variant="standard"
                    margin="normal"
                    fullWidth
                    // autoFocus
                    sx={{
                      marginLeft: 2,
                    }}
                  />
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    ふりがな
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    marginTop: 0,
                    marginBottom: 4,
                  }}
                >
                  <TextField
                    id="lastNameKana"
                    placeholder="姓"
                    name="lastNameKana"
                    type="text"
                    value={this.state.lastNameKana}
                    onChange={this.handleInputChange}
                    variant="standard"
                    margin="normal"
                    fullWidth
                    // autoFocus
                    sx={{
                      marginRight: 2,
                    }}
                  />
                  <TextField
                    id="firstNameKana"
                    placeholder="名"
                    name="firstNameKana"
                    type="text"
                    value={this.state.firstNameKana}
                    onChange={this.handleInputChange}
                    variant="standard"
                    margin="normal"
                    fullWidth
                    // autoFocus
                    sx={{
                      marginLeft: 2,
                    }}
                  />
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    所属会社名
                  </Typography>
                </Stack>
                <TextField
                  id="companyName"
                  placeholder="所属会社名"
                  name="companyName"
                  type="text"
                  value={this.state.companyName}
                  onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  // autoFocus
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    メールアドレス
                  </Typography>
                  <RequiredField />
                </Stack>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#DD3900",
                    mb: 0,
                  }}
                >
                  {this.state.error}
                </Typography>
                <TextField
                  id="email"
                  placeholder="例）info@sagaseru.com"
                  name="email"
                  type="text"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  // autoFocus
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 2.5,
                      mr: 1,
                    }}
                  >
                    入場可能期間
                  </Typography>
                  {/* <RequiredField /> */}
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: 3,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      marginBottom: 0,
                      marginRight: 4,
                    }}
                  >
                    開始日
                  </Typography>
                  <DatePicker
                    value={this.state.dateStart}
                    onChange={this.handleDateStartChange}
                  />
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      marginBottom: 0,
                      marginRight: 4,
                    }}
                  >
                    終了日
                  </Typography>
                  <DatePicker
                    value={this.state.dateEnd}
                    minDate={this.state.dateStart}
                    onChange={this.handleDateEndChange}
                  />
                  {this.state.dateEnd !== "" && this.state.dateEnd !== null && (
                    <Button
                      onClick={this.handleDateDelete}
                      edge="start"
                      sx={{
                        borderRadius: 0,
                        height: 24,
                        borderColor: "#E5E5E5",
                        color: "#DD3900",
                      }}
                    >
                      終了日をリセットする
                    </Button>
                  )}
                </Stack>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#DD3900",
                    mb: 4,
                  }}
                >
                  {this.state.errorDate}
                </Typography>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    メンバー共有招待メールの送信
                  </Typography>
                </Stack>
                <Checkbox
                  value={this.state.invitationType}
                  onChange={this.handleInvitationTypeChange}
                  inputProps={{
                    "aria-label": "Checkbox invitationType",
                  }}
                />

                {fieldList.map((obj, index) => (
                  <Box>
                    <Stack
                      direction="row"
                      sx={{
                        mb: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Regular",
                          fontSize: 15,
                          fontWeight: "400",
                          color: "#383838",
                          mb: 0,
                          mr: 1,
                        }}
                      >
                        {obj.fieldNameJp}
                      </Typography>
                      {/* <RequiredField /> */}
                    </Stack>
                    <TextField
                      id={obj.fieldName}
                      // placeholder="所属会社名"
                      name={obj.fieldName}
                      type="text"
                      value={this.state[obj.fieldName]}
                      onChange={this.handleInputChange}
                      variant="standard"
                      margin="normal"
                      fullWidth
                      // autoFocus
                      sx={{
                        marginTop: 1,
                        marginBottom: 4,
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/user-list")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                component="div"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.state.userDetails.currentSiteName}：作業員の登録
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationDrawer topIndex={2} bottomIndex={null} />
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 0,
            }}
          >
            <Grid item xs={12} mb={2}>
              <Toolbar />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mb: 0,
                  mr: 1,
                }}
              >
                アクセス権限がありません
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  }
}

function RegisterUserScreen(props) {
  const navigate = useNavigate();
  return <InputComponent {...props} navigate={navigate} />;
}

export default RegisterUserScreen;
