import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export const ValidationSnackBar = ({ open, onClose, snackbar }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={onClose}
      autoHideDuration={6000}
    >
      <Alert {...snackbar} onClose={onClose} />
    </Snackbar>
  );
};
