import React from "react";
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import RequiredField from "../components/RequiredField";
import LoadingProgress from "../components/LoadingProgress";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Stack from "@mui/material/Stack";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    background: {
      default: "#E5E5E5",
    },
  },
});

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authCode: "",
      password: "",
      confirmPassword: "",
      showPassword: false,
      authCodeError: null,
      verificationError: null,
      isLoading: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSendVerificationCode =
      this.handleSendVerificationCode.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if (event.target.value.length == 6) {
      this.setState({
        authCodeError: null,
        verificationError: null,
      });
    } else {
      this.setState({
        authCodeError: "メールに認証コードが送信されました。ご確認ください。",
        verificationError: null,
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.forgotPasswordSubmit();
  }

  handleClickShowPassword(event) {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleMouseDownPassword(event) {
    event.preventDefault();
  }

  handleSendVerificationCode(event) {
    Auth.resendSignUp(this.props.location.state.username);
  }

  async forgotPasswordSubmit() {
    try {
      this.setState({
        isLoading: true,
      });

      await Auth.confirmSignUp(
        this.props.location.state.username,
        this.state.authCode
      );

      this.setState({
        isLoading: false,
      });

      this.props.navigate("/sign-in");
    } catch (error) {
      console.log(error);
      this.setState({
        verificationError: "認証コードが間違っています。",
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            position: "absolute",
            top: 99,
            left: 99,
            width: 440,
            backgroundColor: "#F9F9F9",
          }}
        >
          <Box
            sx={{
              paddingTop: 4,
              paddingBottom: 4,
              paddingLeft: 4,
              paddingRight: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Typography
              component="div"
              sx={{
                fontFamily: "Regular",
                fontSize: 28,
                fontWeight: "700",
                color: "#383838",
                marginBottom: 3,
              }}
            >
              認証コードを入力
            </Typography>
            {/* <Typography
              component="div"
              sx={{
                fontFamily: 'Regular',
                fontSize: 15,
                fontWeight: '400',
                color: '#383838',
                marginBottom: 0,
              }}
            >
              パスワードを再発行します。
            </Typography>
            <Typography
              component="div"
              sx={{
                fontFamily: 'Regular',
                fontSize: 15,
                fontWeight: '400',
                color: '#383838',
                marginBottom: 3,
              }}
            >
              ご登録済みのメールアドレスを入力してください。
            </Typography> */}
            <Box component="form" sx={{ width: 328 }}>
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  marginBottom: 0,
                }}
              >
                メールアドレス
              </Typography>
              {/* <TextField
                id="username"
                name="username"
                type="text"
                value={this.props.location.state.username}
                variant='standard'
                margin="normal"
                fullWidth
                autoComplete="email"
                autoFocus
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              /> */}
              <Box
                sx={{
                  height: 20,
                  mt: 1,
                  mb: 4,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "400",
                    color: "#383838",
                  }}
                >
                  {this.props.location.state.username}
                </Typography>
              </Box>
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  認証コード
                </Typography>
                <RequiredField />
              </Stack>
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 12,
                  fontWeight: "500",
                  color: "#DD3900",
                  mb: 0,
                }}
              >
                {this.state.authCodeError}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 12,
                  fontWeight: "500",
                  color: "#DD3900",
                  mb: 0,
                }}
              >
                {this.state.verificationError}
              </Typography>
              <TextField
                id="authCode"
                placeholder="認証コード"
                name="authCode"
                type="text"
                value={this.state.authCode}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                autoFocus
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
            </Box>
            {Boolean(!this.state.authCode | !!this.state.authCodeError) && (
              <Button
                fullWidth
                variant="contained"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#C8C8C8",
                  backgroundColor: "#E5E5E5",
                  height: 40,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  marginBottom: 5,
                  justifyContent: "space-between",
                  "&:hover": {
                    backgroundColor: "#E5E5E5",
                  },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/rightarrow-gray.png")}
                    width="20"
                    height="20"
                  />
                }
                disableElevation
              >
                登録する
              </Button>
            )}
            {this.state.authCode && !this.state.authCodeError && (
              <Button
                type="submit"
                onClick={this.handleSubmit}
                fullWidth
                variant="contained"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#383838",
                  backgroundColor: "#EBE91A",
                  height: 48,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  marginBottom: 5,
                  justifyContent: "space-between",
                  "&:hover": {
                    backgroundColor: "#EBE91A",
                  },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/rightarrow-on.png")}
                    width="20"
                    height="20"
                  />
                }
                disableElevation
              >
                登録する
              </Button>
            )}
            <Button
              onClick={this.handleSendVerificationCode}
              sx={{
                fontFamily: "Regular",
                fontSize: 15,
                fontWeight: "700",
                color: "#383838",
                paddingLeft: 0,
                "&:hover": {
                  backgroundColor: "#F9F9F9",
                },
              }}
            >
              認証コードを再送する
            </Button>
          </Box>
        </Container>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            position: "absolute",
            top: 637,
            left: 981,
            width: 195,
            height: 97,
            backgroundColor: "#F9F9F9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={require("../assets/images/sagaseru-logo.png")}
            width="101"
            height="24"
          />
        </Container>
      </ThemeProvider>
    );
  }
}

function UserVerificationScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default UserVerificationScreen;
