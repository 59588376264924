import React from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

export default function SearchBox({
  searchWords,
  searchTarget,
  handleInputChange,
}) {
  return (
    <Box display="flex" alignItems="center">
      <FormControl
        sx={{
          width: "15em",
          height: "2em",
          marginTop: 1.5,
          marginLeft: 3,
          marginBottom: 1,
        }}
      >
        <InputLabel id="demo-simple-select-label">検索カラム</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="searchTarget"
          value={searchTarget}
          onChange={handleInputChange}
        >
          <MenuItem value="siteName">現場名</MenuItem>
          <MenuItem value="masterCode">案件管理番号</MenuItem>
          <MenuItem value="address1">住所</MenuItem>
        </Select>
      </FormControl>
      <TextField
        name="searchWords"
        variant="standard"
        placeholder="名前で絞り込む"
        value={searchWords}
        onChange={handleInputChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img
                src={require("../assets/images/search-on.png")}
                width="16"
                height="16"
              />
            </InputAdornment>
          ),
        }}
        sx={{
          marginTop: 7,
          marginLeft: 3.5,
          marginBottom: 1,
          width: 200,
        }}
      />
    </Box>
  );
}
