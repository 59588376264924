import { API, graphqlOperation } from "aws-amplify";
import { customCompanyVendor } from "./customQueries";

export default async function fetchCompanyVendors(filter = null) {
  try {
    const companyVendorList = [];
    let response;
    response = await API.graphql(
      graphqlOperation(customCompanyVendor, {
        filter: filter,
        limit: 500,
      })
    );

    companyVendorList.push(...response.data.listCompanyVendors.items);
    while (response.data.listCompanyVendors.nextToken !== null) {
      response = await API.graphql(
        graphqlOperation(customCompanyVendor, {
          filter: filter,
          limit: 500,
          nextToken: response.data.listCompanyVendors.nextToken,
        })
      );
      companyVendorList.push(...response.data.listCompanyVendors.items);
    }
    return [...companyVendorList];
  } catch (error) {
    console.log(error);
  }
}
