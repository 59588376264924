import { createSiteLogHistory } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";

export default async function createSiteLogHistoryWrapper(input) {
  try {
    const res = await API.graphql(
      graphqlOperation(createSiteLogHistory, {
        input,
      })
    );
    return res;
  } catch (error) {
    console.log(error);
  }
}
