import { API, graphqlOperation } from "aws-amplify";
import { listFreeidUsers } from "../../graphql/queries";

export default async function fetchFreeidUsers(filter = null) {
  const BATCH_SIZE = 100;

  try {
    let freeidUserList = [];

    if (filter?.or && filter.or.length > BATCH_SIZE) {
      const batches = Math.ceil(filter.or.length / BATCH_SIZE);
      for (let i = 0; i < batches; i++) {
        const currentBatch = filter.or.slice(
          i * BATCH_SIZE,
          (i + 1) * BATCH_SIZE
        );
        const batchResults = await fetchBatch({ or: currentBatch });
        freeidUserList.push(...batchResults);
      }
    } else {
      freeidUserList = await fetchBatch(filter);
    }

    return freeidUserList;
  } catch (error) {
    console.log(error);
  }
}

async function fetchBatch(filter) {
  const freeidUserList = [];
  let response;
  response = await API.graphql(
    graphqlOperation(listFreeidUsers, {
      filter: filter,
      limit: 500,
    })
  );

  freeidUserList.push(...response.data.listFreeidUsers.items);
  while (response.data.listFreeidUsers.nextToken !== null) {
    response = await API.graphql(
      graphqlOperation(listFreeidUsers, {
        filter: filter,
        limit: 500,
        nextToken: response.data.listFreeidUsers.nextToken,
      })
    );
    freeidUserList.push(...response.data.listFreeidUsers.items);
  }
  return freeidUserList;
}
