import { API, graphqlOperation } from "aws-amplify";
import { getCompany } from "../../graphql/queries";

export default async function fetchCompany(condition) {
  try {
    const response = await API.graphql(graphqlOperation(getCompany, condition));
    return response.data.getCompany;
  } catch (error) {
    console.log(error);
  }
}
