import * as React from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import CommonDialog from "../components/CommonDialog";
import NavigationMenu from "../components/NavigationMenu";
import LoadingProgress from "../components/LoadingProgress";
import VendorListTable from "../components/VendorListTable";
import TopBar from "../components/TopBar";
import fetchJobs from "../components/queries/FetchJobs";
import fetchCompanyVendors from "../components/queries/FetchCompanyVendors";
import fetchVendors from "../components/queries/FetchVendors";
import fetchSites from "../components/queries/FetchSites";
import getRoleLevel from "../components/utils/getRoleLevel";
import makeDrawerList from "../components/utils/makeDrawerList";
import { DRAWER_WIDTH } from "../components/utils/constants";
import companyToVendors from "../components/utils/companyToVendors";
import { deleteVendor, deleteCompanyVendor } from "../graphql/mutations";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import UpdateVendorWrapper from "../components/mutations/UpdateVendor";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from "@mui/material";
class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorList: [],
      accessLevel: "",
      isLoading: false,
      drawerList: [],
      navigationList: [],
      targetArray: [],
      liftUpSnackbar: null,
      commDlg: false,
      vendorToSites: {},
      showDialog: false,
      updatingVendorInfo: null,
    };
    this.deleteItems = this.deleteItems.bind(this);
    this.setTargetArray = this.setTargetArray.bind(this);
    this.handleLiftUpCloseSnackbar = this.handleLiftUpCloseSnackbar.bind(this);
    this.toggleIsActive = this.toggleIsActive.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    const userInfo = await Auth.currentAuthenticatedUser();
    const companyId = userInfo.attributes["custom:company_id"];
    const listCompanyVendors = await fetchCompanyVendors({
      companyId: {
        eq: companyId,
      },
    });

    const vendorToSites = await this.vendorToSitesMapping(companyId);

    const chunkSize = 150;
    const filterChunks = [];
    for (let i = 0; i < listCompanyVendors.length; i += chunkSize) {
      filterChunks.push(
        listCompanyVendors.slice(i, i + chunkSize).map((companyVendor) => ({
          id: {
            eq: companyVendor.vendorId,
          },
        }))
      );
    }

    const vendorPromises = filterChunks.map(async (filterArray) => {
      const vendors = await fetchVendors({
        or: filterArray,
      });
      return vendors;
    });

    const roleLevel = await getRoleLevel();
    fetchJobs();
    const [drawerList, navigationList] = await makeDrawerList();
    const vendorList = await Promise.all(vendorPromises).then(
      (vendorChunks) => {
        const vendors = vendorChunks.flat();
        return vendors;
      }
    );

    this.setState({
      accessLevel: roleLevel,
      drawerList,
      navigationList,
      vendorList,
      isLoading: false,
      vendorName: "",
      vendorToSites,
    });
  }

  vendorToSitesMapping = async (companyId) => {
    const sites = await fetchSites({
      companyId: {
        eq: companyId,
      },
    });

    const vendorToSites = {};
    sites.forEach((site) => {
      // TODO: 開発都合でSite作成時に発生した空データのvendorIdsを回避
      if (!site?.vendorIds || site.vendorIds.length === 0) {
        return;
      }
      site.vendorIds.forEach((vendorId) => {
        if (!vendorToSites[vendorId]) {
          vendorToSites[vendorId] = [];
        }
        vendorToSites[vendorId].push(site.id);
      });
    });
    return vendorToSites;
  };

  toggleIsActive = (activeStatus) => {
    this.setState({
      showDialog: true,
      updatingVendorInfo: { activeStatus, vendorIds: this.state.targetArray },
    });
  };

  renderButtonBasedOnStatus = () => {
    const buttonDisabled = this.state.targetArray.length === 0;
    const commonButtonStyle = {
      fontFamily: "Regular",
      fontSize: 16,
      fontWeight: "700",
      color: buttonDisabled ? "#B0B0B0" : "#F9F9F9",
      backgroundColor: buttonDisabled ? "#E0E0E0" : "#DD3900",
      height: 32,
      borderRadius: 100,
      paddingHorizontal: 18,
      marginLeft: 2,
      "&:hover": {
        backgroundColor: buttonDisabled ? "#E0E0E0" : "#DD3900",
      },
    };

    const activateButtonStyle = {
      ...commonButtonStyle,
      backgroundColor: buttonDisabled ? "#E0E0E0" : "#4caf50",
      "&:hover": {
        backgroundColor: buttonDisabled ? "#E0E0E0" : "green",
      },
    };

    const deactivateButtonStyle = {
      ...commonButtonStyle,
      color: buttonDisabled ? "#B0B0B0" : "#000000",
      backgroundColor: buttonDisabled ? "#E0E0E0" : "#E0E0E0",
      "&:hover": {
        backgroundColor: buttonDisabled ? "#E0E0E0" : "#E0E0E0",
      },
    };

    const isActiveButtonNeeded = this.state.vendorList.some(
      (vendor) =>
        this.state.targetArray.includes(vendor.id) && vendor.isActive === false
    );
    if (isActiveButtonNeeded) {
      return (
        <Button
          onClick={() => this.toggleIsActive(true)}
          disabled={buttonDisabled}
          sx={activateButtonStyle}
          endIcon={
            <img
              src={require("../assets/images/addcircle-on.png")}
              width="20"
              height="20"
            />
          }
        >
          有効化する
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => this.toggleIsActive(false)}
          disabled={buttonDisabled}
          sx={deactivateButtonStyle}
          endIcon={
            <img
              src={require("../assets/images/remove-on.png")}
              width="20"
              height="20"
            />
          }
        >
          無効化する
        </Button>
      );
    }
  };

  handleYes = async () => {
    const { updatingVendorInfo } = this.state;
    if (!updatingVendorInfo) return;

    const vendorsToUpdate = this.state.vendorList.filter((vendor) =>
      updatingVendorInfo.vendorIds.includes(vendor.id)
    );

    try {
      for (const vendor of vendorsToUpdate) {
        await UpdateVendorWrapper(
          { ...vendor, isActive: updatingVendorInfo.activeStatus },
          vendor
        );
      }

      this.setState((prevState) => ({
        vendorList: prevState.vendorList.map((vendor) =>
          vendorsToUpdate.find((v) => v.id === vendor.id)
            ? { ...vendor, isActive: updatingVendorInfo.activeStatus }
            : vendor
        ),
        targetArray: [],
        showDialog: false,
        updatingVendorInfo: null,
        liftUpSnackbar: {
          open: true,
          message: "更新しました。",
          severity: "success",
        },
      }));
    } catch (error) {
      console.error("Failed to update", error);
      this.setState({
        showDialog: false,
        updatingVendorInfo: null,
        liftUpSnackbar: {
          open: true,
          message: "更新に失敗しました。",
          severity: "error",
        },
      });
    }
  };

  handleNo = () => {
    this.setState({ showDialog: false, updatingVendorInfo: null });
  };

  renderConfirmDialog = () => {
    if (!this.state.showDialog) {
      return null;
    }

    return (
      <Dialog maxWidth="xs" open={this.state.showDialog}>
        <DialogTitle>業者の有効・無効ステータスを変更されますか?</DialogTitle>
        <DialogContent dividers>
          {`変更される場合は、「はい」を押して下さい。`}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleYes}>はい</Button>
          <Button onClick={this.handleNo}>いいえ</Button>
        </DialogActions>
      </Dialog>
    );
  };

  canDelete = async (id) => {
    const siteIds = this.state.vendorToSites[id] || [];
    return [siteIds.length === 0, siteIds];
  };

  deleteItems = async () => {
    if (this.state.targetArray.length === 0) return;

    const duplicateMap = await this.validateThenDelete();
    if (Object.keys(duplicateMap).length > 0) {
      await this.validationDisplay(duplicateMap);
    }

    const newArray = await companyToVendors();
    this.setState({
      vendorList: newArray,
      targetArray: [],
      commDlg: false,
    });
  };

  validateThenDelete = async () => {
    const duplicateMap = {};
    await Promise.all(
      this.state.targetArray.map(async (id) => {
        const [whetherDelete, sites] = await this.canDelete(id);
        if (whetherDelete) {
          await this.deleteVendorRelating(id);
        } else {
          duplicateMap[id] = sites;
        }
      })
    );
    return duplicateMap;
  };

  deleteVendorRelating = async (id) => {
    const userInfo = await Auth.currentAuthenticatedUser();
    const companyId = userInfo.attributes["custom:company_id"];
    await API.graphql(graphqlOperation(deleteVendor, { input: { id } }));
    await API.graphql(
      graphqlOperation(deleteCompanyVendor, {
        input: {
          vendorId: id,
          companyId,
        },
      })
    );
  };

  validationDisplay = async (duplicateMap) => {
    const vendors = await fetchVendors({
      or: Object.keys(duplicateMap).map((id) => ({ id: { eq: id } })),
    });

    const validateMsg = await this.processVendors(vendors, duplicateMap);

    this.setState({
      liftUpSnackbar: {
        open: true,
        message: `${validateMsg.join(
          "\n"
        )} に使用されているため、削除に失敗しました。`,
        severity: "error",
      },
    });
  };

  processVendors = async (vendors, duplicateMap) => {
    return await Promise.all(
      vendors.map(async (vendor) => {
        const siteIds = duplicateMap[vendor.id];
        const sitesInfo = await fetchSites({
          or: siteIds.map((siteId) => ({ id: { eq: siteId } })),
        });
        return this.formatMsg(vendor, sitesInfo);
      })
    );
  };

  formatMsg = (vendor, sitesInfo) => {
    const sitesArr = sitesInfo.map(
      (site) => `現場コード: ${site.id} - 現場名: ${site.siteName}\n`
    );
    return `業者コード: ${vendor.vendorCode}は、\n${sitesArr.join("")}`;
  };

  handleLiftUpCloseSnackbar = () => this.setState({ liftUpSnackbar: null });

  setTargetArray = (newId) => {
    this.setState({
      targetArray: [...newId],
    });
  };

  render() {
    const { liftUpSnackbar } = this.state;
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {this.renderConfirmDialog()}
        <TopBar topTitle="第1次業者一覧">
          <Button
            type="submit"
            onClick={() =>
              this.props.navigate("/register-vendor", {
                state: {
                  topIndex: 1,
                },
              })
            }
            variant="contained"
            sx={{
              fontFamily: "Regular",
              fontSize: 16,
              fontWeight: "700",
              color: "#383838",
              backgroundColor: "#EBE91A",
              height: 32,
              borderRadius: 100,
              paddingHorizontal: 18,
              "&:hover": {
                backgroundColor: "#EBE91A",
              },
            }}
            endIcon={
              <img
                src={require("../assets/images/addcircle-on.png")}
                width="20"
                height="20"
              />
            }
            disableElevation
          >
            追加する
          </Button>
          <CommonDialog
            msg={`${this.state.vendorName}を削除しますか？`}
            isOpen={this.state.commDlg}
            doYes={this.deleteItems}
            doNo={() => {
              this.setState({ commDlg: false });
            }}
          />
          {this.renderButtonBasedOnStatus()}
          <Button
            type="submit"
            onClick={() => {
              this.state.vendorList.map((vendor) => {
                this.state.targetArray.map((row) => {
                  if (vendor.id === row) {
                    this.setState({
                      vendorName: vendor.vendorName,
                    });
                  }
                });
              });
              this.setState({ commDlg: true });
            }}
            variant="contained"
            sx={{
              fontFamily: "Regular",
              fontSize: 16,
              fontWeight: "700",
              color: "#F9F9F9",
              backgroundColor: "#DD3900",
              height: 32,
              borderRadius: 100,
              paddingHorizontal: 18,
              "&:hover": {
                backgroundColor: "#DD3900",
              },
              marginLeft: 2,
            }}
            endIcon={
              <img
                src={require("../assets/images/remove-on.png")}
                width="20"
                height="20"
              />
            }
            disableElevation
            disabled={!(this.state.targetArray.length > 0)}
          >
            削除する
          </Button>
          <Button
            type="submit"
            onClick={() =>
              this.props.navigate("/register-vendor-bulk", {
                state: {
                  topIndex: 1,
                },
              })
            }
            variant="contained"
            sx={{
              fontFamily: "Regular",
              fontSize: 16,
              fontWeight: "700",
              color: "#383838",
              backgroundColor: "#EBE91A",
              height: 32,
              borderRadius: 100,
              paddingHorizontal: 18,
              "&:hover": {
                backgroundColor: "#EBE91A",
              },
              marginLeft: 2,
            }}
            endIcon={
              <img
                src={require("../assets/images/addcircle-on.png")}
                width="20"
                height="20"
              />
            }
            disableElevation
          >
            一括追加する
          </Button>
        </TopBar>
        <Box
          component="nav"
          sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <NavigationMenu
            drawerList={this.state.drawerList}
            navigationList={this.state.navigationList}
            topIndex={1}
            bottomIndex={null}
          />
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#F9F9F9",
            height: "100%",
            minHeight: "100vh",
            px: 3,
          }}
        >
          <LoadingProgress isLoading={this.state.isLoading} />
          <Toolbar />
          <Grid item xs={12}>
            <VendorListTable
              rows={this.state.vendorList}
              setTargetArray={this.setTargetArray}
              targetArray={this.state.targetArray}
            />
          </Grid>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={liftUpSnackbar?.open}
          onClose={this.handleLiftUpCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert
            onClose={this.handleLiftUpCloseSnackbar}
            severity={liftUpSnackbar?.severity}
          >
            {liftUpSnackbar?.message}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

function VendorList(props) {
  const navigate = useNavigate();
  return <InputComponent {...props} navigate={navigate} />;
}

export default VendorList;
