import { API, graphqlOperation } from "aws-amplify";
import { listSiteGates } from "../../graphql/queries";

export default async function fetchSiteGates(filter) {
  try {
    const siteGateList = [];
    let response;
    response = await API.graphql(
      graphqlOperation(listSiteGates, {
        filter: filter,
        limit: 500,
      })
    );

    siteGateList.push(...response.data.listSiteGates.items);
    while (response.data.listSiteGates.nextToken !== null) {
      response = await API.graphql(
        graphqlOperation(listSiteGates, {
          filter: filter,
          limit: 500,
          nextToken: response.data.listSiteGates.nextToken,
        })
      );
      siteGateList.push(...response.data.listSiteGates.items);
    }
    return [...siteGateList];
  } catch (error) {
    console.log(error);
  }
}
