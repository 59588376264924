import { API, graphqlOperation } from "aws-amplify";
import { listCompanyRoles } from "../../graphql/queries";

export default async function fetchCompanyRoles(filter = null) {
  try {
    const companyRoleList = [];
    let response;
    response = await API.graphql(
      graphqlOperation(listCompanyRoles, {
        filter: filter,
        limit: 500,
      })
    );

    companyRoleList.push(...response.data.listCompanyRoles.items);
    while (response.data.listCompanyRoles.nextToken !== null) {
      response = await API.graphql(
        graphqlOperation(listCompanyRoles, {
          filter: filter,
          limit: 500,
          nextToken: response.data.listCompanyRoles.nextToken,
        })
      );
      companyRoleList.push(...response.data.listCompanyRoles.items);
    }
    return companyRoleList;
  } catch (error) {
    console.log(error);
  }
}
