import React, { useState } from "react";
import LoadingProgress from "../components/LoadingProgress";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { format } from "date-fns";

const columns = [
  {
    field: "usedAt",
    headerName: "入場時刻",
    headerClassName: "super-app-theme--header",
    width: 200,
    editable: false,
    valueFormatter: (params) =>
      format(
        new Date(
          new Date(params.value).toLocaleString("ja-JP", { timeZone: "Japan" })
        ),
        "yyyy-MM-dd HH:mm:ss"
      ),
  },
  {
    field: "exitedAt",
    headerName: "退場時刻",
    headerClassName: "super-app-theme--header",
    width: 200,
    editable: false,
    valueGetter: (params) => {
      if (params.row.exitedAt) {
        return format(
          new Date(
            new Date(params.row.exitedAt).toLocaleString("ja-JP", {
              timeZone: "Japan",
            })
          ),
          "yyyy-MM-dd HH:mm:ss"
        );
      } else {
        return "-";
      }
    },
  },
  {
    field: "exited",
    headerName: "退場",
    headerClassName: "super-app-theme--header",
    width: 150,
    editable: false,
    valueGetter: (params) => {
      if (params.row.exited) {
        return "退場済み";
      } else {
        return "-";
      }
    },
  },
  {
    field: "time",
    headerName: "滞在時間",
    headerClassName: "super-app-theme--header",
    width: 150,
    editable: false,
    valueGetter: (params) => {
      if (params.row.exited) {
        var endTime = new Date(
          new Date(params.row.exitedAt).toLocaleString("ja-JP", {
            timeZone: "Japan",
          })
        );
      } else {
        var endTime = new Date(
          new Date().toLocaleString("ja-JP", { timeZone: "Japan" })
        );
      }

      const startTime = new Date(
        new Date(params.row.usedAt).toLocaleString("ja-JP", {
          timeZone: "Japan",
        })
      );
      return `${Math.round(
        (endTime.getTime() - startTime.getTime()) / 1000 / 60 / 60
      )} 時間`;
    },
  },
  {
    field: "deviceName",
    headerName: "ゲート",
    headerClassName: "super-app-theme--header",
    width: 150,
    editable: false,
  },
  {
    field: "thermometryInfo",
    headerClassName: "super-app-theme--header",
    headerName: "検温",
    width: 150,
    editable: false,
  },
];

export default function UserDetailsTable({ rows, userDetails }) {
  const [selectionModel, setSelectionModel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [pageSize, setPageSize] = React.useState(10);

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: "#F9F9F9",
          border: 0,
          pl: 0,
          pt: 1,
          pb: 1,
          fontFamily: "Regular",
          fontSize: 12,
          fontWeight: "400",
          color: "#8D8D8D",
        },
        "& .super-app-theme--rows": {
          backgroundColor: "#F9F9F9",
          borderBottom: 1,
          borderColor: "#E5E5E5",
          pl: 0,
          fontFamily: "Regular",
          fontSize: 15,
          fontWeight: "400",
          color: "#383838",
          "&:hover": {
            backgroundColor: "#E3E9FA",
            borderBottom: 1,
            borderColor: "#99B4E3",
          },
        },
      }}
    >
      <LoadingProgress isLoading={isLoading} />
      <DataGrid
        rows={rows}
        columns={columns}
        isRowSelectable={false}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        disableSelectionOnClick
        sx={{
          borderTop: 1,
          borderBottom: 0,
          borderRight: 0,
          borderLeft: 0,
          borderRadius: 0,
          borderColor: "#E5E5E5",
        }}
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        getRowClassName={(params) => `super-app-theme--rows`}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        rowsPerPageOptions={[10, 50, 100]}
      />
    </Box>
  );
}
