import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function RequiredField() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        pt: 0.25,
        pb: 0.25,
        pl: 0.5,
        pr: 0.5,
        width: 28,
        height: 19,
        borderRadius: 0.5,
        backgroundColor: "#ECECEC",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Regular",
          fontSize: 10,
          fontWeight: "500",
          color: "#DD3900",
        }}
      >
        必須
      </Typography>
    </Box>
  );
}
