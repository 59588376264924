import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Auth } from "aws-amplify";

import makeDrawerList from "../components/utils/makeDrawerList";
import NavigationMenu from "../components/NavigationMenu";
import LoadingProgress from "../components/LoadingProgress";
import RegisterVendorBulkTable from "../components/RegisterVendorBulkTable";
import TopBar from "../components/TopBar";
import fetchUser from "../components/queries/FetchUser";
import createVendorWrapper from "../components/mutations/CreateVendor";
import createCompanyVendorWrapper from "../components/mutations/CreateCompanyVendor";
import { DRAWER_WIDTH } from "../components/utils/constants";
import validateVendorCode from "../components/utils/validateVendorCode";
import {
  Box,
  CssBaseline,
  Toolbar,
  Grid,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorList: this.props.location.state.vendorList,
      siteDetails: "",
      isLoading: false,
      drawerList: [],
      navigationList: [],
      snackbar: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  getSiteId = async () => {
    const userInfo = await Auth.currentAuthenticatedUser();
    const getUser = await fetchUser({
      id: userInfo.attributes.sub,
    });
    return getUser.currentSiteId;
  };

  async onSubmit() {
    try {
      this.setState({
        isLoading: true,
      });

      for (let i = 0; i < this.state.vendorList.length; i++) {
        const isDuplicate = await validateVendorCode(
          this.state.vendorList[i].vendorCode
        );
        if (!isDuplicate) {
          const newId = uuidv4();
          const input = {
            id: newId,
            vendorCode: this.state.vendorList[i].vendorCode,
            vendorName: this.state.vendorList[i].vendorName,
            isActive: true,
          };
          await createVendorWrapper(input);

          const userInfo = await Auth.currentAuthenticatedUser();
          const inputCompanyVendor = {
            companyId: userInfo.attributes["custom:company_id"],
            vendorId: newId,
          };
          createCompanyVendorWrapper(inputCompanyVendor);
        } else {
          this.setState({
            snackbar: {
              children: "業者コードが重複しています。",
              severity: "error",
            },
          });
          return;
        }
      }
      this.props.navigate("/vendor-list");
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleCloseSnackbar = () => this.setState({ snackbar: null });

  async componentDidMount() {
    const [drawerList, navigationList] = await makeDrawerList();
    this.setState({
      drawerList,
      navigationList,
    });
  }

  render() {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <TopBar
          topTitle="1次業者登録の確認"
          backTo="/register-vendor-bulk"
          navigate={this.props.navigate}
        >
          {this.state.vendorList && (
            <Button
              type="submit"
              onClick={this.handleSubmit}
              variant="contained"
              sx={{
                fontFamily: "Regular",
                fontSize: 16,
                fontWeight: "700",
                color: "#383838",
                backgroundColor: "#EBE91A",
                height: 40,
                borderRadius: 100,
                paddingHorizontal: 18,
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: "#EBE91A",
                },
              }}
              endIcon={
                <img
                  src={require("../assets/images/rightarrow-on.png")}
                  width="20"
                  height="20"
                />
              }
              disableElevation
            >
              登録する
            </Button>
          )}
          {Boolean(!this.state.vendorList) && (
            <Button
              variant="contained"
              sx={{
                fontFamily: "Regular",
                fontSize: 16,
                fontWeight: "700",
                color: "#C8C8C8",
                backgroundColor: "#E5E5E5",
                height: 40,
                borderRadius: 100,
                paddingHorizontal: 18,
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: "#E5E5E5",
                },
              }}
              endIcon={
                <img
                  src={require("../assets/images/rightarrow-gray.png")}
                  width="20"
                  height="20"
                />
              }
              disableElevation
            >
              登録する
            </Button>
          )}
        </TopBar>
        <Box
          component="nav"
          sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <NavigationMenu
            drawerList={this.state.drawerList}
            navigationList={this.state.navigationList}
            bottomIndex={null}
          />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#F9F9F9",
            height: "100%",
            minHeight: "100vh",
            p: 3,
          }}
        >
          <LoadingProgress isLoading={this.state.isLoading} />
          <Grid item xs={12} mb={2}>
            <Toolbar />
            <Box component="form" sx={{ width: 800 }}>
              <RegisterVendorBulkTable rows={this.state.vendorList} />
            </Box>
          </Grid>
        </Box>
        {!!this.state.snackbar && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open
            onClose={this.handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert
              {...this.state.snackbar}
              onClose={this.handleCloseSnackbar}
            />
          </Snackbar>
        )}
      </Box>
    );
  }
}

function RegisterUserBulkConfirmScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default RegisterUserBulkConfirmScreen;
