import * as React from "react";
import { Auth, API, graphqlOperation, Storage } from "aws-amplify";
import DOMPurify from "dompurify";
import { getUser, getCompany } from "../graphql/queries";
import { createSiteAndSiteVendor } from "../graphql/mutations";
import { useNavigate, useLocation } from "react-router-dom";
import { sha256 } from "crypto-hash";
import companyToVendors from "../components/utils/companyToVendors";
import NavigationMenu from "../components/NavigationMenu";
import LoadingProgress from "../components/LoadingProgress";
import RequiredField from "../components/RequiredField";
import CustomizedHook from "../components/SelectBoxAppend";
import makeDrawerList from "../components/utils/makeDrawerList";
import { DEFAULT_ROLE_LEVEL } from "../components/utils/constants";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SiteSpecifiedItem from "../components/SiteSpecifiedItem";
import fetchSites from "../components/queries/FetchSites";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";

const drawerWidth = 280;

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      companyDetails: "",
      itemList: "",
      masterCode: "",
      siteName: "",
      address1: "",
      notesFileUrl: "",
      notesFile: "",
      contentText1: "",
      choiceText1: "",
      contentText2: "",
      choiceText2: "",
      contentText3: "",
      choiceText3: "",
      contentText4: "",
      choiceText4: "",
      contentText5: "",
      choiceText5: "",
      contentText6: "",
      choiceText6: "",
      contentText7: "",
      choiceText7: "",
      contentText8: "",
      choiceText8: "",
      contentText9: "",
      choiceText9: "",
      contentText10: "",
      choiceText10: "",
      contentText11: "",
      choiceText11: "",
      contentText12: "",
      choiceText12: "",
      contentText13: "",
      choiceText13: "",
      contentText14: "",
      choiceText14: "",
      contentText15: "",
      choiceText15: "",
      contentText16: "",
      choiceText16: "",
      contentText17: "",
      choiceText17: "",
      contentText18: "",
      choiceText18: "",
      contentText19: "",
      choiceText19: "",
      contentText20: "",
      choiceText20: "",
      contentText21: "",
      choiceText21: "",
      contentText22: "",
      choiceText22: "",
      contentText23: "",
      choiceText23: "",
      contentText24: "",
      choiceText24: "",
      contentText25: "",
      choiceText25: "",
      contentText26: "",
      choiceText26: "",
      contentText27: "",
      choiceText27: "",
      contentText28: "",
      choiceText28: "",
      contentText29: "",
      choiceText29: "",
      contentText30: "",
      choiceText30: "",
      contentText31: "",
      choiceText31: "",
      contentText32: "",
      choiceText32: "",
      contentText33: "",
      choiceText33: "",
      contentText34: "",
      choiceText34: "",
      contentText35: "",
      choiceText35: "",
      contentText36: "",
      choiceText36: "",
      contentText37: "",
      choiceText37: "",
      contentText38: "",
      choiceText38: "",
      contentText39: "",
      choiceText39: "",
      contentText40: "",
      choiceText40: "",
      contentText41: "",
      choiceText41: "",
      contentText42: "",
      choiceText42: "",
      contentText43: "",
      choiceText43: "",
      contentText44: "",
      choiceText44: "",
      contentText45: "",
      choiceText45: "",
      contentText46: "",
      choiceText46: "",
      contentText47: "",
      choiceText47: "",
      contentText48: "",
      choiceText48: "",
      contentText49: "",
      choiceText49: "",
      contentText50: "",
      choiceText50: "",
      contentText51: "",
      choiceText51: "",
      contentText52: "",
      choiceText52: "",
      contentText53: "",
      choiceText53: "",
      contentText54: "",
      choiceText54: "",
      contentText55: "",
      choiceText55: "",
      contentText56: "",
      choiceText56: "",
      contentText57: "",
      choiceText57: "",
      contentText58: "",
      choiceText58: "",
      contentText59: "",
      choiceText59: "",
      contentText60: "",
      choiceText60: "",
      contentText61: "",
      choiceText61: "",
      contentText62: "",
      choiceText62: "",
      contentText63: "",
      choiceText63: "",
      contentText64: "",
      choiceText64: "",
      contentText65: "",
      choiceText65: "",
      contentText66: "",
      choiceText66: "",
      contentText67: "",
      choiceText67: "",
      contentText68: "",
      choiceText68: "",
      contentText69: "",
      choiceText69: "",
      contentText70: "",
      choiceText70: "",
      contentText71: "",
      choiceText71: "",
      contentText72: "",
      choiceText72: "",
      contentText73: "",
      choiceText73: "",
      contentText74: "",
      choiceText74: "",
      contentText75: "",
      choiceText75: "",
      contentText76: "",
      choiceText76: "",
      contentText77: "",
      choiceText77: "",
      contentText78: "",
      choiceText78: "",
      contentText79: "",
      choiceText79: "",
      contentText80: "",
      choiceText80: "",
      contentText81: "",
      choiceText81: "",
      contentText82: "",
      choiceText82: "",
      contentText83: "",
      choiceText83: "",
      contentText84: "",
      choiceText84: "",
      contentText85: "",
      choiceText85: "",
      contentText86: "",
      choiceText86: "",
      contentText87: "",
      choiceText87: "",
      contentText88: "",
      choiceText88: "",
      contentText89: "",
      choiceText89: "",
      contentText90: "",
      choiceText90: "",
      contentText91: "",
      choiceText91: "",
      contentText92: "",
      choiceText92: "",
      contentText93: "",
      choiceText93: "",
      contentText94: "",
      choiceText94: "",
      contentText95: "",
      choiceText95: "",
      contentText96: "",
      choiceText96: "",
      contentText97: "",
      choiceText97: "",
      contentText98: "",
      choiceText98: "",
      contentText99: "",
      choiceText99: "",
      contentText100: "",
      choiceText100: "",
      isLoading: false,
      accessLevel: "",
      vendorList: [],
      vendors: [],
      error: false,
      drawerList: [],
      navigationList: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.prepareVendorIds = this.prepareVendorIds.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.fetchRoles();
    this.fetchItems();
    const listVendors = await companyToVendors();
    // 有効な業者のみ表示
    const activeVendors = listVendors.filter(
      (vendor) => vendor.isActive !== false
    );
    const [drawerList, navigationList] = await makeDrawerList();

    this.setState({
      vendorList: activeVendors, // 有効な業者のみ表示に後で変更
      drawerList,
      navigationList,
      isLoading: false,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleFileChange(event) {
    this.onFileChange(event);
    event.preventDefault();
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  prepareVendorIds = (id) => {
    this.setState({
      vendors: id,
    });
  };

  async onFileChange(e) {
    const userInfo = await Auth.currentAuthenticatedUser();
    const file = e.target.files[0];
    try {
      if (file.name && file.name !== "undefined") {
        await Storage.put(
          userInfo.attributes["custom:company_id"] +
            "/" +
            this.state.userDetails.currentSiteId +
            "/notes-files/" +
            file.name,
          file,
          {}
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  async onSubmit() {
    try {
      this.setState({
        isLoading: true,
      });

      let bucketName;
      if (process.env.REACT_APP_ENV === "prod") {
        bucketName =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450190221-prod.s3.ap-northeast-1.amazonaws.com/public/";
      } else if (process.env.REACT_APP_ENV === "test") {
        bucketName =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450194438-test.s3.ap-northeast-1.amazonaws.com/public/";
      } else {
        bucketName =
          "https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450213306-dev.s3.ap-northeast-1.amazonaws.com/public/";
      }
      const userInfo = await Auth.currentAuthenticatedUser();
      const listSites = await fetchSites({
        masterCode: {
          eq: this.state.masterCode,
        },
      });
      if (listSites.length > 0) {
        throw new Error("案件管理番号が重複しています。");
      }
      const shortid = require("shortid");
      var r = "";
      const c = {
        small: "abcdefghijklmnopqrstuvwxyz",
        capital: "ABCDEFGHIJKLMNPQRSTUVWXYZ",
        num: "1234567890",
        symbol: "_-!",
      };
      const str = r.concat(c.small, c.capital, c.num, c.symbol);
      shortid.characters(str);
      const newId = shortid.generate();

      const hashResult = await sha256(
        "siteCode=" + newId + "&siteName=" + this.state.siteName
      );

      if (
        this.state.notesFile.name &&
        this.state.notesFile.name !== "undefined"
      ) {
        await Storage.put(
          userInfo.attributes["custom:company_id"] +
            "/" +
            newId +
            "/notes-files/" +
            this.state.notesFile.name,
          this.state.notesFile,
          {}
        );
      }
      const ids = this.state.vendors.map((vendor) => {
        return vendor.id;
      });

      const input = {
        id: newId,
        masterCode: this.state.masterCode,
        siteName: this.state.siteName,
        address1: this.state.address1,
        notesFileUrl:
          this.state.notesFile.name && this.state.notesFile.name !== "undefined"
            ? bucketName +
              userInfo.attributes["custom:company_id"] +
              "/" +
              newId +
              "/notes-files/" +
              this.state.notesFile.name
            : "",
        qrCodeDescription: "siteCode=" + newId + "&sha256=" + hashResult,
        contentText1: DOMPurify.sanitize(this.state.contentText1),
        choiceText1: DOMPurify.sanitize(this.state.choiceText1),
        contentText2: DOMPurify.sanitize(this.state.contentText2),
        choiceText2: DOMPurify.sanitize(this.state.choiceText2),
        contentText3: DOMPurify.sanitize(this.state.contentText3),
        choiceText3: DOMPurify.sanitize(this.state.choiceText3),
        contentText4: DOMPurify.sanitize(this.state.contentText4),
        choiceText4: DOMPurify.sanitize(this.state.choiceText4),
        contentText5: DOMPurify.sanitize(this.state.contentText5),
        choiceText5: DOMPurify.sanitize(this.state.choiceText5),
        contentText6: DOMPurify.sanitize(this.state.contentText6),
        choiceText6: DOMPurify.sanitize(this.state.choiceText6),
        contentText7: DOMPurify.sanitize(this.state.contentText7),
        choiceText7: DOMPurify.sanitize(this.state.choiceText7),
        contentText8: DOMPurify.sanitize(this.state.contentText8),
        choiceText8: DOMPurify.sanitize(this.state.choiceText8),
        contentText9: DOMPurify.sanitize(this.state.contentText9),
        choiceText9: DOMPurify.sanitize(this.state.choiceText9),
        contentText10: DOMPurify.sanitize(this.state.contentText10),
        choiceText10: DOMPurify.sanitize(this.state.choiceText10),
        contentText11: DOMPurify.sanitize(this.state.contentText11),
        choiceText11: DOMPurify.sanitize(this.state.choiceText11),
        contentText12: DOMPurify.sanitize(this.state.contentText12),
        choiceText12: DOMPurify.sanitize(this.state.choiceText12),
        contentText13: DOMPurify.sanitize(this.state.contentText13),
        choiceText13: DOMPurify.sanitize(this.state.choiceText13),
        contentText14: DOMPurify.sanitize(this.state.contentText14),
        choiceText14: DOMPurify.sanitize(this.state.choiceText14),
        contentText15: DOMPurify.sanitize(this.state.contentText15),
        choiceText15: DOMPurify.sanitize(this.state.choiceText15),
        contentText16: DOMPurify.sanitize(this.state.contentText16),
        choiceText16: DOMPurify.sanitize(this.state.choiceText16),
        contentText17: DOMPurify.sanitize(this.state.contentText17),
        choiceText17: DOMPurify.sanitize(this.state.choiceText17),
        contentText18: DOMPurify.sanitize(this.state.contentText18),
        choiceText18: DOMPurify.sanitize(this.state.choiceText18),
        contentText19: DOMPurify.sanitize(this.state.contentText19),
        choiceText19: DOMPurify.sanitize(this.state.choiceText19),
        contentText20: DOMPurify.sanitize(this.state.contentText20),
        choiceText20: DOMPurify.sanitize(this.state.choiceText20),
        contentText21: DOMPurify.sanitize(this.state.contentText21),
        choiceText21: DOMPurify.sanitize(this.state.choiceText21),
        contentText22: DOMPurify.sanitize(this.state.contentText22),
        choiceText22: DOMPurify.sanitize(this.state.choiceText22),
        contentText23: DOMPurify.sanitize(this.state.contentText23),
        choiceText23: DOMPurify.sanitize(this.state.choiceText23),
        contentText24: DOMPurify.sanitize(this.state.contentText24),
        choiceText24: DOMPurify.sanitize(this.state.choiceText24),
        contentText25: DOMPurify.sanitize(this.state.contentText25),
        choiceText25: DOMPurify.sanitize(this.state.choiceText25),
        contentText26: DOMPurify.sanitize(this.state.contentText26),
        choiceText26: DOMPurify.sanitize(this.state.choiceText26),
        contentText27: DOMPurify.sanitize(this.state.contentText27),
        choiceText27: DOMPurify.sanitize(this.state.choiceText27),
        contentText28: DOMPurify.sanitize(this.state.contentText28),
        choiceText28: DOMPurify.sanitize(this.state.choiceText28),
        contentText29: DOMPurify.sanitize(this.state.contentText29),
        choiceText29: DOMPurify.sanitize(this.state.choiceText29),
        contentText30: DOMPurify.sanitize(this.state.contentText30),
        choiceText30: DOMPurify.sanitize(this.state.choiceText30),
        contentText31: DOMPurify.sanitize(this.state.contentText31),
        choiceText31: DOMPurify.sanitize(this.state.choiceText31),
        contentText32: DOMPurify.sanitize(this.state.contentText32),
        choiceText32: DOMPurify.sanitize(this.state.choiceText32),
        contentText33: DOMPurify.sanitize(this.state.contentText33),
        choiceText33: DOMPurify.sanitize(this.state.choiceText33),
        contentText34: DOMPurify.sanitize(this.state.contentText34),
        choiceText34: DOMPurify.sanitize(this.state.choiceText34),
        contentText35: DOMPurify.sanitize(this.state.contentText35),
        choiceText35: DOMPurify.sanitize(this.state.choiceText35),
        contentText36: DOMPurify.sanitize(this.state.contentText36),
        choiceText36: DOMPurify.sanitize(this.state.choiceText36),
        contentText37: DOMPurify.sanitize(this.state.contentText37),
        choiceText37: DOMPurify.sanitize(this.state.choiceText37),
        contentText38: DOMPurify.sanitize(this.state.contentText38),
        choiceText38: DOMPurify.sanitize(this.state.choiceText38),
        contentText39: DOMPurify.sanitize(this.state.contentText39),
        choiceText39: DOMPurify.sanitize(this.state.choiceText39),
        contentText40: DOMPurify.sanitize(this.state.contentText40),
        choiceText40: DOMPurify.sanitize(this.state.choiceText40),
        contentText41: DOMPurify.sanitize(this.state.contentText41),
        choiceText41: DOMPurify.sanitize(this.state.choiceText41),
        contentText42: DOMPurify.sanitize(this.state.contentText42),
        choiceText42: DOMPurify.sanitize(this.state.choiceText42),
        contentText43: DOMPurify.sanitize(this.state.contentText43),
        choiceText43: DOMPurify.sanitize(this.state.choiceText43),
        contentText44: DOMPurify.sanitize(this.state.contentText44),
        choiceText44: DOMPurify.sanitize(this.state.choiceText44),
        contentText45: DOMPurify.sanitize(this.state.contentText45),
        choiceText45: DOMPurify.sanitize(this.state.choiceText45),
        contentText46: DOMPurify.sanitize(this.state.contentText46),
        choiceText46: DOMPurify.sanitize(this.state.choiceText46),
        contentText47: DOMPurify.sanitize(this.state.contentText47),
        choiceText47: DOMPurify.sanitize(this.state.choiceText47),
        contentText48: DOMPurify.sanitize(this.state.contentText48),
        choiceText48: DOMPurify.sanitize(this.state.choiceText48),
        contentText49: DOMPurify.sanitize(this.state.contentText49),
        choiceText49: DOMPurify.sanitize(this.state.choiceText49),
        contentText50: DOMPurify.sanitize(this.state.contentText50),
        choiceText50: DOMPurify.sanitize(this.state.choiceText50),
        contentText51: DOMPurify.sanitize(this.state.contentText51),
        choiceText51: DOMPurify.sanitize(this.state.choiceText51),
        contentText52: DOMPurify.sanitize(this.state.contentText52),
        choiceText52: DOMPurify.sanitize(this.state.choiceText52),
        contentText53: DOMPurify.sanitize(this.state.contentText53),
        choiceText53: DOMPurify.sanitize(this.state.choiceText53),
        contentText54: DOMPurify.sanitize(this.state.contentText54),
        choiceText54: DOMPurify.sanitize(this.state.choiceText54),
        contentText55: DOMPurify.sanitize(this.state.contentText55),
        choiceText55: DOMPurify.sanitize(this.state.choiceText55),
        contentText56: DOMPurify.sanitize(this.state.contentText56),
        choiceText56: DOMPurify.sanitize(this.state.choiceText56),
        contentText57: DOMPurify.sanitize(this.state.contentText57),
        choiceText57: DOMPurify.sanitize(this.state.choiceText57),
        contentText58: DOMPurify.sanitize(this.state.contentText58),
        choiceText58: DOMPurify.sanitize(this.state.choiceText58),
        contentText59: DOMPurify.sanitize(this.state.contentText59),
        choiceText59: DOMPurify.sanitize(this.state.choiceText59),
        contentText60: DOMPurify.sanitize(this.state.contentText60),
        choiceText60: DOMPurify.sanitize(this.state.choiceText60),
        contentText61: DOMPurify.sanitize(this.state.contentText61),
        choiceText61: DOMPurify.sanitize(this.state.choiceText61),
        contentText62: DOMPurify.sanitize(this.state.contentText62),
        choiceText62: DOMPurify.sanitize(this.state.choiceText62),
        contentText63: DOMPurify.sanitize(this.state.contentText63),
        choiceText63: DOMPurify.sanitize(this.state.choiceText63),
        contentText64: DOMPurify.sanitize(this.state.contentText64),
        choiceText64: DOMPurify.sanitize(this.state.choiceText64),
        contentText65: DOMPurify.sanitize(this.state.contentText65),
        choiceText65: DOMPurify.sanitize(this.state.choiceText65),
        contentText66: DOMPurify.sanitize(this.state.contentText66),
        choiceText66: DOMPurify.sanitize(this.state.choiceText66),
        contentText67: DOMPurify.sanitize(this.state.contentText67),
        choiceText67: DOMPurify.sanitize(this.state.choiceText67),
        contentText68: DOMPurify.sanitize(this.state.contentText68),
        choiceText68: DOMPurify.sanitize(this.state.choiceText68),
        contentText69: DOMPurify.sanitize(this.state.contentText69),
        choiceText69: DOMPurify.sanitize(this.state.choiceText69),
        contentText70: DOMPurify.sanitize(this.state.contentText70),
        choiceText70: DOMPurify.sanitize(this.state.choiceText70),
        contentText71: DOMPurify.sanitize(this.state.contentText71),
        choiceText71: DOMPurify.sanitize(this.state.choiceText71),
        contentText72: DOMPurify.sanitize(this.state.contentText72),
        choiceText72: DOMPurify.sanitize(this.state.choiceText72),
        contentText73: DOMPurify.sanitize(this.state.contentText73),
        choiceText73: DOMPurify.sanitize(this.state.choiceText73),
        contentText74: DOMPurify.sanitize(this.state.contentText74),
        choiceText74: DOMPurify.sanitize(this.state.choiceText74),
        contentText75: DOMPurify.sanitize(this.state.contentText75),
        choiceText75: DOMPurify.sanitize(this.state.choiceText75),
        contentText76: DOMPurify.sanitize(this.state.contentText76),
        choiceText76: DOMPurify.sanitize(this.state.choiceText76),
        contentText77: DOMPurify.sanitize(this.state.contentText77),
        choiceText77: DOMPurify.sanitize(this.state.choiceText77),
        contentText78: DOMPurify.sanitize(this.state.contentText78),
        choiceText78: DOMPurify.sanitize(this.state.choiceText78),
        contentText79: DOMPurify.sanitize(this.state.contentText79),
        choiceText79: DOMPurify.sanitize(this.state.choiceText79),
        contentText80: DOMPurify.sanitize(this.state.contentText80),
        choiceText80: DOMPurify.sanitize(this.state.choiceText80),
        contentText81: DOMPurify.sanitize(this.state.contentText81),
        choiceText81: DOMPurify.sanitize(this.state.choiceText81),
        contentText82: DOMPurify.sanitize(this.state.contentText82),
        choiceText82: DOMPurify.sanitize(this.state.choiceText82),
        contentText83: DOMPurify.sanitize(this.state.contentText83),
        choiceText83: DOMPurify.sanitize(this.state.choiceText83),
        contentText84: DOMPurify.sanitize(this.state.contentText84),
        choiceText84: DOMPurify.sanitize(this.state.choiceText84),
        contentText85: DOMPurify.sanitize(this.state.contentText85),
        choiceText85: DOMPurify.sanitize(this.state.choiceText85),
        contentText86: DOMPurify.sanitize(this.state.contentText86),
        choiceText86: DOMPurify.sanitize(this.state.choiceText86),
        contentText87: DOMPurify.sanitize(this.state.contentText87),
        choiceText87: DOMPurify.sanitize(this.state.choiceText87),
        contentText88: DOMPurify.sanitize(this.state.contentText88),
        choiceText88: DOMPurify.sanitize(this.state.choiceText88),
        contentText89: DOMPurify.sanitize(this.state.contentText89),
        choiceText89: DOMPurify.sanitize(this.state.choiceText89),
        contentText90: DOMPurify.sanitize(this.state.contentText90),
        choiceText90: DOMPurify.sanitize(this.state.choiceText90),
        contentText91: DOMPurify.sanitize(this.state.contentText91),
        choiceText91: DOMPurify.sanitize(this.state.choiceText91),
        contentText92: DOMPurify.sanitize(this.state.contentText92),
        choiceText92: DOMPurify.sanitize(this.state.choiceText92),
        contentText93: DOMPurify.sanitize(this.state.contentText93),
        choiceText93: DOMPurify.sanitize(this.state.choiceText93),
        contentText94: DOMPurify.sanitize(this.state.contentText94),
        choiceText94: DOMPurify.sanitize(this.state.choiceText94),
        contentText95: DOMPurify.sanitize(this.state.contentText95),
        choiceText95: DOMPurify.sanitize(this.state.choiceText95),
        contentText96: DOMPurify.sanitize(this.state.contentText96),
        choiceText96: DOMPurify.sanitize(this.state.choiceText96),
        contentText97: DOMPurify.sanitize(this.state.contentText97),
        choiceText97: DOMPurify.sanitize(this.state.choiceText97),
        contentText98: DOMPurify.sanitize(this.state.contentText98),
        choiceText98: DOMPurify.sanitize(this.state.choiceText98),
        contentText99: DOMPurify.sanitize(this.state.contentText99),
        choiceText99: DOMPurify.sanitize(this.state.choiceText99),
        contentText100: DOMPurify.sanitize(this.state.contentText100),
        choiceText100: DOMPurify.sanitize(this.state.choiceText100),
        companyId: userInfo.attributes["custom:company_id"],
        vendorIds: ids,
      };

      await API.graphql(
        graphqlOperation(createSiteAndSiteVendor, {
          input,
        })
      );

      this.setState({
        isLoading: false,
      });

      this.props.navigate("/select-site");
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error.message === "案件管理番号が重複しています。") {
        this.setState({
          error: true,
        });
      }
    }
  }

  async fetchRoles() {
    try {
      this.setState({
        isLoading: true,
      });
      const userInfo = await Auth.currentAuthenticatedUser();

      const filterCompanyRole = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      };

      const resCompanyRoles = await fetchCompanyRoles(filterCompanyRole);
      console.log("resCompanyRoles", resCompanyRoles);
      let roleLevel;
      roleLevel = resCompanyRoles[0].roleLevel;
      if (resCompanyRoles[0].roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = resCompanyRoles[0].roleLevel;
      } else {
        const responseUser = await API.graphql(
          graphqlOperation(getUser, {
            id: userInfo.attributes.sub,
          })
        );

        const filterSiteRole = {
          and: [
            {
              siteCode: {
                eq: responseUser.data.getUser.currentSiteId,
              },
            },
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };

        const resSiteRoles = await fetchSiteRoles(filterSiteRole);
        console.log("resSiteRoles", resSiteRoles);
        roleLevel = resSiteRoles[0].roleLevel;
      }

      this.setState({
        accessLevel: roleLevel,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchItems() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  unifyDuplication(tmpArray) {
    tmpArray = tmpArray.filter(
      (element, index, self) =>
        self.findIndex((e) => e.id === element.id) === index
    );
    return tmpArray;
  }

  render() {
    if (
      (this.state.isLoading === true) |
      (this.state.accessLevel === 1) |
      (this.state.accessLevel === 2)
    ) {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/select-site")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                component="div"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                現場の作成
              </Typography>
              {Boolean(
                !this.state.siteName |
                  !this.state.address1 |
                  !this.state.masterCode |
                  (this.state.vendors.length === 0)
              ) && (
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#C8C8C8",
                    backgroundColor: "#E5E5E5",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    "&:hover": {
                      backgroundColor: "#E5E5E5",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-gray.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  作成する
                </Button>
              )}
              {this.state.siteName &&
                this.state.address1 &&
                this.state.masterCode &&
                this.state.vendors.length > 0 && (
                  <Button
                    type="submit"
                    onClick={this.handleSubmit}
                    variant="contained"
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 16,
                      fontWeight: "700",
                      color: "#383838",
                      backgroundColor: "#EBE91A",
                      height: 40,
                      borderRadius: 100,
                      paddingHorizontal: 18,
                      "&:hover": {
                        backgroundColor: "#EBE91A",
                      },
                    }}
                    endIcon={
                      <img
                        src={require("../assets/images/rightarrow-on.png")}
                        width="20"
                        height="20"
                      />
                    }
                    disableElevation
                  >
                    作成する
                  </Button>
                )}
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationMenu
              drawerList={this.state.drawerList}
              navigationList={this.state.navigationList}
              topIndex={0}
              bottomIndex={null}
            />
          </Box>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 0,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Toolbar />
            <Box
              component="form"
              sx={{
                width: 451,
                paddingLeft: 3,
                paddingRight: 5,
                paddingTop: 2,
                paddingBottom: 3,
              }}
            >
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  案件管理番号
                </Typography>
                <RequiredField />
              </Stack>
              <TextField
                id="masterCode"
                placeholder="案件管理番号"
                name="masterCode"
                type="text"
                value={this.state.masterCode}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
                error={this.state.error === true}
                helperText={
                  this.state.error === true
                    ? "案件管理番号が重複しています。"
                    : ""
                }
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  現場名
                </Typography>
                <RequiredField />
              </Stack>
              <TextField
                id="siteName"
                placeholder="現場名"
                name="siteName"
                type="text"
                value={this.state.siteName}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  住所
                </Typography>
                <RequiredField />
              </Stack>
              <TextField
                id="address1"
                placeholder="住所"
                name="address1"
                type="text"
                value={this.state.address1}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              {this.state.vendorList && (
                <CustomizedHook
                  vendorList={this.state.vendorList}
                  vendorIds={this.state.vendorIds}
                  defaultSelected={this.state.defaultSelected}
                  setReload={this.setReload}
                  prepareVendorIds={this.prepareVendorIds}
                  unifyDuplication={this.unifyDuplication}
                />
              )}
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  現場注意事項ファイル
                </Typography>
              </Stack>
              <input
                type="file"
                accept="application/pdf"
                onChange={(event) =>
                  this.setState({
                    notesFile: event.target.files[0],
                  })
                }
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 17,
                    fontWeight: "700",
                    color: "#383838",
                    mt: 4,
                    mb: 2,
                    mr: 1,
                  }}
                >
                  現場固有チェック項目1
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  チェック内容文章
                </Typography>
              </Stack>
              <TextField
                id="contentText1"
                name="contentText1"
                type="text"
                value={this.state.contentText1}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  選択肢文字列
                </Typography>
              </Stack>
              <TextField
                id="choiceText1"
                name="choiceText1"
                type="text"
                value={this.state.choiceText1}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 17,
                    fontWeight: "700",
                    color: "#383838",
                    mt: 2,
                    mb: 2,
                    mr: 1,
                  }}
                >
                  現場固有チェック項目2
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  チェック内容文章
                </Typography>
              </Stack>
              <TextField
                id="contentText2"
                name="contentText2"
                type="text"
                value={this.state.contentText2}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  選択肢文字列
                </Typography>
              </Stack>
              <TextField
                id="choiceText2"
                name="choiceText2"
                type="text"
                value={this.state.choiceText2}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 17,
                    fontWeight: "700",
                    color: "#383838",
                    mt: 2,
                    mb: 2,
                    mr: 1,
                  }}
                >
                  現場固有チェック項目3
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  チェック内容文章
                </Typography>
              </Stack>
              <TextField
                id="contentText3"
                name="contentText3"
                type="text"
                value={this.state.contentText3}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  選択肢文字列
                </Typography>
              </Stack>
              <TextField
                id="choiceText3"
                name="choiceText3"
                type="text"
                value={this.state.choiceText3}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 17,
                    fontWeight: "700",
                    color: "#383838",
                    mt: 2,
                    mb: 2,
                    mr: 1,
                  }}
                >
                  現場固有チェック項目4
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  チェック内容文章
                </Typography>
              </Stack>
              <TextField
                id="contentText4"
                name="contentText4"
                type="text"
                value={this.state.contentText4}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  選択肢文字列
                </Typography>
              </Stack>
              <TextField
                id="choiceText4"
                name="choiceText4"
                type="text"
                value={this.state.choiceText4}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 17,
                    fontWeight: "700",
                    color: "#383838",
                    mt: 2,
                    mb: 2,
                    mr: 1,
                  }}
                >
                  現場固有チェック項目5
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  チェック内容文章
                </Typography>
              </Stack>
              <TextField
                id="contentText5"
                name="contentText5"
                type="text"
                value={this.state.contentText5}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  選択肢文字列
                </Typography>
              </Stack>
              <TextField
                id="choiceText5"
                name="choiceText5"
                type="text"
                value={this.state.choiceText5}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              {[...Array(95)].map((_, i) => (
                <SiteSpecifiedItem
                  handleInputChange={this.handleInputChange}
                  index={i}
                  state={this.state}
                />
              ))}
            </Box>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/select-site")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                component="div"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                現場の作成
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationMenu
              drawerList={this.state.drawerList}
              navigationList={this.state.navigationList}
              topIndex={0}
              bottomIndex={null}
            />
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 0,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Grid item xs={12} mb={2}>
              <Toolbar />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mb: 0,
                  mr: 1,
                }}
              >
                アクセス権限がありません
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  }
}

function RegisterSiteScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default RegisterSiteScreen;
