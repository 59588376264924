import * as React from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUser, getCompany } from "../graphql/queries";
import { createSiteGate } from "../graphql/mutations";
import { useNavigate, useLocation } from "react-router-dom";
import NavigationDrawer from "../components/NavigationDrawer";
import LoadingProgress from "../components/LoadingProgress";
import RequiredField from "../components/RequiredField";
import fetchSiteRoles from "../components/queries/FetchSiteRoles";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";
import { DEFAULT_ROLE_LEVEL } from "../components/utils/constants";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const drawerWidth = 280;

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      companyDetails: "",
      siteName: "",
      contractCompanyCode: "",
      deviceName: "",
      accessLevel: "",
      isLoading: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    this.fetchUsers();
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  async componentDidMount() {
    this.fetchUsers();
    this.fetchRoles();
  }

  async onSubmit() {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();

      const shortid = require("shortid");
      var r = "";
      const c = {
        small: "abcdefghijklmnopqrstuvwxyz",
        capital: "ABCDEFGHIJKLMNPQRSTUVWXYZ",
        num: "1234567890",
        symbol: "_-!",
      };
      const str = r.concat(c.small, c.capital, c.num, c.symbol);
      shortid.characters(str);
      const newId = shortid.generate();

      const input = {
        id: newId,
        deviceCode: newId,
        contractCompanyCode: this.state.companyDetails.id,
        deviceName: this.state.deviceName,
        siteCode: this.state.userDetails.currentSiteId,
        siteName: this.state.userDetails.currentSiteName,
        companyId: userInfo.attributes["custom:company_id"],
        userId: userInfo.attributes.sub,
      };

      const response = await API.graphql(
        graphqlOperation(createSiteGate, {
          input,
        })
      );

      this.props.navigate("/select-device", {
        state: {
          siteCode: this.state.userDetails.currentSiteId,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchRoles() {
    try {
      this.setState({
        isLoading: true,
      });
      const userInfo = await Auth.currentAuthenticatedUser();

      const filterCompanyRole = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      };

      const resCompanyRoles = await fetchCompanyRoles(filterCompanyRole);
      let roleLevel;
      if (resCompanyRoles[0].roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = resCompanyRoles[0].roleLevel;
      } else {
        const responseUser = await API.graphql(
          graphqlOperation(getUser, {
            id: userInfo.attributes.sub,
          })
        );

        const filterSiteRole = {
          and: [
            {
              siteCode: {
                eq: responseUser.data.getUser.currentSiteId,
              },
            },
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };

        const resSiteRoles = await fetchSiteRoles(filterSiteRole);
        roleLevel = resSiteRoles[0].roleLevel;
      }

      this.setState({
        accessLevel: roleLevel,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchUsers() {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });
    } catch (error) {
      console.log(error);
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  render() {
    if (
      (this.state.isLoading === true) |
      (this.state.accessLevel === 1) |
      (this.state.accessLevel === 2) |
      (this.state.accessLevel === 4)
    ) {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() =>
                  this.props.navigate("/select-device", {
                    state: {
                      siteCode: this.state.userDetails.currentSiteId,
                    },
                  })
                }
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.state.userDetails.currentSiteName}：顔認証端末の登録
              </Typography>
              {this.state.companyDetails.id && this.state.deviceName && (
                <Button
                  type="submit"
                  onClick={this.handleSubmit}
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#383838",
                    backgroundColor: "#EBE91A",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: "#EBE91A",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-on.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  登録する
                </Button>
              )}
              {Boolean(
                !this.state.companyDetails.id | !this.state.deviceName
              ) && (
                <Button
                  // type="submit"
                  // onClick={this.handleSubmit}
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#C8C8C8",
                    backgroundColor: "#E5E5E5",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: "#E5E5E5",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-gray.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  登録する
                </Button>
              )}
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationDrawer topIndex={3} bottomIndex={null} />
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 3,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Grid item xs={12} mb={2}>
              <Toolbar />
              <Box component="form" sx={{ width: 480 }}>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    契約企業コード
                  </Typography>
                  <RequiredField />
                </Stack>
                <TextField
                  id="contractCompanyCode"
                  placeholder="契約企業コード"
                  name="contractCompanyCode"
                  type="text"
                  value={this.state.companyDetails.id}
                  // onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  autoFocus
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    ゲート名
                  </Typography>
                  <RequiredField />
                </Stack>
                <TextField
                  id="deviceName"
                  placeholder="ゲート名"
                  name="deviceName"
                  type="text"
                  value={this.state.deviceName}
                  onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  autoFocus
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
              </Box>
            </Grid>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/select-device")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                component="div"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.state.userDetails.currentSiteName}：顔認証端末の登録
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationDrawer topIndex={3} bottomIndex={null} />
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 0,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Grid item xs={12} mb={2}>
              <Toolbar />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mb: 0,
                  mr: 1,
                }}
              >
                アクセス権限がありません
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  }
}

function RegisterDeviceScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default RegisterDeviceScreen;
