import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export default function QrCode({ value, qrRef }) {
  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={value}
      size={200}
      bgColor={"#F9F9F9"}
      level={"H"}
    />
  );

  const downloadQrCode = (e) => {
    e.preventDefault();
    const canvas = qrRef.current.querySelector("canvas");
    const image = canvas.toDataURL("image/png");
    const anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `qrCode.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <Box
      sx={{
        mt: 1,
        mb: 1,
      }}
    >
      <div ref={qrRef}>{qrcode}</div>
      <Button
        onClick={downloadQrCode}
        variant="contained"
        sx={{
          fontFamily: "Regular",
          fontSize: 16,
          fontWeight: "700",
          color: "#383838",
          backgroundColor: "#EBE91A",
          height: 32,
          borderRadius: 100,
          paddingHorizontal: 18,
          mt: 1,
          mr: 1,
          "&:hover": {
            backgroundColor: "#EBE91A",
          },
        }}
        endIcon={
          <img
            src={require("../assets/images/download-on.png")}
            width="20"
            height="20"
          />
        }
        disableElevation
      >
        ダウンロードする
      </Button>
    </Box>
  );
}
