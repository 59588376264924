import * as React from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUser, getCompany, getSiteGate } from "../graphql/queries";
import { updateSiteGate } from "../graphql/mutations";
import { useNavigate, useLocation } from "react-router-dom";
import NavigationDrawer from "../components/NavigationDrawer";
import LoadingProgress from "../components/LoadingProgress";
import RequiredField from "../components/RequiredField";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";
import fetchSites from "../components/queries/FetchSites";
import fetchSiteRoles from "../components/queries/FetchSiteRoles";
import { DEFAULT_ROLE_LEVEL } from "../components/utils/constants";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const drawerWidth = 280;

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      companyDetails: "",
      siteName: "",
      contractCompanyCode: "",
      deviceName: "",
      deviceCode: "",
      siteCode: "",
      siteList: [],
      siteIndex: 0,
      accessLevel: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  handleOnChange(event) {
    this.setState({ siteIndex: event.target.value });
  }

  async componentDidMount() {
    this.fetchUsers();
    this.fetchRoles();
  }

  async onSubmit() {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();

      const input = {
        id: this.state.deviceCode,
        deviceCode: this.state.deviceCode,
        contractCompanyCode: this.state.contractCompanyCode,
        deviceName: this.state.deviceName,
        siteCode: this.state.userDetails.currentSiteId,
        siteName: this.state.userDetails.currentSiteName,
        companyId: userInfo.attributes["custom:company_id"],
        userId: userInfo.attributes.sub,
      };

      const response = await API.graphql(
        graphqlOperation(updateSiteGate, {
          input,
        })
      );

      this.props.navigate("/select-device", {
        state: {
          siteCode: this.state.userDetails.currentSiteId,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchRoles() {
    try {
      this.setState({
        isLoading: true,
      });
      const userInfo = await Auth.currentAuthenticatedUser();

      const filterCompanyRole = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      };

      const resCompanyRoles = await fetchCompanyRoles(filterCompanyRole);

      let roleLevel;
      if (resCompanyRoles[0].roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = resCompanyRoles[0].roleLevel;
      } else {
        const responseUser = await API.graphql(
          graphqlOperation(getUser, {
            id: userInfo.attributes.sub,
          })
        );

        const filterSiteRole = {
          and: [
            {
              siteCode: {
                eq: responseUser.data.getUser.currentSiteId,
              },
            },
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };

        const resSiteRoles = await fetchSiteRoles(filterSiteRole);
        roleLevel = resSiteRoles[0].roleLevel;
      }

      this.setState({
        accessLevel: roleLevel,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchUsers() {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseDevice = await API.graphql(
        graphqlOperation(getSiteGate, {
          id: this.props.location.state.id,
        })
      );
      this.setState({
        deviceCode: responseDevice.data.getSiteGate.id,
        siteName: responseDevice.data.getSiteGate.siteName,
        siteCode: responseDevice.data.getSiteGate.siteCode,
        contractCompanyCode:
          responseDevice.data.getSiteGate.contractCompanyCode,
        deviceName: responseDevice.data.getSiteGate.deviceName,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });

      const filterSiteList = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
        ],
      };

      const resSites = await fetchSites(filterSiteList);
      this.setState({
        siteList: resSites,
      });
      this.setState({
        siteIndex: this.state.siteList.findIndex((sites) => {
          return sites.id === this.state.siteCode;
        }),
      });
    } catch (error) {
      console.log(error);
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  render() {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 480,
        },
      },
    };

    if (
      (this.state.isLoading === true) |
      (this.state.accessLevel === 1) |
      (this.state.accessLevel === 2) |
      (this.state.accessLevel === 4)
    ) {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() =>
                  this.props.navigate("/select-device", {
                    state: {
                      siteCode: this.state.userDetails.currentSiteId,
                    },
                  })
                }
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.state.userDetails.currentSiteName}：顔認証端末の更新
              </Typography>
              {this.state.contractCompanyCode && this.state.deviceName && (
                <Button
                  type="submit"
                  onClick={this.handleSubmit}
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#383838",
                    backgroundColor: "#EBE91A",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    // marginBottom: 5,
                    justifyContent: "space-between",
                    mr: 1,
                    "&:hover": {
                      backgroundColor: "#EBE91A",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-on.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  更新する
                </Button>
              )}
              {Boolean(
                !this.state.contractCompanyCode | !this.state.deviceName
              ) && (
                <Button
                  // type="submit"
                  // onClick={this.handleSubmit}
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#C8C8C8",
                    backgroundColor: "#E5E5E5",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    // marginBottom: 5,
                    justifyContent: "space-between",
                    mr: 1,
                    "&:hover": {
                      backgroundColor: "#E5E5E5",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-gray.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  更新する
                </Button>
              )}
              <Button
                type="submit"
                onClick={() =>
                  this.props.navigate("/select-device", {
                    state: {
                      siteCode: this.state.userDetails.currentSiteId,
                    },
                  })
                }
                variant="outlined"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#383838",
                  backgroundColor: "#F9F9F9",
                  borderColor: "#C8C8C8",
                  borderWidth: 1,
                  height: 40,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  "&:hover": {
                    backgroundColor: "#F9F9F9",
                  },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/remove-on.png")}
                    width="20"
                    height="20"
                  />
                }
                disableElevation
              >
                キャンセル
              </Button>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationDrawer topIndex={3} bottomIndex={null} />
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 3,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Grid item xs={12} mb={2}>
              <Toolbar />
              <Box component="form" sx={{ width: 480 }}>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    契約企業コード
                  </Typography>
                </Stack>
                <TextField
                  id="contractCompanyCode"
                  name="contractCompanyCode"
                  type="text"
                  value={this.state.contractCompanyCode}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  autoFocus
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    現場コード
                  </Typography>
                </Stack>
                <TextField
                  id="siteCode"
                  name="siteCode"
                  type="text"
                  value={this.state.siteCode}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  autoFocus
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    デバイスコード
                  </Typography>
                </Stack>
                <TextField
                  id="deviceCode"
                  placeholder="デバイスコード"
                  name="deviceCode"
                  type="text"
                  value={this.state.deviceCode}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  autoFocus
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />

                {/* <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Regular',
                    fontSize: 15,
                    fontWeight: '400',
                    color: '#383838',
                    mb: 0,
                    mr: 1,
                  }}
                >
                  現場名
                </Typography>
                <RequiredField />
              </Stack>
              <div>
                <FormControl sx={{ m: 1, width: 480, mt: 3 }}>
                  <Select
                    labelId="sitename-select-label"
                    id="sitename-select"
                    value={this.state.siteList.findIndex(
                      (sites) => sites.id === this.state.siteCode,
                    )}
                    label="現場名"
                    onChange={this.handleOnChange}
                    defaultValue={this.state.siteList.findIndex(
                      (sites) => sites.id === this.state.siteCode,
                    )}
                    MenuProps={MenuProps}
                  >
                    {this.state.siteList.map((obj, index) => (
                      <MenuItem key={index} value={index}>
                        {obj.siteName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                    </div> 
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Regular',
                    fontSize: 15,
                    fontWeight: '400',
                    color: '#383838',
                    mb: 0,
                    mr: 1,
                  }}
                >
                  現場コード
                </Typography>
                <RequiredField />
              </Stack>
              <div>
                <FormControl sx={{ m: 1, width: 480, mt: 3 }}>
                  <Select
                    labelId="sitecode-select-label"
                    id="sitecode-select"
                    value={this.state.siteList.findIndex(
                      (sites) => sites.id === this.state.siteCode,
                    )}
                    label="現場コード"
                    onChange={this.handleOnChange}
                    defaultValue={this.state.siteList.findIndex(
                      (sites) => sites.id === this.state.siteCode,
                    )}
                    MenuProps={MenuProps}
                  >
                    {this.state.siteList.map((obj, index) => (
                      <MenuItem key={index} value={index}>
                        {obj.id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div> */}
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    ゲート名
                  </Typography>
                  <RequiredField />
                </Stack>
                <TextField
                  id="deviceName"
                  placeholder="ゲート名"
                  name="deviceName"
                  type="text"
                  value={this.state.deviceName}
                  onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  autoFocus
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
              </Box>
            </Grid>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/select-device")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                component="div"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.state.userDetails.currentSiteName}：顔認証端末の更新
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationDrawer topIndex={3} bottomIndex={null} />
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 0,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Grid item xs={12} mb={2}>
              <Toolbar />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mb: 0,
                  mr: 1,
                }}
              >
                アクセス権限がありません
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  }
}

function EditDeviceScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default EditDeviceScreen;
