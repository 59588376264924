import * as React from "react";
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import NavigationDrawerCustom from "../components/NavigationDrawerCustom";
import RequiredField from "../components/RequiredField";
import LoadingProgress from "../components/LoadingProgress";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Stack from "@mui/material/Stack";

const drawerWidth = 280;

class ChangePasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authState: this.props.authState,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      showPassword: false,
      isLoading: false,
      oldPasswordError: null,
      passwordError: null,
      passwordLengthError: null,
    };
    this.handleOldPasswordChange = this.handleOldPasswordChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.changePassword();
  }

  handleClickShowPassword(event) {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleMouseDownPassword(event) {
    event.preventDefault();
  }

  handleOldPasswordChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      oldPasswordError: null,
    });
  }

  handlePasswordChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if (event.target.value == this.state.newPassword) {
      this.setState({
        passwordError: null,
      });
    } else {
      this.setState({
        passwordError: "パスワードが一致しません。もう一度入力してください。",
      });
    }

    if (event.target.value.length >= 8) {
      this.setState({
        passwordLengthError: null,
      });
    } else {
      this.setState({
        passwordLengthError: "パスワードは8文字以上が必須です。",
      });
    }
  }

  async changePassword() {
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          this.state.oldPassword,
          this.state.newPassword
        );
      })
      .then((data) => console.log(data))
      .then((user) => this.props.navigate("/my-account"))
      .catch((err) => {
        console.log(err);
        if (err.code === "NotAuthorizedException") {
          this.setState({
            isLoading: false,
            oldPasswordError: "入力されたパスワードが間違っています",
          });
        }
      });
  }

  render() {
    const drawerList = ["作業中現場一覧"];

    const navigationList = ["/select-site"];

    return (
      <Box sx={{ display: "flex" }}>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/my-account")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                パスワードの更新
              </Typography>
              {Boolean(
                !this.state.oldPassword |
                  !this.state.newPassword |
                  !this.state.confirmPassword |
                  !!this.state.passwordError |
                  !!this.state.passwordLengthError
              ) && (
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#C8C8C8",
                    backgroundColor: "#E5E5E5",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: "#E5E5E5",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-gray.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  更新する
                </Button>
              )}
              {Boolean(
                this.state.oldPassword &&
                  this.state.newPassword &&
                  this.state.confirmPassword &&
                  !this.state.passwordError &&
                  !this.state.passwordLengthError
              ) && (
                <Button
                  type="submit"
                  onClick={this.handleSubmit}
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#383838",
                    backgroundColor: "#EBE91A",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    mr: 1,
                    "&:hover": {
                      backgroundColor: "#EBE91A",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-on.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  更新する
                </Button>
              )}
            </Toolbar>
          </AppBar>
        </Box>
        <NavigationDrawerCustom
          topIndex={null}
          bottomIndex={null}
          drawerList={drawerList}
          navigationList={navigationList}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#F9F9F9",
            height: "100%",
            minHeight: "100vh",
            p: 3,
          }}
        >
          <LoadingProgress isLoading={this.state.isLoading} />
          <Grid item xs={12} mb={2}>
            <Toolbar />
            <Box component="form" sx={{ width: 480 }}>
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  古いパスワード
                </Typography>
                <RequiredField />
              </Stack>
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 12,
                  fontWeight: "500",
                  color: "#DD3900",
                  mb: 0,
                }}
              >
                {this.state.oldPasswordError}
              </Typography>
              <FormControl
                fullWidth
                variant="standard"
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              >
                <Input
                  id="oldPassword"
                  placeholder="古いパスワード"
                  name="oldPassword"
                  type={this.state.showPassword ? "text" : "password"}
                  value={this.state.oldPassword}
                  onChange={this.handleOldPasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  新しいパスワード（8文字以上、半角）
                </Typography>
                <RequiredField />
              </Stack>
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 12,
                  fontWeight: "500",
                  color: "#DD3900",
                  mb: 0,
                }}
              >
                {this.state.passwordError}
              </Typography>
              <FormControl
                fullWidth
                variant="standard"
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              >
                <Input
                  id="newPassword"
                  placeholder="新しいパスワード"
                  name="newPassword"
                  type={this.state.showPassword ? "text" : "password"}
                  value={this.state.newPassword}
                  onChange={this.handlePasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  パスワード再確認
                </Typography>
                <RequiredField />
              </Stack>
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 12,
                  fontWeight: "500",
                  color: "#DD3900",
                  mb: 0,
                }}
              >
                {this.state.passwordError}
              </Typography>
              <FormControl
                fullWidth
                variant="standard"
                sx={{
                  marginTop: 1,
                  marginBottom: 5,
                }}
              >
                <Input
                  id="confirmPassword"
                  placeholder="パスワード再確認"
                  name="confirmPassword"
                  type={this.state.showPassword ? "text" : "password"}
                  value={this.state.confirmPassword}
                  onChange={this.handlePasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }
}

function ChangePasswordScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <ChangePasswordComponent
      {...props}
      navigate={navigate}
      location={location}
    />
  );
}

export default ChangePasswordScreen;
