import { API, graphqlOperation } from "aws-amplify";
import { getSite } from "../../graphql/queries";

export default async function fetchSite(condition) {
  try {
    const response = await API.graphql(graphqlOperation(getSite, condition));
    return response.data.getSite;
  } catch (error) {
    console.log(error);
  }
}
