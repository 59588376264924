import React from "react";
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import RequiredField from "../components/RequiredField";
import LoadingProgress from "../components/LoadingProgress";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Stack from "@mui/material/Stack";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    background: {
      default: "#E5E5E5",
    },
  },
});

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: "",
      username: "",
      password: "",
      confirmPassword: "",
      lastName: "",
      firstName: "",
      phoneNumberBefore: "",
      phoneNumber: "",
      showPassword: false,
      emailError: null,
      passwordError: null,
      passwordLengthError: null,
      phoneNumberError: null,
      usernameExistsErorr: null,
      isLoading: false,
      isInvited: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const url = window.location.href.split("/");
    this.setState({
      isInvited: !!url[4],
      companyId: url[7],
      firstName: url[4] ? decodeURI(url[4]) : "",
      lastName: url[5] ? decodeURI(url[5]) : "",
      username: url[6],
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleEmailChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      usernameExistsError: null,
    });

    if (/\S+@\S+\.\S+/.test(event.target.value)) {
      this.setState({
        emailError: null,
      });
    } else {
      this.setState({
        emailError: "不正な形式です。",
      });
    }
  }

  handlePasswordChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if (event.target.value == this.state.password) {
      this.setState({
        passwordError: null,
      });
    } else {
      this.setState({
        passwordError: "パスワードが一致しません。もう一度入力してください。",
      });
    }

    if (event.target.value.length >= 8) {
      this.setState({
        passwordLengthError: null,
      });
    } else {
      this.setState({
        passwordLengthError: "パスワードは8文字以上が必須です。",
      });
    }
  }

  handlePhoneNumberChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const jpPattern = /^0[789]0?\d{4}?\d{4}/g;
    this.setState({
      [name]: value,
    });

    if (
      jpPattern.test(event.target.value) &&
      event.target.value.length === 11
    ) {
      const removeZero = "+81" + (event.target.value - 0);
      this.setState({
        phoneNumberError: null,
        phoneNumber: removeZero,
      });
    } else {
      this.setState({
        phoneNumberError:
          "不正な形式です。08012345678の形式で入力してください。",
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.signUp();
  }

  handleClickShowPassword(event) {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleMouseDownPassword(event) {
    event.preventDefault();
  }

  async signUp() {
    try {
      this.setState({
        isLoading: true,
      });

      await Auth.signUp({
        username: this.state.username,
        password: this.state.password,
        attributes: {
          email: this.state.email,
          family_name: this.state.lastName,
          given_name: this.state.firstName,
          phone_number: this.state.phoneNumber,
          "custom:company_id": this.state.companyId,
        },
      });

      this.setState({
        isLoading: false,
      });

      this.props.navigate("/user-verification", {
        state: {
          username: this.state.username,
        },
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error.code === "UsernameExistsException") {
        this.setState({
          usernameExistsError: "既に登録済みのメールアドレスです",
        });
      }
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <Box
              sx={{
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 4,
                paddingRight: 4,
                display: "flex",
                background: "#ffffff",
                flexDirection: "column",
              }}
            >
              <LoadingProgress isLoading={this.state.isLoading} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={require("../assets/images/sagaseru-logo.png")}
                  width="101"
                  height="24"
                />
                <Typography
                  component="div"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 28,
                    fontWeight: "700",
                    color: "#383838",
                    marginBottom: 3,
                  }}
                >
                  ユーザーの登録
                </Typography>
              </Box>
              <Box component="form" sx={{ width: 328 }}>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    契約企業コード
                  </Typography>
                  <RequiredField />
                </Stack>
                <TextField
                  id="companyId"
                  placeholder="契約企業コード"
                  name="companyId"
                  type="text"
                  value={this.state.companyId}
                  onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  autoFocus
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    メールアドレス
                  </Typography>
                  <RequiredField />
                </Stack>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#DD3900",
                    mb: 0,
                  }}
                >
                  {this.state.emailError}
                  {this.state.usernameExistsError}
                </Typography>
                <TextField
                  id="username"
                  placeholder="メールアドレス"
                  name="username"
                  type="text"
                  value={this.state.username}
                  onChange={this.handleEmailChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  autoComplete="email"
                  // autoFocus
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    パスワード（8文字以上、半角）
                  </Typography>
                  <RequiredField />
                </Stack>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#DD3900",
                    mb: 0,
                  }}
                >
                  {this.state.passwordLengthError}
                </Typography>
                <FormControl
                  fullWidth
                  variant="standard"
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                >
                  <Input
                    id="password"
                    placeholder="パスワード"
                    name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    パスワード再確認
                  </Typography>
                  <RequiredField />
                </Stack>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#DD3900",
                    mb: 0,
                  }}
                >
                  {this.state.passwordError}
                </Typography>
                <FormControl
                  fullWidth
                  variant="standard"
                  sx={{
                    marginTop: 1,
                    marginBottom: 5,
                  }}
                >
                  <Input
                    id="confirmPassword"
                    placeholder="パスワード"
                    name="confirmPassword"
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.state.confirmPassword}
                    onChange={this.handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    姓
                  </Typography>
                  <RequiredField />
                </Stack>
                <TextField
                  id="lastName"
                  placeholder="姓"
                  name="lastName"
                  type="text"
                  value={this.state.lastName}
                  onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  // autoFocus
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    名
                  </Typography>
                  <RequiredField />
                </Stack>
                <TextField
                  id="firstName"
                  placeholder="名"
                  name="firstName"
                  type="text"
                  value={this.state.firstName}
                  onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  // autoFocus
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#383838",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    携帯電話番号（ハイフン無し）
                  </Typography>
                  <RequiredField />
                </Stack>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#DD3900",
                    mb: 0,
                  }}
                >
                  {this.state.phoneNumberError}
                </Typography>
                <TextField
                  id="phoneNumberBefore"
                  placeholder="電話番号"
                  name="phoneNumberBefore"
                  type="text"
                  value={this.state.phoneNumberBefore}
                  onChange={this.handlePhoneNumberChange}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  // autoFocus
                  sx={{
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                />
              </Box>
              {Boolean(
                !this.state.companyId |
                  !this.state.username |
                  !this.state.password |
                  !this.state.confirmPassword |
                  !this.state.lastName |
                  !this.state.firstName |
                  !this.state.phoneNumber |
                  this.state.emailError |
                  this.state.passwordError |
                  this.state.passwordLengthError |
                  this.state.phoneNumberError
              ) && (
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#C8C8C8",
                    backgroundColor: "#E5E5E5",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    marginBottom: 5,
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: "#E5E5E5",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-gray.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  登録する
                </Button>
              )}
              {this.state.companyId &&
                this.state.username &&
                this.state.password &&
                this.state.confirmPassword &&
                this.state.lastName &&
                this.state.firstName &&
                this.state.phoneNumber &&
                !this.state.emailError &&
                !this.state.passwordError &&
                !this.state.passwordLengthError &&
                !this.state.phoneNumberError && (
                  <Button
                    type="submit"
                    onClick={this.handleSubmit}
                    fullWidth
                    variant="contained"
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 16,
                      fontWeight: "700",
                      color: "#383838",
                      backgroundColor: "#EBE91A",
                      height: 48,
                      borderRadius: 100,
                      paddingHorizontal: 18,
                      marginBottom: 5,
                      justifyContent: "space-between",
                      "&:hover": {
                        backgroundColor: "#EBE91A",
                      },
                    }}
                    endIcon={
                      <img
                        src={require("../assets/images/rightarrow-on.png")}
                        width="20"
                        height="20"
                      />
                    }
                    disableElevation
                  >
                    登録する
                  </Button>
                )}
              <Button
                onClick={() => this.props.navigate("/sign-in")}
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "700",
                  color: "#383838",
                  paddingLeft: 0,
                  "&:hover": {
                    backgroundColor: "#F9F9F9",
                  },
                }}
              >
                ログイン画面に戻る
              </Button>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

function SignUpScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default SignUpScreen;
