export const customCompanyVendor = `
  query ListCompanyVendors(
    $companyId: ID
    $vendorId: ModelIDKeyConditionInput
    $filter: ModelCompanyVendorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyVendors(
      companyId: $companyId
      vendorId: $vendorId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        companyId
        vendorId
        createdAt
        updatedAt
        vendor {
          vendorCode
          vendorName
          isActive
        }
      }
      nextToken
    }
  }
`;

export const siteLogHistoryBySiteLogIdWithSiteLog = `
  query SiteLogHistoryBySiteLogId(
    $siteLogId: ID!
    $filter: ModelSiteLogHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    siteLogHistoryBySiteLogId(
      siteLogId: $siteLogId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        siteLogId
        siteLog {
          id
          usedAt
          authType
          companyName
          siteName
          enteredSiteLogId
        }
        usedAt
        preUsedAt
        editor
        email
        action
        createdAt
      }
      nextToken
    }
  }
`;
