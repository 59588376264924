import { API, graphqlOperation } from "aws-amplify";
import { siteLogHistoryBySiteLogIdWithSiteLog } from "./customQueries";

export default async function fetchSiteLogHistoryBySiteLogIdWithSiteLog({
  filter,
  siteLogId,
  limit = 500,
  sortDirection = "ASC",
}) {
  try {
    const siteLogHistoryList = [];
    let response;
    response = await API.graphql(
      graphqlOperation(siteLogHistoryBySiteLogIdWithSiteLog, {
        filter,
        siteLogId,
        limit,
        sortDirection,
      })
    );

    siteLogHistoryList.push(...response.data.siteLogHistoryBySiteLogId.items);
    while (response.data.siteLogHistoryBySiteLogId.nextToken !== null) {
      response = await API.graphql(
        graphqlOperation(siteLogHistoryBySiteLogIdWithSiteLog, {
          filter,
          siteLogId,
          limit,
          nextToken: response.data.siteLogHistoryBySiteLogId.nextToken,
        })
      );
      siteLogHistoryList.push(...response.data.siteLogHistoryBySiteLogId.items);
    }
    return siteLogHistoryList;
  } catch (error) {
    console.log(error);
  }
}
