import React, { useState, useRef } from "react";
import { Auth } from "aws-amplify";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import advancedFormat from "dayjs/plugin/advancedFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import updateSiteLogWrapper from "./mutations/UpdateSiteLogWrapper";
import updateEnterExitHistoryWrapper from "./mutations/UpdateEnterExitHistoryWrapper";
import createSiteLogWrapper from "./mutations/CreateSiteLogWrapper";
import createSiteLogHistoryWrapper from "./mutations/CreateSiteLogHistoryWrapper";
import fetchEnterExitHistorys from "./queries/FetchEnterExitHistorys";
import fetchExitedSiteLogsByUpdatedAt from "./queries/FetchExitedSiteLogsByUpdatedAt";
import fetchSiteCodeByFreeidId from "./queries/FetchSiteCodeByFreeidId";
import { ERROR_MESSAGES } from "./utils/constants";

const TimestampEditor = (props) => {
  dayjs.extend(advancedFormat);
  dayjs.extend(localizedFormat);
  dayjs.extend(timezone);
  dayjs.locale("ja");
  const now = new Date();
  const { setSnackbar, id, value, api, field, row } = props;
  const [currentTime, setCurrentTime] = useState(new Date(value || now));
  const [preTime, setPreTime] = useState(new Date(value || now));
  const [openDialog, setOpenDialog] = useState(false);
  const inputRef = useRef(null);

  async function getUserInfo() {
    const userInfo = await Auth.currentAuthenticatedUser();
    const fullName = `${userInfo.attributes.family_name} ${userInfo.attributes.given_name}`;
    const email = userInfo.attributes.email;

    return {
      fullName,
      email,
    };
  }

  const setApi = (isoJp) => {
    api.setEditCellValue({ id, field, value: isoJp }, props.commitParams);
    api.commitCellChange({ id, field });
    api.setCellMode(id, field, "view");
  };

  const converterToIso = (time = new Date()) => {
    return dayjs(time).format();
  };

  const isoToDisplay = (isoStr) => {
    return dayjs(isoStr).format("YYYY-MM-DD HH:mm:ss");
  };

  const formatToIsoJp = () => {
    const isoJp = dayjs(currentTime).format();
    const displayTime = isoToDisplay(isoJp);
    setApi(isoJp);
    setCurrentTime(displayTime);
    return isoJp;
  };

  const isoNow = converterToIso();

  const handleEnterExitHistory = async ({
    crudType,
    entryOrExit,
    enteredSiteLogId,
    exitedAt,
    latestEnteredPreUsedAt,
    latestEnteredUsedAt,
    latestEnteredCreatedAt,
    latestEnteredEditor,
    latestEnteredEmail,
    latestExitedPreUsedAt,
    latestExitedUsedAt,
    latestExitedCreatedAt,
    latestExitedEditor,
    latestExitedEmail,
    usedAt,
  }) => {
    const filter = {
      enteredSiteLogId: {
        eq: enteredSiteLogId,
      },
    };
    const res = await fetchEnterExitHistorys(filter, 1);
    if (entryOrExit === "entry") {
      const input = {
        id: res[0].id,
        latestEnteredPreUsedAt,
        latestEnteredUsedAt,
        latestEnteredCreatedAt,
        latestEnteredEditor,
        latestEnteredEmail,
        usedAt,
      };
      updateEnterExitHistoryWrapper(input);
    } else if (entryOrExit === "exit") {
      if (crudType === "create") {
        const input = {
          id: res[0].id,
          exited: true,
          exitedAt,
          latestExitedUsedAt,
          latestExitedCreatedAt,
          latestExitedEditor,
          latestExitedEmail,
        };
        updateEnterExitHistoryWrapper(input);
      } else if (crudType === "update") {
        if (res.length === 1) {
          const input = {
            id: res[0].id,
            exited: true,
            exitedAt,
            latestExitedPreUsedAt,
            latestExitedUsedAt,
            latestExitedCreatedAt,
            latestExitedEditor,
            latestExitedEmail,
          };
          updateEnterExitHistoryWrapper(input);
        }
      }
    }
  };

  const createSiteLogHistory = async (
    preEditedAt,
    editedAt,
    siteLogId,
    action,
    authType
  ) => {
    const { fullName, email } = await getUserInfo();

    const siteLogHistoryInput = {
      action: action,
      authType: authType,
      email: email,
      editor: fullName,
      preUsedAt: preEditedAt,
      siteLogId: siteLogId,
      usedAt: editedAt,
    };
    await createSiteLogHistoryWrapper(siteLogHistoryInput);
  };

  const modifyNotExistingExitedAt = async (isoJp) => {
    updateEntrySiteLog(isoJp);
    createExitedSiteLog(isoJp);
  };

  const updateEntrySiteLog = async (exitedAt) => {
    const entryRecordInput = {
      siteCode: row.siteCode,
      companyId: row.companyId,
      deviceCode: row.deviceCode,
      deviceName: row.deviceName,
      exitDeviceCode: row.deviceCode,
      exitDeviceName: row.deviceName,
      exitedAt: exitedAt,
      exited: true,
      id: row.id,
    };
    await updateSiteLogWrapper(entryRecordInput);
  };

  const createExitedSiteLog = async (usedAt) => {
    const exitedRecordInput = {
      authType: "退場",
      companyName: row.companyName,
      companyId: row.companyId,
      deviceCode: row.deviceCode,
      deviceName: row.deviceName,
      enteredSiteLogId: row.id,
      familyName: row.familyName,
      firstName: row.firstName,
      freeidId: row.freeidId,
      siteCode: row.siteCode,
      thermometryInfo: "0.0",
      usedAt: usedAt,
    };
    const res = await createSiteLogWrapper(exitedRecordInput);
    await createSiteLogHistory(
      "",
      usedAt,
      res.data.createSiteLog.id,
      "create",
      "退場"
    );

    const { fullName, email } = await getUserInfo();

    handleEnterExitHistory({
      crudType: "create",
      entryOrExit: "exit",
      enteredSiteLogId: row.id,
      exitedAt: usedAt,
      latestExitedPreUsedAt: usedAt,
      latestExitedUsedAt: usedAt,
      latestExitedCreatedAt: isoNow,
      latestExitedEditor: fullName,
      latestExitedEmail: email,
    });
  };

  const modifyExistingExitedAt = async (isoJp) => {
    const typeExited = await fetchExitedSiteLogsByUpdatedAt({
      enteredSiteLogId: row.id,
      limit: 1,
      sortDirection: "DESC",
    });
    if (typeExited.length > 0) {
      updateExitedSiteAndHistory(isoJp, typeExited);
    }

    const entryRecordInput = {
      id: row.id,
      exitedAt: isoJp,
      siteCode: row.siteCode,
      deviceCode: row.deviceCode,
      companyId: row.companyId,
    };
    await updateSiteLogWrapper(entryRecordInput);
    const { fullName, email } = await getUserInfo();

    handleEnterExitHistory({
      crudType: "update",
      entryOrExit: "exit",
      enteredSiteLogId: row.id,
      exitedAt: isoJp,
      latestExitedPreUsedAt: row.exitedAt,
      latestExitedUsedAt: isoJp,
      latestExitedCreatedAt: isoNow,
      latestExitedEditor: fullName,
      latestExitedEmail: email,
    });
  };

  const updateExitedSiteAndHistory = async (isoJp, typeExited) => {
    const exitedRecordInput = {
      companyId: row.companyId,
      deviceCode: row.deviceCode,
      deviceName: row.deviceName,
      id: typeExited[0].id,
      siteCode: row.siteCode,
      usedAt: isoJp,
    };
    updateSiteLogWrapper(exitedRecordInput);
    createSiteLogHistory(
      row.exitedAt,
      isoJp,
      typeExited[0].id,
      "update",
      "退場"
    );
  };

  const modifyExistingUsedAt = async (preEditedAt, editedAt) => {
    const entryRecordInput = {
      companyId: row.companyId,
      deviceCode: row.deviceCode,
      exitDeviceCode: row.deviceCode,
      exitDeviceName: row.deviceName,
      id: row.id,
      siteCode: row.siteCode,
      usedAt: editedAt,
    };
    const res = await updateSiteLogWrapper(entryRecordInput);
    await createSiteLogHistory(
      preEditedAt,
      editedAt,
      res.data.updateSiteLog.id,
      "update",
      "入場"
    );
    const { fullName, email } = await getUserInfo();

    handleEnterExitHistory({
      crudType: "update",
      entryOrExit: "entry",
      enteredSiteLogId: row.id,
      latestEnteredPreUsedAt: row.usedAt,
      latestEnteredUsedAt: editedAt,
      latestEnteredCreatedAt: isoNow,
      latestEnteredEditor: fullName,
      latestEnteredEmail: email,
      usedAt: editedAt,
    });
  };

  const swapFromTo = (from, to, filter) => {
    if (to < from) {
      [from, to] = [to, from];
    }
    filter.and.push({
      usedAt: {
        between: [from, to],
      },
    });
    return filter;
  };

  const doYes = async () => {
    const isoJp = formatToIsoJp();
    switch (field) {
      case "usedAt":
        {
          const isoNow = converterToIso(new Date());
          const isoUsedAt = converterToIso(row.usedAt);
          let filter = {
            and: [
              {
                authType: {
                  eq: "退場",
                },
              },
              {
                enteredSiteLogId: {
                  ne: row.id,
                },
              },
            ],
          };
          filter = swapFromTo(isoUsedAt, isoJp, filter);
          const pastExited = await fetchSiteCodeByFreeidId({
            siteCode: row.siteCode,
            freeidId: {
              eq: row.freeidId,
            },
            filter: filter,
          });
          if (pastExited.length > 0) {
            setSnackbar({
              children: ERROR_MESSAGES.entryAfterPreExit,
              severity: "error",
            });
            break;
          }
          const selfRecordFilter = {
            and: [
              {
                authType: {
                  eq: "退場",
                },
              },
              {
                enteredSiteLogId: {
                  eq: row.id,
                },
              },
            ],
          };
          const selfRecord = await fetchSiteCodeByFreeidId({
            siteCode: row.siteCode,
            freeidId: {
              eq: row.freeidId,
            },
            filter: selfRecordFilter,
          });
          if (isoJp > selfRecord[0]?.usedAt) {
            setSnackbar({
              children: ERROR_MESSAGES.entryBeforeTheExit,
              severity: "error",
            });
            break;
          }
          if (isoJp > isoNow) {
            setSnackbar({
              children: ERROR_MESSAGES.entryBeforeFuture,
              severity: "error",
            });
            break;
          }
        }
        modifyExistingUsedAt(row.usedAt, isoJp);
        break;
      case "exitedAt":
        {
          const isoNow = converterToIso(new Date());
          const isoUsedAt = converterToIso(row.usedAt);
          let filter = {
            and: [
              {
                authType: {
                  eq: "入場",
                },
              },
              {
                id: {
                  ne: row.id,
                },
              },
            ],
          };
          filter = swapFromTo(isoJp, isoUsedAt, filter);
          const futureEntry = await fetchSiteCodeByFreeidId({
            siteCode: row.siteCode,
            freeidId: {
              eq: row.freeidId,
            },
            filter: filter,
          });
          if (futureEntry.length > 0) {
            setSnackbar({
              children: ERROR_MESSAGES.exitBeforeNextEntry,
              severity: "error",
            });
            break;
          }
          if (isoJp > isoNow) {
            setSnackbar({
              children: ERROR_MESSAGES.exitBeforeFuture,
              severity: "error",
            });
            break;
          }
          if (isoUsedAt > isoJp) {
            setSnackbar({
              children: ERROR_MESSAGES.exitAfterTheEntry,
              severity: "error",
            });
            break;
          }
        }
        if (!row[field]) {
          modifyNotExistingExitedAt(isoJp);
        } else {
          modifyExistingExitedAt(isoJp);
        }
        break;
      default:
        break;
    }
    setOpenDialog(false);
  };

  const doNo = () => {
    setCurrentTime(preTime);
    setOpenDialog(false);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleChange = (newTime) => {
    setCurrentTime(newTime);
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={ja}
      localeText={{
        cancelButtonLabel: "キャンセル",
        okButtonLabel: "選択",
      }}
    >
      <MobileDateTimePicker
        ampm={false}
        value={currentTime}
        onChange={handleChange}
        onAccept={handleDialogOpen}
        renderInput={(params) => <TextField {...params} inputRef={inputRef} />}
        toolbarTitle="日付選択"
      />
      <Dialog open={openDialog}>
        <DialogContent>
          編集後日時: {dayjs(currentTime.$d).format("YYYY年MM月DD日 HH:mm:ss")}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => doYes()} color="primary">
            編集する
          </Button>
          <Button onClick={() => doNo()} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default function SiteOverviewTable({ rows, fetchItems }) {
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const columns = [
    {
      field: "name",
      headerName: "名前",
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: true,
      width: 150,
      valueGetter: (params) =>
        `${params.row.familyName || ""} ${params.row.firstName || ""}`,
    },
    {
      field: "companyName",
      headerName: "会社名",
      headerClassName: "super-app-theme--header",
      width: 150,
      editable: false,
    },
    {
      field: "usedAt",
      headerName: "入場時刻",
      headerClassName: "super-app-theme--header",
      width: 200,
      editable: true,
      renderEditCell: (params) => (
        <TimestampEditor setSnackbar={setSnackbar} {...params} />
      ),
      renderCell: (params) => {
        if (params.row.history?.entry) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip
                placement="top-start"
                enterTouchDelay={0}
                title={
                  <>
                    <Typography variant="body2" sx={{ fontSize: 14 }}>
                      {`編集者: ${params.row.history.entry.editor}`}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: 14 }}>
                      {`メールアドレス: ${params.row.history.entry.email}`}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: 14 }}>
                      {`編集日: ${params.row.history.entry.createdAt}`}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: 14 }}>
                      {`編集前時刻: ${params.row.history.entry.preUsedAt}`}
                    </Typography>
                  </>
                }
              >
                <ReportProblemIcon
                  sx={{ marginRight: 1 }}
                  className="orange-icon"
                />
              </Tooltip>
              {format(new Date(params.value), "yyyy-MM-dd HH:mm:ss", {
                timeZone: "Japan",
              })}
            </div>
          );
        }
      },
    },
    {
      field: "exitedAt",
      headerName: "退場時刻",
      headerClassName: "super-app-theme--header",
      width: 200,
      editable: true,
      renderEditCell: (params) => (
        <TimestampEditor setSnackbar={setSnackbar} {...params} />
      ),
      renderCell: (params) => {
        if (params.row.history?.exited) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip
                placement="top-start"
                enterTouchDelay={0}
                title={
                  <>
                    <Typography variant="body2" sx={{ fontSize: 14 }}>
                      {`編集者: ${params.row.history.exited.editor}`}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: 14 }}>
                      {`メールアドレス: ${params.row.history.exited.email}`}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: 14 }}>
                      {`編集日: ${params.row.history.exited.createdAt}`}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: 14 }}>
                      {`編集前時刻: ${params.row.history.exited.preUsedAt}`}
                    </Typography>
                  </>
                }
              >
                <ReportProblemIcon
                  sx={{ marginRight: 1 }}
                  className="orange-icon"
                />
              </Tooltip>
              {params.value
                ? format(new Date(params.value), "yyyy-MM-dd HH:mm:ss", {
                    timeZone: "Japan",
                  })
                : "-"}
            </div>
          );
        }
      },
    },
    {
      field: "exited",
      headerName: "退場",
      headerClassName: "super-app-theme--header",
      width: 150,
      editable: false,
      valueGetter: (params) => {
        if (params.row.exited) {
          return "退場済み";
        } else {
          return "-";
        }
      },
    },
    {
      field: "time",
      headerName: "滞在時間",
      headerClassName: "super-app-theme--header",
      width: 150,
      editable: false,
      valueGetter: (params) => {
        if (params.row.exited) {
          var endTime = new Date(
            new Date(params.row.exitedAt).toLocaleString("ja-JP", {
              timeZone: "Japan",
            })
          );
        } else {
          var endTime = new Date(
            new Date().toLocaleString("ja-JP", { timeZone: "Japan" })
          );
        }

        const startTime = new Date(
          new Date(params.row.usedAt).toLocaleString("ja-JP", {
            timeZone: "Japan",
          })
        );
        return `${Math.round(
          (endTime.getTime() - startTime.getTime()) / 1000 / 60 / 60
        )} 時間`;
      },
    },
    {
      field: "deviceName",
      headerName: "ゲート",
      headerClassName: "super-app-theme--header",
      width: 150,
      editable: false,
    },
    {
      field: "thermometryInfo",
      headerClassName: "super-app-theme--header",
      headerName: "検温",
      width: 150,
      editable: false,
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: "#F9F9F9",
          border: 0,
          pl: 0,
          pt: 1,
          pb: 1,
          fontFamily: "Regular",
          fontSize: 12,
          fontWeight: "400",
          color: "#8D8D8D",
        },
        "& .super-app-theme--rows": {
          backgroundColor: "#F9F9F9",
          borderBottom: 1,
          borderColor: "#E5E5E5",
          pl: 0,
          fontFamily: "Regular",
          fontSize: 15,
          fontWeight: "400",
          color: "#383838",
          "&:hover": {
            backgroundColor: "#E3E9FA",
            borderBottom: 1,
            borderColor: "#99B4E3",
          },
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        onCellEditCommit={fetchItems}
        selectionModel={selectionModel}
        disableSelectionOnClick
        sx={{
          borderTop: 1,
          borderBottom: 0,
          borderRight: 0,
          borderLeft: 0,
          borderRadius: 0,
          borderColor: "#E5E5E5",
        }}
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        getRowClassName={(params) => `super-app-theme--rows`}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        rowsPerPageOptions={[10, 50, 100]}
      />
      {!!snackbar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
}
