import { createCompanyVendor } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";

export default async function createCompanyVendorWrapper(input) {
  try {
    await API.graphql(
      graphqlOperation(createCompanyVendor, {
        input,
      })
    );
  } catch (error) {
    console.log(error);
  }
}
