import { API, graphqlOperation } from "aws-amplify";
import { siteCodeByFreeidId } from "../../graphql/queries";

export default async function fetchSiteCodeByFreeidId({
  filter = null,
  siteCode,
  freeidId,
  limit = 500,
  sortDirection = "ASC",
}) {
  try {
    const siteLogList = [];
    let response;
    response = await API.graphql(
      graphqlOperation(siteCodeByFreeidId, {
        filter,
        siteCode,
        freeidId,
        limit,
        sortDirection,
      })
    );

    siteLogList.push(...response.data.siteCodeByFreeidId.items);
    while (response.data.siteCodeByFreeidId.nextToken !== null) {
      response = await API.graphql(
        graphqlOperation(siteCodeByFreeidId, {
          filter,
          siteCode,
          freeidId,
          limit,
          nextToken: response.data.siteCodeByFreeidId.nextToken,
        })
      );
      siteLogList.push(...response.data.siteCodeByFreeidId.items);
    }
    return siteLogList;
  } catch (error) {
    console.log(error);
  }
}
