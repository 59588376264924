import React from "react";
import { PDFDocument, PageSizes } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import Button from "@mui/material/Button";
import poster from "../assets/images/FreeiD&サガセル_現場掲示チラシ_.pdf";
import SourceHanSansJP from "../assets/font/SourceHanSansJP-Bold.otf";

const LoadPDF = ({ siteName, companyName, qrRef }) => {
  let imageBase64;
  if (qrRef.current) {
    const canvas = qrRef.current.querySelector("canvas");
    imageBase64 = canvas.toDataURL("image/png");
  }

  const modifyPdf = async () => {
    const existingPdfBytes = await fetch(poster).then((res) =>
      res.arrayBuffer()
    );

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    pdfDoc.registerFontkit(fontkit);
    const SourceHanSansJPFont = await fetch(SourceHanSansJP).then((res) =>
      res.arrayBuffer()
    );
    const sourceHanSansJPFont = await pdfDoc.embedFont(SourceHanSansJPFont);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    firstPage.setSize(PageSizes.A3[0], PageSizes.A3[1]);
    const { width, height } = firstPage.getSize();
    firstPage.drawText(`${siteName}`, {
      x: 34,
      y: height - 85,
      size: 22,
      font: sourceHanSansJPFont,
    });

    const pngImageBytes = await fetch(imageBase64).then((res) =>
      res.arrayBuffer()
    );
    const pngImage = await pdfDoc.embedPng(pngImageBytes);
    firstPage.drawImage(pngImage, {
      x: 540,
      y: 510,
      width: 90,
      height: 90,
    });

    firstPage.drawText(`掲示会社名： ${companyName}`, {
      x: width - 300,
      y: height - 40,
      size: 16,
      font: sourceHanSansJPFont,
    });

    const pdfBytes = await pdfDoc.save();
    const docUrl = URL.createObjectURL(
      new Blob([pdfBytes], { type: "application/pdf" })
    );
    const alink = document.createElement("a");
    alink.href = docUrl;
    alink.download = `FreeiD&サガセル_現場掲示チラシ_${siteName}.pdf`;
    alink.click();
  };

  return (
    <Button
      type="submit"
      onClick={modifyPdf}
      variant="contained"
      sx={{
        fontFamily: "Regular",
        fontSize: 16,
        fontWeight: "700",
        color: "#383838",
        backgroundColor: "#EBE91A",
        height: 60,
        borderRadius: 100,
        paddingHorizontal: 18,
        mt: 1,
        mr: 1,
        "&:hover": {
          backgroundColor: "#EBE91A",
        },
      }}
      endIcon={
        <img
          src={require("../assets/images/download-on.png")}
          width="20"
          height="20"
        />
      }
      disableElevation
    >
      ポスター印刷用をダウンロードする
      <br />
      (A3サイズ)
    </Button>
  );
};

export default LoadPDF;
