import React, { useState } from "react";
import LoadingProgress from "./LoadingProgress";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import Box from "@mui/material/Box";

const columns = [
  {
    field: "familyName",
    headerName: "姓",
    headerClassName: "super-app-theme--header",
    editable: false,
    sortable: true,
    width: 150,
  },
  {
    field: "givenName",
    headerName: "名",
    headerClassName: "super-app-theme--header",
    editable: false,
    sortable: true,
    width: 150,
  },
  {
    field: "email",
    headerName: "メールアドレス",
    headerClassName: "super-app-theme--header",
    editable: false,
    sortable: true,
    width: 250,
  },
  {
    field: "phoneNumber",
    headerName: "電話番号",
    headerClassName: "super-app-theme--header",
    editable: false,
    sortable: true,
    width: 150,
  },
  {
    field: "password",
    headerName: "パスワード",
    headerClassName: "super-app-theme--header",
    editable: false,
    sortable: true,
    width: 150,
    valueGetter: (params) => `${"*".repeat(params.row.password?.length) || ""}`,
  },
];

export default function RegisterCompanyUserBulkTable({ rows }) {
  const [selectionModel, setSelectionModel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: "#F9F9F9",
          border: 0,
          pl: 0,
          pt: 1,
          pb: 1,
          fontFamily: "Regular",
          fontSize: 12,
          fontWeight: "400",
          color: "#8D8D8D",
        },
        "& .super-app-theme--rows": {
          backgroundColor: "#F9F9F9",
          borderBottom: 1,
          borderColor: "#E5E5E5",
          pl: 0,
          fontFamily: "Regular",
          fontSize: 15,
          fontWeight: "400",
          color: "#383838",
          "&:hover": {
            backgroundColor: "#E3E9FA",
            borderBottom: 1,
            borderColor: "#99B4E3",
          },
        },
      }}
    >
      <LoadingProgress isLoading={isLoading} />
      {
        <DataGrid
          rows={rows}
          columns={columns}
          selectionModel={selectionModel}
          disableSelectionOnClick
          sx={{
            borderTop: 1,
            borderBottom: 0,
            borderRight: 0,
            borderLeft: 0,
            borderRadius: 0,
            borderColor: "#E5E5E5",
          }}
          getRowClassName={(params) => `super-app-theme--rows`}
          getRowId={(row) => row.email}
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
          rowsPerPageOptions={[10, 50, 100]}
        />
      }
    </Box>
  );
}
