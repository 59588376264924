import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUser, getCompany } from "../graphql/queries";
import fetchCompanyRoles from "./queries/FetchCompanyRoles";
import fetchSiteRoles from "./queries/FetchSiteRoles";
import { DEFAULT_ROLE_LEVEL } from "./utils/constants";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Regular",
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          marginLeft: 8,
          marginTop: -2,
          marginBottom: -2,
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&& .Mui-selected, && .Mui-selected:hover": {
            backgroundColor: "#ECECEC",
            borderRadius: 100,
            marginHorizontal: 16,
            "& .MuiListItemText-primary": {
              fontWeight: "700",
            },
          },
          paddingTop: 8,
          paddingBottom: 0,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {},
          "&:hover": {
            backgroundColor: "#ECECEC",
            borderRadius: 100,
            marginHorizontal: 16,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#E5E5E5",
        },
        middle: {
          marginRight: 38,
          marginLeft: 32,
        },
      },
    },
  },
});

const drawerWidth = 280;

const iconTop = [
  <img src={require("../assets/images/menu-on.png")} width="16" height="16" />,
  <img src={require("../assets/images/site-on.png")} width="16" height="16" />,
  <img src={require("../assets/images/user-on.png")} width="16" height="16" />,
  <img
    src={require("../assets/images/settings-on.png")}
    width="18"
    height="16"
  />,
  <img
    src={require("../assets/images/camera-on.png")}
    width="16"
    height="16"
  />,
  <img
    src={require("../assets/images/qrcode-on.png")}
    width="16"
    height="16"
  />,
];

const iconBottom = [
  <img
    src={require("../assets/images/settings-on.png")}
    width="18"
    height="16"
  />,
  <img src={require("../assets/images/user-on.png")} width="16" height="16" />,
];

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function NavigationDrawer({ topIndex, bottomIndex }) {
  const [drawerList, setDrawerList] = useState(null);
  const [navigationList, setNavigationList] = useState(null);
  const [userInformation, setUserInformation] = useState(null);
  const [profileList, setProfileList] = useState(null);
  const [roleLevel, setRoleLevel] = useState(null);
  const [selectedTopIndex, setSelectedTopIndex] = useState(topIndex);
  const [selectedBottomIndex, setSelectedBottomIndex] = useState(bottomIndex);
  const { height, width } = useWindowDimensions();

  const navigation = useNavigate();

  useEffect(() => {
    getRoles();
  }, []);

  async function getRoles() {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();

      const filterCompanyRole = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      };

      const resCompanyRoles = await fetchCompanyRoles(filterCompanyRole);
      let roleLevel;
      let userData;
      let companyData;
      if (resCompanyRoles[0].roleLevel !== DEFAULT_ROLE_LEVEL) {
        const responseUser = await API.graphql(
          graphqlOperation(getUser, {
            id: userInfo.attributes.sub,
          })
        );
        userData = responseUser.data.getUser;

        const responseCompany = await API.graphql(
          graphqlOperation(getCompany, {
            id: userInfo.attributes["custom:company_id"],
          })
        );
        companyData = responseCompany.data.getCompany;

        roleLevel = resCompanyRoles[0].roleLevel;
      } else {
        const responseUser = await API.graphql(
          graphqlOperation(getUser, {
            id: userInfo.attributes.sub,
          })
        );
        userData = responseUser.data.getUser;

        const responseCompany = await API.graphql(
          graphqlOperation(getCompany, {
            id: userInfo.attributes["custom:company_id"],
          })
        );
        companyData = responseCompany.data.getCompany;

        const filterSiteRole = {
          and: [
            {
              siteCode: {
                eq: responseUser.data.getUser.currentSiteId,
              },
            },
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };

        const resSiteRoles = await fetchSiteRoles(filterSiteRole);
        roleLevel = resSiteRoles[0].roleLevel;
      }

      let drawerList;
      let navigationList;
      let userInformation;
      let profileList;

      if ((roleLevel === 1) | (roleLevel === 2)) {
        drawerList = [
          "現場一覧",
          "現場状況",
          "メンバー管理",
          "顔認証端末",
          "QRコード・現場ポスター",
          "現場の設定",
        ];

        navigationList = [
          "/select-site",
          "/site-overview",
          "/user-list",
          "/select-device",
          "/site-qr-code",
          "/edit-site",
        ];

        userInformation = [
          companyData.companyName,
          userData.lastName + "" + userData.firstName,
        ];

        profileList = ["/edit-company", "/my-account"];
      } else if (roleLevel === 4) {
        drawerList = [
          "現場一覧",
          "現場状況",
          "メンバー管理",
          "顔認証端末",
          "QRコード・現場ポスター",
          "現場の設定",
        ];

        navigationList = [
          "/select-site",
          "/site-overview",
          "/user-list",
          "/select-device",
          "/site-qr-code",
          "/edit-site",
        ];

        userInformation = [userData.lastName + "" + userData.firstName];

        profileList = [];
      } else {
        drawerList = [
          "現場一覧",
          "現場状況",
          "メンバー管理",
          "顔認証端末",
          "QRコード",
        ];

        navigationList = [
          "/select-site",
          "/site-overview",
          "/user-list",
          "/select-device",
          "/site-qr-code",
        ];

        userInformation = [userData.lastName + "" + userData.firstName];

        profileList = ["/my-account"];
      }

      setDrawerList(drawerList);
      setNavigationList(navigationList);
      setUserInformation(userInformation);
      setProfileList(profileList);
      setRoleLevel(roleLevel);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            borderTop: 4,
            borderTopColor: "#EBE91A",
            borderRightColor: "#E5E5E5",
            backgroundColor: "#F9F9F9",
          },
        }}
        variant="permanent"
        open
      >
        <Toolbar>
          <img
            src={require("../assets/images/sagaseru-logo.png")}
            width="76"
            height="18"
          />
        </Toolbar>
        <Divider variant="middle" />
        {drawerList && navigationList && (
          <List disablePadding sx={{ paddingBottom: (height - 625) / 8 }}>
            {drawerList.map((text, index) => (
              <ListItem key={text}>
                <ListItemButton
                  selected={selectedTopIndex === index}
                  onClick={() => {
                    setSelectedTopIndex(index);
                    setSelectedBottomIndex(null);
                    navigation(navigationList[index]);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 35,
                    }}
                  >
                    {iconTop[index]}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    primaryTypographyProps={{
                      fontSize: 16,
                      fontWeight: "500",
                      color: "#383838",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        <Divider variant="middle" />
        {userInformation && (
          <List disablePadding>
            {userInformation.map((text, index) => (
              <ListItem key={text}>
                <ListItemButton
                  selected={selectedBottomIndex === index}
                  onClick={() => {
                    setSelectedTopIndex(null);
                    setSelectedBottomIndex(index);
                    navigation(profileList[index]);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 35,
                      // marginLeft: 2,
                    }}
                  >
                    {iconBottom[index]}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    primaryTypographyProps={{
                      fontSize: 16,
                      fontWeight: "500",
                      color: "#383838",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        <Divider variant="middle" />
        {["ログアウト"] && (
          <List disablePadding>
            {["ログアウト"].map((text, index) => (
              <ListItem key={text}>
                <ListItemButton
                  onClick={() => {
                    Auth.signOut();
                    navigation("/sign-in");
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 35,
                      // marginLeft: 2,
                    }}
                  >
                    {iconBottom[index]}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    primaryTypographyProps={{
                      fontSize: 16,
                      fontWeight: "500",
                      color: "#383838",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Drawer>
    </ThemeProvider>
  );
}
