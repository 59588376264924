import { createVendorHistory } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";

export default async function CreateVendorHistoryWrapper(newRow, oldRow) {
  try {
    let editedColumn;
    if (oldRow.vendorCode !== newRow.vendorCode) {
      editedColumn = "vendorCode";
    } else if (oldRow.vendorName !== newRow.vendorName) {
      editedColumn = "vendorName";
    }
    if (!editedColumn) {
      throw new Error("editedColumnが空です");
    }

    const input = {
      editedColumn,
      editor: `${newRow.userInfo.family_name} ${newRow.userInfo.given_name}`,
      preVendorCode: oldRow.vendorCode,
      preVendorName: oldRow.vendorName,
      vendorCode: newRow.vendorCode,
      vendorId: newRow.id,
      vendorName: newRow.vendorName,
    };

    await API.graphql(
      graphqlOperation(createVendorHistory, {
        input,
      })
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
}
