import * as React from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUser } from "../graphql/queries";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";
import SiteOverviewTable from "../components/SiteOverviewTable";
import NavigationDrawer from "../components/NavigationDrawer";
import DatePicker from "../components/DatePicker";
import LoadingProgress from "../components/LoadingProgress";
import { DEFAULT_ROLE_LEVEL } from "../components/utils/constants";
import fetchSiteLogs from "../components/queries/FetchSiteLogs";
import fetchUser from "../components/queries/FetchUser";
import fetchCompany from "../components/queries/FetchCompany";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";
import fetchSiteRoles from "../components/queries/FetchSiteRoles";
import fetchSiteLogHistoryBySiteLogIdWithSiteLog from "../components/queries/FetchSiteLogHistoryBySiteLogIdWithSiteLog";
import fetchExitedSiteLogsByUpdatedAt from "../components/queries/FetchExitedSiteLogsByUpdatedAt";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

const drawerWidth = 280;

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "80%" },
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: { sm: 4, xs: 1 },
};

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      searchWords: "",
      companyDetails: "",
      dateInput: new Date(
        new Date().toLocaleString("ja-JP", { timeZone: "Japan" })
      ),
      itemList: "",
      itemListNotExited: "",
      onsite: false,
      offsite: false,
      inactive: false,
      onsiteUsers: "",
      enteredUsers: "",
      activeUsers: "",
      accessLevel: "",
      isLoading: false,
      open: false,
      setOpen: false,
      mobileOpen: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    this.fetchItems();
  }

  handleDateChange(event) {
    this.setState({
      dateInput: event,
    });

    this.fetchItems();
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  handleOpen(event) {
    this.setState({
      open: true,
      setOpen: true,
    });
  }

  handleClose(event) {
    this.setState({
      open: false,
      setOpen: false,
    });
  }

  handleDrawerToggle(event) {
    this.setState({
      mobileOpen: !this.state.mobileOpen,
    });
  }

  async componentDidMount() {
    this.fetchItems();
    this.fetchRoles();
  }

  async fetchRoles() {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();

      const filterCompanyRole = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      };

      const resCompanyRole = await fetchCompanyRoles(filterCompanyRole);
      let roleLevel;
      if (resCompanyRole[0].roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = resCompanyRole[0].roleLevel;
      } else {
        const responseUser = await API.graphql(
          graphqlOperation(getUser, {
            id: userInfo.attributes.sub,
          })
        );

        const filterSiteRole = {
          and: [
            {
              siteCode: {
                eq: responseUser.data.getUser.currentSiteId,
              },
            },
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };

        const resSiteRoles = await fetchSiteRoles(filterSiteRole);
        roleLevel = resSiteRoles[0].roleLevel;
      }

      this.setState({
        accessLevel: roleLevel,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchItems() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await fetchUser({
        id: userInfo.attributes.sub,
      });
      this.setState({
        userDetails: responseUser,
      });

      const responseCompany = await fetchCompany({
        id: userInfo.attributes["custom:company_id"],
      });
      this.setState({
        companyDetails: responseCompany,
      });

      const dateStartDayBefore = new Date(
        new Date(
          new Date(this.state.dateInput).toLocaleString("ja-JP", {
            timeZone: "Japan",
          })
        ).setHours(9, 0, 0, 0)
      );

      const dateStartDayBeforeNotExited = new Date(
        new Date(
          new Date("2022-01-01").toLocaleString("ja-JP", {
            timeZone: "Japan",
          })
        ).setHours(9, 0, 0, 0)
      );

      const dateEndFilter = new Date(
        new Date(
          new Date(this.state.dateInput).toLocaleString("ja-JP", {
            timeZone: "Japan",
          })
        ).setHours(9, 0, 0, 0)
      );
      dateEndFilter.setDate(dateEndFilter.getDate() + 1);

      const dateEndFilterNotExited = new Date(
        new Date(
          new Date().toLocaleString("ja-JP", {
            timeZone: "Japan",
          })
        ).setHours(9, 0, 0, 0)
      );
      dateEndFilterNotExited.setDate(dateEndFilterNotExited.getDate());

      const convertToDisplayTime = (time) => {
        const displayTime =
          time && time !== "null"
            ? dayjs(time).format("YYYY-MM-DD HH:mm:ss")
            : "";
        return displayTime;
      };

      const inCaseEntry = async (siteLog, log) => {
        const lastEntryHistory =
          await fetchSiteLogHistoryBySiteLogIdWithSiteLog({
            filter: {
              action: {
                eq: "update",
              },
            },
            siteLogId: siteLog.id,
            limit: 1,
            sortDirection: "DESC",
          });
        if (lastEntryHistory.length === 1) {
          log.history.entry = {
            createdAt: dayjs(lastEntryHistory[0].createdAt).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            editor: lastEntryHistory[0].editor,
            email: lastEntryHistory[0].email,
            preUsedAt: lastEntryHistory[0].preUsedAt,
          };
        }
      };

      const inCaseExited = async (siteLog, log) => {
        const exitedSiteLog = await fetchExitedSiteLogsByUpdatedAt({
          enteredSiteLogId: siteLog.id,
          limit: 1,
          sortDirection: "DESC",
        });
        if (exitedSiteLog.length === 1) {
          const lastExitedHistory =
            await fetchSiteLogHistoryBySiteLogIdWithSiteLog({
              filter: {
                action: {
                  eq: "update",
                },
              },
              siteLogId: exitedSiteLog[0].id,
              limit: 1,
              sortDirection: "DESC",
            });
          if (lastExitedHistory.length === 1) {
            log.history.exited = {
              createdAt: dayjs(lastExitedHistory[0].createdAt).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              editor: lastExitedHistory[0].editor,
              email: lastExitedHistory[0].email,
              preUsedAt: lastExitedHistory[0].preUsedAt,
            };
          }
        }
      };

      const addHistoryToRows = async (listSiteLogs) => {
        const entryExitLogs = await Promise.all(
          listSiteLogs.map(async (siteLog) => {
            const displayUsedAt = convertToDisplayTime(siteLog.usedAt);
            const displayExitedAt = convertToDisplayTime(siteLog.exitedAt);
            const log = {
              ...siteLog,
              usedAt: displayUsedAt,
              exitedAt: displayExitedAt,
              history: {},
            };

            await inCaseEntry(siteLog, log);
            await inCaseExited(siteLog, log);
            return log;
          })
        );
        return entryExitLogs;
      };

      if (this.state.onsite) {
        const filter = {
          and: [
            {
              siteCode: {
                eq: this.state.userDetails.currentSiteId,
              },
            },
            {
              usedAt: {
                between: [dateStartDayBefore, dateEndFilter],
              },
            },
            {
              authType: {
                eq: "入場",
              },
            },
          ],
        };

        const filterNotExited = {
          and: [
            {
              siteCode: {
                eq: this.state.userDetails.currentSiteId,
              },
            },
            {
              usedAt: {
                between: [dateStartDayBeforeNotExited, dateEndFilter],
              },
            },
            {
              authType: {
                eq: "-",
              },
            },
          ],
        };

        const resSiteLogs = await fetchSiteLogs(filter);
        const resNotExitedSiteLogs = await fetchSiteLogs(filterNotExited);

        const transformedItemList = await addHistoryToRows(resSiteLogs);
        this.setState({
          itemList: transformedItemList,
          itemListNotExited: resNotExitedSiteLogs,
        });
      } else if (this.state.offsite) {
        const filter = {
          and: [
            {
              siteCode: {
                eq: this.state.userDetails.currentSiteId,
              },
            },
            {
              usedAt: {
                between: [dateStartDayBefore, dateEndFilter],
              },
            },
            {
              authType: {
                eq: "入場",
              },
            },
          ],
        };

        const resSiteLogs = await fetchSiteLogs(filter);

        const transformedItemList = await addHistoryToRows(resSiteLogs);
        this.setState({
          itemList: transformedItemList,
        });
      } else {
        const filter = {
          and: [
            {
              siteCode: {
                eq: this.state.userDetails.currentSiteId,
              },
            },
            {
              usedAt: {
                between: [dateStartDayBefore, dateEndFilter],
              },
            },
            {
              authType: {
                eq: "入場",
              },
            },
          ],
        };

        const filterNotExited = {
          and: [
            {
              siteCode: {
                eq: this.state.userDetails.currentSiteId,
              },
            },
            {
              usedAt: {
                between: [dateStartDayBeforeNotExited, dateEndFilterNotExited],
              },
            },
            {
              authType: {
                eq: "入場",
              },
            },
            {
              exitedAt: {
                attributeExists: false,
              },
            },
          ],
        };

        const resSiteLogs = await fetchSiteLogs(filter);
        const resNotExitedSiteLogs = await fetchSiteLogs(filterNotExited);
        const transformedItemList = await addHistoryToRows(resSiteLogs);

        this.setState({
          itemList: transformedItemList.sort(function (a, b) {
            if (a.usedAt > b.usedAt) {
              return -1;
            }
            if (a.usedAt < b.usedAt) {
              return 1;
            }
            return 0;
          }),
          itemListNotExited: resNotExitedSiteLogs.sort(function (a, b) {
            if (a.usedAt > b.usedAt) {
              return -1;
            }
            if (a.usedAt < b.usedAt) {
              return 1;
            }
            return 0;
          }),
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  render() {
    const drawerListMobile = ["作業中現場一覧", "現場状況"];
    const navigationListMobile = ["/select-site", "/site-overview"];

    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { md: `calc(100% - ${drawerWidth}px)` },
            ml: { md: `${drawerWidth}px` },
            backgroundColor: "#F9F9F9",
            borderBottom: 1,
            borderColor: "#E5E5E5",
          }}
          elevation={0}
        >
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" }, color: "#383838" }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontSize: 21,
                fontWeight: "700",
                color: "#383838",
                flexGrow: 1,
              }}
            >
              現場状況
            </Typography>
            <Button
              type="submit"
              onClick={() => this.props.navigate("/site-data-export")}
              variant="outlined"
              sx={{
                fontSize: 15,
                fontWeight: "700",
                color: "#383838",
                backgroundColor: "#F9F9F9",
                borderColor: "#C8C8C8",
                borderWidth: 1,
                height: 32,
                borderRadius: 100,
                paddingHorizontal: 18,
                mr: 1,
                "&:hover": {
                  backgroundColor: "#F9F9F9",
                },
              }}
              endIcon={
                <img
                  src={require("../assets/images/download-on.png")}
                  width="18"
                  height="18"
                />
              }
              disableElevation
            >
              出面表を出力
            </Button>
            {Boolean(
              (this.state.accessLevel === 1) |
                (this.state.accessLevel === 2) |
                (this.state.accessLevel === 4)
            ) && (
              <Button
                type="submit"
                onClick={() =>
                  this.props.navigate("/register-device", {
                    state: {
                      siteCode: this.state.userDetails.currentSiteId,
                    },
                  })
                }
                variant="outlined"
                sx={{
                  fontSize: 15,
                  fontWeight: "700",
                  color: "#383838",
                  backgroundColor: "#EBE91A",
                  borderColor: "#EBE91A",
                  borderWidth: 1,
                  height: 32,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  "&:hover": {
                    backgroundColor: "#EBE91A",
                    borderColor: "#EBE91A",
                  },
                  display: { xs: "none", sm: "none", md: "flex" },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/add-on.png")}
                    width="18"
                    height="18"
                  />
                }
                disableElevation
              >
                顔認証端末を登録する
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <div>
              <Toolbar>
                <img
                  src={require("../assets/images/sagaseru-logo.png")}
                  width="76"
                  height="18"
                />
              </Toolbar>
              {drawerListMobile && navigationListMobile && (
                <List disablePadding>
                  {drawerListMobile.map((text, index) => (
                    <ListItem key={text}>
                      <ListItemButton
                        onClick={() => {
                          this.props.navigate(navigationListMobile[index]);
                        }}
                      >
                        <ListItemText
                          primary={text}
                          primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: "500",
                            color: "#383838",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
              <Divider variant="middle" />
              {["ログアウト"] && (
                <List disablePadding>
                  {["ログアウト"].map((text, index) => (
                    <ListItem key={text}>
                      <ListItemButton
                        onClick={() => {
                          Auth.signOut();
                          this.props.navigate("/sign-in");
                        }}
                      >
                        <ListItemText
                          primary={text}
                          primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: "500",
                            color: "#383838",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          </Drawer>
          <NavigationDrawer topIndex={1} bottomIndex={null} />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#F9F9F9",
            height: "100%",
            minHeight: "100vh",
            pt: 4,
            pb: 4,
            pl: 3,
            pr: 3,
          }}
        >
          <LoadingProgress isLoading={this.state.isLoading} />
          <Grid item container xs={12} mb={4}>
            <Toolbar />
            <Grid item xs={12} mb={4}>
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  fontSize: { sm: 28, xs: 21 },
                  fontWeight: "700",
                  color: "#383838",
                  mb: 1,
                }}
              >
                {this.props.location.state?.siteName
                  ? this.props.location.state.siteName
                  : this.state.userDetails.currentSiteName}
              </Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  fontSize: 18,
                  fontWeight: "500",
                  color: "#8D8D8D",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                案件管理番号:{" "}
                {this.props.location.state?.masterCode
                  ? this.props.location.state.masterCode
                  : this.state.userDetails.currentMasterCode}
              </Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  fontSize: 18,
                  fontWeight: "500",
                  color: "#8D8D8D",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                現場コード: {this.state.userDetails.currentSiteId}
              </Typography>
            </Grid>
            <Grid item xs={1.75}>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: "400",
                  color: "#383838",
                }}
              >
                日付
              </Typography>
              <DatePicker
                value={this.state.dateInput}
                onChange={this.handleDateChange}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} mb={2}>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                }}
              >
                現場状況
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Button onClick={this.handleOpen}>退場認証漏れ一覧</Button>
            </Grid>
          </Grid>
          <SiteOverviewTable
            rows={this.state.itemList}
            fetchItems={this.fetchItems}
          />
        </Box>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ padding: { xs: 2, sm: 2 } }}
        >
          <Box sx={style}>
            <Grid item container xs={12}>
              <Grid item xs={10}>
                <Typography
                  sx={{
                    fontSize: 21,
                    fontWeight: "700",
                    color: "#383838",
                  }}
                >
                  退場認証漏れ一覧
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Button onClick={this.handleClose}>閉じる</Button>
              </Grid>
            </Grid>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <SiteOverviewTable rows={this.state.itemListNotExited} />
            </Typography>
          </Box>
        </Modal>
      </Box>
    );
  }
}

function SiteOverviewScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <Input {...props} navigate={navigate} location={location} />;
}

export default SiteOverviewScreen;
