import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import LoadingProgress from "../components/LoadingProgress";
import Box from "@mui/material/Box";

const columns = [
  {
    field: "name",
    headerName: "名前",
    headerClassName: "super-app-theme--header",
    editable: false,
    sortable: true,
    width: 150,
    valueGetter: (params) =>
      `${params.row.familyName || ""} ${params.row.firstName || ""}`,
  },
  {
    field: "email",
    headerName: "メールアドレス",
    headerClassName: "super-app-theme--header",
    width: 250,
    editable: false,
  },
  {
    field: "companyName",
    headerName: "会社",
    headerClassName: "super-app-theme--header",
    width: 150,
    editable: false,
  },
  {
    field: "startDate",
    headerName: "入場開始日",
    headerClassName: "super-app-theme--header",
    width: 150,
    editable: false,
  },
  {
    field: "endDate",
    headerName: "入場終了日",
    headerClassName: "super-app-theme--header",
    width: 150,
    editable: false,
  },
  {
    field: "applyStatus",
    headerName: "ステータス",
    headerClassName: "super-app-theme--header",
    width: 150,
    editable: false,
  },
];

export default function UserListTable({ rows, userDetails, accessLevel }) {
  const [selectionModel, setSelectionModel] = useState([]);
  const [timer, setTimer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();
  const [pageSize, setPageSize] = React.useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(Math.random());
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  const onSubmit = async (newSelectionModel) => {
    try {
      navigation("/user-details", {
        state: {
          id: newSelectionModel.id,
          freeidId: newSelectionModel.row.freeidId,
        },
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: "#F9F9F9",
          border: 0,
          pl: 0,
          pt: 1,
          pb: 1,
          fontFamily: "Regular",
          fontSize: 12,
          fontWeight: "400",
          color: "#8D8D8D",
        },
        "& .super-app-theme--rows": {
          backgroundColor: "#F9F9F9",
          borderBottom: 1,
          borderColor: "#E5E5E5",
          pl: 0,
          fontFamily: "Regular",
          fontSize: 15,
          fontWeight: "400",
          color: "#383838",
          "&:hover": {
            backgroundColor: "#E3E9FA",
            borderBottom: 1,
            borderColor: "#99B4E3",
          },
        },
      }}
    >
      <LoadingProgress isLoading={isLoading} />
      <DataGrid
        rows={rows}
        columns={columns}
        onCellClick={(newSelectionModel) => {
          if (
            (accessLevel === 1) |
            (accessLevel === 2) |
            (accessLevel === 3) |
            (accessLevel === 4)
          ) {
            setSelectionModel(newSelectionModel);
            onSubmit(newSelectionModel);
          }
        }}
        selectionModel={selectionModel}
        disableSelectionOnClick
        sx={{
          borderTop: 1,
          borderBottom: 0,
          borderRight: 0,
          borderLeft: 0,
          borderRadius: 0,
          borderColor: "#E5E5E5",
        }}
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        getRowClassName={(params) => `super-app-theme--rows`}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        rowsPerPageOptions={[10, 50, 100]}
      />
    </Box>
  );
}
