import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Auth } from "aws-amplify";
import createVendorWrapper from "../components/mutations/CreateVendor";
import createCompanyVendorWrapper from "../components/mutations/CreateCompanyVendor";
import fetchUser from "../components/queries/FetchUser";
import fetchCompany from "../components/queries/FetchCompany";
import LoadingProgress from "../components/LoadingProgress";
import RequiredField from "../components/RequiredField";
import NavigationMenu from "../components/NavigationMenu";
import TopBar from "../components/TopBar";
import getRoleLevel from "../components/utils/getRoleLevel";
import makeDrawerList from "../components/utils/makeDrawerList";
import validateVendorCode from "../components/utils/validateVendorCode";
import { DRAWER_WIDTH } from "../components/utils/constants";
import {
  Box,
  Snackbar,
  Alert,
  Button,
  CssBaseline,
  Toolbar,
  Typography,
  Grid,
  TextField,
  Stack,
} from "@mui/material";

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      vendorCode: "",
      vendorName: "",
      isActive: "",
      isLoading: false,
      accessLevel: "",
      drawerList: [],
      navigationList: [],
      snackbar: null,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    const roleLevel = await getRoleLevel();
    const userInfo = await Auth.currentAuthenticatedUser();
    const getUser = await fetchUser({
      id: userInfo.attributes.sub,
    });
    const getCompany = await fetchCompany({
      id: userInfo.attributes["custom:company_id"],
    });
    const [drawerList, navigationList] = await makeDrawerList();

    this.setState({
      userDetails: getUser,
      companyDetails: getCompany,
      accessLevel: roleLevel,
      drawerList,
      navigationList,
      isLoading: false,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleFileChange(event) {
    this.onFileChange(event);
    event.preventDefault();
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({
      isLoading: true,
    });
    const isDuplicate = await validateVendorCode(this.state.vendorCode);
    if (!isDuplicate) {
      const newId = uuidv4();
      const input = {
        id: newId,
        vendorCode: this.state.vendorCode,
        vendorName: this.state.vendorName,
        isActive: true,
      };
      await createVendorWrapper(input);

      const inputCompanyVendor = {
        companyId: this.state.userDetails.companyId,
        vendorId: newId,
      };
      createCompanyVendorWrapper(inputCompanyVendor);
      this.setState({
        isLoading: false,
      });
      this.props.navigate("/vendor-list");
    } else {
      this.setState({
        snackbar: {
          children: "業者コードが重複しています。",
          severity: "error",
        },
      });
      this.setState({
        isLoading: false,
      });
    }
  }

  handleCloseSnackbar = () => this.setState({ snackbar: null });

  render() {
    if (
      (this.state.isLoading === true) |
      (this.state.accessLevel === 1) |
      (this.state.accessLevel === 2)
    ) {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          {this.state.vendorName && this.state.vendorCode ? (
            <TopBar
              topTitle="第1次業者の作成"
              backTo="/vendor-list"
              navigate={this.props.navigate}
            >
              <Button
                type="submit"
                onClick={this.handleSubmit}
                variant="contained"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#383838",
                  backgroundColor: "#EBE91A",
                  height: 32,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  "&:hover": {
                    backgroundColor: "#EBE91A",
                  },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/addcircle-on.png")}
                    width="20"
                    height="20"
                  />
                }
                disableElevation
              >
                作成する
              </Button>
            </TopBar>
          ) : (
            <TopBar
              topTitle="第1次業者の作成"
              backTo="/vendor-list"
              navigate={this.props.navigate}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#C8C8C8",
                  backgroundColor: "#E5E5E5",
                  height: 32,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  "&:hover": {
                    backgroundColor: "#E5E5E5",
                  },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/addcircle-on.png")}
                    width="20"
                    height="20"
                  />
                }
                disableElevation
              >
                作成する
              </Button>
            </TopBar>
          )}
          <Box
            component="nav"
            sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationMenu
              drawerList={this.state.drawerList}
              navigationList={this.state.navigationList}
              topIndex={1}
              bottomIndex={null}
            />
          </Box>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 0,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Toolbar />
            <Box
              component="form"
              sx={{
                width: 451,
                paddingLeft: 3,
                paddingRight: 5,
                paddingTop: 2,
                paddingBottom: 3,
              }}
            >
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  第1次業者コード
                </Typography>
                <RequiredField />
              </Stack>
              <TextField
                id="vendorCode"
                placeholder="第1次業者コード"
                name="vendorCode"
                type="text"
                value={this.state.vendorCode}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  第1次業者名
                </Typography>
                <RequiredField />
              </Stack>
              <TextField
                id="vendorName"
                placeholder="第1次業者名"
                name="vendorName"
                type="text"
                value={this.state.vendorName}
                onChange={this.handleInputChange}
                variant="standard"
                margin="normal"
                fullWidth
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
            </Box>
          </Box>
          {!!this.state.snackbar && (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open
              onClose={this.handleCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert
                {...this.state.snackbar}
                onClose={this.handleCloseSnackbar}
              />
            </Snackbar>
          )}
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <TopBar
            topTitle="第1次業者の作成"
            rightText={"作成する"}
            color="#383838"
            backgroundColor="#E5E5E5"
            hover="#E5E5E5"
          />
          <NavigationMenu topIndex={1} bottomIndex={null} />
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: "#F9F9F9", height: "100vh", p: 0 }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Grid item xs={12} mb={2}>
              <Toolbar />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mb: 0,
                  mr: 1,
                }}
              >
                アクセス権限がありません
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  }
}

function RegisterSiteScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default RegisterSiteScreen;
