import * as React from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUser, getSite, getCompany, listUsers } from "../graphql/queries";
import { createSiteClearance } from "../graphql/mutations";
import { useNavigate, useLocation } from "react-router-dom";
import NavigationDrawer from "../components/NavigationDrawer";
import LoadingProgress from "../components/LoadingProgress";
import RegisterUserBulkTable from "../components/RegisterUserBulkTable";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

const drawerWidth = 280;

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: "",
      userDetails: "",
      companyDetails: "",
      isLoading: false,
      isDateValidationError: false,
      isBlankValidationError: false,
      isEmailValidationError: false,
    };

    this.handleFileSelect = this.handleFileSelect.bind(this);
  }

  parseCsv(data) {
    return data.split("\r\n").map((row) => row.split(","));
  }

  dateFormat(date) {
    if (date) {
      const correctPattern = /\d{4}-(\d{1,2})-(\d{1,2})/;
      const slashPattern = /(\d{4})\/(\d{1,2})\/(\d{1,2})/g;
      const slashUsPattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/g;
      const hyphenUsPattern = /(\d{1,2})-(\d{1,2})-(\d{4})/g;
      const threeToTwoDigits = /[^\d]0(\d{2,})/g;
      const jpPattern = /年|月|日/;

      let replacedDate = "";
      let zeroPaddingDate = "";
      if (correctPattern.test(date)) {
        return date;
      } else if (date.length < 8) {
        this.state.isDateValidationError = true;
        return;
      } else if (jpPattern.test(date)) {
        this.state.isDateValidationError = true;
        return;
      } else if (slashPattern.test(date)) {
        zeroPaddingDate = date.replace(slashPattern, `$1-0$2-0$3`);
        replacedDate = zeroPaddingDate.replace(threeToTwoDigits, "-$1");
      } else if (slashUsPattern.test(date)) {
        zeroPaddingDate = date.replace(slashUsPattern, "$3-0$1-0$2");
        replacedDate = zeroPaddingDate.replace(threeToTwoDigits, "-$1");
      } else if (hyphenUsPattern.test(date)) {
        zeroPaddingDate = date.replace(hyphenUsPattern, "$3-0$1-0$2");
        replacedDate = zeroPaddingDate.replace(threeToTwoDigits, "-$1");
      }
      return replacedDate;
    }
    return date;
  }

  requiredValidation(familyName, firstName, email) {
    if (!familyName || !firstName || !email) {
      this.state.isBlankValidationError = true;
    }
  }

  emailValidation(email) {
    const pattern =
      /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/;
    if (!pattern.test(email)) {
      this.state.isEmailValidationError = true;
    }
  }

  csvValidation(familyName, firstName, email) {
    this.requiredValidation(familyName, firstName, email);
    this.emailValidation(email);
  }

  fitInvitationType(row) {
    if (row === "0" || row === "1") {
      return (Number(row) + 1).toString();
    } else if (row === "2") {
      return "0";
    } else {
      return "0";
    }
  }

  csvToJson(csvStr, userOptions) {
    if (typeof csvStr !== "string") return null;

    const options = { header: 0, columnName: [], ignoreBlankLine: true };
    if (userOptions) {
      if (userOptions.header) options.header = userOptions.header;
      if (userOptions.columnName) options.columnName = userOptions.columnName;
    }

    const rows = csvStr.split("\n");
    const json = [];
    let line = [];
    let row = "";
    let data = {};

    for (let i = 1, len = rows.length; i < len; i++) {
      if (i + 1 <= options.header) continue;
      if (
        (options.ignoreBlankLine && rows[i] === "") ||
        !/[^,]/.test(rows[i].trim())
      )
        continue;

      line = rows[i].split(",");
      // validation
      line[6] = this.dateFormat(line[6]);
      line[7] = this.dateFormat(line[7].trim());
      this.csvValidation(line[0], line[1], line[5]);

      if (options.columnName.length > 0) {
        data = {};
        for (let j = 0, len2 = options.columnName.length; j < len2; j++) {
          if (typeof line[j] !== "undefined") {
            row = line[j];

            row = row.replace(/^"(.+)?"$/, "$1");
            row = row.replace("\r", "");
          } else {
            row = null;
          }
          if (options.columnName[j] === "invitationType") {
            data[options.columnName[j]] = this.fitInvitationType(row);
          } else {
            data[options.columnName[j]] = row;
          }
        }
        json.push(data);
      } else {
        json.push(line);
      }
    }

    return json;
  }

  async handleFileSelect(event) {
    const userOptions = {
      header: 0,
      columnName: [
        "familyName",
        "firstName",
        "familyNameKana",
        "firstNameKana",
        "companyName",
        "email",
        "startDate",
        "endDate",
        "invitationType",
        "isChief",
        "gender",
        "zipCode",
        "address1",
        "address2",
        "address3",
        "mobileNumber",
        "birthDate",
        "emergencyContactName",
        "emergencyContactTell",
        "isSelfEmployment",
        "companyCEOName",
        "companyZipCode",
        "companyAddress1",
        "companyAddress2",
        "companyAddress3",
        "companyTell",
        "companyOccupation",
        "experienceYearPresent",
        "experienceMonthPresent",
        "experienceYearTotal",
        "experienceMonthTotal",
        "licenceQualification",
        "licenceQualificationOthers",
        "skillTraining",
        "skillTrainingOthers",
        "specialTraining",
        "specialTrainingOthers",
        "isForeigner",
        "residenceCardNumber1",
        "residenceCardNumber2",
        "residenceCardNumber3",
        "stayPeriod",
        "isSMEOwnerOrSingleMaster",
        "insuranceGroupName",
        "insuranceNumber1",
        "insuranceNumber2",
        "insuranceNumber3",
        "insuranceExpirationDate",
        "medicalCheckupCategory",
        "medicalCheckupLatestDate",
        "bloodPressureMax",
        "bloodPressureMin",
        "bloodType",
        "anamnesis",
        "anamnesisOthers",
        "canWorkWithoutProblems",
        "companyOrder",
        "primaryCompanyName",
        "secondaryCompanyName",
        "thirdCompanyName",
        "fourthCompanyName",
        "groupName",
        "isForeman",
        "youthElderly",
        "notes",
        "pihAgreementFlag",
        "normKnowledge",
        "agreementOfYouth",
        "agreementOfElderly",
        "shootingProhibited",
        "snsPostingProhibited",
        "disclosureProhibited",
        "discardCopyProhibited",
        "observancePledge",
        "checkListInputContents",
      ],
      ignoreBlankLine: true,
    };
    this.state.isDateValidationError = false;
    this.state.isEmailValidationError = false;
    this.state.isBlankValidationError = false;
    const file = this.csvToJson(
      await event.target.files[0].text(),
      userOptions
    );

    this.setState({
      userList: file,
    });
  }

  async componentDidMount() {
    this.fetchUsers();
  }

  async fetchUsers() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseSite = await API.graphql(
        graphqlOperation(getSite, {
          id: responseUser.data.getUser.currentSiteId,
        })
      );
      this.setState({
        siteDetails: responseSite.data.getSite,
      });
      console.log(this.state.siteDetails);

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  render() {
    const drawerList = [
      this.state.userDetails.currentSiteName,
      "現場状況",
      "メンバー管理",
      "現場の設定",
      "顔認証端末",
      "QRコード",
    ];

    const navigationList = [
      "/select-site",
      "/site-overview",
      "/user-list",
      "/edit-site",
      "/select-device",
      "/site-qr-code",
    ];

    const userInformation = [
      this.state.companyDetails.companyName,
      this.state.userDetails.lastName + "" + this.state.userDetails.firstName,
    ];

    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            backgroundColor: "#F9F9F9",
            borderBottom: 1,
            borderColor: "#E5E5E5",
          }}
          elevation={0}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              onClick={() => this.props.navigate("/user-list")}
              sx={{
                mr: 2,
                pr: 2,
                pl: 1,
                borderRadius: 0,
                borderRight: 1,
                height: 64,
                borderColor: "#E5E5E5",
                color: "#383838",
              }}
            >
              <img
                src={require("../assets/images/leftarrow-on.png")}
                width="16"
                height="16"
              />
            </IconButton>
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontSize: 21,
                fontWeight: "700",
                color: "#383838",
                flexGrow: 1,
              }}
            >
              人員を一括でアップロード
            </Typography>
            {this.state.userList &&
              !this.state.isDateValidationError &&
              !this.state.isEmailValidationError &&
              !this.state.isBlankValidationError && (
                <Button
                  type="submit"
                  onClick={() =>
                    this.props.navigate("/register-user-bulk-confirm", {
                      state: {
                        userList: this.state.userList,
                      },
                    })
                  }
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#383838",
                    backgroundColor: "#EBE91A",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: "#EBE91A",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-on.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  アップロードする
                </Button>
              )}
            {(Boolean(!this.state.userList) ||
              this.state.isDateValidationError ||
              this.state.isEmailValidationError ||
              this.state.isBlankValidationError) && (
              <Button
                variant="contained"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#C8C8C8",
                  backgroundColor: "#E5E5E5",
                  height: 40,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  justifyContent: "space-between",
                  "&:hover": {
                    backgroundColor: "#E5E5E5",
                  },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/rightarrow-gray.png")}
                    width="20"
                    height="20"
                  />
                }
                disableElevation
              >
                アップロードする
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <NavigationDrawer
            topIndex={2}
            bottomIndex={null}
            drawerList={drawerList}
            navigationList={navigationList}
            userInformation={userInformation}
          />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#F9F9F9",
            height: "100%",
            minHeight: "100vh",
            p: 3,
          }}
        >
          <LoadingProgress isLoading={this.state.isLoading} />
          <Grid item xs={12} md={6}>
            <Toolbar />
            {this.state.isDateValidationError && (
              <Alert severity="error" sx={{ mb: 1 }}>
                日付の形式に誤りがあります。修正して再度インポートしてください
              </Alert>
            )}
            {this.state.isEmailValidationError && (
              <Alert severity="error" sx={{ mb: 1 }}>
                メールアドレスの形式に誤りがあります。修正して再度インポートしてください。
              </Alert>
            )}
            {this.state.isBlankValidationError && (
              <Alert severity="error" sx={{ mb: 1 }}>
                氏名やメールアドレスが不足している行があります。修正して再度インポートしてください。
              </Alert>
            )}
            <Box
              component="form"
              sx={{
                display: "flex",
                direction: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Card
                sx={{
                  width: 600,
                  height: 160,
                  border: "1px dashed #777777",
                  borderRadius: 2,
                  padding: 3,
                  marginTop: 3,
                  marginLeft: 3,
                  marginRight: 3,
                  textAlign: "center",
                }}
                elevation={0}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    width: 540,
                    mb: 0,
                    mr: 1,
                  }}
                >
                  インポートする際、CSV形式のファイルを使用してください。
                </Typography>
                <Link
                  href="https://sagaseru7aa34bf2aa544a35bfb38c9a626e2450190221-prod.s3.ap-northeast-1.amazonaws.com/public/%E4%B8%80%E6%8B%AC%E7%99%BB%E9%8C%B2%E3%82%B5%E3%83%B3%E3%83%95%E3%82%9A%E3%83%AB.csv"
                  target="_blank"
                  download
                >
                  CSVテンプレートをダウンロード
                </Link>
                <Box component="form" sx={{ mt: 4, width: 540 }}>
                  <input
                    type="file"
                    accept="text/csv"
                    onChange={this.handleFileSelect}
                  />
                </Box>
              </Card>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }
}

function RegisterUserBulkScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default RegisterUserBulkScreen;
