import { API, graphqlOperation } from "aws-amplify";
import { listFreeidCompanyUsers } from "../../graphql/queries";

export default async function fetchFreeidUsers(filter = null) {
  try {
    const freeidCompanyUserList = [];
    let response;
    response = await API.graphql(
      graphqlOperation(listFreeidCompanyUsers, {
        filter: filter,
        limit: 500,
      })
    );

    freeidCompanyUserList.push(...response.data.listFreeidCompanyUsers.items);
    while (response.data.listFreeidCompanyUsers.nextToken !== null) {
      response = await API.graphql(
        graphqlOperation(listFreeidCompanyUsers, {
          filter: filter,
          limit: 500,
          nextToken: response.data.listFreeidCompanyUsers.nextToken,
        })
      );
      freeidCompanyUserList.push(...response.data.listFreeidCompanyUsers.items);
    }
    return freeidCompanyUserList;
  } catch (error) {
    console.log(error);
  }
}
