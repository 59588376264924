import React, { useState } from "react";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ja } from "date-fns/locale";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

function DateIcon(props) {
  return (
    <img
      {...props}
      src={require("../assets/images/calendar-on.png")}
      width="16"
      height="16"
    />
  );
}

export default function DatePicker({ value, onChange, minDate }) {
  const [open, setOpen] = useState(false);
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "#C8C8C8",
        pl: 0,
        pt: 0.5,
        pb: 0.5,
        pr: 0.5,
      }}
    >
      <LocalizationProvider locale={ja} dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat="yyyy年MM月dd日"
          value={value}
          onChange={onChange}
          minDate={minDate}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          inputProps={{
            style: {
              fontFamily: "Regular",
              fontSize: 18,
              fontWeight: "400",
              color: "#383838",
              width: 165,
              borderBottomWidth: 0,
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              backgroundColor: "#F9F9F9",
            },
          }}
          components={{
            OpenPickerIcon: DateIcon,
          }}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <input onKeyDown={onKeyDown} ref={inputRef} {...inputProps} />
              {InputProps?.endAdornment}
            </Box>
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}
