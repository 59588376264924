import * as React from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import {
  getUser,
  getCompany,
  getFreeidUser,
  getSiteClearance,
} from "../graphql/queries";
import { deleteSiteClearanceWithExternalRequests } from "../graphql/mutations";
import { useNavigate, useLocation } from "react-router-dom";
import UserDetailsTable from "../components/UserDetailsTable";
import NavigationDrawer from "../components/NavigationDrawer";
import LoadingProgress from "../components/LoadingProgress";
import CommonDialog from "../components/CommonDialog";
import { DEFAULT_ROLE_LEVEL } from "../components/utils/constants";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import fetchSite from "../components/queries/FetchSite";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";
import fetchSiteRoles from "../components/queries/FetchSiteRoles";
import fetchSiteLogs from "../components/queries/FetchSiteLogs";

const drawerWidth = 280;

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      companyDetails: "",
      userList: "",
      itemList: "",
      accessLevel: "",
      applyStatus: "",
      toggle: true,
      isLoading: false,
      commDlg: false,
      setCommDlg: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    this.fetchUsers();
  }

  handleChange(event) {
    this.setState({
      toggle: !this.state.toggle,
    });
    event.preventDefault();
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  handleDelete(event) {
    this.onDelete();
  }

  async componentDidMount() {
    this.fetchRoles();
    this.fetchUsers();
  }

  async fetchRoles() {
    try {
      this.setState({
        isLoading: true,
      });
      const userInfo = await Auth.currentAuthenticatedUser();

      const filterCompanyRole = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      };

      const resCompanyRole = await fetchCompanyRoles(filterCompanyRole);
      let roleLevel;
      if (resCompanyRole[0].roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = resCompanyRole[0].roleLevel;
      } else {
        const responseUser = await API.graphql(
          graphqlOperation(getUser, {
            id: userInfo.attributes.sub,
          })
        );

        const filterSiteRole = {
          and: [
            {
              siteCode: {
                eq: responseUser.data.getUser.currentSiteId,
              },
            },
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };

        const resSiteRoles = await fetchSiteRoles(filterSiteRole);
        roleLevel = resSiteRoles[0].roleLevel;
      }

      this.setState({
        accessLevel: roleLevel,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchUsers() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });

      if (this.props.location.state.freeidId) {
        const responseFreeidUsers = await API.graphql(
          graphqlOperation(getFreeidUser, {
            id: this.props.location.state.freeidId,
          })
        );
        this.setState({
          userList: responseFreeidUsers.data.getFreeidUser,
        });
      }

      const responseSiteClearances = await API.graphql(
        graphqlOperation(getSiteClearance, {
          id: this.props.location.state.id,
        })
      );
      const siteClearanceList = responseSiteClearances.data.getSiteClearance;

      const condition = {
        id: siteClearanceList.siteCode,
      };
      const responseSite = await fetchSite(condition);
      const contentTexts = [];
      for (let i = 1; i <= 100; i++) {
        if (responseSite[`contentText${i}`]) {
          contentTexts.push(responseSite[`contentText${i}`]);
        } else {
          break;
        }
      }
      siteClearanceList.checkListQuestions = contentTexts;
      this.setState({
        siteClearanceList: siteClearanceList,
      });

      this.setState({
        applyStatus: responseSiteClearances.data.getSiteClearance.applyStatus,
      });

      if (this.props.location.state.freeidId) {
        const filterSiteLogs = {
          and: [
            {
              siteCode: {
                eq: responseSiteClearances.data.getSiteClearance.siteCode,
              },
            },
            {
              freeidId: {
                eq: this.props.location.state.freeidId,
              },
            },
            {
              authType: {
                eq: "入場",
              },
            },
          ],
        };

        const resSiteLogs = await fetchSiteLogs(filterSiteLogs);
        console.log("resSiteLogs", resSiteLogs);

        this.setState({
          itemList: resSiteLogs.sort(function (a, b) {
            if (a.usedAt > b.usedAt) {
              return -1;
            }
            if (a.usedAt < b.usedAt) {
              return 1;
            }
            return 0;
          }),
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  async onDelete() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const input = {
        id: this.props.location.state.id,
        freeidId: this.props.location.state.freeidId,
        familyName: this.state.siteClearanceList.familyName,
        firstName: this.state.siteClearanceList.firstName,
        name:
          this.state.siteClearanceList.familyName +
          this.state.siteClearanceList.firstName,
      };

      const response = await API.graphql(
        graphqlOperation(deleteSiteClearanceWithExternalRequests, input)
      );

      this.props.navigate("/user-list");

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    if (
      (this.state.isLoading === true) |
      (this.state.accessLevel === 1) |
      (this.state.accessLevel === 2) |
      (this.state.accessLevel === 4)
    ) {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/user-list")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.state.userDetails.currentSiteName}：作業員の詳細
              </Typography>
              <CommonDialog
                msg={"削除しますか？"}
                isOpen={this.state.commDlg}
                doYes={this.handleDelete}
                doNo={() => {
                  this.setState({ commDlg: false });
                }}
              />
              <Button
                type="submit"
                onClick={() => this.setState({ commDlg: true })}
                variant="outlined"
                sx={{
                  fontSize: 15,
                  fontWeight: "700",
                  color: "#F9F9F9",
                  backgroundColor: "#DD3900",
                  borderColor: "#DD3900",
                  borderWidth: 1,
                  height: 32,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  mr: 1,
                  "&:hover": {
                    backgroundColor: "#DD3900",
                  },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/remove-on.png")}
                    width="18"
                    height="18"
                  />
                }
                disableElevation
              >
                削除する
              </Button>
              {Boolean(this.state.siteClearanceList) && (
                <Button
                  type="submit"
                  onClick={() =>
                    this.props.navigate("/edit-user", {
                      state: {
                        userList: this.state.userList,
                        siteClearanceList: this.state.siteClearanceList,
                        freeidId: this.props.location.state.freeidId,
                      },
                    })
                  }
                  variant="outlined"
                  sx={{
                    fontSize: 15,
                    fontWeight: "700",
                    color: "#383838",
                    backgroundColor: "#F9F9F9",
                    borderColor: "#C8C8C8",
                    borderWidth: 1,
                    height: 32,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    "&:hover": {
                      backgroundColor: "#F9F9F9",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/add-on.png")}
                      width="18"
                      height="18"
                    />
                  }
                  disableElevation
                >
                  編集する
                </Button>
              )}
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationDrawer topIndex={2} bottomIndex={null} />
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 3,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Grid item xs={12} mb={2}>
              <Toolbar />
              {this.state.siteClearanceList && (
                <Typography
                  variant="h5"
                  component="h2"
                  sx={{
                    fontSize: 28,
                    fontWeight: "700",
                    color: "#383838",
                    marginBottom: 3,
                  }}
                >
                  {this.state.siteClearanceList.familyName +
                    this.state.siteClearanceList.firstName}
                </Typography>
              )}
            </Grid>
            <UserDetailsTable
              rows={this.state.itemList}
              userDetails={this.state.userDetails}
            />
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/user-list")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                component="div"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.state.userDetails.currentSiteName}：作業員の詳細
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationDrawer topIndex={2} bottomIndex={null} />
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 0,
            }}
          >
            <Grid item xs={12} mb={2}>
              <Toolbar />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mb: 0,
                  mr: 1,
                }}
              >
                アクセス権限がありません
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  }
}

function UserDetailsScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default UserDetailsScreen;
