import * as React from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import {
  getUser,
  getCompany,
  getSite,
  listSiteRoles,
} from "../graphql/queries";
import {
  createSiteRole,
  updateSiteRole,
  createSiteClearance,
} from "../graphql/mutations";
import { useNavigate } from "react-router-dom";
import TeamMemberRegistrationGrid from "../components/TeamMemberRegistrationGrid";
import TeamMemberEditGrid from "../components/TeamMemberEditGrid";
import NavigationDrawer from "../components/NavigationDrawer";
import LoadingProgress from "../components/LoadingProgress";
import fetchSiteRoles from "../components/queries/FetchSiteRoles";
import fetchUsers from "../components/queries/FetchUsers";
import { DEFAULT_ROLE_LEVEL } from "../components/utils/constants";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { v4 as uuidv4 } from "uuid";
import { sha256 } from "crypto-hash";
import { format } from "date-fns";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";

const drawerWidth = 280;
const roles = [
  "開発者",
  "企業管理者",
  "企業管理者(閲覧のみ)",
  "現場管理者",
  "チームメンバー",
];

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      siteDetails: "",
      searchWords: "",
      companyDetails: "",
      userList: [],
      userListByRole: [],
      isLoading: false,
      selectedUserIds: [],
      accessLevel: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    this.fetchUsers();
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  async componentDidMount() {
    await this.fetchUsers();
    await this.fetchRoles();
    await this.fetchUserRoles();
  }

  async onSubmit() {
    try {
      this.setState({
        isLoading: true,
      });

      for (let i = 0; i < this.state.userListByRole.length; i++) {
        if (this.state.userListByRole[i].isCreated) {
          const input = {
            id: this.state.userListByRole[i].id,
            roleName: this.state.userListByRole[i].roleName,
            roleLevel: this.state.userListByRole[i].roleLevel,
            companyId: this.state.userListByRole[i].companyId,
            siteCode: this.state.userListByRole[i].siteCode,
            userId: this.state.userListByRole[i].userId,
          };
          const response = await API.graphql(
            graphqlOperation(updateSiteRole, {
              input,
            })
          );
        } else {
          const input = {
            id: this.state.userListByRole[i].id,
            roleName: this.state.userListByRole[i].roleName,
            roleLevel: this.state.userListByRole[i].roleLevel,
            companyId: this.state.userListByRole[i].companyId,
            siteCode: this.state.userListByRole[i].siteCode,
            userId: this.state.userListByRole[i].userId,
          };
          const response = await API.graphql(
            graphqlOperation(createSiteRole, {
              input,
            })
          );

          const newId = uuidv4();
          const hashResult = await sha256(
            "siteCode=" +
              this.state.userDetails.currentSiteId +
              "&siteName=" +
              this.state.userDetails.currentSiteName
          );
          const todayDate = format(
            new Date(new Date().toLocaleString("ja-JP", { timeZone: "Japan" })),
            "yyyy-MM-dd"
          );
          const inputSiteClearance = {
            id: newId,
            siteCode: this.state.userDetails.currentSiteId,
            siteName: this.state.userDetails.currentSiteName,
            siteAddress: this.state.siteDetails.address1,
            isEnteredBasicInfo: false,
            applyStatus: "招待中",
            startDate: todayDate,
            endDate: todayDate,
            notesFileUrl: "",
            familyName: this.state.userListByRole[i].familyName,
            firstName: this.state.userListByRole[i].firstName,
            familyNameKana: "",
            firstNameKana: "",
            email: this.state.userListByRole[i].email,
            companyName: "",
            companyId: this.state.userListByRole[i].companyId,
            userId: this.state.userListByRole[i].userId,
            invitationCode: newId,
            qrCodeDescription:
              "siteCode=" +
              this.state.userDetails.currentSiteId +
              "&sha256=" +
              hashResult,
          };
          const responseSiteClearance = await API.graphql(
            graphqlOperation(createSiteClearance, {
              input: inputSiteClearance,
            })
          );
        }
      }

      this.setState({
        isLoading: false,
      });
      this.props.navigate("/team-member-registration");
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  }

  async handleOnChange(event) {
    const updateUserListByRole = [];
    for (let i = 0; i < this.state.userListByRole.length; i++) {
      updateUserListByRole.push(this.state.userListByRole[i]);
    }
    for (let i = 0; i < this.state.selectedUserIds.length; i++) {
      const user = this.state.userList.find(
        (u) => u.id === this.state.selectedUserIds[i]
      );
      if (
        !this.state.userListByRole.some(
          (u) => u.userId === this.state.selectedUserIds[i]
        )
      ) {
        updateUserListByRole.push({
          companyId: this.state.userDetails.companyId,
          id: uuidv4(),
          roleLevel: 5,
          roleName: null,
          siteCode: this.state.userDetails.currentSiteId,
          userId: user.id,
          name: user.lastName + " " + user.firstName,
          isCreated: false,
          email: user.email,
          familyName: user.lastName,
          firstName: user.firstName,
        });
      }
    }

    this.setState({ userListByRole: updateUserListByRole });
  }

  async fetchUsers() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseSite = await API.graphql(
        graphqlOperation(getSite, {
          id: responseUser.data.getUser.currentSiteId,
        })
      );
      this.setState({
        siteDetails: responseSite.data.getSite,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });

      const filter = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            isActive: {
              ne: false,
            },
          },
        ],
      };

      const resUsers = await fetchUsers(filter);
      this.setState({
        userList: resUsers,
      });
      if (!this.state.searchWords) {
        const filter = {
          and: [
            {
              companyId: {
                eq: userInfo.attributes["custom:company_id"],
              },
            },
            {
              isActive: {
                ne: false,
              },
            },
          ],
        };

        const resUsers = await fetchUsers(filter);

        await this.setState({
          userList: resUsers,
        });
      } else {
        const filter = {
          and: [
            {
              companyId: {
                eq: userInfo.attributes["custom:company_id"],
              },
            },
            {
              lastName: {
                contains: this.state.searchWords,
              },
            },
            {
              isActive: {
                ne: false,
              },
            },
          ],
        };

        const resUsers = await fetchUsers(filter);

        await this.setState({
          userList: resUsers,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  async fetchRoles() {
    try {
      this.setState({
        isLoading: true,
      });
      const userInfo = await Auth.currentAuthenticatedUser();

      const filterCompanyRole = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      };

      const resCompanyRoles = await fetchCompanyRoles(filterCompanyRole);
      let roleLevel;
      if (resCompanyRoles[0].roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = resCompanyRoles[0].roleLevel;
      } else {
        const responseUser = await API.graphql(
          graphqlOperation(getUser, {
            id: userInfo.attributes.sub,
          })
        );

        const filterSiteRole = {
          and: [
            {
              siteCode: {
                eq: responseUser.data.getUser.currentSiteId,
              },
            },
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };

        const resSiteRoles = await fetchSiteRoles(filterSiteRole);
        roleLevel = resSiteRoles[0].roleLevel;
      }

      this.setState({
        accessLevel: roleLevel,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchUserRoles() {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });

      const filterSite = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            siteCode: {
              eq: this.state.userDetails.currentSiteId,
            },
          },
        ],
      };
      const resSiteRoles = await fetchSiteRoles(filterSite);
      const updateUserListByRole = [];
      for (let i = 0; i < resSiteRoles.length; i++) {
        const user = this.state.userList.find(
          (u) => u.id === resSiteRoles[i].userId
        );
        if (user) {
          updateUserListByRole.push({
            companyId: resSiteRoles[i].companyId,
            id: resSiteRoles[i].id,
            roleLevel: resSiteRoles[i].roleLevel,
            roleName: resSiteRoles[i].roleName,
            siteCode: this.state.userDetails.currentSiteId,
            userId: resSiteRoles[i].userId,
            name: user.lastName + " " + user.firstName,
            isCreated: true,
            email: user.email,
            familyName: user.lastName,
            firstName: user.firstName,
          });
        }
      }
      this.setState({ userListByRole: updateUserListByRole });
    } catch (error) {
      console.log(error);
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  updateState(selectedUserIds) {
    this.setState({ selectedUserIds: selectedUserIds });
  }

  updateEditCell(obj) {
    const updateUserListByRole = [];
    for (let i = 0; i < this.state.userListByRole.length; i++) {
      if (this.state.userListByRole[i].id === obj.id) {
        updateUserListByRole.push({
          companyId: this.state.userListByRole[i].companyId,
          id: this.state.userListByRole[i].id,
          roleLevel: roles.indexOf(obj.value) + 1,
          roleName: obj.value,
          siteCode: this.state.userListByRole[i].siteCode,
          userId: this.state.userListByRole[i].userId,
          name: this.state.userListByRole[i].name,
          isCreated: this.state.userListByRole[i].isCreated,
          email: this.state.userListByRole[i].email,
          familyName: this.state.userListByRole[i].familyName,
          firstName: this.state.userListByRole[i].firstName,
        });
      } else {
        updateUserListByRole.push({
          companyId: this.state.userListByRole[i].companyId,
          id: this.state.userListByRole[i].id,
          roleLevel: this.state.userListByRole[i].roleLevel,
          roleName: this.state.userListByRole[i].roleName,
          siteCode: this.state.userListByRole[i].siteCode,
          userId: this.state.userListByRole[i].userId,
          name: this.state.userListByRole[i].name,
          isCreated: this.state.userListByRole[i].isCreated,
          email: this.state.userListByRole[i].email,
          familyName: this.state.userListByRole[i].familyName,
          firstName: this.state.userListByRole[i].firstName,
        });
      }
    }
    this.setState({ userListByRole: updateUserListByRole });
  }

  render() {
    if (
      (this.state.isLoading === true) |
      (this.state.accessLevel === 1) |
      (this.state.accessLevel === 2) |
      (this.state.accessLevel === 4)
    ) {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/user-list")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.state.userDetails.currentSiteName}：メンバーの管理
              </Typography>
              {this.state.userListByRole && (
                <Button
                  type="submit"
                  onClick={this.handleSubmit}
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#383838",
                    backgroundColor: "#EBE91A",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: "#EBE91A",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-on.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  設定する
                </Button>
              )}
              {Boolean(!this.state.userListByRole) && (
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#C8C8C8",
                    backgroundColor: "#E5E5E5",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: "#E5E5E5",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-gray.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  登録する
                </Button>
              )}
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationDrawer topIndex={2} bottomIndex={null} />
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 3,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Grid item xs={12} mb={2}>
              <Toolbar />
              <Typography
                variant="h5"
                component="h2"
                sx={{
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  marginBottom: 3,
                }}
              >
                社内メンバー一覧
                <Link
                  sx={{
                    fontSize: 21,
                    marginLeft: 2,
                  }}
                  href="/user-list"
                >
                  メンバー一覧
                </Link>
              </Typography>
              <Box component="form" noValidate autoComplete="off">
                <TextField
                  id="searchWords"
                  name="searchWords"
                  variant="standard"
                  placeholder="名前で絞り込む"
                  value={this.state.searchWords}
                  onChange={this.handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={require("../assets/images/search-on.png")}
                          width="16"
                          height="16"
                        />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginBottom: 3,
                    width: 439,
                  }}
                />
              </Box>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} md={5}>
                <TeamMemberRegistrationGrid
                  rows={this.state.userList}
                  updateState={this.updateState.bind(this)}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    marginTop: 18,
                    marginLeft: 2,
                    marginRight: 2,
                  }}
                >
                  現場に社内メンバーを追加して権限を設定
                </Typography>
                <Button
                  type="submit"
                  onClick={this.handleOnChange}
                  variant="contained"
                  sx={{
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#383838",
                    backgroundColor: "#EBE91A",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    marginTop: 1,
                    marginLeft: 4,
                    "&:hover": {
                      backgroundColor: "#EBE91A",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-on.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  追加する
                </Button>
              </Grid>
              <Grid item xs={12} md={5}>
                <TeamMemberEditGrid
                  rows={this.state.userListByRole}
                  updateEditCell={this.updateEditCell.bind(this)}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/user-list")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                component="div"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.state.userDetails.currentSiteName}：メンバーの管理
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationDrawer topIndex={2} bottomIndex={null} />
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 0,
            }}
          >
            <Grid item xs={12} mb={2}>
              <Toolbar />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mb: 0,
                  mr: 1,
                }}
              >
                アクセス権限がありません
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  }
}

function TeamMemberRegistrationScreen(props) {
  const navigate = useNavigate();
  return <Input {...props} navigate={navigate} />;
}

export default TeamMemberRegistrationScreen;
