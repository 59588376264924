import getRoleLevel from "./getRoleLevel";

const makeDrawerList = async () => {
  const accessLevel = await getRoleLevel();
  const drawerList = ["作業中現場一覧"];
  if (accessLevel <= 2) {
    drawerList.push("第1次業者一覧");
    drawerList.push("社内メンバー一覧");
    drawerList.push("データ管理");
  }
  const navigationList = ["/select-site"];
  if (accessLevel <= 2) {
    navigationList.push("/vendor-list");
    navigationList.push("/company-user-list");
    navigationList.push("/manage-data");
  }
  return [drawerList, navigationList];
};

export default makeDrawerList;
