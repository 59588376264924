import { API, graphqlOperation } from "aws-amplify";
import { getUser } from "../../graphql/queries";

export default async function fetchUser(condition) {
  try {
    const response = await API.graphql(graphqlOperation(getUser, condition));
    return response.data.getUser;
  } catch (error) {
    console.log(error);
  }
}
