import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Snackbar,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";
import fetchUsers from "../components/queries/FetchUsers";
import updateUserWrapper from "../components/mutations/UpdateUserWrapper";
import { Auth, API, graphqlOperation } from "aws-amplify";
import NavigationMenu from "../components/NavigationMenu";
import LoadingProgress from "../components/LoadingProgress";
import CompanyUserEditGrid from "../components/CompanyUserEditGrid";
import TopBar from "../components/TopBar";
import {
  ROLES,
  DRAWER_WIDTH,
  DEFAULT_ROLE_LEVEL,
} from "../components/utils/constants";
import makeDrawerList from "../components/utils/makeDrawerList";
import { updateCompanyRole } from "../graphql/mutations";

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessLevel: "",
      isLoading: false,
      searchWords: "",
      userList: [],
      userListByRole: [],
      searchedUserListByRole: [],
      selectedUserIds: [],
      userInfo: {},
      drawerList: [],
      navigationList: [],
      targetArray: [],
      updatingUserInfo: null,
      liftUpSnackbar: null,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setTargetArray = this.setTargetArray.bind(this);
  }

  async componentDidMount() {
    await this.userListSet();
    this.myAccessLevelSet();
    this.companyRolesSet();
    const [drawerList, navigationList] = await makeDrawerList();

    this.setState({
      drawerList,
      navigationList,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
    this.userListSet();
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  async onSubmit() {
    try {
      this.setState({
        isLoading: true,
      });

      const updatePromises = this.state.userListByRole.map((user) => {
        const input = {
          id: user.id,
          roleLevel: user.roleLevel,
          companyId: user.companyId,
          userId: user.userId,
        };
        return API.graphql(graphqlOperation(updateCompanyRole, { input }));
      });
      await Promise.all(updatePromises);

      this.setState({
        isLoading: false,
      });
      this.props.navigate("/company-user-list");
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  }

  toggleIsActive = (activeStatus) => {
    this.setState({
      showDialog: true,
      updatingUserInfo: {
        activeStatus,
        userIds: this.state.targetArray,
      },
    });
  };

  renderButtonBasedOnStatus = () => {
    const buttonDisabled = this.state.targetArray.length === 0;

    const commonButtonStyle = {
      fontFamily: "Regular",
      fontSize: 16,
      fontWeight: "700",
      color: buttonDisabled ? "#B0B0B0" : "#F9F9F9",
      backgroundColor: buttonDisabled ? "#E0E0E0" : "#DD3900",
      height: 32,
      borderRadius: 100,
      paddingHorizontal: 18,
      marginLeft: 2,
      "&:hover": {
        backgroundColor: buttonDisabled ? "#E0E0E0" : "#DD3900",
      },
    };

    const activateButtonStyle = {
      ...commonButtonStyle,
      backgroundColor: buttonDisabled ? "#E0E0E0" : "#4caf50",
      "&:hover": {
        backgroundColor: buttonDisabled ? "#E0E0E0" : "green",
      },
    };

    const deactivateButtonStyle = {
      ...commonButtonStyle,
      color: buttonDisabled ? "#B0B0B0" : "#000000",
      backgroundColor: buttonDisabled ? "#E0E0E0" : "#E0E0E0",
      "&:hover": {
        backgroundColor: buttonDisabled ? "#E0E0E0" : "#E0E0E0",
      },
    };

    const isActiveButtonNeeded = this.state.userListByRole.some(
      (user) =>
        this.state.targetArray.includes(user.id) && user.isActive === false
    );

    if (isActiveButtonNeeded) {
      return (
        <Button
          onClick={() => this.toggleIsActive(true)}
          disabled={buttonDisabled}
          sx={activateButtonStyle}
          endIcon={
            <img
              src={require("../assets/images/addcircle-on.png")}
              width="20"
              height="20"
            />
          }
        >
          有効化する
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => this.toggleIsActive(false)}
          disabled={buttonDisabled}
          sx={deactivateButtonStyle}
          endIcon={
            <img
              src={require("../assets/images/remove-on.png")}
              width="20"
              height="20"
            />
          }
        >
          無効化する
        </Button>
      );
    }
  };

  handleYes = async () => {
    const { updatingUserInfo } = this.state;
    if (!updatingUserInfo) return;

    const usersToUpdate = this.state.userListByRole.filter((user) =>
      updatingUserInfo.userIds.includes(user.id)
    );

    try {
      for (const user of usersToUpdate) {
        const input = {
          id: user.userId,
          isActive: updatingUserInfo.activeStatus,
        };

        await updateUserWrapper(input);
      }

      const updateUsers = (users, usersToUpdate, updatingUserInfo) =>
        users.map((user) =>
          usersToUpdate.some((update) => update.id === user.id)
            ? { ...user, isActive: updatingUserInfo.activeStatus }
            : user
        );

      this.setState((prevState) => ({
        userListByRole: updateUsers(
          prevState.userListByRole,
          usersToUpdate,
          updatingUserInfo
        ),
        searchedUserListByRole: updateUsers(
          prevState.searchedUserListByRole,
          usersToUpdate,
          updatingUserInfo
        ),
        targetArray: [],
        showDialog: false,
        updatingUserInfo: null,
        liftUpSnackbar: {
          open: true,
          message: "更新しました。",
          severity: "success",
        },
      }));
    } catch (error) {
      console.error("Failed to update", error);
      this.setState({
        showDialog: false,
        updatingUserInfo: null,
        liftUpSnackbar: {
          open: true,
          message: "更新に失敗しました。",
          severity: "error",
        },
      });
    }
  };

  handleNo = () => {
    this.setState({ showDialog: false, updatingUserInfo: null });
  };

  renderConfirmDialog = () => {
    if (!this.state.showDialog) {
      return null;
    }

    return (
      <Dialog maxWidth="xs" open={this.state.showDialog}>
        <DialogTitle>
          ユーザーの有効・無効ステータスを変更されますか?
        </DialogTitle>
        <DialogContent dividers>
          {`変更される場合は、「はい」を押して下さい。`}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleYes}>はい</Button>
          <Button onClick={this.handleNo}>いいえ</Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleLiftUpCloseSnackbar = () => this.setState({ liftUpSnackbar: null });

  setTargetArray = (newId) => {
    this.setState({
      targetArray: [...newId],
    });
  };

  async userListSet() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      let listUsers = [];
      let searchedUserListByRole = [];
      listUsers = await fetchUsers({
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
        ],
      });

      if (this.state.searchWords) {
        searchedUserListByRole = this.state.userListByRole.filter((user) => {
          const isUserMatched = listUsers.some(
            (listUser) =>
              user.userId === listUser.id &&
              (listUser.firstName.includes(this.state.searchWords) ||
                listUser.lastName.includes(this.state.searchWords))
          );
          return isUserMatched;
        });
      }

      this.setState({
        searchedUserListByRole,
        userList: listUsers,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  async myAccessLevelSet() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();
      const [listCompanyRoles] = await fetchCompanyRoles({
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      });
      let roleLevel;
      if (listCompanyRoles.roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = listCompanyRoles.roleLevel;
      }
      this.setState({
        accessLevel: roleLevel,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async companyRolesSet() {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();

      const listCompanyRoles = await fetchCompanyRoles({
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
        ],
      });
      const updateUserListByRole = [];
      this.state.userList.forEach((user) => {
        listCompanyRoles.forEach((companyRole) => {
          if (user.id === companyRole.userId) {
            updateUserListByRole.push({
              id: companyRole.id,
              roleLevel: companyRole.roleLevel,
              roleName: ROLES[companyRole.roleLevel - 1],
              companyId: companyRole.companyId,
              userId: companyRole.userId,
              name: user.lastName + " " + user.firstName,
              email: user.email,
              familyName: user.lastName,
              firstName: user.firstName,
              createdAt: user.createdAt,
              isActive: user.isActive,
            });
          }
        });
      });
      this.setState({
        userListByRole: updateUserListByRole,
      });
    } catch (error) {
      console.log(error);
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  updateState(selectedUserIds) {
    this.setState({
      selectedUserIds: selectedUserIds,
    });
  }

  updateEditCell(obj) {
    const updatedUserListByRole = this.state.userListByRole.map((user) => {
      if (user.id === obj.id) {
        return {
          ...user,
          roleLevel: ROLES.indexOf(obj.value) + 1,
          roleName: obj.value,
        };
      }
      return user;
    });
    this.setState({
      userListByRole: updatedUserListByRole,
    });
  }

  render() {
    if (
      (this.state.isLoading === true) |
      (this.state.accessLevel === 1) |
      (this.state.accessLevel === 2)
    ) {
      const { liftUpSnackbar } = this.state;
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <TopBar topTitle="社内メンバー一覧">
            {this.renderConfirmDialog()}
            {this.renderButtonBasedOnStatus()}
            <Button
              type="submit"
              onClick={this.handleSubmit}
              variant="contained"
              sx={{
                fontFamily: "Regular",
                fontSize: 16,
                fontWeight: "700",
                color: "#383838",
                backgroundColor: "#EBE91A",
                height: 32,
                borderRadius: 100,
                paddingHorizontal: 18,
                marginLeft: 2,
                "&:hover": {
                  backgroundColor: "#EBE91A",
                },
              }}
              endIcon={
                <img
                  src={require("../assets/images/addcircle-on.png")}
                  width="20"
                  height="20"
                />
              }
              disableElevation
            >
              設定する
            </Button>
            <Button
              type="submit"
              onClick={() =>
                this.props.navigate("/register-company-user-bulk", {
                  state: {
                    topIndex: 2,
                  },
                })
              }
              variant="contained"
              sx={{
                fontFamily: "Regular",
                fontSize: 16,
                fontWeight: "700",
                color: "#383838",
                backgroundColor: "#EBE91A",
                height: 32,
                borderRadius: 100,
                paddingHorizontal: 18,
                "&:hover": {
                  backgroundColor: "#EBE91A",
                },
                marginLeft: 2,
              }}
              endIcon={
                <img
                  src={require("../assets/images/addcircle-on.png")}
                  width="20"
                  height="20"
                />
              }
              disableElevation
            >
              一括設定する
            </Button>
          </TopBar>
          <Box
            component="nav"
            sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationMenu
              drawerList={this.state.drawerList}
              navigationList={this.state.navigationList}
              topIndex={2}
              bottomIndex={null}
            />
          </Box>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              px: 3,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Toolbar />
            <Grid item xs={12} mt={2}>
              <Box component="form" noValidate autoComplete="off">
                <TextField
                  id="searchWords"
                  name="searchWords"
                  variant="standard"
                  placeholder="名前で絞り込む"
                  value={this.state.searchWords}
                  onChange={this.handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={require("../assets/images/search-on.png")}
                          width="16"
                          height="16"
                        />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginBottom: 3,
                    width: 439,
                  }}
                />
              </Box>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} md={12}>
                <CompanyUserEditGrid
                  rows={
                    this.state.searchWords
                      ? this.state.searchedUserListByRole
                      : this.state.userListByRole
                  }
                  updateEditCell={this.updateEditCell.bind(this)}
                  setTargetArray={this.setTargetArray}
                  targetArray={this.state.targetArray}
                />
              </Grid>
            </Grid>
          </Box>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={liftUpSnackbar?.open}
            onClose={this.handleLiftUpCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert
              onClose={this.handleLiftUpCloseSnackbar}
              severity={liftUpSnackbar?.severity}
            >
              {liftUpSnackbar?.message}
            </Alert>
          </Snackbar>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <TopBar topTitle="社内メンバー一覧">
            <Button
              type="submit"
              onClick={this.handleSubmit}
              variant="contained"
              sx={{
                fontFamily: "Regular",
                fontSize: 16,
                fontWeight: "700",
                color: "#383838",
                backgroundColor: "#EBE91A",
                height: 32,
                borderRadius: 100,
                paddingHorizontal: 18,
                "&:hover": {
                  backgroundColor: "#EBE91A",
                },
              }}
              endIcon={
                <img
                  src={require("../assets/images/addcircle-on.png")}
                  width="20"
                  height="20"
                />
              }
              disableElevation
            >
              設定する
            </Button>
            <Button
              type="submit"
              onClick={() =>
                this.props.navigate("/register-company-user-bulk", {
                  state: {
                    topIndex: 1,
                  },
                })
              }
              variant="contained"
              sx={{
                fontFamily: "Regular",
                fontSize: 16,
                fontWeight: "700",
                color: "#383838",
                backgroundColor: "#EBE91A",
                height: 32,
                borderRadius: 100,
                paddingHorizontal: 18,
                "&:hover": {
                  backgroundColor: "#EBE91A",
                },
                marginLeft: 2,
              }}
              endIcon={
                <img
                  src={require("../assets/images/addcircle-on.png")}
                  width="20"
                  height="20"
                />
              }
              disableElevation
            >
              一括設定する
            </Button>
          </TopBar>
          <NavigationMenu
            drawerList={this.state.drawerList}
            navigationList={this.state.navigationList}
            topIndex={2}
            bottomIndex={null}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 0,
            }}
          >
            <Grid item xs={12} mb={2}>
              <Toolbar />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mb: 0,
                  mr: 1,
                }}
              >
                アクセス権限がありません
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  }
}

function CompanyUserList(props) {
  const navigate = useNavigate();
  return <InputComponent {...props} navigate={navigate} />;
}

export default CompanyUserList;
