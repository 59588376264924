import * as React from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUser, getCompany, getSite } from "../graphql/queries";
import { useNavigate } from "react-router-dom";
import NavigationDrawer from "../components/NavigationDrawer";
import LoadingProgress from "../components/LoadingProgress";
import QrCode from "../components/QrCode";
import LoadPDF from "./PosterScreen";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

const drawerWidth = 280;

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      companyDetails: "",
      siteCode: "",
      siteName: "",
      qrCodeDescription: "",
      isLoading: false,
    };
    this.qrRef = React.createRef();
  }

  async componentDidMount() {
    this.fetchItems();
  }

  async fetchItems() {
    try {
      this.setState({
        isLoading: true,
      });

      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseSite = await API.graphql(
        graphqlOperation(getSite, {
          id: responseUser.data.getUser.currentSiteId,
        })
      );
      this.setState({
        siteCode: responseSite.data.getSite.id,
        siteName: responseSite.data.getSite.siteName,
        qrCodeDescription: responseSite.data.getSite.qrCodeDescription,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  render() {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            backgroundColor: "#F9F9F9",
            borderBottom: 1,
            borderColor: "#E5E5E5",
          }}
          elevation={0}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              onClick={() => this.props.navigate("/site-overview")}
              sx={{
                mr: 2,
                pr: 2,
                pl: 1,
                borderRadius: 0,
                borderRight: 1,
                height: 64,
                borderColor: "#E5E5E5",
                color: "#383838",
              }}
            >
              <img
                src={require("../assets/images/leftarrow-on.png")}
                width="16"
                height="16"
              />
            </IconButton>
            <Typography
              component="div"
              sx={{
                fontFamily: "Regular",
                fontSize: 21,
                fontWeight: "700",
                color: "#383838",
                flexGrow: 1,
              }}
            >
              {this.state.userDetails.currentSiteName}：QRコード・現場ポスター
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <NavigationDrawer topIndex={4} bottomIndex={null} />
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#F9F9F9",
            height: "100%",
            minHeight: "100vh",
            p: 0,
          }}
        >
          <LoadingProgress isLoading={this.state.isLoading} />
          <Grid item xs={12} mb={2}>
            <Toolbar />
          </Grid>
          <Box
            component="form"
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 10,
              paddingBottom: 13,
            }}
          >
            <QrCode value={this.state.qrCodeDescription} qrRef={this.qrRef} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "105rem",
              width: "100%",
            }}
          >
            {this.state.userDetails.currentSiteName &&
              this.state.companyDetails.companyName &&
              this.state.qrCodeDescription && (
                <LoadPDF
                  siteName={this.state.userDetails.currentSiteName}
                  companyName={this.state.companyDetails.companyName}
                  qrRef={this.qrRef}
                />
              )}
          </Box>
        </Box>
      </Box>
    );
  }
}

function SiteQrCodeScreen(props) {
  const navigate = useNavigate();
  return <InputComponent {...props} navigate={navigate} />;
}

export default SiteQrCodeScreen;
