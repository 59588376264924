import { Auth } from "aws-amplify";
import fetchCompanyVendors from "../queries/FetchCompanyVendors";
import fetchVendors from "../queries/FetchVendors";

export default async function companyToVendors() {
  const userInfo = await Auth.currentAuthenticatedUser();
  const listCompanyVendors = await fetchCompanyVendors({
    companyId: {
      eq: userInfo.attributes["custom:company_id"],
    },
  });

  const chunkSize = 150;
  const filterChunks = [];
  for (let i = 0; i < listCompanyVendors.length; i += chunkSize) {
    filterChunks.push(
      listCompanyVendors.slice(i, i + chunkSize).map((companyVendor) => ({
        id: {
          eq: companyVendor.vendorId,
        },
      }))
    );
  }
  const vendorPromises = filterChunks.map((filterArray) => {
    return fetchVendors({
      or: filterArray,
    });
  });

  const arrayVendors = await Promise.all(vendorPromises);
  const listVendors = arrayVendors.flat();
  return listVendors;
}
