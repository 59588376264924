/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSiteClearanceWithInvitation = /* GraphQL */ `
  mutation CreateSiteClearanceWithInvitation(
    $id: ID!
    $siteCode: ID!
    $siteName: String
    $siteAddress: String
    $isEnteredBasicInfo: Boolean
    $applyStatus: String
    $startDate: String
    $endDate: String
    $educationDate: String
    $notesFileUrl: String
    $familyName: String
    $firstName: String
    $familyNameKana: String
    $firstNameKana: String
    $email: String
    $companyName: String
    $companyId: ID
    $userId: ID
    $invitationCode: ID!
    $qrCodeDescription: String
    $gender: String
    $zipCode: String
    $address1: String
    $address2: String
    $address3: String
    $mobileNumber: String
    $birthDate: String
    $emergencyContactName: String
    $emergencyContactTell: String
    $isSelfEmployment: String
    $companyCEOName: String
    $companyZipCode: String
    $companyAddress1: String
    $companyAddress2: String
    $companyAddress3: String
    $companyTell: String
    $companyOccupation: String
    $experienceYearPresent: Int
    $experienceMonthPresent: Int
    $experienceYearTotal: Int
    $experienceMonthTotal: Int
    $licenceQualification: String
    $licenceQualificationOthers: String
    $skillTraining: String
    $skillTrainingOthers: String
    $specialTraining: String
    $specialTrainingOthers: String
    $isForeigner: String
    $residenceCardNumber1: String
    $residenceCardNumber2: String
    $residenceCardNumber3: String
    $stayPeriod: String
    $isSMEOwnerOrSingleMaster: String
    $insuranceGroupName: String
    $insuranceNumber1: String
    $insuranceNumber2: String
    $insuranceNumber3: String
    $insuranceExpirationDate: String
    $medicalCheckupCategory: String
    $medicalCheckupLatestDate: String
    $bloodPressureMax: Int
    $bloodPressureMin: Int
    $bloodType: String
    $anamnesis: String
    $anamnesisOthers: String
    $canWorkWithoutProblems: String
    $companyOrder: Int
    $primaryCompanyName: String
    $secondaryCompanyName: String
    $thirdCompanyName: String
    $fourthCompanyName: String
    $groupName: String
    $isForeman: String
    $youthElderly: String
    $notes: String
    $pihAgreementFlag: Boolean
    $normKnowledge: String
    $agreementOfYouth: String
    $agreementOfElderly: String
    $shootingProhibited: String
    $snsPostingProhibited: String
    $disclosureProhibited: String
    $discardCopyProhibited: String
    $observancePledge: String
    $checkListInputContents: String
    $invitationType: Int
    $providerCompanyName: String
    $isChief: Boolean
  ) {
    createSiteClearanceWithInvitation(
      id: $id
      siteCode: $siteCode
      siteName: $siteName
      siteAddress: $siteAddress
      isEnteredBasicInfo: $isEnteredBasicInfo
      applyStatus: $applyStatus
      startDate: $startDate
      endDate: $endDate
      educationDate: $educationDate
      notesFileUrl: $notesFileUrl
      familyName: $familyName
      firstName: $firstName
      familyNameKana: $familyNameKana
      firstNameKana: $firstNameKana
      email: $email
      companyName: $companyName
      companyId: $companyId
      userId: $userId
      invitationCode: $invitationCode
      qrCodeDescription: $qrCodeDescription
      gender: $gender
      zipCode: $zipCode
      address1: $address1
      address2: $address2
      address3: $address3
      mobileNumber: $mobileNumber
      birthDate: $birthDate
      emergencyContactName: $emergencyContactName
      emergencyContactTell: $emergencyContactTell
      isSelfEmployment: $isSelfEmployment
      companyCEOName: $companyCEOName
      companyZipCode: $companyZipCode
      companyAddress1: $companyAddress1
      companyAddress2: $companyAddress2
      companyAddress3: $companyAddress3
      companyTell: $companyTell
      companyOccupation: $companyOccupation
      experienceYearPresent: $experienceYearPresent
      experienceMonthPresent: $experienceMonthPresent
      experienceYearTotal: $experienceYearTotal
      experienceMonthTotal: $experienceMonthTotal
      licenceQualification: $licenceQualification
      licenceQualificationOthers: $licenceQualificationOthers
      skillTraining: $skillTraining
      skillTrainingOthers: $skillTrainingOthers
      specialTraining: $specialTraining
      specialTrainingOthers: $specialTrainingOthers
      isForeigner: $isForeigner
      residenceCardNumber1: $residenceCardNumber1
      residenceCardNumber2: $residenceCardNumber2
      residenceCardNumber3: $residenceCardNumber3
      stayPeriod: $stayPeriod
      isSMEOwnerOrSingleMaster: $isSMEOwnerOrSingleMaster
      insuranceGroupName: $insuranceGroupName
      insuranceNumber1: $insuranceNumber1
      insuranceNumber2: $insuranceNumber2
      insuranceNumber3: $insuranceNumber3
      insuranceExpirationDate: $insuranceExpirationDate
      medicalCheckupCategory: $medicalCheckupCategory
      medicalCheckupLatestDate: $medicalCheckupLatestDate
      bloodPressureMax: $bloodPressureMax
      bloodPressureMin: $bloodPressureMin
      bloodType: $bloodType
      anamnesis: $anamnesis
      anamnesisOthers: $anamnesisOthers
      canWorkWithoutProblems: $canWorkWithoutProblems
      companyOrder: $companyOrder
      primaryCompanyName: $primaryCompanyName
      secondaryCompanyName: $secondaryCompanyName
      thirdCompanyName: $thirdCompanyName
      fourthCompanyName: $fourthCompanyName
      groupName: $groupName
      isForeman: $isForeman
      youthElderly: $youthElderly
      notes: $notes
      pihAgreementFlag: $pihAgreementFlag
      normKnowledge: $normKnowledge
      agreementOfYouth: $agreementOfYouth
      agreementOfElderly: $agreementOfElderly
      shootingProhibited: $shootingProhibited
      snsPostingProhibited: $snsPostingProhibited
      disclosureProhibited: $disclosureProhibited
      discardCopyProhibited: $discardCopyProhibited
      observancePledge: $observancePledge
      checkListInputContents: $checkListInputContents
      invitationType: $invitationType
      providerCompanyName: $providerCompanyName
      isChief: $isChief
    ) {
      status
      messages
      data {
        id
      }
    }
  }
`;
export const updateSiteClearanceWithExternalRequests = /* GraphQL */ `
  mutation UpdateSiteClearanceWithExternalRequests(
    $id: ID!
    $startDate: String
    $endDate: String
    $educationDate: String
    $firstName: String
    $familyName: String
    $firstNameKana: String
    $familyNameKana: String
    $email: String
    $companyName: String
    $companyId: ID
    $siteCode: ID!
    $freeidId: String
    $applyStatus: String
    $isChief: Boolean
  ) {
    updateSiteClearanceWithExternalRequests(
      id: $id
      startDate: $startDate
      endDate: $endDate
      educationDate: $educationDate
      firstName: $firstName
      familyName: $familyName
      firstNameKana: $firstNameKana
      familyNameKana: $familyNameKana
      email: $email
      companyName: $companyName
      companyId: $companyId
      siteCode: $siteCode
      freeidId: $freeidId
      applyStatus: $applyStatus
      isChief: $isChief
    ) {
      status
      messages
      data {
        id
      }
    }
  }
`;
export const deleteSiteClearanceWithExternalRequests = /* GraphQL */ `
  mutation DeleteSiteClearanceWithExternalRequests(
    $id: ID!
    $firstName: String
    $familyName: String
    $freeidId: String
  ) {
    deleteSiteClearanceWithExternalRequests(
      id: $id
      firstName: $firstName
      familyName: $familyName
      freeidId: $freeidId
    ) {
      status
      messages
      data {
        id
      }
    }
  }
`;
export const createSiteAndSiteVendor = /* GraphQL */ `
  mutation CreateSiteAndSiteVendor($input: inputSiteAndSiteVendor) {
    createSiteAndSiteVendor(input: $input) {
      status
      messages
      data {
        id
      }
    }
  }
`;
export const updateSiteAndSiteVendor = /* GraphQL */ `
  mutation UpdateSiteAndSiteVendor($input: inputSiteAndSiteVendor) {
    updateSiteAndSiteVendor(input: $input) {
      status
      messages
      data {
        id
      }
    }
  }
`;
export const sendMail = /* GraphQL */ `
  mutation SendMail($input: inputInviteUserAndMail) {
    sendMail(input: $input) {
      status
      messages
      data
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      email
      firstName
      lastName
      firstNameFurigana
      lastNameFurigana
      phoneNumber
      currentSiteId
      currentMasterCode
      currentSiteName
      currentSiteFloor
      currentSiteRoom
      currentRowIndex
      currentColumnIndex
      isActive
      tags {
        nextToken
      }
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      companyId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      companyroles {
        nextToken
      }
      siteroles {
        nextToken
      }
      sitemanagements {
        nextToken
      }
      siteaccesss {
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      email
      firstName
      lastName
      firstNameFurigana
      lastNameFurigana
      phoneNumber
      currentSiteId
      currentMasterCode
      currentSiteName
      currentSiteFloor
      currentSiteRoom
      currentRowIndex
      currentColumnIndex
      isActive
      tags {
        nextToken
      }
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      companyId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      companyroles {
        nextToken
      }
      siteroles {
        nextToken
      }
      sitemanagements {
        nextToken
      }
      siteaccesss {
        nextToken
      }
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      contractCompanyCode
      companyName
      address1
      address2
      city
      zipCode
      type
      phoneNumber
      emailFormat
      agreementUrl
      licenceQualificationChoices
      skillTrainingChoices
      specialTrainingChoices
      occupationChoices
      primaryCompanies
      floorplans {
        nextToken
      }
      tags {
        nextToken
      }
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      items {
        nextToken
      }
      createdAt
      updatedAt
      users {
        nextToken
      }
      sites {
        nextToken
      }
      companyroles {
        nextToken
      }
      siteroles {
        nextToken
      }
      sitemanagements {
        nextToken
      }
      siteaccesss {
        nextToken
      }
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      contractCompanyCode
      companyName
      address1
      address2
      city
      zipCode
      type
      phoneNumber
      emailFormat
      agreementUrl
      licenceQualificationChoices
      skillTrainingChoices
      specialTrainingChoices
      occupationChoices
      primaryCompanies
      floorplans {
        nextToken
      }
      tags {
        nextToken
      }
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      items {
        nextToken
      }
      createdAt
      updatedAt
      users {
        nextToken
      }
      sites {
        nextToken
      }
      companyroles {
        nextToken
      }
      siteroles {
        nextToken
      }
      sitemanagements {
        nextToken
      }
      siteaccesss {
        nextToken
      }
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      contractCompanyCode
      companyName
      address1
      address2
      city
      zipCode
      type
      phoneNumber
      emailFormat
      agreementUrl
      licenceQualificationChoices
      skillTrainingChoices
      specialTrainingChoices
      occupationChoices
      primaryCompanies
      floorplans {
        nextToken
      }
      tags {
        nextToken
      }
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      items {
        nextToken
      }
      createdAt
      updatedAt
      users {
        nextToken
      }
      sites {
        nextToken
      }
      companyroles {
        nextToken
      }
      siteroles {
        nextToken
      }
      sitemanagements {
        nextToken
      }
      siteaccesss {
        nextToken
      }
    }
  }
`;
export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      id
      siteName
      masterCode
      address1
      address2
      address3
      city
      zipCode
      notesFileUrl
      qrCodeDescription
      contentText1
      choiceText1
      contentText2
      choiceText2
      contentText3
      choiceText3
      contentText4
      choiceText4
      contentText5
      choiceText5
      contentText6
      choiceText6
      contentText7
      choiceText7
      contentText8
      choiceText8
      contentText9
      choiceText9
      contentText10
      choiceText10
      contentText11
      choiceText11
      contentText12
      choiceText12
      contentText13
      choiceText13
      contentText14
      choiceText14
      contentText15
      choiceText15
      contentText16
      choiceText16
      contentText17
      choiceText17
      contentText18
      choiceText18
      contentText19
      choiceText19
      contentText20
      choiceText20
      contentText21
      choiceText21
      contentText22
      choiceText22
      contentText23
      choiceText23
      contentText24
      choiceText24
      contentText25
      choiceText25
      contentText26
      choiceText26
      contentText27
      choiceText27
      contentText28
      choiceText28
      contentText29
      choiceText29
      contentText30
      choiceText30
      contentText31
      choiceText31
      contentText32
      choiceText32
      contentText33
      choiceText33
      contentText34
      choiceText34
      contentText35
      choiceText35
      contentText36
      choiceText36
      contentText37
      choiceText37
      contentText38
      choiceText38
      contentText39
      choiceText39
      contentText40
      choiceText40
      contentText41
      choiceText41
      contentText42
      choiceText42
      contentText43
      choiceText43
      contentText44
      choiceText44
      contentText45
      choiceText45
      contentText46
      choiceText46
      contentText47
      choiceText47
      contentText48
      choiceText48
      contentText49
      choiceText49
      contentText50
      choiceText50
      contentText51
      choiceText51
      contentText52
      choiceText52
      contentText53
      choiceText53
      contentText54
      choiceText54
      contentText55
      choiceText55
      contentText56
      choiceText56
      contentText57
      choiceText57
      contentText58
      choiceText58
      contentText59
      choiceText59
      contentText60
      choiceText60
      contentText61
      choiceText61
      contentText62
      choiceText62
      contentText63
      choiceText63
      contentText64
      choiceText64
      contentText65
      choiceText65
      contentText66
      choiceText66
      contentText67
      choiceText67
      contentText68
      choiceText68
      contentText69
      choiceText69
      contentText70
      choiceText70
      contentText71
      choiceText71
      contentText72
      choiceText72
      contentText73
      choiceText73
      contentText74
      choiceText74
      contentText75
      choiceText75
      contentText76
      choiceText76
      contentText77
      choiceText77
      contentText78
      choiceText78
      contentText79
      choiceText79
      contentText80
      choiceText80
      contentText81
      choiceText81
      contentText82
      choiceText82
      contentText83
      choiceText83
      contentText84
      choiceText84
      contentText85
      choiceText85
      contentText86
      choiceText86
      contentText87
      choiceText87
      contentText88
      choiceText88
      contentText89
      choiceText89
      contentText90
      choiceText90
      contentText91
      choiceText91
      contentText92
      choiceText92
      contentText93
      choiceText93
      contentText94
      choiceText94
      contentText95
      choiceText95
      contentText96
      choiceText96
      contentText97
      choiceText97
      contentText98
      choiceText98
      contentText99
      choiceText99
      contentText100
      choiceText100
      latitude
      longitude
      altitude
      height
      floors
      latitude1
      longitude1
      latitude2
      longitude2
      latitude3
      longitude3
      latitude4
      longitude4
      latitude5
      longitude5
      itemRssi
      anchorRssi
      updateFrequency
      floorplans {
        nextToken
      }
      tags {
        nextToken
      }
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      companyId
      vendorIds
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      siteroles {
        nextToken
      }
      sitemanagements {
        nextToken
      }
      siteaccesss {
        nextToken
      }
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      id
      siteName
      masterCode
      address1
      address2
      address3
      city
      zipCode
      notesFileUrl
      qrCodeDescription
      contentText1
      choiceText1
      contentText2
      choiceText2
      contentText3
      choiceText3
      contentText4
      choiceText4
      contentText5
      choiceText5
      contentText6
      choiceText6
      contentText7
      choiceText7
      contentText8
      choiceText8
      contentText9
      choiceText9
      contentText10
      choiceText10
      contentText11
      choiceText11
      contentText12
      choiceText12
      contentText13
      choiceText13
      contentText14
      choiceText14
      contentText15
      choiceText15
      contentText16
      choiceText16
      contentText17
      choiceText17
      contentText18
      choiceText18
      contentText19
      choiceText19
      contentText20
      choiceText20
      contentText21
      choiceText21
      contentText22
      choiceText22
      contentText23
      choiceText23
      contentText24
      choiceText24
      contentText25
      choiceText25
      contentText26
      choiceText26
      contentText27
      choiceText27
      contentText28
      choiceText28
      contentText29
      choiceText29
      contentText30
      choiceText30
      contentText31
      choiceText31
      contentText32
      choiceText32
      contentText33
      choiceText33
      contentText34
      choiceText34
      contentText35
      choiceText35
      contentText36
      choiceText36
      contentText37
      choiceText37
      contentText38
      choiceText38
      contentText39
      choiceText39
      contentText40
      choiceText40
      contentText41
      choiceText41
      contentText42
      choiceText42
      contentText43
      choiceText43
      contentText44
      choiceText44
      contentText45
      choiceText45
      contentText46
      choiceText46
      contentText47
      choiceText47
      contentText48
      choiceText48
      contentText49
      choiceText49
      contentText50
      choiceText50
      contentText51
      choiceText51
      contentText52
      choiceText52
      contentText53
      choiceText53
      contentText54
      choiceText54
      contentText55
      choiceText55
      contentText56
      choiceText56
      contentText57
      choiceText57
      contentText58
      choiceText58
      contentText59
      choiceText59
      contentText60
      choiceText60
      contentText61
      choiceText61
      contentText62
      choiceText62
      contentText63
      choiceText63
      contentText64
      choiceText64
      contentText65
      choiceText65
      contentText66
      choiceText66
      contentText67
      choiceText67
      contentText68
      choiceText68
      contentText69
      choiceText69
      contentText70
      choiceText70
      contentText71
      choiceText71
      contentText72
      choiceText72
      contentText73
      choiceText73
      contentText74
      choiceText74
      contentText75
      choiceText75
      contentText76
      choiceText76
      contentText77
      choiceText77
      contentText78
      choiceText78
      contentText79
      choiceText79
      contentText80
      choiceText80
      contentText81
      choiceText81
      contentText82
      choiceText82
      contentText83
      choiceText83
      contentText84
      choiceText84
      contentText85
      choiceText85
      contentText86
      choiceText86
      contentText87
      choiceText87
      contentText88
      choiceText88
      contentText89
      choiceText89
      contentText90
      choiceText90
      contentText91
      choiceText91
      contentText92
      choiceText92
      contentText93
      choiceText93
      contentText94
      choiceText94
      contentText95
      choiceText95
      contentText96
      choiceText96
      contentText97
      choiceText97
      contentText98
      choiceText98
      contentText99
      choiceText99
      contentText100
      choiceText100
      latitude
      longitude
      altitude
      height
      floors
      latitude1
      longitude1
      latitude2
      longitude2
      latitude3
      longitude3
      latitude4
      longitude4
      latitude5
      longitude5
      itemRssi
      anchorRssi
      updateFrequency
      floorplans {
        nextToken
      }
      tags {
        nextToken
      }
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      companyId
      vendorIds
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      siteroles {
        nextToken
      }
      sitemanagements {
        nextToken
      }
      siteaccesss {
        nextToken
      }
    }
  }
`;
export const deleteCompanyRole = /* GraphQL */ `
  mutation DeleteCompanyRole(
    $input: DeleteCompanyRoleInput!
    $condition: ModelCompanyRoleConditionInput
  ) {
    deleteCompanyRole(input: $input, condition: $condition) {
      id
      roleName
      roleLevel
      createdAt
      updatedAt
      companyId
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSiteRole = /* GraphQL */ `
  mutation DeleteSiteRole(
    $input: DeleteSiteRoleInput!
    $condition: ModelSiteRoleConditionInput
  ) {
    deleteSiteRole(input: $input, condition: $condition) {
      id
      roleName
      roleLevel
      createdAt
      updatedAt
      companyId
      siteCode
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteFreeidUser = /* GraphQL */ `
  mutation DeleteFreeidUser(
    $input: DeleteFreeidUserInput!
    $condition: ModelFreeidUserConditionInput
  ) {
    deleteFreeidUser(input: $input, condition: $condition) {
      id
      isEnteredBasicInfo
      familyName
      firstName
      familyNameKana
      firstNameKana
      gender
      zipCode
      address1
      address2
      address3
      mobileNumber
      email
      birthDate
      emergencyContactName
      emergencyContactTell
      isSelfEmployment
      companyName
      companyCEOName
      companyZipCode
      companyAddress1
      companyAddress2
      companyAddress3
      companyTell
      companyOccupation
      experienceYearPresent
      experienceMonthPresent
      experienceYearTotal
      experienceMonthTotal
      licenceQualification
      licenceQualificationOthers
      skillTraining
      skillTrainingOthers
      specialTraining
      specialTrainingOthers
      isForeigner
      residenceCardNumber1
      residenceCardNumber2
      residenceCardNumber3
      stayPeriod
      isSMEOwnerOrSingleMaster
      insuranceGroupName
      insuranceNumber1
      insuranceNumber2
      insuranceNumber3
      insuranceExpirationDate
      medicalCheckupCategory
      medicalCheckupLatestDate
      bloodPressureMax
      bloodPressureMin
      bloodType
      anamnesis
      anamnesisOthers
      canWorkWithoutProblems
      lastUpdateDate
      hasKentaikyo
      hasCcus
      ccusNumber
      healthInsurance
      pensionInsurance
      employmentInsurance
      employmentInsuranceNumber
      hasChutaikyo
      createdAt
      updatedAt
      companyId
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteFreeidCompanyUser = /* GraphQL */ `
  mutation DeleteFreeidCompanyUser(
    $input: DeleteFreeidCompanyUserInput!
    $condition: ModelFreeidCompanyUserConditionInput
  ) {
    deleteFreeidCompanyUser(input: $input, condition: $condition) {
      id
      freeidId
      pihAgreementFlag
      createdAt
      updatedAt
      companyId
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSiteClearance = /* GraphQL */ `
  mutation DeleteSiteClearance(
    $input: DeleteSiteClearanceInput!
    $condition: ModelSiteClearanceConditionInput
  ) {
    deleteSiteClearance(input: $input, condition: $condition) {
      id
      freeidId
      invitationCode
      qrCodeDescription
      masterCode
      siteName
      siteAddress
      applyStatus
      startDate
      endDate
      notesFileUrl
      fieldUserId
      isEnteredBasicInfo
      name
      firstName
      familyName
      firstNameKana
      familyNameKana
      gender
      zipCode
      address1
      address2
      address3
      mobileNumber
      email
      birthDate
      emergencyContactName
      emergencyContactTell
      isSelfEmployment
      companyName
      companyCEOName
      companyZipCode
      companyAddress1
      companyAddress2
      companyAddress3
      companyTell
      companyOccupation
      experienceYearPresent
      experienceMonthPresent
      experienceYearTotal
      experienceMonthTotal
      licenceQualification
      licenceQualificationOthers
      skillTraining
      skillTrainingOthers
      specialTraining
      specialTrainingOthers
      hasKentaikyo
      hasCcus
      ccusNumber
      isForeigner
      residenceCardNumber1
      residenceCardNumber2
      residenceCardNumber3
      stayPeriod
      isSMEOwnerOrSingleMaster
      insuranceGroupName
      insuranceNumber1
      insuranceNumber2
      insuranceNumber3
      insuranceExpirationDate
      medicalCheckupCategory
      medicalCheckupLatestDate
      bloodPressureMax
      bloodPressureMin
      bloodType
      anamnesis
      anamnesisOthers
      canWorkWithoutProblems
      lastUpdateDate
      licenceQualificationChoices
      skillTrainingChoices
      specialTrainingChoices
      occupationChoices
      primaryCompanies
      agreementUrl
      pihAgreementFlag
      checkListInfo
      contentText
      choiceText
      companyOrder
      primaryCompanyName
      secondaryCompanyName
      secondaryCompanyNameKana
      thirdCompanyName
      thirdCompanyNameKana
      fourthCompanyName
      fourthCompanyNameKana
      groupName
      siteOccupation
      workerType
      isForeman
      youthElderly
      notes
      normKnowledge
      agreementOfYouth
      agreementOfElderly
      shootingProhibited
      snsPostingProhibited
      disclosureProhibited
      discardCopyProhibited
      observancePledge
      checkListInputContents
      healthInsurance
      pensionInsurance
      employmentInsurance
      employmentInsuranceNumber
      hasChutaikyo
      accessLevel
      educationDate
      isChief
      createdAt
      updatedAt
      companyId
      siteCode
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSiteClearanceHistory = /* GraphQL */ `
  mutation DeleteSiteClearanceHistory(
    $input: DeleteSiteClearanceHistoryInput!
    $condition: ModelSiteClearanceHistoryConditionInput
  ) {
    deleteSiteClearanceHistory(input: $input, condition: $condition) {
      id
      siteClearanceId
      changedColumns
      createdAt
      updatedAt
      accessLevel
      address1
      address2
      address3
      agreementOfElderly
      agreementOfYouth
      agreementUrl
      anamnesis
      anamnesisOthers
      applyStatus
      birthDate
      bloodPressureMax
      bloodPressureMin
      bloodType
      canWorkWithoutProblems
      ccusNumber
      checkListInfo
      checkListInputContents
      choiceText
      companyAddress1
      companyAddress2
      companyAddress3
      companyCEOName
      companyId
      companyName
      companyOccupation
      companyOrder
      companyTell
      companyZipCode
      contentText
      discardCopyProhibited
      disclosureProhibited
      educationDate
      email
      emergencyContactName
      emergencyContactTell
      employmentInsurance
      employmentInsuranceNumber
      endDate
      experienceMonthPresent
      experienceMonthTotal
      experienceYearPresent
      experienceYearTotal
      familyName
      familyNameKana
      fieldUserId
      firstName
      firstNameKana
      fourthCompanyName
      fourthCompanyNameKana
      freeidId
      gender
      groupName
      hasCcus
      hasChutaikyo
      hasKentaikyo
      healthInsurance
      insuranceExpirationDate
      insuranceGroupName
      insuranceNumber1
      insuranceNumber2
      insuranceNumber3
      invitationCode
      isChief
      isEnteredBasicInfo
      isForeigner
      isForeman
      isSelfEmployment
      isSMEOwnerOrSingleMaster
      lastUpdateDate
      licenceQualification
      licenceQualificationChoices
      licenceQualificationOthers
      masterCode
      medicalCheckupCategory
      medicalCheckupLatestDate
      mobileNumber
      name
      normKnowledge
      notes
      notesFileUrl
      observancePledge
      occupationChoices
      pensionInsurance
      pihAgreementFlag
      primaryCompanies
      primaryCompanyName
      qrCodeDescription
      residenceCardNumber1
      residenceCardNumber2
      residenceCardNumber3
      secondaryCompanyName
      secondaryCompanyNameKana
      shootingProhibited
      siteAddress
      siteClearanceCreatedAt
      siteClearanceUpdatedAt
      siteCode
      siteName
      siteOccupation
      skillTraining
      skillTrainingChoices
      skillTrainingOthers
      snsPostingProhibited
      specialTraining
      specialTrainingChoices
      specialTrainingOthers
      startDate
      stayPeriod
      thirdCompanyName
      thirdCompanyNameKana
      userId
      workerType
      youthElderly
      zipCode
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      siteClearance {
        id
        freeidId
        invitationCode
        qrCodeDescription
        masterCode
        siteName
        siteAddress
        applyStatus
        startDate
        endDate
        notesFileUrl
        fieldUserId
        isEnteredBasicInfo
        name
        firstName
        familyName
        firstNameKana
        familyNameKana
        gender
        zipCode
        address1
        address2
        address3
        mobileNumber
        email
        birthDate
        emergencyContactName
        emergencyContactTell
        isSelfEmployment
        companyName
        companyCEOName
        companyZipCode
        companyAddress1
        companyAddress2
        companyAddress3
        companyTell
        companyOccupation
        experienceYearPresent
        experienceMonthPresent
        experienceYearTotal
        experienceMonthTotal
        licenceQualification
        licenceQualificationOthers
        skillTraining
        skillTrainingOthers
        specialTraining
        specialTrainingOthers
        hasKentaikyo
        hasCcus
        ccusNumber
        isForeigner
        residenceCardNumber1
        residenceCardNumber2
        residenceCardNumber3
        stayPeriod
        isSMEOwnerOrSingleMaster
        insuranceGroupName
        insuranceNumber1
        insuranceNumber2
        insuranceNumber3
        insuranceExpirationDate
        medicalCheckupCategory
        medicalCheckupLatestDate
        bloodPressureMax
        bloodPressureMin
        bloodType
        anamnesis
        anamnesisOthers
        canWorkWithoutProblems
        lastUpdateDate
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        agreementUrl
        pihAgreementFlag
        checkListInfo
        contentText
        choiceText
        companyOrder
        primaryCompanyName
        secondaryCompanyName
        secondaryCompanyNameKana
        thirdCompanyName
        thirdCompanyNameKana
        fourthCompanyName
        fourthCompanyNameKana
        groupName
        siteOccupation
        workerType
        isForeman
        youthElderly
        notes
        normKnowledge
        agreementOfYouth
        agreementOfElderly
        shootingProhibited
        snsPostingProhibited
        disclosureProhibited
        discardCopyProhibited
        observancePledge
        checkListInputContents
        healthInsurance
        pensionInsurance
        employmentInsurance
        employmentInsuranceNumber
        hasChutaikyo
        accessLevel
        educationDate
        isChief
        createdAt
        updatedAt
        companyId
        siteCode
        userId
      }
    }
  }
`;
export const deleteSiteLog = /* GraphQL */ `
  mutation DeleteSiteLog(
    $input: DeleteSiteLogInput!
    $condition: ModelSiteLogConditionInput
  ) {
    deleteSiteLog(input: $input, condition: $condition) {
      id
      freeidId
      thermometryInfo
      usedAt
      authType
      deviceName
      companyName
      siteName
      firstName
      familyName
      createdAt
      updatedAt
      exited
      exitedAt
      exitDeviceCode
      exitDeviceName
      enteredSiteLogId
      companyId
      siteCode
      deviceCode
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      sitegate {
        id
        deviceCode
        siteName
        contractCompanyCode
        deviceName
        companyId
        siteCode
        userId
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSiteLogHistory = /* GraphQL */ `
  mutation UpdateSiteLogHistory(
    $input: UpdateSiteLogHistoryInput!
    $condition: ModelSiteLogHistoryConditionInput
  ) {
    updateSiteLogHistory(input: $input, condition: $condition) {
      id
      siteLogId
      usedAt
      preUsedAt
      editor
      email
      action
      authType
      createdAt
      updatedAt
      siteLog {
        id
        freeidId
        thermometryInfo
        usedAt
        authType
        deviceName
        companyName
        siteName
        firstName
        familyName
        createdAt
        updatedAt
        exited
        exitedAt
        exitDeviceCode
        exitDeviceName
        enteredSiteLogId
        companyId
        siteCode
        deviceCode
        userId
      }
    }
  }
`;
export const deleteSiteLogHistory = /* GraphQL */ `
  mutation DeleteSiteLogHistory(
    $input: DeleteSiteLogHistoryInput!
    $condition: ModelSiteLogHistoryConditionInput
  ) {
    deleteSiteLogHistory(input: $input, condition: $condition) {
      id
      siteLogId
      usedAt
      preUsedAt
      editor
      email
      action
      authType
      createdAt
      updatedAt
      siteLog {
        id
        freeidId
        thermometryInfo
        usedAt
        authType
        deviceName
        companyName
        siteName
        firstName
        familyName
        createdAt
        updatedAt
        exited
        exitedAt
        exitDeviceCode
        exitDeviceName
        enteredSiteLogId
        companyId
        siteCode
        deviceCode
        userId
      }
    }
  }
`;
export const deleteSiteGate = /* GraphQL */ `
  mutation DeleteSiteGate(
    $input: DeleteSiteGateInput!
    $condition: ModelSiteGateConditionInput
  ) {
    deleteSiteGate(input: $input, condition: $condition) {
      id
      deviceCode
      siteName
      contractCompanyCode
      deviceName
      companyId
      siteCode
      userId
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      sitelogs {
        nextToken
      }
    }
  }
`;
export const deleteSiteManagement = /* GraphQL */ `
  mutation DeleteSiteManagement(
    $input: DeleteSiteManagementInput!
    $condition: ModelSiteManagementConditionInput
  ) {
    deleteSiteManagement(input: $input, condition: $condition) {
      id
      companyName
      siteName
      fieldUserId
      lastName
      firstName
      email
      phoneNumber
      status
      accessLevel
      startDate
      endDate
      companyId
      siteId
      userId
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSiteAccess = /* GraphQL */ `
  mutation DeleteSiteAccess(
    $input: DeleteSiteAccessInput!
    $condition: ModelSiteAccessConditionInput
  ) {
    deleteSiteAccess(input: $input, condition: $condition) {
      id
      companyName
      siteName
      gateName
      lastName
      firstName
      userTemperature
      entry
      exit
      createdAt
      updatedAt
      companyId
      siteId
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteCompanyVendor = /* GraphQL */ `
  mutation DeleteCompanyVendor(
    $input: DeleteCompanyVendorInput!
    $condition: ModelCompanyVendorConditionInput
  ) {
    deleteCompanyVendor(input: $input, condition: $condition) {
      companyId
      vendorId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      vendor {
        id
        vendorCode
        vendorName
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSharePointDataLink = /* GraphQL */ `
  mutation CreateSharePointDataLink(
    $input: CreateSharePointDataLinkInput!
    $condition: ModelSharePointDataLinkConditionInput
  ) {
    createSharePointDataLink(input: $input, condition: $condition) {
      id
      companyId
      dataType
      dataId
      sent
      sentAt
      shareable
      latestSentAt
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSharePointDataLink = /* GraphQL */ `
  mutation UpdateSharePointDataLink(
    $input: UpdateSharePointDataLinkInput!
    $condition: ModelSharePointDataLinkConditionInput
  ) {
    updateSharePointDataLink(input: $input, condition: $condition) {
      id
      companyId
      dataType
      dataId
      sent
      sentAt
      shareable
      latestSentAt
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSharePointDataLink = /* GraphQL */ `
  mutation DeleteSharePointDataLink(
    $input: DeleteSharePointDataLinkInput!
    $condition: ModelSharePointDataLinkConditionInput
  ) {
    deleteSharePointDataLink(input: $input, condition: $condition) {
      id
      companyId
      dataType
      dataId
      sent
      sentAt
      shareable
      latestSentAt
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSharePointDataLinkHistory = /* GraphQL */ `
  mutation CreateSharePointDataLinkHistory(
    $input: CreateSharePointDataLinkHistoryInput!
    $condition: ModelSharePointDataLinkHistoryConditionInput
  ) {
    createSharePointDataLinkHistory(input: $input, condition: $condition) {
      id
      companyId
      dataType
      dataId
      fileName
      content
      sent
      sentAt
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSharePointDataLinkHistory = /* GraphQL */ `
  mutation UpdateSharePointDataLinkHistory(
    $input: UpdateSharePointDataLinkHistoryInput!
    $condition: ModelSharePointDataLinkHistoryConditionInput
  ) {
    updateSharePointDataLinkHistory(input: $input, condition: $condition) {
      id
      companyId
      dataType
      dataId
      fileName
      content
      sent
      sentAt
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSharePointDataLinkHistory = /* GraphQL */ `
  mutation DeleteSharePointDataLinkHistory(
    $input: DeleteSharePointDataLinkHistoryInput!
    $condition: ModelSharePointDataLinkHistoryConditionInput
  ) {
    deleteSharePointDataLinkHistory(input: $input, condition: $condition) {
      id
      companyId
      dataType
      dataId
      fileName
      content
      sent
      sentAt
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSiteVendor = /* GraphQL */ `
  mutation DeleteSiteVendor(
    $input: DeleteSiteVendorInput!
    $condition: ModelSiteVendorConditionInput
  ) {
    deleteSiteVendor(input: $input, condition: $condition) {
      id
      siteId
      vendorId
      createdAt
      updatedAt
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      vendor {
        id
        vendorCode
        vendorName
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      uuid
      iosId
      tagName
      itemDate
      itemVendor
      itemName
      itemStatus
      itemNotes
      tagStatus
      anchorStatus
      latitude
      longitude
      altitude
      anchorFloor
      intendedFloor
      siteFloor
      anchorRoom
      siteRoom
      anchorRowIndex
      anchorColumnIndex
      siteRowIndex
      siteColumnIndex
      rssi
      image1
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      companyId
      siteId
      userId
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      uuid
      iosId
      tagName
      itemDate
      itemVendor
      itemName
      itemStatus
      itemNotes
      tagStatus
      anchorStatus
      latitude
      longitude
      altitude
      anchorFloor
      intendedFloor
      siteFloor
      anchorRoom
      siteRoom
      anchorRowIndex
      anchorColumnIndex
      siteRowIndex
      siteColumnIndex
      rssi
      image1
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      companyId
      siteId
      userId
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      uuid
      iosId
      tagName
      itemDate
      itemVendor
      itemName
      itemStatus
      itemNotes
      tagStatus
      anchorStatus
      latitude
      longitude
      altitude
      anchorFloor
      intendedFloor
      siteFloor
      anchorRoom
      siteRoom
      anchorRowIndex
      anchorColumnIndex
      siteRowIndex
      siteColumnIndex
      rssi
      image1
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      companyId
      siteId
      userId
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const createPosition = /* GraphQL */ `
  mutation CreatePosition(
    $input: CreatePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    createPosition(input: $input, condition: $condition) {
      id
      uuid
      createdAt
      latitude
      longitude
      altitude
      siteFloor
      siteRoom
      siteRowIndex
      siteColumnIndex
      rssi
      companyId
      siteId
      userId
      tagId
      tag {
        id
        uuid
        iosId
        tagName
        itemDate
        itemVendor
        itemName
        itemStatus
        itemNotes
        tagStatus
        anchorStatus
        latitude
        longitude
        altitude
        anchorFloor
        intendedFloor
        siteFloor
        anchorRoom
        siteRoom
        anchorRowIndex
        anchorColumnIndex
        siteRowIndex
        siteColumnIndex
        rssi
        image1
        companyId
        siteId
        userId
        createdAt
        updatedAt
      }
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const updatePosition = /* GraphQL */ `
  mutation UpdatePosition(
    $input: UpdatePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    updatePosition(input: $input, condition: $condition) {
      id
      uuid
      createdAt
      latitude
      longitude
      altitude
      siteFloor
      siteRoom
      siteRowIndex
      siteColumnIndex
      rssi
      companyId
      siteId
      userId
      tagId
      tag {
        id
        uuid
        iosId
        tagName
        itemDate
        itemVendor
        itemName
        itemStatus
        itemNotes
        tagStatus
        anchorStatus
        latitude
        longitude
        altitude
        anchorFloor
        intendedFloor
        siteFloor
        anchorRoom
        siteRoom
        anchorRowIndex
        anchorColumnIndex
        siteRowIndex
        siteColumnIndex
        rssi
        image1
        companyId
        siteId
        userId
        createdAt
        updatedAt
      }
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const deletePosition = /* GraphQL */ `
  mutation DeletePosition(
    $input: DeletePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    deletePosition(input: $input, condition: $condition) {
      id
      uuid
      createdAt
      latitude
      longitude
      altitude
      siteFloor
      siteRoom
      siteRowIndex
      siteColumnIndex
      rssi
      companyId
      siteId
      userId
      tagId
      tag {
        id
        uuid
        iosId
        tagName
        itemDate
        itemVendor
        itemName
        itemStatus
        itemNotes
        tagStatus
        anchorStatus
        latitude
        longitude
        altitude
        anchorFloor
        intendedFloor
        siteFloor
        anchorRoom
        siteRoom
        anchorRowIndex
        anchorColumnIndex
        siteRowIndex
        siteColumnIndex
        rssi
        image1
        companyId
        siteId
        userId
        createdAt
        updatedAt
      }
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const createUserPosition = /* GraphQL */ `
  mutation CreateUserPosition(
    $input: CreateUserPositionInput!
    $condition: ModelUserPositionConditionInput
  ) {
    createUserPosition(input: $input, condition: $condition) {
      id
      uuid
      createdAt
      siteFloor
      siteRoom
      siteRowIndex
      siteColumnIndex
      rssi
      companyId
      siteId
      userId
      tagId
      tag {
        id
        uuid
        iosId
        tagName
        itemDate
        itemVendor
        itemName
        itemStatus
        itemNotes
        tagStatus
        anchorStatus
        latitude
        longitude
        altitude
        anchorFloor
        intendedFloor
        siteFloor
        anchorRoom
        siteRoom
        anchorRowIndex
        anchorColumnIndex
        siteRowIndex
        siteColumnIndex
        rssi
        image1
        companyId
        siteId
        userId
        createdAt
        updatedAt
      }
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateUserPosition = /* GraphQL */ `
  mutation UpdateUserPosition(
    $input: UpdateUserPositionInput!
    $condition: ModelUserPositionConditionInput
  ) {
    updateUserPosition(input: $input, condition: $condition) {
      id
      uuid
      createdAt
      siteFloor
      siteRoom
      siteRowIndex
      siteColumnIndex
      rssi
      companyId
      siteId
      userId
      tagId
      tag {
        id
        uuid
        iosId
        tagName
        itemDate
        itemVendor
        itemName
        itemStatus
        itemNotes
        tagStatus
        anchorStatus
        latitude
        longitude
        altitude
        anchorFloor
        intendedFloor
        siteFloor
        anchorRoom
        siteRoom
        anchorRowIndex
        anchorColumnIndex
        siteRowIndex
        siteColumnIndex
        rssi
        image1
        companyId
        siteId
        userId
        createdAt
        updatedAt
      }
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteUserPosition = /* GraphQL */ `
  mutation DeleteUserPosition(
    $input: DeleteUserPositionInput!
    $condition: ModelUserPositionConditionInput
  ) {
    deleteUserPosition(input: $input, condition: $condition) {
      id
      uuid
      createdAt
      siteFloor
      siteRoom
      siteRowIndex
      siteColumnIndex
      rssi
      companyId
      siteId
      userId
      tagId
      tag {
        id
        uuid
        iosId
        tagName
        itemDate
        itemVendor
        itemName
        itemStatus
        itemNotes
        tagStatus
        anchorStatus
        latitude
        longitude
        altitude
        anchorFloor
        intendedFloor
        siteFloor
        anchorRoom
        siteRoom
        anchorRowIndex
        anchorColumnIndex
        siteRowIndex
        siteColumnIndex
        rssi
        image1
        companyId
        siteId
        userId
        createdAt
        updatedAt
      }
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      itemName
      itemVendor
      companyId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      itemName
      itemVendor
      companyId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      itemName
      itemVendor
      companyId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const createManufacturer = /* GraphQL */ `
  mutation CreateManufacturer(
    $input: CreateManufacturerInput!
    $condition: ModelManufacturerConditionInput
  ) {
    createManufacturer(input: $input, condition: $condition) {
      id
      itemVendor
      companyId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateManufacturer = /* GraphQL */ `
  mutation UpdateManufacturer(
    $input: UpdateManufacturerInput!
    $condition: ModelManufacturerConditionInput
  ) {
    updateManufacturer(input: $input, condition: $condition) {
      id
      itemVendor
      companyId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteManufacturer = /* GraphQL */ `
  mutation DeleteManufacturer(
    $input: DeleteManufacturerInput!
    $condition: ModelManufacturerConditionInput
  ) {
    deleteManufacturer(input: $input, condition: $condition) {
      id
      itemVendor
      companyId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const createFloorPlan = /* GraphQL */ `
  mutation CreateFloorPlan(
    $input: CreateFloorPlanInput!
    $condition: ModelFloorPlanConditionInput
  ) {
    createFloorPlan(input: $input, condition: $condition) {
      id
      siteFloor
      image
      cellWidth
      cellHeight
      anchorRssi
      itemRssi
      companyId
      siteId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateFloorPlan = /* GraphQL */ `
  mutation UpdateFloorPlan(
    $input: UpdateFloorPlanInput!
    $condition: ModelFloorPlanConditionInput
  ) {
    updateFloorPlan(input: $input, condition: $condition) {
      id
      siteFloor
      image
      cellWidth
      cellHeight
      anchorRssi
      itemRssi
      companyId
      siteId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteFloorPlan = /* GraphQL */ `
  mutation DeleteFloorPlan(
    $input: DeleteFloorPlanInput!
    $condition: ModelFloorPlanConditionInput
  ) {
    deleteFloorPlan(input: $input, condition: $condition) {
      id
      siteFloor
      image
      cellWidth
      cellHeight
      anchorRssi
      itemRssi
      companyId
      siteId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteVendor = /* GraphQL */ `
  mutation DeleteVendor(
    $input: DeleteVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    deleteVendor(input: $input, condition: $condition) {
      id
      vendorCode
      vendorName
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const deleteVendorHistory = /* GraphQL */ `
  mutation DeleteVendorHistory(
    $input: DeleteVendorHistoryInput!
    $condition: ModelVendorHistoryConditionInput
  ) {
    deleteVendorHistory(input: $input, condition: $condition) {
      id
      vendorId
      editedColumn
      preVendorName
      vendorName
      preVendorCode
      vendorCode
      editor
      createdAt
      updatedAt
      vendor {
        id
        vendorCode
        vendorName
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const createEnterExitHistory = /* GraphQL */ `
  mutation CreateEnterExitHistory(
    $input: CreateEnterExitHistoryInput!
    $condition: ModelEnterExitHistoryConditionInput
  ) {
    createEnterExitHistory(input: $input, condition: $condition) {
      id
      companyId
      siteCode
      siteName
      familyName
      firstName
      freeidId
      usedAt
      deviceName
      enteredThermometryInfo
      exitedThermometryInfo
      exitedAt
      exitDeviceName
      exited
      vendorCode
      vendorName
      companyName
      enteredSiteLogId
      latestEnteredPreUsedAt
      latestEnteredUsedAt
      latestEnteredCreatedAt
      latestEnteredEditor
      latestEnteredEmail
      latestExitedPreUsedAt
      latestExitedUsedAt
      latestExitedCreatedAt
      latestExitedEditor
      latestExitedEmail
      siteOccupation
      createdAt
      updatedAt
    }
  }
`;
export const updateEnterExitHistory = /* GraphQL */ `
  mutation UpdateEnterExitHistory(
    $input: UpdateEnterExitHistoryInput!
    $condition: ModelEnterExitHistoryConditionInput
  ) {
    updateEnterExitHistory(input: $input, condition: $condition) {
      id
      companyId
      siteCode
      siteName
      familyName
      firstName
      freeidId
      usedAt
      deviceName
      enteredThermometryInfo
      exitedThermometryInfo
      exitedAt
      exitDeviceName
      exited
      vendorCode
      vendorName
      companyName
      enteredSiteLogId
      latestEnteredPreUsedAt
      latestEnteredUsedAt
      latestEnteredCreatedAt
      latestEnteredEditor
      latestEnteredEmail
      latestExitedPreUsedAt
      latestExitedUsedAt
      latestExitedCreatedAt
      latestExitedEditor
      latestExitedEmail
      siteOccupation
      createdAt
      updatedAt
    }
  }
`;
export const deleteEnterExitHistory = /* GraphQL */ `
  mutation DeleteEnterExitHistory(
    $input: DeleteEnterExitHistoryInput!
    $condition: ModelEnterExitHistoryConditionInput
  ) {
    deleteEnterExitHistory(input: $input, condition: $condition) {
      id
      companyId
      siteCode
      siteName
      familyName
      firstName
      freeidId
      usedAt
      deviceName
      enteredThermometryInfo
      exitedThermometryInfo
      exitedAt
      exitDeviceName
      exited
      vendorCode
      vendorName
      companyName
      enteredSiteLogId
      latestEnteredPreUsedAt
      latestEnteredUsedAt
      latestEnteredCreatedAt
      latestEnteredEditor
      latestEnteredEmail
      latestExitedPreUsedAt
      latestExitedUsedAt
      latestExitedCreatedAt
      latestExitedEditor
      latestExitedEmail
      siteOccupation
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      firstName
      lastName
      firstNameFurigana
      lastNameFurigana
      phoneNumber
      currentSiteId
      currentMasterCode
      currentSiteName
      currentSiteFloor
      currentSiteRoom
      currentRowIndex
      currentColumnIndex
      isActive
      tags {
        nextToken
      }
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      companyId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      companyroles {
        nextToken
      }
      siteroles {
        nextToken
      }
      sitemanagements {
        nextToken
      }
      siteaccesss {
        nextToken
      }
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      id
      siteName
      masterCode
      address1
      address2
      address3
      city
      zipCode
      notesFileUrl
      qrCodeDescription
      contentText1
      choiceText1
      contentText2
      choiceText2
      contentText3
      choiceText3
      contentText4
      choiceText4
      contentText5
      choiceText5
      contentText6
      choiceText6
      contentText7
      choiceText7
      contentText8
      choiceText8
      contentText9
      choiceText9
      contentText10
      choiceText10
      contentText11
      choiceText11
      contentText12
      choiceText12
      contentText13
      choiceText13
      contentText14
      choiceText14
      contentText15
      choiceText15
      contentText16
      choiceText16
      contentText17
      choiceText17
      contentText18
      choiceText18
      contentText19
      choiceText19
      contentText20
      choiceText20
      contentText21
      choiceText21
      contentText22
      choiceText22
      contentText23
      choiceText23
      contentText24
      choiceText24
      contentText25
      choiceText25
      contentText26
      choiceText26
      contentText27
      choiceText27
      contentText28
      choiceText28
      contentText29
      choiceText29
      contentText30
      choiceText30
      contentText31
      choiceText31
      contentText32
      choiceText32
      contentText33
      choiceText33
      contentText34
      choiceText34
      contentText35
      choiceText35
      contentText36
      choiceText36
      contentText37
      choiceText37
      contentText38
      choiceText38
      contentText39
      choiceText39
      contentText40
      choiceText40
      contentText41
      choiceText41
      contentText42
      choiceText42
      contentText43
      choiceText43
      contentText44
      choiceText44
      contentText45
      choiceText45
      contentText46
      choiceText46
      contentText47
      choiceText47
      contentText48
      choiceText48
      contentText49
      choiceText49
      contentText50
      choiceText50
      contentText51
      choiceText51
      contentText52
      choiceText52
      contentText53
      choiceText53
      contentText54
      choiceText54
      contentText55
      choiceText55
      contentText56
      choiceText56
      contentText57
      choiceText57
      contentText58
      choiceText58
      contentText59
      choiceText59
      contentText60
      choiceText60
      contentText61
      choiceText61
      contentText62
      choiceText62
      contentText63
      choiceText63
      contentText64
      choiceText64
      contentText65
      choiceText65
      contentText66
      choiceText66
      contentText67
      choiceText67
      contentText68
      choiceText68
      contentText69
      choiceText69
      contentText70
      choiceText70
      contentText71
      choiceText71
      contentText72
      choiceText72
      contentText73
      choiceText73
      contentText74
      choiceText74
      contentText75
      choiceText75
      contentText76
      choiceText76
      contentText77
      choiceText77
      contentText78
      choiceText78
      contentText79
      choiceText79
      contentText80
      choiceText80
      contentText81
      choiceText81
      contentText82
      choiceText82
      contentText83
      choiceText83
      contentText84
      choiceText84
      contentText85
      choiceText85
      contentText86
      choiceText86
      contentText87
      choiceText87
      contentText88
      choiceText88
      contentText89
      choiceText89
      contentText90
      choiceText90
      contentText91
      choiceText91
      contentText92
      choiceText92
      contentText93
      choiceText93
      contentText94
      choiceText94
      contentText95
      choiceText95
      contentText96
      choiceText96
      contentText97
      choiceText97
      contentText98
      choiceText98
      contentText99
      choiceText99
      contentText100
      choiceText100
      latitude
      longitude
      altitude
      height
      floors
      latitude1
      longitude1
      latitude2
      longitude2
      latitude3
      longitude3
      latitude4
      longitude4
      latitude5
      longitude5
      itemRssi
      anchorRssi
      updateFrequency
      floorplans {
        nextToken
      }
      tags {
        nextToken
      }
      positions {
        nextToken
      }
      userpositions {
        nextToken
      }
      companyId
      vendorIds
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      siteroles {
        nextToken
      }
      sitemanagements {
        nextToken
      }
      siteaccesss {
        nextToken
      }
    }
  }
`;
export const createCompanyRole = /* GraphQL */ `
  mutation CreateCompanyRole(
    $input: CreateCompanyRoleInput!
    $condition: ModelCompanyRoleConditionInput
  ) {
    createCompanyRole(input: $input, condition: $condition) {
      id
      roleName
      roleLevel
      createdAt
      updatedAt
      companyId
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCompanyRole = /* GraphQL */ `
  mutation UpdateCompanyRole(
    $input: UpdateCompanyRoleInput!
    $condition: ModelCompanyRoleConditionInput
  ) {
    updateCompanyRole(input: $input, condition: $condition) {
      id
      roleName
      roleLevel
      createdAt
      updatedAt
      companyId
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSiteRole = /* GraphQL */ `
  mutation CreateSiteRole(
    $input: CreateSiteRoleInput!
    $condition: ModelSiteRoleConditionInput
  ) {
    createSiteRole(input: $input, condition: $condition) {
      id
      roleName
      roleLevel
      createdAt
      updatedAt
      companyId
      siteCode
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSiteRole = /* GraphQL */ `
  mutation UpdateSiteRole(
    $input: UpdateSiteRoleInput!
    $condition: ModelSiteRoleConditionInput
  ) {
    updateSiteRole(input: $input, condition: $condition) {
      id
      roleName
      roleLevel
      createdAt
      updatedAt
      companyId
      siteCode
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const createFreeidUser = /* GraphQL */ `
  mutation CreateFreeidUser(
    $input: CreateFreeidUserInput!
    $condition: ModelFreeidUserConditionInput
  ) {
    createFreeidUser(input: $input, condition: $condition) {
      id
      isEnteredBasicInfo
      familyName
      firstName
      familyNameKana
      firstNameKana
      gender
      zipCode
      address1
      address2
      address3
      mobileNumber
      email
      birthDate
      emergencyContactName
      emergencyContactTell
      isSelfEmployment
      companyName
      companyCEOName
      companyZipCode
      companyAddress1
      companyAddress2
      companyAddress3
      companyTell
      companyOccupation
      experienceYearPresent
      experienceMonthPresent
      experienceYearTotal
      experienceMonthTotal
      licenceQualification
      licenceQualificationOthers
      skillTraining
      skillTrainingOthers
      specialTraining
      specialTrainingOthers
      isForeigner
      residenceCardNumber1
      residenceCardNumber2
      residenceCardNumber3
      stayPeriod
      isSMEOwnerOrSingleMaster
      insuranceGroupName
      insuranceNumber1
      insuranceNumber2
      insuranceNumber3
      insuranceExpirationDate
      medicalCheckupCategory
      medicalCheckupLatestDate
      bloodPressureMax
      bloodPressureMin
      bloodType
      anamnesis
      anamnesisOthers
      canWorkWithoutProblems
      lastUpdateDate
      hasKentaikyo
      hasCcus
      ccusNumber
      healthInsurance
      pensionInsurance
      employmentInsurance
      employmentInsuranceNumber
      hasChutaikyo
      createdAt
      updatedAt
      companyId
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateFreeidUser = /* GraphQL */ `
  mutation UpdateFreeidUser(
    $input: UpdateFreeidUserInput!
    $condition: ModelFreeidUserConditionInput
  ) {
    updateFreeidUser(input: $input, condition: $condition) {
      id
      isEnteredBasicInfo
      familyName
      firstName
      familyNameKana
      firstNameKana
      gender
      zipCode
      address1
      address2
      address3
      mobileNumber
      email
      birthDate
      emergencyContactName
      emergencyContactTell
      isSelfEmployment
      companyName
      companyCEOName
      companyZipCode
      companyAddress1
      companyAddress2
      companyAddress3
      companyTell
      companyOccupation
      experienceYearPresent
      experienceMonthPresent
      experienceYearTotal
      experienceMonthTotal
      licenceQualification
      licenceQualificationOthers
      skillTraining
      skillTrainingOthers
      specialTraining
      specialTrainingOthers
      isForeigner
      residenceCardNumber1
      residenceCardNumber2
      residenceCardNumber3
      stayPeriod
      isSMEOwnerOrSingleMaster
      insuranceGroupName
      insuranceNumber1
      insuranceNumber2
      insuranceNumber3
      insuranceExpirationDate
      medicalCheckupCategory
      medicalCheckupLatestDate
      bloodPressureMax
      bloodPressureMin
      bloodType
      anamnesis
      anamnesisOthers
      canWorkWithoutProblems
      lastUpdateDate
      hasKentaikyo
      hasCcus
      ccusNumber
      healthInsurance
      pensionInsurance
      employmentInsurance
      employmentInsuranceNumber
      hasChutaikyo
      createdAt
      updatedAt
      companyId
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const createFreeidCompanyUser = /* GraphQL */ `
  mutation CreateFreeidCompanyUser(
    $input: CreateFreeidCompanyUserInput!
    $condition: ModelFreeidCompanyUserConditionInput
  ) {
    createFreeidCompanyUser(input: $input, condition: $condition) {
      id
      freeidId
      pihAgreementFlag
      createdAt
      updatedAt
      companyId
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateFreeidCompanyUser = /* GraphQL */ `
  mutation UpdateFreeidCompanyUser(
    $input: UpdateFreeidCompanyUserInput!
    $condition: ModelFreeidCompanyUserConditionInput
  ) {
    updateFreeidCompanyUser(input: $input, condition: $condition) {
      id
      freeidId
      pihAgreementFlag
      createdAt
      updatedAt
      companyId
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSiteClearance = /* GraphQL */ `
  mutation CreateSiteClearance(
    $input: CreateSiteClearanceInput!
    $condition: ModelSiteClearanceConditionInput
  ) {
    createSiteClearance(input: $input, condition: $condition) {
      id
      freeidId
      invitationCode
      qrCodeDescription
      masterCode
      siteName
      siteAddress
      applyStatus
      startDate
      endDate
      notesFileUrl
      fieldUserId
      isEnteredBasicInfo
      name
      firstName
      familyName
      firstNameKana
      familyNameKana
      gender
      zipCode
      address1
      address2
      address3
      mobileNumber
      email
      birthDate
      emergencyContactName
      emergencyContactTell
      isSelfEmployment
      companyName
      companyCEOName
      companyZipCode
      companyAddress1
      companyAddress2
      companyAddress3
      companyTell
      companyOccupation
      experienceYearPresent
      experienceMonthPresent
      experienceYearTotal
      experienceMonthTotal
      licenceQualification
      licenceQualificationOthers
      skillTraining
      skillTrainingOthers
      specialTraining
      specialTrainingOthers
      hasKentaikyo
      hasCcus
      ccusNumber
      isForeigner
      residenceCardNumber1
      residenceCardNumber2
      residenceCardNumber3
      stayPeriod
      isSMEOwnerOrSingleMaster
      insuranceGroupName
      insuranceNumber1
      insuranceNumber2
      insuranceNumber3
      insuranceExpirationDate
      medicalCheckupCategory
      medicalCheckupLatestDate
      bloodPressureMax
      bloodPressureMin
      bloodType
      anamnesis
      anamnesisOthers
      canWorkWithoutProblems
      lastUpdateDate
      licenceQualificationChoices
      skillTrainingChoices
      specialTrainingChoices
      occupationChoices
      primaryCompanies
      agreementUrl
      pihAgreementFlag
      checkListInfo
      contentText
      choiceText
      companyOrder
      primaryCompanyName
      secondaryCompanyName
      secondaryCompanyNameKana
      thirdCompanyName
      thirdCompanyNameKana
      fourthCompanyName
      fourthCompanyNameKana
      groupName
      siteOccupation
      workerType
      isForeman
      youthElderly
      notes
      normKnowledge
      agreementOfYouth
      agreementOfElderly
      shootingProhibited
      snsPostingProhibited
      disclosureProhibited
      discardCopyProhibited
      observancePledge
      checkListInputContents
      healthInsurance
      pensionInsurance
      employmentInsurance
      employmentInsuranceNumber
      hasChutaikyo
      accessLevel
      educationDate
      isChief
      createdAt
      updatedAt
      companyId
      siteCode
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSiteClearance = /* GraphQL */ `
  mutation UpdateSiteClearance(
    $input: UpdateSiteClearanceInput!
    $condition: ModelSiteClearanceConditionInput
  ) {
    updateSiteClearance(input: $input, condition: $condition) {
      id
      freeidId
      invitationCode
      qrCodeDescription
      masterCode
      siteName
      siteAddress
      applyStatus
      startDate
      endDate
      notesFileUrl
      fieldUserId
      isEnteredBasicInfo
      name
      firstName
      familyName
      firstNameKana
      familyNameKana
      gender
      zipCode
      address1
      address2
      address3
      mobileNumber
      email
      birthDate
      emergencyContactName
      emergencyContactTell
      isSelfEmployment
      companyName
      companyCEOName
      companyZipCode
      companyAddress1
      companyAddress2
      companyAddress3
      companyTell
      companyOccupation
      experienceYearPresent
      experienceMonthPresent
      experienceYearTotal
      experienceMonthTotal
      licenceQualification
      licenceQualificationOthers
      skillTraining
      skillTrainingOthers
      specialTraining
      specialTrainingOthers
      hasKentaikyo
      hasCcus
      ccusNumber
      isForeigner
      residenceCardNumber1
      residenceCardNumber2
      residenceCardNumber3
      stayPeriod
      isSMEOwnerOrSingleMaster
      insuranceGroupName
      insuranceNumber1
      insuranceNumber2
      insuranceNumber3
      insuranceExpirationDate
      medicalCheckupCategory
      medicalCheckupLatestDate
      bloodPressureMax
      bloodPressureMin
      bloodType
      anamnesis
      anamnesisOthers
      canWorkWithoutProblems
      lastUpdateDate
      licenceQualificationChoices
      skillTrainingChoices
      specialTrainingChoices
      occupationChoices
      primaryCompanies
      agreementUrl
      pihAgreementFlag
      checkListInfo
      contentText
      choiceText
      companyOrder
      primaryCompanyName
      secondaryCompanyName
      secondaryCompanyNameKana
      thirdCompanyName
      thirdCompanyNameKana
      fourthCompanyName
      fourthCompanyNameKana
      groupName
      siteOccupation
      workerType
      isForeman
      youthElderly
      notes
      normKnowledge
      agreementOfYouth
      agreementOfElderly
      shootingProhibited
      snsPostingProhibited
      disclosureProhibited
      discardCopyProhibited
      observancePledge
      checkListInputContents
      healthInsurance
      pensionInsurance
      employmentInsurance
      employmentInsuranceNumber
      hasChutaikyo
      accessLevel
      educationDate
      isChief
      createdAt
      updatedAt
      companyId
      siteCode
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSiteClearanceHistory = /* GraphQL */ `
  mutation CreateSiteClearanceHistory(
    $input: CreateSiteClearanceHistoryInput!
    $condition: ModelSiteClearanceHistoryConditionInput
  ) {
    createSiteClearanceHistory(input: $input, condition: $condition) {
      id
      siteClearanceId
      changedColumns
      createdAt
      updatedAt
      accessLevel
      address1
      address2
      address3
      agreementOfElderly
      agreementOfYouth
      agreementUrl
      anamnesis
      anamnesisOthers
      applyStatus
      birthDate
      bloodPressureMax
      bloodPressureMin
      bloodType
      canWorkWithoutProblems
      ccusNumber
      checkListInfo
      checkListInputContents
      choiceText
      companyAddress1
      companyAddress2
      companyAddress3
      companyCEOName
      companyId
      companyName
      companyOccupation
      companyOrder
      companyTell
      companyZipCode
      contentText
      discardCopyProhibited
      disclosureProhibited
      educationDate
      email
      emergencyContactName
      emergencyContactTell
      employmentInsurance
      employmentInsuranceNumber
      endDate
      experienceMonthPresent
      experienceMonthTotal
      experienceYearPresent
      experienceYearTotal
      familyName
      familyNameKana
      fieldUserId
      firstName
      firstNameKana
      fourthCompanyName
      fourthCompanyNameKana
      freeidId
      gender
      groupName
      hasCcus
      hasChutaikyo
      hasKentaikyo
      healthInsurance
      insuranceExpirationDate
      insuranceGroupName
      insuranceNumber1
      insuranceNumber2
      insuranceNumber3
      invitationCode
      isChief
      isEnteredBasicInfo
      isForeigner
      isForeman
      isSelfEmployment
      isSMEOwnerOrSingleMaster
      lastUpdateDate
      licenceQualification
      licenceQualificationChoices
      licenceQualificationOthers
      masterCode
      medicalCheckupCategory
      medicalCheckupLatestDate
      mobileNumber
      name
      normKnowledge
      notes
      notesFileUrl
      observancePledge
      occupationChoices
      pensionInsurance
      pihAgreementFlag
      primaryCompanies
      primaryCompanyName
      qrCodeDescription
      residenceCardNumber1
      residenceCardNumber2
      residenceCardNumber3
      secondaryCompanyName
      secondaryCompanyNameKana
      shootingProhibited
      siteAddress
      siteClearanceCreatedAt
      siteClearanceUpdatedAt
      siteCode
      siteName
      siteOccupation
      skillTraining
      skillTrainingChoices
      skillTrainingOthers
      snsPostingProhibited
      specialTraining
      specialTrainingChoices
      specialTrainingOthers
      startDate
      stayPeriod
      thirdCompanyName
      thirdCompanyNameKana
      userId
      workerType
      youthElderly
      zipCode
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      siteClearance {
        id
        freeidId
        invitationCode
        qrCodeDescription
        masterCode
        siteName
        siteAddress
        applyStatus
        startDate
        endDate
        notesFileUrl
        fieldUserId
        isEnteredBasicInfo
        name
        firstName
        familyName
        firstNameKana
        familyNameKana
        gender
        zipCode
        address1
        address2
        address3
        mobileNumber
        email
        birthDate
        emergencyContactName
        emergencyContactTell
        isSelfEmployment
        companyName
        companyCEOName
        companyZipCode
        companyAddress1
        companyAddress2
        companyAddress3
        companyTell
        companyOccupation
        experienceYearPresent
        experienceMonthPresent
        experienceYearTotal
        experienceMonthTotal
        licenceQualification
        licenceQualificationOthers
        skillTraining
        skillTrainingOthers
        specialTraining
        specialTrainingOthers
        hasKentaikyo
        hasCcus
        ccusNumber
        isForeigner
        residenceCardNumber1
        residenceCardNumber2
        residenceCardNumber3
        stayPeriod
        isSMEOwnerOrSingleMaster
        insuranceGroupName
        insuranceNumber1
        insuranceNumber2
        insuranceNumber3
        insuranceExpirationDate
        medicalCheckupCategory
        medicalCheckupLatestDate
        bloodPressureMax
        bloodPressureMin
        bloodType
        anamnesis
        anamnesisOthers
        canWorkWithoutProblems
        lastUpdateDate
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        agreementUrl
        pihAgreementFlag
        checkListInfo
        contentText
        choiceText
        companyOrder
        primaryCompanyName
        secondaryCompanyName
        secondaryCompanyNameKana
        thirdCompanyName
        thirdCompanyNameKana
        fourthCompanyName
        fourthCompanyNameKana
        groupName
        siteOccupation
        workerType
        isForeman
        youthElderly
        notes
        normKnowledge
        agreementOfYouth
        agreementOfElderly
        shootingProhibited
        snsPostingProhibited
        disclosureProhibited
        discardCopyProhibited
        observancePledge
        checkListInputContents
        healthInsurance
        pensionInsurance
        employmentInsurance
        employmentInsuranceNumber
        hasChutaikyo
        accessLevel
        educationDate
        isChief
        createdAt
        updatedAt
        companyId
        siteCode
        userId
      }
    }
  }
`;
export const updateSiteClearanceHistory = /* GraphQL */ `
  mutation UpdateSiteClearanceHistory(
    $input: UpdateSiteClearanceHistoryInput!
    $condition: ModelSiteClearanceHistoryConditionInput
  ) {
    updateSiteClearanceHistory(input: $input, condition: $condition) {
      id
      siteClearanceId
      changedColumns
      createdAt
      updatedAt
      accessLevel
      address1
      address2
      address3
      agreementOfElderly
      agreementOfYouth
      agreementUrl
      anamnesis
      anamnesisOthers
      applyStatus
      birthDate
      bloodPressureMax
      bloodPressureMin
      bloodType
      canWorkWithoutProblems
      ccusNumber
      checkListInfo
      checkListInputContents
      choiceText
      companyAddress1
      companyAddress2
      companyAddress3
      companyCEOName
      companyId
      companyName
      companyOccupation
      companyOrder
      companyTell
      companyZipCode
      contentText
      discardCopyProhibited
      disclosureProhibited
      educationDate
      email
      emergencyContactName
      emergencyContactTell
      employmentInsurance
      employmentInsuranceNumber
      endDate
      experienceMonthPresent
      experienceMonthTotal
      experienceYearPresent
      experienceYearTotal
      familyName
      familyNameKana
      fieldUserId
      firstName
      firstNameKana
      fourthCompanyName
      fourthCompanyNameKana
      freeidId
      gender
      groupName
      hasCcus
      hasChutaikyo
      hasKentaikyo
      healthInsurance
      insuranceExpirationDate
      insuranceGroupName
      insuranceNumber1
      insuranceNumber2
      insuranceNumber3
      invitationCode
      isChief
      isEnteredBasicInfo
      isForeigner
      isForeman
      isSelfEmployment
      isSMEOwnerOrSingleMaster
      lastUpdateDate
      licenceQualification
      licenceQualificationChoices
      licenceQualificationOthers
      masterCode
      medicalCheckupCategory
      medicalCheckupLatestDate
      mobileNumber
      name
      normKnowledge
      notes
      notesFileUrl
      observancePledge
      occupationChoices
      pensionInsurance
      pihAgreementFlag
      primaryCompanies
      primaryCompanyName
      qrCodeDescription
      residenceCardNumber1
      residenceCardNumber2
      residenceCardNumber3
      secondaryCompanyName
      secondaryCompanyNameKana
      shootingProhibited
      siteAddress
      siteClearanceCreatedAt
      siteClearanceUpdatedAt
      siteCode
      siteName
      siteOccupation
      skillTraining
      skillTrainingChoices
      skillTrainingOthers
      snsPostingProhibited
      specialTraining
      specialTrainingChoices
      specialTrainingOthers
      startDate
      stayPeriod
      thirdCompanyName
      thirdCompanyNameKana
      userId
      workerType
      youthElderly
      zipCode
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      siteClearance {
        id
        freeidId
        invitationCode
        qrCodeDescription
        masterCode
        siteName
        siteAddress
        applyStatus
        startDate
        endDate
        notesFileUrl
        fieldUserId
        isEnteredBasicInfo
        name
        firstName
        familyName
        firstNameKana
        familyNameKana
        gender
        zipCode
        address1
        address2
        address3
        mobileNumber
        email
        birthDate
        emergencyContactName
        emergencyContactTell
        isSelfEmployment
        companyName
        companyCEOName
        companyZipCode
        companyAddress1
        companyAddress2
        companyAddress3
        companyTell
        companyOccupation
        experienceYearPresent
        experienceMonthPresent
        experienceYearTotal
        experienceMonthTotal
        licenceQualification
        licenceQualificationOthers
        skillTraining
        skillTrainingOthers
        specialTraining
        specialTrainingOthers
        hasKentaikyo
        hasCcus
        ccusNumber
        isForeigner
        residenceCardNumber1
        residenceCardNumber2
        residenceCardNumber3
        stayPeriod
        isSMEOwnerOrSingleMaster
        insuranceGroupName
        insuranceNumber1
        insuranceNumber2
        insuranceNumber3
        insuranceExpirationDate
        medicalCheckupCategory
        medicalCheckupLatestDate
        bloodPressureMax
        bloodPressureMin
        bloodType
        anamnesis
        anamnesisOthers
        canWorkWithoutProblems
        lastUpdateDate
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        agreementUrl
        pihAgreementFlag
        checkListInfo
        contentText
        choiceText
        companyOrder
        primaryCompanyName
        secondaryCompanyName
        secondaryCompanyNameKana
        thirdCompanyName
        thirdCompanyNameKana
        fourthCompanyName
        fourthCompanyNameKana
        groupName
        siteOccupation
        workerType
        isForeman
        youthElderly
        notes
        normKnowledge
        agreementOfYouth
        agreementOfElderly
        shootingProhibited
        snsPostingProhibited
        disclosureProhibited
        discardCopyProhibited
        observancePledge
        checkListInputContents
        healthInsurance
        pensionInsurance
        employmentInsurance
        employmentInsuranceNumber
        hasChutaikyo
        accessLevel
        educationDate
        isChief
        createdAt
        updatedAt
        companyId
        siteCode
        userId
      }
    }
  }
`;
export const createSiteLog = /* GraphQL */ `
  mutation CreateSiteLog(
    $input: CreateSiteLogInput!
    $condition: ModelSiteLogConditionInput
  ) {
    createSiteLog(input: $input, condition: $condition) {
      id
      freeidId
      thermometryInfo
      usedAt
      authType
      deviceName
      companyName
      siteName
      firstName
      familyName
      createdAt
      updatedAt
      exited
      exitedAt
      exitDeviceCode
      exitDeviceName
      enteredSiteLogId
      companyId
      siteCode
      deviceCode
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      sitegate {
        id
        deviceCode
        siteName
        contractCompanyCode
        deviceName
        companyId
        siteCode
        userId
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSiteLog = /* GraphQL */ `
  mutation UpdateSiteLog(
    $input: UpdateSiteLogInput!
    $condition: ModelSiteLogConditionInput
  ) {
    updateSiteLog(input: $input, condition: $condition) {
      id
      freeidId
      thermometryInfo
      usedAt
      authType
      deviceName
      companyName
      siteName
      firstName
      familyName
      createdAt
      updatedAt
      exited
      exitedAt
      exitDeviceCode
      exitDeviceName
      enteredSiteLogId
      companyId
      siteCode
      deviceCode
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      sitegate {
        id
        deviceCode
        siteName
        contractCompanyCode
        deviceName
        companyId
        siteCode
        userId
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSiteLogHistory = /* GraphQL */ `
  mutation CreateSiteLogHistory(
    $input: CreateSiteLogHistoryInput!
    $condition: ModelSiteLogHistoryConditionInput
  ) {
    createSiteLogHistory(input: $input, condition: $condition) {
      id
      siteLogId
      usedAt
      preUsedAt
      editor
      email
      action
      authType
      createdAt
      updatedAt
      siteLog {
        id
        freeidId
        thermometryInfo
        usedAt
        authType
        deviceName
        companyName
        siteName
        firstName
        familyName
        createdAt
        updatedAt
        exited
        exitedAt
        exitDeviceCode
        exitDeviceName
        enteredSiteLogId
        companyId
        siteCode
        deviceCode
        userId
      }
    }
  }
`;
export const createSiteGate = /* GraphQL */ `
  mutation CreateSiteGate(
    $input: CreateSiteGateInput!
    $condition: ModelSiteGateConditionInput
  ) {
    createSiteGate(input: $input, condition: $condition) {
      id
      deviceCode
      siteName
      contractCompanyCode
      deviceName
      companyId
      siteCode
      userId
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      sitelogs {
        nextToken
      }
    }
  }
`;
export const updateSiteGate = /* GraphQL */ `
  mutation UpdateSiteGate(
    $input: UpdateSiteGateInput!
    $condition: ModelSiteGateConditionInput
  ) {
    updateSiteGate(input: $input, condition: $condition) {
      id
      deviceCode
      siteName
      contractCompanyCode
      deviceName
      companyId
      siteCode
      userId
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      sitelogs {
        nextToken
      }
    }
  }
`;
export const createSiteManagement = /* GraphQL */ `
  mutation CreateSiteManagement(
    $input: CreateSiteManagementInput!
    $condition: ModelSiteManagementConditionInput
  ) {
    createSiteManagement(input: $input, condition: $condition) {
      id
      companyName
      siteName
      fieldUserId
      lastName
      firstName
      email
      phoneNumber
      status
      accessLevel
      startDate
      endDate
      companyId
      siteId
      userId
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSiteManagement = /* GraphQL */ `
  mutation UpdateSiteManagement(
    $input: UpdateSiteManagementInput!
    $condition: ModelSiteManagementConditionInput
  ) {
    updateSiteManagement(input: $input, condition: $condition) {
      id
      companyName
      siteName
      fieldUserId
      lastName
      firstName
      email
      phoneNumber
      status
      accessLevel
      startDate
      endDate
      companyId
      siteId
      userId
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSiteAccess = /* GraphQL */ `
  mutation CreateSiteAccess(
    $input: CreateSiteAccessInput!
    $condition: ModelSiteAccessConditionInput
  ) {
    createSiteAccess(input: $input, condition: $condition) {
      id
      companyName
      siteName
      gateName
      lastName
      firstName
      userTemperature
      entry
      exit
      createdAt
      updatedAt
      companyId
      siteId
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSiteAccess = /* GraphQL */ `
  mutation UpdateSiteAccess(
    $input: UpdateSiteAccessInput!
    $condition: ModelSiteAccessConditionInput
  ) {
    updateSiteAccess(input: $input, condition: $condition) {
      id
      companyName
      siteName
      gateName
      lastName
      firstName
      userTemperature
      entry
      exit
      createdAt
      updatedAt
      companyId
      siteId
      userId
      user {
        id
        username
        email
        firstName
        lastName
        firstNameFurigana
        lastNameFurigana
        phoneNumber
        currentSiteId
        currentMasterCode
        currentSiteName
        currentSiteFloor
        currentSiteRoom
        currentRowIndex
        currentColumnIndex
        isActive
        companyId
        createdAt
        updatedAt
      }
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const createCompanyVendor = /* GraphQL */ `
  mutation CreateCompanyVendor(
    $input: CreateCompanyVendorInput!
    $condition: ModelCompanyVendorConditionInput
  ) {
    createCompanyVendor(input: $input, condition: $condition) {
      companyId
      vendorId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      vendor {
        id
        vendorCode
        vendorName
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCompanyVendor = /* GraphQL */ `
  mutation UpdateCompanyVendor(
    $input: UpdateCompanyVendorInput!
    $condition: ModelCompanyVendorConditionInput
  ) {
    updateCompanyVendor(input: $input, condition: $condition) {
      companyId
      vendorId
      createdAt
      updatedAt
      company {
        id
        contractCompanyCode
        companyName
        address1
        address2
        city
        zipCode
        type
        phoneNumber
        emailFormat
        agreementUrl
        licenceQualificationChoices
        skillTrainingChoices
        specialTrainingChoices
        occupationChoices
        primaryCompanies
        createdAt
        updatedAt
      }
      vendor {
        id
        vendorCode
        vendorName
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSiteVendor = /* GraphQL */ `
  mutation CreateSiteVendor(
    $input: CreateSiteVendorInput!
    $condition: ModelSiteVendorConditionInput
  ) {
    createSiteVendor(input: $input, condition: $condition) {
      id
      siteId
      vendorId
      createdAt
      updatedAt
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      vendor {
        id
        vendorCode
        vendorName
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSiteVendor = /* GraphQL */ `
  mutation UpdateSiteVendor(
    $input: UpdateSiteVendorInput!
    $condition: ModelSiteVendorConditionInput
  ) {
    updateSiteVendor(input: $input, condition: $condition) {
      id
      siteId
      vendorId
      createdAt
      updatedAt
      site {
        id
        siteName
        masterCode
        address1
        address2
        address3
        city
        zipCode
        notesFileUrl
        qrCodeDescription
        contentText1
        choiceText1
        contentText2
        choiceText2
        contentText3
        choiceText3
        contentText4
        choiceText4
        contentText5
        choiceText5
        contentText6
        choiceText6
        contentText7
        choiceText7
        contentText8
        choiceText8
        contentText9
        choiceText9
        contentText10
        choiceText10
        contentText11
        choiceText11
        contentText12
        choiceText12
        contentText13
        choiceText13
        contentText14
        choiceText14
        contentText15
        choiceText15
        contentText16
        choiceText16
        contentText17
        choiceText17
        contentText18
        choiceText18
        contentText19
        choiceText19
        contentText20
        choiceText20
        contentText21
        choiceText21
        contentText22
        choiceText22
        contentText23
        choiceText23
        contentText24
        choiceText24
        contentText25
        choiceText25
        contentText26
        choiceText26
        contentText27
        choiceText27
        contentText28
        choiceText28
        contentText29
        choiceText29
        contentText30
        choiceText30
        contentText31
        choiceText31
        contentText32
        choiceText32
        contentText33
        choiceText33
        contentText34
        choiceText34
        contentText35
        choiceText35
        contentText36
        choiceText36
        contentText37
        choiceText37
        contentText38
        choiceText38
        contentText39
        choiceText39
        contentText40
        choiceText40
        contentText41
        choiceText41
        contentText42
        choiceText42
        contentText43
        choiceText43
        contentText44
        choiceText44
        contentText45
        choiceText45
        contentText46
        choiceText46
        contentText47
        choiceText47
        contentText48
        choiceText48
        contentText49
        choiceText49
        contentText50
        choiceText50
        contentText51
        choiceText51
        contentText52
        choiceText52
        contentText53
        choiceText53
        contentText54
        choiceText54
        contentText55
        choiceText55
        contentText56
        choiceText56
        contentText57
        choiceText57
        contentText58
        choiceText58
        contentText59
        choiceText59
        contentText60
        choiceText60
        contentText61
        choiceText61
        contentText62
        choiceText62
        contentText63
        choiceText63
        contentText64
        choiceText64
        contentText65
        choiceText65
        contentText66
        choiceText66
        contentText67
        choiceText67
        contentText68
        choiceText68
        contentText69
        choiceText69
        contentText70
        choiceText70
        contentText71
        choiceText71
        contentText72
        choiceText72
        contentText73
        choiceText73
        contentText74
        choiceText74
        contentText75
        choiceText75
        contentText76
        choiceText76
        contentText77
        choiceText77
        contentText78
        choiceText78
        contentText79
        choiceText79
        contentText80
        choiceText80
        contentText81
        choiceText81
        contentText82
        choiceText82
        contentText83
        choiceText83
        contentText84
        choiceText84
        contentText85
        choiceText85
        contentText86
        choiceText86
        contentText87
        choiceText87
        contentText88
        choiceText88
        contentText89
        choiceText89
        contentText90
        choiceText90
        contentText91
        choiceText91
        contentText92
        choiceText92
        contentText93
        choiceText93
        contentText94
        choiceText94
        contentText95
        choiceText95
        contentText96
        choiceText96
        contentText97
        choiceText97
        contentText98
        choiceText98
        contentText99
        choiceText99
        contentText100
        choiceText100
        latitude
        longitude
        altitude
        height
        floors
        latitude1
        longitude1
        latitude2
        longitude2
        latitude3
        longitude3
        latitude4
        longitude4
        latitude5
        longitude5
        itemRssi
        anchorRssi
        updateFrequency
        companyId
        vendorIds
        createdAt
        updatedAt
      }
      vendor {
        id
        vendorCode
        vendorName
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const createVendor = /* GraphQL */ `
  mutation CreateVendor(
    $input: CreateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    createVendor(input: $input, condition: $condition) {
      id
      vendorCode
      vendorName
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const updateVendor = /* GraphQL */ `
  mutation UpdateVendor(
    $input: UpdateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    updateVendor(input: $input, condition: $condition) {
      id
      vendorCode
      vendorName
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const createVendorHistory = /* GraphQL */ `
  mutation CreateVendorHistory(
    $input: CreateVendorHistoryInput!
    $condition: ModelVendorHistoryConditionInput
  ) {
    createVendorHistory(input: $input, condition: $condition) {
      id
      vendorId
      editedColumn
      preVendorName
      vendorName
      preVendorCode
      vendorCode
      editor
      createdAt
      updatedAt
      vendor {
        id
        vendorCode
        vendorName
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateVendorHistory = /* GraphQL */ `
  mutation UpdateVendorHistory(
    $input: UpdateVendorHistoryInput!
    $condition: ModelVendorHistoryConditionInput
  ) {
    updateVendorHistory(input: $input, condition: $condition) {
      id
      vendorId
      editedColumn
      preVendorName
      vendorName
      preVendorCode
      vendorCode
      editor
      createdAt
      updatedAt
      vendor {
        id
        vendorCode
        vendorName
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;
