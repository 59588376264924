import React, { useState } from "react";
import {
  DataGrid,
  jaJP,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";

import LoadingProgress from "../components/LoadingProgress";
import { format } from "date-fns";
import dayjs from "dayjs";

const RELEASE_DATE_OF_ENTER_EXIT_HISTORY = new Date("2023-10-18");

const columns = [
  {
    field: "masterCode",
    headerName: "案件コード",
    headerClassName: "super-app-theme--header",
    editable: false,
    sortable: true,
    width: 150,
  },
  {
    field: "siteName",
    headerName: "現場名",
    headerClassName: "super-app-theme--header",
    editable: false,
    sortable: true,
    width: 250,
  },
  {
    field: "vendorCode",
    headerName: "1次事業者コード",
    headerClassName: "super-app-theme--header",
    editable: false,
    sortable: true,
    width: 150,
    valueGetter: (params) => {
      if (params.row.vendorCode) {
        return params.row.vendorCode;
      } else {
        return "";
      }
    },
  },
  {
    field: "primaryCompanyName",
    headerName: "1次事業者名",
    headerClassName: "super-app-theme--header",
    editable: false,
    sortable: true,
    width: 150,
    valueGetter: (params) => {
      if (
        params.row.vendorName &&
        new Date(params.row.usedAt) >= RELEASE_DATE_OF_ENTER_EXIT_HISTORY
      ) {
        return params.row.vendorName;
      } else if (
        params.row.primaryCompanyName &&
        new Date(params.row.usedAt) < RELEASE_DATE_OF_ENTER_EXIT_HISTORY
      ) {
        return params.row.primaryCompanyName;
      } else {
        return "";
      }
    },
  },
  {
    field: "name",
    headerName: "名前",
    headerClassName: "super-app-theme--header",
    editable: false,
    sortable: true,
    width: 150,
  },
  {
    field: "companyName",
    headerName: "会社名",
    headerClassName: "super-app-theme--header",
    width: 150,
    editable: false,
  },
  {
    field: "usedAt",
    headerName: "入場時刻",
    headerClassName: "super-app-theme--header",
    width: 200,
    editable: false,
    valueFormatter: (params) =>
      format(
        new Date(
          new Date(params.value).toLocaleString("ja-JP", { timeZone: "Japan" })
        ),
        "yyyy-MM-dd HH:mm:ss"
      ),
  },
  {
    field: "exitedAt",
    headerName: "退場時刻",
    headerClassName: "super-app-theme--header",
    width: 200,
    editable: false,
    valueGetter: (params) => {
      if (params.row.exitedAt) {
        return format(
          new Date(
            new Date(params.row.exitedAt).toLocaleString("ja-JP", {
              timeZone: "Japan",
            })
          ),
          "yyyy-MM-dd HH:mm:ss"
        );
      } else {
        return "";
      }
    },
  },
  {
    field: "exited",
    headerName: "退場",
    headerClassName: "super-app-theme--header",
    width: 100,
    editable: false,
    valueGetter: (params) => {
      if (params.row.exited) {
        return "退場済み";
      } else {
        return "";
      }
    },
  },
  {
    field: "time",
    headerName: "滞在時間",
    headerClassName: "super-app-theme--header",
    width: 100,
    editable: false,
    valueGetter: (params) => {
      if (params.row.exited) {
        var endTime = new Date(
          new Date(params.row.exitedAt).toLocaleString("ja-JP", {
            timeZone: "Japan",
          })
        );
      } else {
        var endTime = new Date(
          new Date().toLocaleString("ja-JP", { timeZone: "Japan" })
        );
      }

      const startTime = new Date(
        new Date(params.row.usedAt).toLocaleString("ja-JP", {
          timeZone: "Japan",
        })
      );
      return `${Math.round(
        (endTime.getTime() - startTime.getTime()) / 1000 / 60 / 60
      )} 時間`;
    },
  },
  {
    field: "deviceName",
    headerName: "ゲート",
    headerClassName: "super-app-theme--header",
    width: 150,
    editable: false,
    valueGetter: (params) => {
      if (params.row.enteredDeviceName) {
        return params.row.enteredDeviceName;
      } else if (params.row.deviceName) {
        return params.row.deviceName;
      } else {
        return "";
      }
    },
  },
  {
    field: "thermometryInfo",
    headerClassName: "super-app-theme--header",
    headerName: "検温結果",
    width: 100,
    editable: false,
    valueGetter: (params) => {
      if (params.row.enteredThermometryInfo) {
        return params.row.enteredThermometryInfo;
      } else if (params.row.thermometryInfo) {
        return params.row.thermometryInfo;
      } else {
        return 0.0;
      }
    },
  },
  {
    field: "siteOccupation",
    headerClassName: "super-app-theme--header",
    headerName: "工種",
    width: 200,
    editable: false,
  },
  {
    field: "latestEnteredPreUsedAt",
    headerClassName: "super-app-theme--header",
    headerName: "修正前時刻(入場)",
    width: 200,
    editable: false,
    valueGetter: (params) => {
      if (params.row.latestEnteredPreUsedAt) {
        const date = dayjs(params.row.latestEnteredPreUsedAt);
        return date.isValid() ? date.format("YYYY-MM-DD HH:mm:ss") : "";
      } else {
        return "";
      }
    },
  },
  {
    field: "latestEnteredUsedAt",
    headerClassName: "super-app-theme--header",
    headerName: "修正後時刻(入場)",
    width: 200,
    editable: false,
    valueGetter: (params) => {
      if (params.row.latestEnteredUsedAt) {
        const date = dayjs(params.row.latestEnteredUsedAt);
        return date.isValid() ? date.format("YYYY-MM-DD HH:mm:ss") : "";
      } else {
        return "";
      }
    },
  },
  {
    field: "latestEnteredCreatedAt",
    headerClassName: "super-app-theme--header",
    headerName: "修正日時(入場)",
    width: 200,
    editable: false,
    valueGetter: (params) => {
      if (params.row.latestEnteredCreatedAt) {
        const date = dayjs(params.row.latestEnteredCreatedAt);
        return date.isValid() ? date.format("YYYY-MM-DD HH:mm:ss") : "";
      } else {
        return "";
      }
    },
  },
  {
    field: "latestEnteredEditor",
    headerClassName: "super-app-theme--header",
    headerName: "編集者(入場)",
    width: 200,
    editable: false,
    valueGetter: (params) => params.row.latestEnteredEditor,
  },
  {
    field: "latestEnteredEmail",
    headerClassName: "super-app-theme--header",
    headerName: "編集者メールアドレス(入場)",
    width: 200,
    editable: false,
    valueGetter: (params) => params.row.latestEnteredEmail,
  },
  {
    field: "latestExitedPreUsedAt",
    headerClassName: "super-app-theme--header",
    headerName: "修正前時刻(退場)",
    width: 200,
    editable: false,
    valueGetter: (params) => {
      if (params.row.latestExitedPreUsedAt) {
        const date = dayjs(params.row.latestExitedPreUsedAt);
        return date.isValid() ? date.format("YYYY-MM-DD HH:mm:ss") : "";
      } else {
        return "";
      }
    },
  },
  {
    field: "latestExitedUsedAt",
    headerClassName: "super-app-theme--header",
    headerName: "修正後時刻(退場)",
    width: 200,
    editable: false,
    valueGetter: (params) => {
      if (params.row.latestExitedUsedAt) {
        const date = dayjs(params.row.latestExitedUsedAt);
        return date.isValid() ? date.format("YYYY-MM-DD HH:mm:ss") : "";
      } else {
        return "";
      }
    },
  },
  {
    field: "latestExitedCreatedAt",
    headerClassName: "super-app-theme--header",
    headerName: "修正日時(退場)",
    width: 200,
    editable: false,
    valueGetter: (params) => {
      if (params.row.latestExitedCreatedAt) {
        const date = dayjs(params.row.latestExitedCreatedAt);
        return date.isValid() ? date.format("YYYY-MM-DD HH:mm:ss") : "";
      } else {
        return "";
      }
    },
  },
  {
    field: "latestExitedEditor",
    headerClassName: "super-app-theme--header",
    headerName: "編集者(退場)",
    width: 200,
    editable: false,
    valueGetter: (params) => params.row.latestExitedEditor || "",
  },
  {
    field: "latestExitedEmail",
    headerClassName: "super-app-theme--header",
    headerName: "編集者メールアドレス(退場)",
    width: 200,
    editable: false,
    valueGetter: (params) => params.row.latestExitedEmail || "",
  },
  {
    field: "latest.createdAt",
    headerClassName: "super-app-theme--header",
    headerName: "最終更新日",
    width: 200,
    editable: false,
    valueGetter: (params) => {
      if (
        params.row.usedAt ||
        params.row.exitedAt ||
        params.row.latestEnteredCreatedAt ||
        params.row.latestExitedCreatedAt
      ) {
        const usedAtDate = params.row.usedAt ? dayjs(params.row.usedAt) : null;
        const exitedAtDate = params.row.exitedAt
          ? dayjs(params.row.exitedAt)
          : null;
        const latestEnteredCreatedAtDate = params.row.latestEnteredCreatedAt
          ? dayjs(params.row.latestEnteredCreatedAt)
          : null;
        const latestExitedCreatedAtDate = params.row.latestExitedCreatedAt
          ? dayjs(params.row.latestExitedCreatedAt)
          : null;
        let latestDate = null;
        if (usedAtDate && usedAtDate.isValid()) {
          latestDate = usedAtDate;
        }
        if (
          exitedAtDate &&
          exitedAtDate.isValid() &&
          (!latestDate || exitedAtDate.isAfter(latestDate))
        ) {
          latestDate = exitedAtDate;
        }
        if (
          latestEnteredCreatedAtDate &&
          latestEnteredCreatedAtDate.isValid() &&
          (!latestEnteredCreatedAtDate ||
            latestEnteredCreatedAtDate.isAfter(latestDate))
        ) {
          latestDate = latestEnteredCreatedAtDate;
        }
        if (
          latestExitedCreatedAtDate &&
          latestExitedCreatedAtDate.isValid() &&
          (!latestExitedCreatedAtDate ||
            latestExitedCreatedAtDate.isAfter(latestDate))
        ) {
          latestDate = latestExitedCreatedAtDate;
        }
        return latestDate ? latestDate.format("YYYY-MM-DD HH:mm:ss") : "";
      } else {
        return "";
      }
    },
  },
];

export default function SiteDataExportTable({
  rows,
  siteName,
  dateStart,
  dateEnd,
}) {
  const [selectionModel, setSelectionModel] = useState([]);
  const [isLoading, setIsLoading] = useState(null);

  const [pageSize, setPageSize] = React.useState(10);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport
          csvOptions={{
            fileName: `${siteName}_${format(
              new Date(
                new Date(dateStart).toLocaleString("ja-JP", {
                  timeZone: "Japan",
                })
              ),
              "yyyy-MM-dd"
            )}_${format(
              new Date(
                new Date(dateEnd).toLocaleString("ja-JP", {
                  timeZone: "Japan",
                })
              ),
              "yyyy-MM-dd"
            )}`,
            utf8WithBom: true,
            allColumns: true,
          }}
          printOptions={{ disableToolbarButton: true }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: "#F9F9F9",
          border: 0,
          pl: 0,
          pt: 1,
          pb: 1,
          fontFamily: "Regular",
          fontSize: 12,
          fontWeight: "400",
          color: "#8D8D8D",
        },
        "& .super-app-theme--rows": {
          backgroundColor: "#F9F9F9",
          borderBottom: 1,
          borderColor: "#E5E5E5",
          pl: 0,
          fontFamily: "Regular",
          fontSize: 15,
          fontWeight: "400",
          color: "#383838",
          "&:hover": {
            backgroundColor: "#E3E9FA",
            borderBottom: 1,
            borderColor: "#99B4E3",
          },
        },
      }}
    >
      <LoadingProgress isLoading={isLoading} />
      {rows && (
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                masterCode: false,
                siteName: false,
                vendorCode: false,
                primaryCompanyName: false,
              },
            },
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          disableSelectionOnClick
          sx={{
            borderTop: 1,
            borderBottom: 0,
            borderRight: 0,
            borderLeft: 0,
            borderRadius: 0,
            borderColor: "#E5E5E5",
          }}
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          getRowClassName={(params) => `super-app-theme--rows`}
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
          rowsPerPageOptions={[10, 50, 100]}
          components={{ Toolbar: CustomToolbar }}
        />
      )}
    </Box>
  );
}
