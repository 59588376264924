import * as React from "react";
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import NavigationMenu from "../components/NavigationMenu";
import LoadingProgress from "../components/LoadingProgress";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const drawerWidth = 280;

class MyAccountComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authState: this.props.authState,
      companyId: "",
      email: "",
      familyName: "",
      givenName: "",
      phoneNumber: "",
      isLoading: false,
      emailError: null,
      passwordError: null,
      passwordLengthError: null,
      phoneNumberError: null,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.findUser();
  }

  findUser() {
    this.setState({
      isLoading: true,
    });
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((user) => {
        this.setState({
          authData: user,
          authState: "signedIn",
          email: user.attributes.email ? user.attributes.email : "",
          familyName: user.attributes.family_name
            ? user.attributes.family_name
            : "",
          givenName: user.attributes.given_name
            ? user.attributes.given_name
            : "",
          phoneNumber: user.attributes.phone_number
            ? user.attributes.phone_number
            : "",
          stateFromStorage: true,
        });

        this.setState({
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({ authState: "signIn" });
      });
  }

  handleInputChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleEmailChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if (/\S+@\S+\.\S+/.test(event.target.value)) {
      this.setState({
        emailError: null,
      });
    } else {
      this.setState({
        emailError: "不正な形式です。",
      });
    }
  }

  handlePasswordChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if (event.target.value == this.state.password) {
      this.setState({
        passwordError: null,
      });
    } else {
      this.setState({
        passwordError: "パスワードが一致しません。もう一度入力してください。",
      });
    }

    if (event.target.value.length >= 8) {
      this.setState({
        passwordLengthError: null,
      });
    } else {
      this.setState({
        passwordLengthError: "パスワードは8文字以上が必須です。",
      });
    }
  }

  handlePhoneNumberChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if (/^\+/.test(event.target.value) && event.target.value.length >= 12) {
      this.setState({
        phoneNumberError: null,
      });
    } else {
      this.setState({
        phoneNumberError:
          "不正な形式です。+810000000000の形式で入力してください。",
      });
    }
  }

  handleSubmit = () => {
    Auth.updateUserAttributes(this.state.authData, {
      email: this.state.email,
      family_name: this.state.familyName,
      given_name: this.state.givenName,
      phone_number: this.state.phoneNumber,
    }).catch((e) => {
      console.log("Error updating user: ");
      console.log(e);
    });
  };

  render() {
    let isLoading = true;
    if (this.state.authData) {
      isLoading = false;
    }
    const drawerList = ["作業中現場一覧"];

    const navigationList = ["/select-site"];

    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/select-site")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                ユーザーの管理
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
        <NavigationMenu bottomIndex={null} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#F9F9F9",
            height: "100%",
            minHeight: "100vh",
            p: 3,
          }}
        >
          <LoadingProgress isLoading={this.state.isLoading} />
          <Grid item xs={12} mb={2}>
            <Toolbar />
            <Box component="form" sx={{ width: 480 }}>
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  お名前
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  marginTop: 0,
                  marginBottom: 4,
                }}
              >
                <TextField
                  id="familyName"
                  name="familyName"
                  type="text"
                  value={this.state.familyName}
                  onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  disabled="true"
                  fullWidth
                />
                <TextField
                  id="givenName"
                  name="givenName"
                  type="text"
                  value={this.state.givenName}
                  onChange={this.handleInputChange}
                  variant="standard"
                  margin="normal"
                  disabled="true"
                  fullWidth
                  sx={{
                    marginLeft: 2,
                  }}
                />
              </Stack>
            </Box>
            <Box component="form" sx={{ width: 480 }}>
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  メールアドレス
                </Typography>
              </Stack>
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 12,
                  fontWeight: "500",
                  color: "#DD3900",
                  mb: 0,
                }}
              >
                {this.state.emailError}
              </Typography>
              <TextField
                id="email"
                placeholder="メールアドレス"
                name="email"
                type="text"
                value={this.state.email}
                onChange={this.handleEmailChange}
                variant="standard"
                margin="normal"
                fullWidth
                autoComplete="email"
                disabled="true"
                // autoFocus
                sx={{
                  marginTop: 1,
                  marginBottom: 4,
                }}
              />
              <Stack
                direction="row"
                sx={{
                  mb: 0,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#383838",
                    mb: 0,
                    mr: 1,
                  }}
                >
                  電話番号
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  marginTop: 0,
                  marginBottom: 4,
                }}
              >
                <TextField
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  value={this.state.phoneNumber}
                  onChange={this.handlePhoneChange}
                  variant="standard"
                  margin="normal"
                  disabled="true"
                  fullWidth
                />
              </Stack>
            </Box>
            <Stack
              direction="row"
              sx={{
                mb: 0,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mb: 0,
                  mr: 1,
                }}
              >
                パスワード
              </Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{
                marginTop: 1,
                marginBottom: 4,
              }}
            >
              <Button
                href="/change-password"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#383838",
                  backgroundColor: "#EBE91A",
                  height: 40,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  pl: 2,
                  pr: 2,
                  // marginBottom: 5,
                  justifyContent: "space-between",
                  mr: 1,
                  "&:hover": {
                    backgroundColor: "#EBE91A",
                  },
                }}
              >
                変更する
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Box>
    );
  }
}

function MyAccountScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <MyAccountComponent {...props} navigate={navigate} location={location} />
  );
}

export default MyAccountScreen;
