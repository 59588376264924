import { Auth } from "aws-amplify";
import fetchCompanyRoles from "../queries/FetchCompanyRoles";
import fetchSiteRoles from "../queries/FetchSiteRoles";
import { DEFAULT_ROLE_LEVEL } from "./constants";

async function getRoleLevel(returnSiteRoleList = false) {
  const userInfo = await Auth.currentAuthenticatedUser();
  const filter = {
    and: [
      {
        companyId: {
          eq: userInfo.attributes["custom:company_id"],
        },
      },
      {
        userId: {
          eq: userInfo.attributes.sub,
        },
      },
    ],
  };
  const [companyRoles] = await fetchCompanyRoles(filter);
  let roleLevel = DEFAULT_ROLE_LEVEL;
  let siteRoleList;
  if (companyRoles.roleLevel !== DEFAULT_ROLE_LEVEL) {
    roleLevel = companyRoles.roleLevel;
  } else {
    const filterSiteRole = {
      and: [
        {
          userId: {
            eq: userInfo.attributes.sub,
          },
        },
      ],
    };
    siteRoleList = await fetchSiteRoles(filterSiteRole);
    if (siteRoleList.length > 0) {
      roleLevel = siteRoleList[0].roleLevel;
    }
  }
  if (returnSiteRoleList) {
    return [roleLevel, siteRoleList];
  }
  return roleLevel;
}

export default getRoleLevel;
