import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth, API, graphqlOperation } from "aws-amplify";
import makeDrawerList from "../components/utils/makeDrawerList";
import NavigationMenu from "../components/NavigationMenu";
import LoadingProgress from "../components/LoadingProgress";
import RegisterCompanyUserBulkTable from "../components/RegisterCompanyUserBulkTable";
import TopBar from "../components/TopBar";
import fetchUser from "../components/queries/FetchUser";
import { sendMail } from "../graphql/mutations";
import { DRAWER_WIDTH } from "../components/utils/constants";
import {
  Box,
  CssBaseline,
  Toolbar,
  Grid,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyUserList: this.props.location.state.companyUserList,
      userDetails: "",
      siteDetails: "",
      companyDetails: "",
      isLoading: false,
      drawerList: [],
      navigationList: [],
      snackbar: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  getCompanyId = async () => {
    const userInfo = await Auth.currentAuthenticatedUser();
    const getUser = await fetchUser({
      id: userInfo.attributes.sub,
    });
    return getUser.companyId;
  };

  async onSubmit() {
    try {
      this.setState({
        isLoading: true,
      });
      const origin = location.origin;
      const companyId = await this.getCompanyId();
      const users = [];
      const input = {};
      let requiredError = false;
      this.state.companyUserList.map((user) => {
        if (
          !!user.email &&
          !!user.familyName &&
          !!user.givenName &&
          !!user.password
        ) {
          users.push({
            email: user.email,
            familyName: user.familyName,
            givenName: user.givenName,
            phoneNumber: user.phoneNumber,
            companyId,
            password: user.password,
          });
          input.users = users;
          input.origin = origin;
        } else {
          requiredError = true;
        }
      });
      if (!requiredError) {
        API.graphql(
          graphqlOperation(sendMail, {
            input,
          })
        );
        this.props.navigate("/company-user-list");
      } else {
        throw new Error("必須項目を入力してください。");
      }
    } catch (error) {
      console.log(error);
      if (error.message === "必須項目を入力してください。") {
        this.setState({
          snackbar: {
            children: error.message,
            severity: "error",
          },
        });
      }
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleCloseSnackbar = () => this.setState({ snackbar: null });

  async componentDidMount() {
    const [drawerList, navigationList] = await makeDrawerList();
    this.setState({
      drawerList,
      navigationList,
    });
  }

  render() {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <TopBar
          topTitle="社内メンバー一括登録招待の確認"
          backTo="/register-company-user-bulk"
          navigate={this.props.navigate}
        >
          {this.state.companyUserList && (
            <Button
              type="submit"
              onClick={this.handleSubmit}
              variant="contained"
              sx={{
                fontFamily: "Regular",
                fontSize: 16,
                fontWeight: "700",
                color: "#383838",
                backgroundColor: "#EBE91A",
                height: 40,
                borderRadius: 100,
                paddingHorizontal: 18,
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: "#EBE91A",
                },
              }}
              endIcon={
                <img
                  src={require("../assets/images/rightarrow-on.png")}
                  width="20"
                  height="20"
                />
              }
              disableElevation
            >
              招待する
            </Button>
          )}
          {Boolean(!this.state.companyUserList) && (
            <Button
              variant="contained"
              sx={{
                fontFamily: "Regular",
                fontSize: 16,
                fontWeight: "700",
                color: "#C8C8C8",
                backgroundColor: "#E5E5E5",
                height: 40,
                borderRadius: 100,
                paddingHorizontal: 18,
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: "#E5E5E5",
                },
              }}
              endIcon={
                <img
                  src={require("../assets/images/rightarrow-gray.png")}
                  width="20"
                  height="20"
                />
              }
              disableElevation
            >
              招待する
            </Button>
          )}
        </TopBar>
        <Box
          component="nav"
          sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <NavigationMenu
            drawerList={this.state.drawerList}
            navigationList={this.state.navigationList}
            topIndex={2}
            bottomIndex={null}
          />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#F9F9F9",
            height: "100%",
            minHeight: "100vh",
            p: 3,
          }}
        >
          <LoadingProgress isLoading={this.state.isLoading} />
          <Grid item xs={12} mb={2}>
            <Toolbar />
            <Box component="form" sx={{ width: "85%" }}>
              <RegisterCompanyUserBulkTable rows={this.state.companyUserList} />
            </Box>
          </Grid>
        </Box>
        {!!this.state.snackbar && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open
            onClose={this.handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert
              {...this.state.snackbar}
              onClose={this.handleCloseSnackbar}
            />
          </Snackbar>
        )}
      </Box>
    );
  }
}

function RegisterCompanyUserBulkConfirm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default RegisterCompanyUserBulkConfirm;
