import { API, graphqlOperation } from "aws-amplify";
import { byVendorCode } from "../../graphql/queries";

export default async function fetchByVendorCode(condition) {
  try {
    const response = await API.graphql(
      graphqlOperation(byVendorCode, condition)
    );
    return response.data.byVendorCode;
  } catch (error) {
    console.log(error);
  }
}
