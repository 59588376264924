import React, { useState, useCallback } from "react";
import {
  DataGrid,
  jaJP,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";

export default function CompanyUserEditGrid({
  rows,
  updateEditCell,
  setTargetArray,
  targetArray,
}) {
  const [pageSize, setPageSize] = useState(10);
  const [filterButtonEl, setFilterButtonEl] = useState(null);

  const columns = [
    {
      field: "name",
      headerName: "名前",
      editable: false,
      sortable: true,
      width: 190,
      valueGetter: (params) =>
        `${params.row.familyName || ""} ${params.row.firstName || ""}`,
    },
    {
      field: "email",
      headerName: "メールアドレス",
      editable: false,
      sortable: true,
      width: 300,
      valueGetter: (params) => `${params.row.email}`,
    },
    {
      field: "roleName",
      label: "権限を選択",
      type: "singleSelect",
      headerName: "権限",
      width: 250,
      editable: true,
      valueOptions: ["企業管理者", "企業管理者(閲覧のみ)"],
    },
    {
      field: "isActive",
      headerName: "ステータス",
      headerClassName: "super-app-theme--header",
      width: 250,
      sortable: true,
      editable: true,
      type: "singleSelect",
      valueOptions: ["有効", "無効"],
      flex: 1,
      hide: true,
      valueGetter: (params) => (params.value !== false ? "有効" : "無効"),
    },
  ];

  const CustomToolbar = useCallback(() => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton ref={setFilterButtonEl} />
        <GridToolbarExport
          ref={setFilterButtonEl}
          csvOptions={{ utf8WithBom: true }}
        />
      </GridToolbarContainer>
    );
  }, []);

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id}
        disableSelectionOnClick
        selectionModel={targetArray}
        onSelectionModelChange={setTargetArray}
        checkboxSelection
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        getRowClassName={(params) =>
          `${params.row.isActive === false ? "grayedOut" : ""}`
        }
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        rowsPerPageOptions={[10, 50, 100]}
        sx={{ marginBottom: 2.5 }}
        onCellEditCommit={(v) => {
          updateEditCell(v);
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        componentsProps={{
          panel: {
            anchorEl: filterButtonEl,
          },
        }}
      />
    </Box>
  );
}
