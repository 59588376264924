import * as React from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUser, getCompany, getFreeidUser } from "../graphql/queries";
import {
  updateSiteClearanceWithExternalRequests,
  updateFreeidUser,
} from "../graphql/mutations";
import { useNavigate, useLocation } from "react-router-dom";
import NavigationDrawer from "../components/NavigationDrawer";
import DatePicker from "../components/DatePicker";
import LoadingProgress from "../components/LoadingProgress";
import fetchCompanyRoles from "../components/queries/FetchCompanyRoles";
import fetchSiteRoles from "../components/queries/FetchSiteRoles";
import fetchSiteClearances from "../components/queries/FetchSiteClearances";
import { DEFAULT_ROLE_LEVEL } from "../components/utils/constants";
import { ValidationSnackBar } from "../components/ValidationSnackBar";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CssBaseline,
  Grid,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { format } from "date-fns";

const drawerWidth = 280;

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: "",
      companyDetails: "",
      familyName: this.props.location.state.userList.familyName,
      firstName: this.props.location.state.userList.firstName,
      familyNameKana: this.props.location.state.userList.familyNameKana,
      firstNameKana: this.props.location.state.userList.firstNameKana,
      companyName: this.props.location.state.userList.companyName,
      email: this.props.location.state.userList.email,
      startDate: this.props.location.state.siteClearanceList.startDate
        ? new Date(
            new Date(
              this.props.location.state.siteClearanceList.startDate
            ).toLocaleString("ja-JP", { timeZone: "Japan" })
          )
        : null,
      endDate: this.props.location.state.siteClearanceList.endDate
        ? new Date(
            new Date(
              this.props.location.state.siteClearanceList.endDate
            ).toLocaleString("ja-JP", { timeZone: "Japan" })
          )
        : null,
      educationDate: this.props.location.state.siteClearanceList.educationDate
        ? new Date(
            new Date(
              this.props.location.state.siteClearanceList.educationDate
            ).toLocaleString("ja-JP", { timeZone: "Japan" })
          )
        : null,
      gender: this.props.location.state.userList.gender,
      zipCode: this.props.location.state.userList.zipCode,
      address1: this.props.location.state.userList.address1,
      address2: this.props.location.state.userList.address2,
      address3: this.props.location.state.userList.address3,
      mobileNumber: this.props.location.state.userList.mobileNumber,
      birthDate: this.props.location.state.userList.birthDate,
      emergencyContactName:
        this.props.location.state.userList.emergencyContactName,
      emergencyContactTell:
        this.props.location.state.userList.emergencyContactTell,
      isSelfEmployment: this.props.location.state.userList.isSelfEmployment,
      companyCEOName: this.props.location.state.userList.companyCEOName,
      companyZipCode: this.props.location.state.userList.companyZipCode,
      companyAddress1: this.props.location.state.userList.companyAddress1,
      companyAddress2: this.props.location.state.userList.companyAddress2,
      companyAddress3: this.props.location.state.userList.companyAddress3,
      companyTell: this.props.location.state.userList.companyTell,
      companyOccupation: this.props.location.state.userList.companyOccupation,
      experienceYearPresent:
        this.props.location.state.userList.experienceYearPresent,
      experienceMonthPresent:
        this.props.location.state.userList.experienceMonthPresent,
      experienceYearTotal:
        this.props.location.state.userList.experienceYearTotal,
      experienceMonthTotal:
        this.props.location.state.userList.experienceMonthTotal,
      licenceQualification:
        this.props.location.state.userList.licenceQualification,
      licenceQualificationOthers:
        this.props.location.state.userList.licenceQualificationOthers,
      skillTraining: this.props.location.state.userList.skillTraining,
      skillTrainingOthers:
        this.props.location.state.userList.skillTrainingOthers,
      specialTraining: this.props.location.state.userList.specialTraining,
      specialTrainingOthers:
        this.props.location.state.userList.specialTrainingOthers,
      hasKentaikyo: this.props.location.state.userList.hasKentaikyo
        ? "はい"
        : "いいえ",
      hasCcus: this.props.location.state.userList.hasCcus ? "はい" : "いいえ",
      ccusNumber: this.props.location.state.userList.ccusNumber,
      isForeigner: this.props.location.state.userList.isForeigner,
      residenceCardNumber1:
        this.props.location.state.userList.residenceCardNumber1,
      residenceCardNumber2:
        this.props.location.state.userList.residenceCardNumber2,
      residenceCardNumber3:
        this.props.location.state.userList.residenceCardNumber3,
      stayPeriod: this.props.location.state.userList.stayPeriod,
      isSMEOwnerOrSingleMaster:
        this.props.location.state.userList.isSMEOwnerOrSingleMaster,
      insuranceGroupName: this.props.location.state.userList.insuranceGroupName,
      insuranceNumber1: this.props.location.state.userList.insuranceNumber1,
      insuranceNumber2: this.props.location.state.userList.insuranceNumber2,
      insuranceNumber3: this.props.location.state.userList.insuranceNumber3,
      insuranceExpirationDate:
        this.props.location.state.userList.insuranceExpirationDate,
      medicalCheckupCategory:
        this.props.location.state.userList.medicalCheckupCategory,
      medicalCheckupLatestDate:
        this.props.location.state.userList.medicalCheckupLatestDate,
      bloodPressureMax: this.props.location.state.userList.bloodPressureMax,
      bloodPressureMin: this.props.location.state.userList.bloodPressureMin,
      bloodType: this.props.location.state.userList.bloodType,
      anamnesis: this.props.location.state.userList.anamnesis,
      anamnesisOthers: this.props.location.state.userList.anamnesisOthers,
      canWorkWithoutProblems:
        this.props.location.state.userList.canWorkWithoutProblems,
      companyOrder: this.props.location.state.siteClearanceList.companyOrder,
      primaryCompanyName:
        this.props.location.state.siteClearanceList.primaryCompanyName,
      secondaryCompanyName:
        this.props.location.state.siteClearanceList.secondaryCompanyName,
      secondaryCompanyNameKana:
        this.props.location.state.siteClearanceList.secondaryCompanyNameKana,
      thirdCompanyName:
        this.props.location.state.siteClearanceList.thirdCompanyName,
      thirdCompanyNameKana:
        this.props.location.state.siteClearanceList.thirdCompanyNameKana,
      fourthCompanyName:
        this.props.location.state.siteClearanceList.fourthCompanyName,
      fourthCompanyNameKana:
        this.props.location.state.siteClearanceList.fourthCompanyNameKana,
      groupName: this.props.location.state.siteClearanceList.groupName,
      siteOccupation:
        this.props.location.state.siteClearanceList.siteOccupation,
      workerType: this.props.location.state.siteClearanceList.workerType,
      isForeman: this.props.location.state.siteClearanceList.isForeman,
      youthElderly: this.props.location.state.siteClearanceList.youthElderly,
      notes: this.props.location.state.siteClearanceList.notes,
      normKnowledge: this.props.location.state.siteClearanceList.normKnowledge,
      agreementOfYouth:
        this.props.location.state.siteClearanceList.agreementOfYouth,
      agreementOfElderly:
        this.props.location.state.siteClearanceList.agreementOfElderly,
      shootingProhibited:
        this.props.location.state.siteClearanceList.shootingProhibited,
      snsPostingProhibited:
        this.props.location.state.siteClearanceList.snsPostingProhibited,
      disclosureProhibited:
        this.props.location.state.siteClearanceList.disclosureProhibited,
      discardCopyProhibited:
        this.props.location.state.siteClearanceList.discardCopyProhibited,
      observancePledge:
        this.props.location.state.siteClearanceList.observancePledge,
      checkListInputContents: this.stringToArray(
        this.props.location.state.siteClearanceList.checkListInputContents
      ),
      checkListQuestions:
        this.props.location.state.siteClearanceList.checkListQuestions,
      error: null,
      freeidId: this.props.location.state.userList.id,
      errorDate: null,
      accessLevel: "",
      isLoading: false,
      hasChutaikyo: this.props.location.state.userList.hasChutaikyo
        ? "はい"
        : "いいえ",
      healthInsurance: this.props.location.state.userList.healthInsurance,
      pensionInsurance: this.props.location.state.userList.pensionInsurance,
      employmentInsurance:
        this.props.location.state.userList.employmentInsurance,
      employmentInsuranceNumber:
        this.props.location.state.userList.employmentInsuranceNumber,
      isChief: this.props.location.state.siteClearanceList.isChief,
      snackbar: null,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleEducationDateChange = this.handleEducationDateChange.bind(this);
    this.handleStringChange = this.handleStringChange.bind(this);
    this.handleDateDelete = this.handleDateDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  stringToArray(string) {
    const resArray = string === null ? "" : string.slice(1, -1).split(/,|、/g);
    return resArray;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleEmailChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if (/\S+@\S+\.\S+/.test(event.target.value)) {
      this.setState({
        error: null,
      });
    } else {
      this.setState({
        error: "不正な形式です。",
      });
    }
  }

  handleStartDateChange(event) {
    this.setState({
      startDate: event,
    });
    if (this.state.endDate === null) {
      this.setState({
        errorDate: null,
      });
    } else {
      if (
        format(
          new Date(event.toLocaleString("ja-JP", { timeZone: "Japan" })),
          "yyyy-MM-dd"
        ) >
        format(
          new Date(
            this.state.endDate.toLocaleString("ja-JP", { timeZone: "Japan" })
          ),
          "yyyy-MM-dd"
        )
      ) {
        this.setState({
          errorDate: "終了日は開始日後に設定してください",
        });
      } else {
        this.setState({
          errorDate: null,
        });
      }
    }
  }

  handleDateDelete = () => {
    this.setState({
      endDate: null,
      errorDate: "",
    });
  };

  handleEndDateChange(event) {
    this.setState({
      endDate: event,
    });
    console.log(event);
    if (this.state.startDate === "") {
      this.setState({
        startDate: this.props.location.state.siteClearanceList.startDate,
      });
    }
    if (
      format(
        new Date(
          this.state.startDate.toLocaleString("ja-JP", { timeZone: "Japan" })
        ),
        "yyyy-MM-dd"
      ) >
      format(
        new Date(event.toLocaleString("ja-JP", { timeZone: "Japan" })),
        "yyyy-MM-dd"
      )
    ) {
      this.setState({
        errorDate: "終了日は開始日後に設定してください",
      });
    } else {
      this.setState({
        errorDate: null,
      });
    }
  }

  handleEducationDateChange(event) {
    this.setState({
      educationDate: event,
    });
  }

  handleStringChange(event) {
    if (event) {
      return event.toString().replace("[", "").replace("]", "");
    } else {
      return event;
    }
  }

  handleSubmit(event) {
    this.onSubmit();
    event.preventDefault();
  }

  async componentDidMount() {
    this.fetchUsers();
    this.fetchRoles();
  }

  handleCloseSnackbar = () => this.setState({ snackbar: null });

  validationIsChiefExists = async () => {
    const resSiteClearances = await fetchSiteClearances({
      and: [
        {
          siteCode: {
            eq: this.state.userDetails.currentSiteId,
          },
        },
        {
          isChief: {
            eq: true,
          },
        },
      ],
    });
    if (resSiteClearances.length > 0 && this.state.isChief === true) {
      throw new Error("すでに所長・工事担当者が設定済みです");
    }
  };

  handleUpdateSiteClearanceWithExternalRequests = async (
    responseFreeidUser,
    todayDate
  ) => {
    if (
      this.state.isChief !== this.props.location.state.siteClearanceList.isChief
    ) {
      await this.validationIsChiefExists();
    }
    const havingFreeidUser = !!responseFreeidUser;
    const updatedStatus = this.returnUpdatedStatus(
      responseFreeidUser,
      todayDate
    );
    const userInfo = await Auth.currentAuthenticatedUser();
    const input = {
      id: this.props.location.state.siteClearanceList.id,
      siteCode: this.state.userDetails.currentSiteId,
      startDate:
        !this.state.startDate || this.state.startDate.length === 0
          ? ""
          : format(this.state.startDate, "yyyy-MM-dd"),
      endDate:
        !this.state.endDate || this.state.endDate.length === 0
          ? ""
          : format(this.state.endDate, "yyyy-MM-dd"),
      educationDate:
        !this.state.educationDate || this.state.educationDate.length === 0
          ? ""
          : format(this.state.educationDate, "yyyy-MM-dd"),
      familyName: havingFreeidUser
        ? this.state.familyName
        : this.props.location.state.siteClearanceList.familyName,
      firstName: havingFreeidUser
        ? this.state.firstName
        : this.props.location.state.siteClearanceList.firstName,
      familyNameKana: havingFreeidUser
        ? this.state.familyNameKana
        : this.props.location.state.siteClearanceList.familyNameKana,
      firstNameKana: havingFreeidUser
        ? this.state.firstNameKana
        : this.props.location.state.siteClearanceList.firstNameKana,
      email: havingFreeidUser
        ? this.state.email
        : this.props.location.state.siteClearanceList.email,
      companyName: havingFreeidUser
        ? this.state.companyName
        : this.props.location.state.siteClearanceList.companyName,
      companyId: userInfo.attributes["custom:company_id"],
      freeidId: this.state.freeidId,
      applyStatus: updatedStatus,
      isChief: this.state.isChief,
    };
    await API.graphql(
      graphqlOperation(updateSiteClearanceWithExternalRequests, input)
    );
  };

  returnUpdatedStatus = (responseFreeidUser, todayDate) => {
    return !responseFreeidUser
      ? "招待中"
      : !responseFreeidUser.data.getFreeidUser.isEnteredBasicInfo
      ? "招待中"
      : !this.props.location.state.siteClearanceList.checkListInputContents
      ? "招待中"
      : !format(this.state.startDate, "yyyy-MM-dd") &&
        !format(this.state.endDate, "yyyy-MM-dd")
      ? "入場可"
      : !format(this.state.startDate, "yyyy-MM-dd") &&
        format(this.state.endDate, "yyyy-MM-dd") &&
        todayDate <= format(this.state.endDate, "yyyy-MM-dd")
      ? "入場可"
      : !format(this.state.startDate, "yyyy-MM-dd") &&
        format(this.state.endDate, "yyyy-MM-dd") &&
        todayDate > format(this.state.endDate, "yyyy-MM-dd")
      ? "終了"
      : format(this.state.startDate, "yyyy-MM-dd") &&
        !format(this.state.endDate, "yyyy-MM-dd") &&
        todayDate < format(this.state.startDate, "yyyy-MM-dd")
      ? "待機中"
      : format(this.state.startDate, "yyyy-MM-dd") &&
        !format(this.state.endDate, "yyyy-MM-dd") &&
        todayDate >= format(this.state.startDate, "yyyy-MM-dd")
      ? "入場可"
      : format(this.state.endDate, "yyyy-MM-dd") &&
        todayDate > format(this.state.endDate, "yyyy-MM-dd")
      ? "終了"
      : format(this.state.startDate, "yyyy-MM-dd") &&
        format(this.state.endDate, "yyyy-MM-dd") &&
        todayDate >= format(this.state.startDate, "yyyy-MM-dd") &&
        todayDate <= format(this.state.endDate, "yyyy-MM-dd")
      ? "入場可"
      : format(this.state.startDate, "yyyy-MM-dd") &&
        format(this.state.endDate, "yyyy-MM-dd") &&
        todayDate < format(this.state.startDate, "yyyy-MM-dd")
      ? "待機中"
      : "待機中";
  };

  async onSubmit() {
    try {
      this.setState({
        isLoading: true,
      });

      let responseFreeidUser;
      if (this.props.location.state.freeidId) {
        responseFreeidUser = await API.graphql(
          graphqlOperation(getFreeidUser, {
            id: this.props.location.state.freeidId,
          })
        );
      }
      const todayDate = format(
        new Date(new Date().toLocaleString("ja-JP", { timeZone: "Japan" })),
        "yyyy-MM-dd"
      );

      const currentStatus =
        this.props.location.state.siteClearanceList.applyStatus;
      await this.handleUpdateSiteClearanceWithExternalRequests(
        responseFreeidUser,
        todayDate
      );

      this.setState({
        isLoading: false,
      });

      this.props.navigate("/user-details", {
        state: {
          id: this.props.location.state.siteClearanceList.id,
          freeidId: this.props.location.state.userList.id,
        },
      });
    } catch (error) {
      console.log(error);
      if (error.message === "すでに所長・工事担当者が設定済みです") {
        this.setState({
          snackbar: {
            children: error.message,
            severity: "error",
          },
        });
      }
      this.setState({
        isLoading: false,
      });
    }
  }

  async fetchRoles() {
    try {
      this.setState({
        isLoading: true,
      });
      const userInfo = await Auth.currentAuthenticatedUser();

      const filterCompanyRole = {
        and: [
          {
            companyId: {
              eq: userInfo.attributes["custom:company_id"],
            },
          },
          {
            userId: {
              eq: userInfo.attributes.sub,
            },
          },
        ],
      };

      const resCompanyRoles = await fetchCompanyRoles(filterCompanyRole);
      let roleLevel;
      if (resCompanyRoles[0].roleLevel !== DEFAULT_ROLE_LEVEL) {
        roleLevel = resCompanyRoles[0].roleLevel;
      } else {
        const responseUser = await API.graphql(
          graphqlOperation(getUser, {
            id: userInfo.attributes.sub,
          })
        );

        const filterSiteRole = {
          and: [
            {
              siteCode: {
                eq: responseUser.data.getUser.currentSiteId,
              },
            },
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };

        const resSiteRoles = await fetchSiteRoles(filterSiteRole);
        roleLevel = resSiteRoles[0].roleLevel;
      }

      this.setState({
        accessLevel: roleLevel,
      });

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchUsers() {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();

      const responseUser = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      this.setState({
        userDetails: responseUser.data.getUser,
      });

      const responseCompany = await API.graphql(
        graphqlOperation(getCompany, {
          id: userInfo.attributes["custom:company_id"],
        })
      );
      this.setState({
        companyDetails: responseCompany.data.getCompany,
      });
    } catch (error) {
      console.log(error);
      if (error === "The user is not authenticated") {
        this.props.navigate("/sign-in");
      }
    }
  }

  handleChange = (event) => {
    this.setState({
      isChief: event.target.checked,
    });
  };

  render() {
    const fieldList = [
      { fieldName: "gender", fieldNameJp: "性別" },
      { fieldName: "zipCode", fieldNameJp: "自宅郵便番号（ハイフンなし）" },
      { fieldName: "address1", fieldNameJp: "自宅住所１（都道府県）" },
      { fieldName: "address2", fieldNameJp: "自宅住所２（市区町村）" },
      { fieldName: "address3", fieldNameJp: "自宅住所３（それ以降）" },
      {
        fieldName: "mobileNumber",
        fieldNameJp: "携帯電話番号（ハイフンなし）",
      },
      { fieldName: "birthDate", fieldNameJp: "生年月日" },
      {
        fieldName: "emergencyContactName",
        fieldNameJp: "緊急連絡先（家族等）氏名",
      },
      {
        fieldName: "emergencyContactTell",
        fieldNameJp: "緊急連絡先（家族等）電話番号",
      },
      { fieldName: "isSelfEmployment", fieldNameJp: "個人事業主" },
      {
        fieldName: "companyCEOName",
        fieldNameJp: "所属会社代表者名（フルネーム）",
      },
      {
        fieldName: "companyZipCode",
        fieldNameJp: "所属会社郵便番号（ハイフンなし）",
      },
      {
        fieldName: "companyAddress1",
        fieldNameJp: "所属会社住所１（都道府県）",
      },
      {
        fieldName: "companyAddress2",
        fieldNameJp: "所属会社住所２（市区町村）",
      },
      {
        fieldName: "companyAddress3",
        fieldNameJp: "所属会社住所３（それ以降）",
      },
      {
        fieldName: "companyTell",
        fieldNameJp: "所属会社電話番号（ハイフンなし）",
      },
      { fieldName: "companyOccupation", fieldNameJp: "所属会社職種" },
      {
        fieldName: "experienceYearPresent",
        fieldNameJp: "経験年数現在の会社（年）",
      },
      {
        fieldName: "experienceMonthPresent",
        fieldNameJp: "経験年数現在の会社（月）",
      },
      { fieldName: "experienceYearTotal", fieldNameJp: "経験年数通算（年）" },
      { fieldName: "experienceMonthTotal", fieldNameJp: "経験年数通算（月）" },
      { fieldName: "licenceQualification", fieldNameJp: "免許・資格" },
      {
        fieldName: "licenceQualificationOthers",
        fieldNameJp: "免許・資格（その他）",
      },
      { fieldName: "skillTraining", fieldNameJp: "技能講習" },
      { fieldName: "skillTrainingOthers", fieldNameJp: "技能講習（その他）" },
      { fieldName: "specialTraining", fieldNameJp: "特別講習" },
      { fieldName: "specialTrainingOthers", fieldNameJp: "特別講習（その他）" },
      { fieldName: "hasKentaikyo", fieldNameJp: "建設業退職者共済の加入状況" },
      {
        fieldName: "hasCcus",
        fieldNameJp: "建設キャリアアップシステムへの技能者登録",
      },
      {
        fieldName: "ccusNumber",
        fieldNameJp: "建設キャリアアップシステムの技能者番号",
      },
      { fieldName: "isForeigner", fieldNameJp: "外国人労働者" },
      {
        fieldName: "residenceCardNumber1",
        fieldNameJp: "在留カードNo.（国籍）",
      },
      {
        fieldName: "residenceCardNumber2",
        fieldNameJp: "在留カードNo.（番号）",
      },
      {
        fieldName: "residenceCardNumber3",
        fieldNameJp: "在留カードNo.（資格区分）",
      },
      { fieldName: "stayPeriod", fieldNameJp: "在留期限" },
      {
        fieldName: "isSMEOwnerOrSingleMaster",
        fieldNameJp: "中小事業主・一人親方",
      },
      {
        fieldName: "insuranceGroupName",
        fieldNameJp: "政府労災保険加入団体名（民間保険不可）",
      },
      {
        fieldName: "insuranceNumber1",
        fieldNameJp: "政府労災保険労災保険No.（管轄）",
      },
      {
        fieldName: "insuranceNumber2",
        fieldNameJp: "政府労災保険労災保険No.（基幹番号）",
      },
      {
        fieldName: "insuranceNumber3",
        fieldNameJp: "政府労災保険労災保険No.（枝番号）",
      },
      {
        fieldName: "insuranceExpirationDate",
        fieldNameJp: "政府労災保険有効期限",
      },
      {
        fieldName: "medicalCheckupCategory",
        fieldNameJp: "健康診断の受診区分",
      },
      {
        fieldName: "medicalCheckupLatestDate",
        fieldNameJp: "直近の健康診断受診年月日",
      },
      { fieldName: "bloodPressureMax", fieldNameJp: "平常時血圧最高" },
      { fieldName: "bloodPressureMin", fieldNameJp: "平常時血圧最低" },
      { fieldName: "bloodType", fieldNameJp: "血液型" },
      { fieldName: "anamnesis", fieldNameJp: "既往症" },
      { fieldName: "anamnesisOthers", fieldNameJp: "既往症（その他）" },
      {
        fieldName: "canWorkWithoutProblems",
        fieldNameJp: "既往症ありの場合制限なく作業可能か",
      },
      { fieldName: "companyOrder", fieldNameJp: "所属会社次数" },
      { fieldName: "primaryCompanyName", fieldNameJp: "第一次事業者名" },
      { fieldName: "secondaryCompanyName", fieldNameJp: "第二次事業者名" },
      {
        fieldName: "secondaryCompanyNameKana",
        fieldNameJp: "第二次事業者名ふりがな",
      },
      { fieldName: "thirdCompanyName", fieldNameJp: "第三次事業者名" },
      {
        fieldName: "thirdCompanyNameKana",
        fieldNameJp: "第三次事業者名ふりがな",
      },
      { fieldName: "fourthCompanyName", fieldNameJp: "第四次事業者名" },
      {
        fieldName: "fourthCompanyNameKana",
        fieldNameJp: "第四次事業者名ふりがな",
      },
      { fieldName: "groupName", fieldNameJp: "所属班名" },
      { fieldName: "siteOccupation", fieldNameJp: "工種" },
      { fieldName: "workerType", fieldNameJp: "作業員区分" },
      { fieldName: "isForeman", fieldNameJp: "職長・安全衛生責任者" },
      { fieldName: "youthElderly", fieldNameJp: "年少者・高年齢者" },
      { fieldName: "notes", fieldNameJp: "特記事項" },
      { fieldName: "agreementOfYouth", fieldNameJp: "年少者の就労制限同意" },
      {
        fieldName: "agreementOfElderly",
        fieldNameJp: "高年齢者の就労制限同意",
      },
      { fieldName: "shootingProhibited", fieldNameJp: "撮影禁止" },
      { fieldName: "snsPostingProhibited", fieldNameJp: "SNS投稿禁止" },
      { fieldName: "disclosureProhibited", fieldNameJp: "情報開示禁止" },
      { fieldName: "discardCopyProhibited", fieldNameJp: "図面破棄複写禁止" },
      { fieldName: "observancePledge", fieldNameJp: "ルール守秘義務遵守誓約" },
      {
        fieldName: "hasChutaikyo",
        fieldNameJp: "中小企業退職金共済制度の加入状況",
      },
      { fieldName: "healthInsurance", fieldNameJp: "健康保険" },
      { fieldName: "pensionInsurance", fieldNameJp: "年金保険" },
      { fieldName: "employmentInsurance", fieldNameJp: "雇用保険" },
      {
        fieldName: "employmentInsuranceNumber",
        fieldNameJp: "雇用保険の被保険者番号（下4桁）",
      },
    ];

    const specificFields = {};
    this.state.checkListQuestions.forEach((val, i) => {
      specificFields[val] = this.state.checkListInputContents[i];
    });

    if (
      (this.state.isLoading === true) |
      (this.state.accessLevel === 1) |
      (this.state.accessLevel === 2) |
      (this.state.accessLevel === 4)
    ) {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() =>
                  this.props.navigate("/user-details", {
                    state: {
                      id: this.props.location.state.siteClearanceList.id,
                      freeidId: this.props.location.state.userList.id,
                    },
                  })
                }
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.state.userDetails.currentSiteName}：作業員の更新
              </Typography>
              {this.props.location.state.siteClearanceList.familyName &&
                this.props.location.state.siteClearanceList.firstName &&
                this.props.location.state.siteClearanceList.email &&
                !this.state.error &&
                !this.state.errorDate &&
                (this.state.accessLevel === 1) |
                  (this.state.accessLevel === 2) |
                  (this.state.accessLevel === 4) && (
                  <Button
                    type="submit"
                    onClick={this.handleSubmit}
                    variant="contained"
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 16,
                      fontWeight: "700",
                      color: "#383838",
                      backgroundColor: "#EBE91A",
                      height: 40,
                      borderRadius: 100,
                      paddingHorizontal: 18,
                      justifyContent: "space-between",
                      mr: 1,
                      "&:hover": {
                        backgroundColor: "#EBE91A",
                      },
                    }}
                    endIcon={
                      <img
                        src={require("../assets/images/rightarrow-on.png")}
                        width="20"
                        height="20"
                      />
                    }
                    disableElevation
                  >
                    更新する
                  </Button>
                )}
              {Boolean(
                !this.props.location.state.siteClearanceList.familyName |
                  !this.props.location.state.siteClearanceList.firstName |
                  !this.props.location.state.siteClearanceList.email |
                  !!this.state.error |
                  !!this.state.errorDate
              ) && (
                <Button
                  // type="submit"
                  // onClick={this.handleSubmit}
                  variant="contained"
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 16,
                    fontWeight: "700",
                    color: "#C8C8C8",
                    backgroundColor: "#E5E5E5",
                    height: 40,
                    borderRadius: 100,
                    paddingHorizontal: 18,
                    justifyContent: "space-between",
                    mr: 1,
                    "&:hover": {
                      backgroundColor: "#E5E5E5",
                    },
                  }}
                  endIcon={
                    <img
                      src={require("../assets/images/rightarrow-gray.png")}
                      width="20"
                      height="20"
                    />
                  }
                  disableElevation
                >
                  更新する
                </Button>
              )}
              <Button
                type="submit"
                onClick={() =>
                  this.props.navigate("/user-details", {
                    state: {
                      userList: this.props.location.state.userList,
                      id: this.props.location.state.siteClearanceList.id,
                      freeidId: this.state.freeidId,
                    },
                  })
                }
                variant="outlined"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 16,
                  fontWeight: "700",
                  color: "#383838",
                  backgroundColor: "#F9F9F9",
                  borderColor: "#C8C8C8",
                  borderWidth: 1,
                  height: 40,
                  borderRadius: 100,
                  paddingHorizontal: 18,
                  "&:hover": {
                    backgroundColor: "#F9F9F9",
                  },
                }}
                endIcon={
                  <img
                    src={require("../assets/images/remove-on.png")}
                    width="20"
                    height="20"
                  />
                }
                disableElevation
              >
                キャンセル
              </Button>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationDrawer topIndex={2} bottomIndex={null} />
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 3,
            }}
          >
            <LoadingProgress isLoading={this.state.isLoading} />
            <Grid item xs={12} mb={2}>
              <Toolbar />
              <Box component="form" sx={{ width: 480 }}>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#8D8D8D",
                    mb: 2.5,
                    mr: 1,
                  }}
                >
                  所長・工事担当者
                </Typography>
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: 3,
                  }}
                >
                  <Checkbox
                    checked={this.state.isChief}
                    onChange={this.handleChange}
                    inputProps={{ "data-testid": "isChief-checkbox" }}
                  />
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#8D8D8D",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    名前
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    marginTop: 0,
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 18,
                      fontWeight: "400",
                      color: "#383838",
                      mt: 1,
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    {this.props.location.state.freeidId
                      ? this.state.familyName + this.state.firstName
                      : this.props.location.state.siteClearanceList.familyName +
                        this.props.location.state.siteClearanceList.firstName}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#8D8D8D",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    フリガナ
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    marginTop: 0,
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 18,
                      fontWeight: "400",
                      color: "#383838",
                      mt: 1,
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    {this.props.location.state.freeidId
                      ? this.state.familyNameKana + this.state.firstNameKana
                      : this.props.location.state.siteClearanceList
                          .familyNameKana +
                        this.props.location.state.siteClearanceList
                          .firstNameKana}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#8D8D8D",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    会社名
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 18,
                    fontWeight: "400",
                    color: "#383838",
                    mt: 1,
                    mb: 2,
                    mr: 1,
                  }}
                >
                  {this.props.location.state.freeidId
                    ? this.state.companyName
                    : this.props.location.state.siteClearanceList.companyName}
                </Typography>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#8D8D8D",
                      mb: 0,
                      mr: 1,
                    }}
                  >
                    メールアドレス
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#DD3900",
                    mb: 0,
                  }}
                >
                  {this.state.error}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 18,
                    fontWeight: "400",
                    color: "#383838",
                    mt: 1,
                    mb: 2,
                    mr: 1,
                  }}
                >
                  {this.props.location.state.freeidId
                    ? this.state.email
                    : this.props.location.state.siteClearanceList.email}
                </Typography>
                <Stack
                  direction="row"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 15,
                      fontWeight: "400",
                      color: "#8D8D8D",
                      mb: 2.5,
                      mr: 1,
                    }}
                  >
                    入場可能期間
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: 3,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 18,
                      fontWeight: "400",
                      color: "#383838",
                      marginBottom: 0,
                      marginRight: 4,
                    }}
                  >
                    開始日
                  </Typography>
                  <DatePicker
                    value={this.state.startDate}
                    onChange={this.handleStartDateChange}
                  />
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 18,
                      fontWeight: "400",
                      color: "#383838",
                      marginBottom: 0,
                      marginRight: 4,
                    }}
                  >
                    終了日
                  </Typography>
                  <DatePicker
                    value={this.state.endDate}
                    minDate={this.state.startDate}
                    onChange={this.handleEndDateChange}
                  />
                  {this.state.endDate ? (
                    <Button
                      onClick={this.handleDateDelete}
                      edge="start"
                      sx={{
                        borderRadius: 0,
                        height: 24,
                        borderColor: "#E5E5E5",
                        color: "#DD3900",
                      }}
                    >
                      終了日をリセットする
                    </Button>
                  ) : (
                    ""
                  )}
                </Stack>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 12,
                    fontWeight: "500",
                    color: "#DD3900",
                    mb: 4,
                  }}
                >
                  {this.state.errorDate}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Regular",
                    fontSize: 15,
                    fontWeight: "400",
                    color: "#8D8D8D",
                    mb: 2.5,
                    mr: 1,
                  }}
                >
                  受入教育実施年月日
                </Typography>
                <Stack
                  direction="row"
                  sx={{
                    marginBottom: 3,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Regular",
                      fontSize: 18,
                      fontWeight: "400",
                      color: "#383838",
                      marginBottom: 0,
                      marginRight: 4,
                    }}
                  >
                    年月日
                  </Typography>
                  <DatePicker
                    value={this.state.educationDate}
                    onChange={this.handleEducationDateChange}
                  />
                </Stack>
                {fieldList.map((obj, index) => (
                  <Box>
                    <Stack
                      direction="row"
                      sx={{
                        mb: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Regular",
                          fontSize: 15,
                          fontWeight: "400",
                          color: "#8D8D8D",
                          mb: 0,
                          mr: 1,
                        }}
                      >
                        {obj.fieldNameJp}
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        height: "100%",
                        mt: 1,
                        mb: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Regular",
                          fontSize: 18,
                          fontWeight: "400",
                          color: "#383838",
                        }}
                      >
                        {this.handleStringChange(this.state[obj.fieldName])}
                      </Typography>
                    </Box>
                  </Box>
                ))}
                {Object.keys(specificFields).map((key) => (
                  <Box>
                    <Stack
                      direction="row"
                      sx={{
                        mb: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Regular",
                          fontSize: 15,
                          fontWeight: "400",
                          color: "#8D8D8D",
                          mb: 0,
                          mr: 1,
                        }}
                      >
                        {key}
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        height: "100%",
                        mt: 1,
                        mb: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Regular",
                          fontSize: 18,
                          fontWeight: "400",
                          color: "#383838",
                        }}
                      >
                        {specificFields[key]}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Box>
          {!!this.state.snackbar && (
            <ValidationSnackBar
              open={this.state.snackbar !== null}
              onClose={this.handleCloseSnackbar}
              snackbar={this.state.snackbar}
            />
          )}
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              backgroundColor: "#F9F9F9",
              borderBottom: 1,
              borderColor: "#E5E5E5",
            }}
            elevation={0}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                onClick={() => this.props.navigate("/user-list")}
                sx={{
                  mr: 2,
                  pr: 2,
                  pl: 1,
                  borderRadius: 0,
                  borderRight: 1,
                  height: 64,
                  borderColor: "#E5E5E5",
                  color: "#383838",
                }}
              >
                <img
                  src={require("../assets/images/leftarrow-on.png")}
                  width="16"
                  height="16"
                />
              </IconButton>
              <Typography
                component="div"
                sx={{
                  fontFamily: "Regular",
                  fontSize: 21,
                  fontWeight: "700",
                  color: "#383838",
                  flexGrow: 1,
                }}
              >
                {this.state.userDetails.currentSiteName}：作業員の更新
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <NavigationDrawer topIndex={2} bottomIndex={null} />
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "#F9F9F9",
              height: "100%",
              minHeight: "100vh",
              p: 0,
            }}
          >
            <Grid item xs={12} mb={2}>
              <Toolbar />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 16,
                paddingBottom: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Regular",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#383838",
                  mb: 0,
                  mr: 1,
                }}
              >
                アクセス権限がありません
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  }
}

function EditUserScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <InputComponent {...props} navigate={navigate} location={location} />;
}

export default EditUserScreen;
