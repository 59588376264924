import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import getRoleLevel from "../components/utils/getRoleLevel";
import fetchUser from "../components/queries/FetchUser";
import fetchCompany from "../components/queries/FetchCompany";
import fetchSiteRoles from "../components/queries/FetchSiteRoles";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const drawerWidth = 280;

const iconTop = [
  <img src={require("../assets/images/menu-on.png")} width="16" height="16" />,
  <img src={require("../assets/images/site-on.png")} width="16" height="16" />,
  <img src={require("../assets/images/user-on.png")} width="16" height="16" />,
  <img
    src={require("../assets/images/settings-on.png")}
    width="18"
    height="16"
  />,
  <img
    src={require("../assets/images/camera-on.png")}
    width="16"
    height="16"
  />,
  <img
    src={require("../assets/images/qrcode-on.png")}
    width="16"
    height="16"
  />,
];

const iconBottom = [
  <img
    src={require("../assets/images/settings-on.png")}
    width="18"
    height="16"
  />,
  <img src={require("../assets/images/user-on.png")} width="16" height="16" />,
];

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function NavigationMenu({
  topIndex,
  bottomIndex,
  drawerList,
  navigationList,
}) {
  const location = useLocation();
  if (location.state?.topIndex) {
    topIndex = location.state.topIndex;
  }
  const [userInformation, setUserInformation] = useState(null);
  const [profileList, setProfileList] = useState(null);
  const [roleLevel, setRoleLevel] = useState(null);
  const [selectedTopIndex, setSelectedTopIndex] = useState(topIndex);
  const [selectedBottomIndex, setSelectedBottomIndex] = useState(bottomIndex);
  const { height, width } = useWindowDimensions();

  const navigation = useNavigate();

  useEffect(() => {
    getMenuList();
  }, []);

  async function getMenuList() {
    try {
      let roleLevel;
      roleLevel = await getRoleLevel();

      const userInfo = await Auth.currentAuthenticatedUser();
      const condition = {
        id: userInfo.attributes.sub,
      };
      const companyCondition = {
        id: userInfo.attributes["custom:company_id"],
      };
      const getUser = await fetchUser(condition);
      if (typeof roleLevel !== "number") {
        const filter = {
          and: [
            {
              siteCode: {
                eq: getUser.currentSiteId,
              },
            },
            {
              userId: {
                eq: userInfo.attributes.sub,
              },
            },
          ],
        };
        const listSiteRoles = await fetchSiteRoles(filter);
        roleLevel = listSiteRoles[0].roleLevel;
      }

      const userInformation = [];
      const profileList = [];

      if ((roleLevel === 1) | (roleLevel === 2)) {
        const companyData = await fetchCompany(companyCondition);
        userInformation.push(companyData.companyName);
        profileList.push("/edit-company");
      }
      userInformation.push(`${getUser.lastName} ${getUser.firstName}`);
      profileList.push("/my-account");

      setUserInformation(userInformation);
      setProfileList(profileList);
      setRoleLevel(roleLevel);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            borderTop: 4,
            borderTopColor: "#EBE91A",
            borderRightColor: "#E5E5E5",
            backgroundColor: "#F9F9F9",
          },
        }}
        variant="permanent"
        open
      >
        <Toolbar>
          <img
            src={require("../assets/images/sagaseru-logo.png")}
            width="76"
            height="18"
          />
        </Toolbar>
        <Divider variant="middle" />
        {drawerList && navigationList && (
          <List disablePadding sx={{ paddingBottom: (height - 625) / 8 }}>
            {drawerList.map((text, index) => (
              <ListItem key={text}>
                <ListItemButton
                  selected={selectedTopIndex === index}
                  onClick={() => {
                    setSelectedTopIndex(index);
                    setSelectedBottomIndex(null);
                    navigation(navigationList[index]);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 35,
                    }}
                  >
                    {iconTop[index]}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    primaryTypographyProps={{
                      fontSize: 16,
                      fontWeight: "500",
                      color: "#383838",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        <Divider variant="middle" />
        {userInformation && (
          <List disablePadding>
            {userInformation.map((text, index) => (
              <ListItem key={text}>
                <ListItemButton
                  selected={selectedBottomIndex === index}
                  onClick={() => {
                    setSelectedTopIndex(null);
                    setSelectedBottomIndex(index);
                    navigation(profileList[index]);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 35,
                    }}
                  >
                    {iconBottom[index]}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    primaryTypographyProps={{
                      fontSize: 16,
                      fontWeight: "500",
                      color: "#383838",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        <Divider variant="middle" />
        {["ログアウト"] && (
          <List disablePadding>
            {["ログアウト"].map((text, index) => (
              <ListItem key={text}>
                <ListItemButton
                  onClick={() => {
                    Auth.signOut();
                    navigation("/sign-in");
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 35,
                    }}
                  >
                    {iconBottom[index]}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    primaryTypographyProps={{
                      fontSize: 16,
                      fontWeight: "500",
                      color: "#383838",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Drawer>
    </ThemeProvider>
  );
}

const theme = createTheme({
  typography: {
    fontFamily: "Regular",
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          marginLeft: 8,
          marginTop: -2,
          marginBottom: -2,
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          "&& .Mui-selected, && .Mui-selected:hover": {
            backgroundColor: "#ECECEC",
            borderRadius: 100,
            marginHorizontal: 16,
            "& .MuiListItemText-primary": {
              fontWeight: "700",
            },
          },
          paddingTop: 8,
          paddingBottom: 0,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {},
          "&:hover": {
            backgroundColor: "#ECECEC",
            borderRadius: 100,
            marginHorizontal: 16,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#E5E5E5",
        },
        middle: {
          marginRight: 38,
          marginLeft: 32,
        },
      },
    },
  },
});
