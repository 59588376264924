import { Auth } from "aws-amplify";
import fetchCompanyByVendor from "../queries/FetchCompanyByVendor";
import fetchByVendorCode from "../queries/FetchByVendorCode";

const validateVendorCode = async (vendorCode) => {
  let isDuplicate = false;
  const userInfo = await Auth.currentAuthenticatedUser();

  const getVendor = await fetchByVendorCode({
    vendorCode,
  });
  if (getVendor.items.length > 0) {
    await Promise.all(
      getVendor.items.map(async (vendor) => {
        const companyByVendor = await fetchCompanyByVendor({
          vendorId: vendor.id,
          companyId: {
            eq: userInfo.attributes["custom:company_id"],
          },
        });
        if (companyByVendor.items.length > 0) {
          isDuplicate = true;
        }
      })
    );
  }
  return isDuplicate;
};

export default validateVendorCode;
